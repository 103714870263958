import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import DatePicker from 'components/controls/DatePicker';
import '../../style/scss/button.scss'

interface DateNavigationButtonProps {
    currentDate: Date | Date[];
    isDayView?: boolean | undefined;
    onNavigate: (action: 'prev' | 'next' | 'date', date?: Date) => void;
}

const DateNavigationButton: React.FC<DateNavigationButtonProps> = ({ 
    currentDate, isDayView = false, onNavigate
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const [isOpen, setIsOpen] = useState(false);
    const [preventReopening, setPreventReopening] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    // Add and remove event listener for handleClickOutside function
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    // Format the display date
    const formatDisplayDate = (dates: Date | Date[], isDayView: boolean): string => {

        // Show month before date for us locale, otherwise show month after date
        const formatForLocale = (date: Date, monthName: string): string => {
            return userLocale === 'en-US' ? `${monthName} ${date.getDate()}` : `${date.getDate()} ${monthName}`;
        };

        // Execute fomatting for single day date
        if (isDayView) {
            if (!(dates instanceof Date)) {
                if (Array.isArray(dates) && dates.length > 0) {
                    dates = dates[0];
                } else {
                    return '';
                }
            }

            // Determine day name
            const dayName = dates.toLocaleDateString(userLocale, { weekday: 'short' });

            // Determine month name
            const monthName = t(`date_time.months.short.${dates.getMonth()}`);

            // Determine ISO week number to show behind date
            const weekNumber = getISOWeekNumber(dates);
            const weekName = t('date_time.general.week');

            // Configure date formatting
            const dayDateFormatting = `${dayName} ${formatForLocale(dates, monthName)} (${weekName} ${weekNumber})`

            return dayDateFormatting
        }

        // Execute formatting for date periods (e.g. Mar 28 - Apr 4)
        if (Array.isArray(dates)) {
            // Return if no dates are provided
            if (dates.length === 0) return '';

            // Define first and last dates from the given period
            const firstDate = dates[0];
            const lastDate = dates[dates.length - 1];
    
            // Determine ISO week number to show behind date
            const firstWeek = getISOWeekNumber(firstDate);

            // Check if the given dates are within the same month or same week
            const sameMonth = firstDate.getMonth() === lastDate.getMonth();
            const sameWeek = firstWeek === getISOWeekNumber(lastDate);
    
            // Get month and week names from the translation files
            const firstDateMonthName = t(`date_time.months.short.${firstDate.getMonth()}`);
            const lastDateMonthName = t(`date_time.months.short.${lastDate.getMonth()}`);
            const weekName = t('date_time.general.week');
    
            // Format output for dates within the same week, to show the week number
            if (sameWeek) {
                if (sameMonth) {
                    // Format differently based on locale: "Apr 4 - 11" or "4 - 11 apr"
                    return userLocale === 'en-US' ?
                        `${firstDateMonthName} ${firstDate.getDate()} - ${lastDate.getDate()} (${weekName} ${firstWeek})` :
                        `${firstDate.getDate()} - ${lastDate.getDate()} ${firstDateMonthName} (${weekName} ${firstWeek})`;
                } else {
                    // Format if dates are not in the same month. Either: "Mar 28 - Apr 4" or "28 maa - 4 apr"
                    return `${formatForLocale(firstDate, firstDateMonthName)} - ${formatForLocale(lastDate, lastDateMonthName)} (${weekName} ${firstWeek})`;
                }
            } else {
                if (sameMonth) {
                    // Format full month: "Mar 1 - 30" or "1 - 30 maa"
                    const monthEndDay = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0).getDate();
                    return userLocale === 'en-US' ? 
                        `${firstDateMonthName} 1 - ${monthEndDay}` : 
                        `1 - ${monthEndDay} ${firstDateMonthName}`;
                } else {
                    // Format dates from different months: "Sep 6 - Mar 5" or "6 sep - 5 maa"
                    return `${formatForLocale(firstDate, firstDateMonthName)} - ${formatForLocale(lastDate, lastDateMonthName)}`;
                }
            }

        // Execute formatting of single dates (e.g. "Apr 4" or "4 apr")
        } else if (dates instanceof Date) {
            const monthName = t(`date_time.months.short.${dates.getMonth()}`);
            return formatForLocale(dates, monthName);
        }
    
        return '';
    };
    
    // Get the ISO weeknumber based on the given date
    function getISOWeekNumber(date: Date): number {
        const dateCopy = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        dateCopy.setDate(dateCopy.getDate() + 3 - (dateCopy.getDay() + 6) % 7);
        const yearStart = new Date(dateCopy.getFullYear(), 0, 4);
        return Math.ceil(((dateCopy.getTime() - yearStart.getTime()) / 86400000 - 3 + (yearStart.getDay() + 6) % 7) / 7) + 1;
    }

    // If date picker is closed by clicking on the button, prevent reopening by the button itself
    // this is needed because of the closing mechanism in the date picker itself. Clicking on the button
    // will reopen the datepicker again.
    const handleDateButtonClick = () => {
        if (isOpen) {
            setIsOpen(false);
            setPreventReopening(true);
        } else if (preventReopening) {
            setPreventReopening(false);
        } else {
            setIsOpen(true);
        }
    };

    // Handle click outside to close the date picker without prevent reopening setting
    const handleOutsideClick = (event: MouseEvent) => {
        let targetElement = event.target as HTMLElement;
    
        // Loops through the html elements to check if the click was on the element with 'datepicker' classname 
        while (targetElement) {
            if (targetElement.classList.contains('datepicker') || targetElement === buttonRef.current) {
                // If clicked within the datepicker or button, do nothing
                return;
            }
            targetElement = targetElement.parentElement!;
        }
    
        // If the loop completed without return, the click was outside the datepicker and button so close it
        setIsOpen(false);
    };

    // Close the datepicker and prevent reopening if closing was called by clicking on the button
    const closeDatePicker = () => {
        setIsOpen(false);
        setPreventReopening(true);
    };

    return (
        <div className='date-navigate-container'>
            <div className={`date-navigate-button ${isOpen && 'active'}`}>
                <button className='previous'
                        onClick={() => { setPreventReopening(false); onNavigate('prev'); }}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <button className='date'
                        onClick={handleDateButtonClick}
                        ref={buttonRef}>
                    {formatDisplayDate(currentDate, isDayView)}
                </button>
                <button className='next'
                        onClick={() => { setPreventReopening(false); onNavigate('next');}}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            </div>
            {isOpen &&
                <DatePicker
                    selectedDate={currentDate}
                    onDayClick={(newDate) => { onNavigate('date', newDate); setIsOpen(false); }}
                    closeDatePicker={closeDatePicker} />
            }
        </div>
    )
}

export default DateNavigationButton;