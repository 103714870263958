import React, { useContext, useEffect, useState } from 'react';
import { FieldOption, SearchSelectFieldType, FieldData } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import SearchSelect from '../basefields/SearchSelect';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/forms.scss';

const SearchSelectField: React.FC<SearchSelectFieldType & { data: FieldData, viewKey: string }> = ({ 
    viewKey, query, postEndpoint, placeholder, objectName, name, data, selectionFormat = 'name', 
    optionFormat, label, helperText, tooltipText, viewInEditMode, onlyAddUniqueItems = false, 
    allowTextualInput = false, isTextualValue = false, disableAddNew = false, disabled, alignment,
    isEditable, viewModeLinkUrl, addNewItem, additionalSubmitDataField, refetchData, handleSubmit
}) => {
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { setUnsavedChanges, setPreventClosing } = useGlobalContext();
    const [ currentId, setCurrentId ] = useState(null);
    const [ currentValue, setCurrentValue ] = useState<string>('');

    // Set the current selected id and current value from the received data
    useEffect(() => {
        if (data[name] && data[name].id && data[name].id !== null) {
            setCurrentId(data[name].id)
            setCurrentValue(data[name][selectionFormat])
        }
    }, [data]);

    // Handle select change
    const handleSelectChange = (selectedOption: FieldOption, newItem?: string) => {

        // Configure the submit data
        const submitData = {
            [name]: selectedOption.id,
            ...(additionalSubmitDataField && { [additionalSubmitDataField]: selectedOption[additionalSubmitDataField] }),
        }

        // If a new item is created, handle submit directly afterwards
        if (newItem === 'new' && handleSubmit) {
            handleSubmit(submitData);
        }

        // If an existing item is selected, set updated data and unsaved changes
        else {
            // Set the updated data
            setUpdatedData({ ...updatedData, ...submitData });

            // Set unsaved changes
            if (selectedOption !== undefined) {
                setUnsavedChanges(viewKey, true)
            }
        }
    }

    // Remove unlinked item from the updated data
    const handleUnlink = () => {
        setCurrentId(null);
        setCurrentValue('');
        setUpdatedData({ ...updatedData, [name]: null });
    }

    // Handle setting the prevent closing state, or enabling it again
    const handleSetPreventClosing = (shouldPreventClose: boolean) => {
        setPreventClosing(viewKey, shouldPreventClose)
    }

    // Determine the link url based on a given link function or fixed link url string
    const configureLinkUrl = viewModeLinkUrl && data && data[name] ? viewModeLinkUrl.function ? viewModeLinkUrl.function(data[name]) : `${viewModeLinkUrl.baseUrl}/${data[name].id}` : null;

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <SearchSelect
                    type='searchselect'
                    name={name}
                    data={data}
                    placeholder={placeholder}
                    value={currentId}
                    query={query}
                    postEndpoint={postEndpoint}
                    objectName={objectName} 
                    onlyAddUniqueItems={onlyAddUniqueItems}
                    disableAddNew={disableAddNew} 
                    allowTextualInput={allowTextualInput}
                    onSelect={handleSelectChange}
                    selectionFormat={selectionFormat}
                    optionFormat={optionFormat} 
                    onUnlink={handleUnlink}
                    addNewItem={addNewItem}
                    onPreventClose={(shouldPreventClose) => handleSetPreventClosing(shouldPreventClose)}
                    refetchData={() => {if (refetchData) refetchData()}}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={currentValue}
                    type='link'
                    linkUrl={configureLinkUrl}
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default SearchSelectField;