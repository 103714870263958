import React, { useContext, useState, useEffect } from 'react';
import { FieldData, RadioFieldType } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

/*
 * RadioField.tsx
 * Handles the logic and functions of a radiobutton. 
 */

const RadioField: React.FC<RadioFieldType & { data: FieldData, viewKey: string }> = ({
    viewKey, name, label, data, options, defaultValue, tooltipText, alignment, disabled = false, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { setUnsavedChanges, errorMessages } = useGlobalContext();
    const hasRightCheck = useAllowedRight;
    const [selectedValue, setSelectedValue] = useState<string | null>(((data && data[name]?.toString()) ?? defaultValue) || 'false');
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set the current value when loading the field
    useEffect(() => {
        if (data && data[name] !== undefined) {
            // If a value is loaded from the database, show this
            if (data[name] !== null) {
                setSelectedValue(data[name].toString());
            } else {
                setSelectedValue(null)
            }
        } else if (defaultValue) {
            // If no value is loaded from the database, show the default value
            setSelectedValue(defaultValue)
            setUpdatedData({ ...updatedData, [name]: defaultValue })
        }
    }, [data, name, defaultValue, setUpdatedData]);

    // Handle radio change
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        setUpdatedData({ ...updatedData, [name]: newValue });

        // Find the initial value of the field
        const initialValue = data[name] !== undefined && data[name] !== null ? data[name].toString() : defaultValue || null;

        // Only set unsaved changes to true if the new value is different from the initial value
        if (newValue !== initialValue) {
            setUnsavedChanges(viewKey, true);
        } else {
            setUnsavedChanges(viewKey, false);
        }
    };

    // Get the error message
    const errorMessage = errorMessages[name];

    const content = (
        <div className={`radio-field ${disabled ? 'disabled' : ''}`}>
            {label && 
                <label htmlFor={name} className='radio-label'>
                    {t(label)}
                    {tooltipText && 
                        <span className="tooltip-icon">
                            <FontAwesomeIcon icon={faCircleQuestion} />
                            <span className="tooltip">{t(tooltipText)}</span>
                        </span>
                    }
                </label>
            }
            <div className='radio-options-container'>
                {options.map((option, index) => (
                    <div key={index} className='radio-option'>
                        <input
                            type="radio"
                            id={`${name}-${option.value}`}
                            name={name}
                            value={option.value.toString()}
                            checked={selectedValue === option.value.toString()}
                            onChange={handleRadioChange}
                            disabled={disabled}
                        />
                        <label htmlFor={`${name}-${option.value}`} className="radio-label">{t(option.name)}</label>
                    </div>
                ))}
            </div>
            {errorMessage && 
                <div className='error-message'>
                    {t(errorMessage, { defaultValue: errorMessage })}
                </div>
            }
        </div> 
    );

    return (
        <div>
            { alignment === 'horizontal'  
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
};

export default RadioField;