import React from 'react';
import { FieldDescriptionType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import '../../../../style/scss/live-edit.scss';
import '../../../../style/scss/forms.scss';

const FieldDescription: React.FC<FieldDescriptionType> = ({ description }) => {
    const { t } = useTranslation();

    return (
        <div className='field-description'>
            <p className='description'>
                {t(description)}
            </p>
        </div>
    );
};

export default FieldDescription;