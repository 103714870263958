import React, { useEffect, useMemo, useState } from 'react';
import { FieldType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'services/utils/dateTimeUtils';
import { useSettings } from 'services/settings/SettingsContext';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import FormFieldContext from 'components/forms/FormFieldContext';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { saveData } from 'services/api/saveData';
import { SubscriptionData } from '../BillingTypes';

const CancelSubscriptionModal: React.FC<{ viewKey?: string, subscription: SubscriptionData }> = ({ 
    viewKey, subscription 
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const { setFloatingAlert, errorMessages, setErrorMessages } = useGlobalContext();
    const { revealModal, closeModal, modalStack } = useModal();
    const [editing, setEditing] = useState(true);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const topModal = modalStack[modalStack.length - 1];

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            type: 'description',
            description: 'billing.cancellation.cancellation_header',
        }, 
        {
            type: 'header',
            header: 'billing.cancellation.cancellation_reason_label'
        }, 
        { 
            type: 'radio', 
            name: 'cancellation_reason', 
            options: [
                { value: 'too_expensive', name: 'billing.cancellation.reason.too_expensive' },
                { value: 'missing_features', name: 'billing.cancellation.reason.missing_features' },
                { value: 'too_complex', name: 'billing.cancellation.reason.too_complex' },
                { value: 'low_usage', name: 'billing.cancellation.reason.low_usage' },
                { value: 'technical_issues', name: 'billing.cancellation.reason.technical_issues' },
                { value: 'bad_support', name: 'billing.cancellation.reason.bad_support' },
                { value: 'business_closed', name: 'billing.cancellation.reason.business_closed' },
                { value: 'found_alternative', name: 'billing.cancellation.reason.found_alternative' },
                { value: 'temporary_pause', name: 'billing.cancellation.reason.temporary_pause' },
                { value: 'other', name: 'billing.cancellation.reason.other' },
            ]
        },
        {
            type: 'textarea',
            name: 'cancellation_remarks',
            placeholder: 'billing.cancellation.cancellation_remark_placeholder',
            rows: 3,
        },
        {
            type: 'header',
            header: "billing.cancellation.cancellation_confirmation_header"
        },
        {
            name: 'cancellation_confirmation',
            label: 'billing.cancellation.cancellation_confirmation_label',
            type: 'text',
            placeholder: 'billing.cancellation.cancellation_confirmation_placeholder',
        },
        {
            type: 'description',
            description: t('billing.cancellation.cancellation_footer', { subscriptionEndDate: formatDate(subscription.end_date!, userLocale, 'writtenMonth') }),
        },  
    ];

    // Reveal the modal on first render
    useEffect(() => {
        revealModal();
    }, []);

    // Generate the field component for the form
    const fieldComponents = useMemo(() => {
        if (!viewKey) return;
        return generateFieldComponents(
            viewKey, 
            fields, 
            undefined, 
            undefined, 
            subscription, 
            updatedData
        );
    }, [subscription, updatedData]);
    
    // Handle the submit of the updated billing details
    const handleSubmit = async () => {
        try {            
            // Start the button loader
            setButtonLoader(true);

            // Initialize object to store the row errors
            const rowErrors: Record<string, string> = {};

            // Validate the required fields
            for (const field of fields) {
                if ('name' in field && field.name !== undefined) {

                    // Show error when cancellation reason is not given
                    if (field.name === 'cancellation_reason' && !updatedData.cancellation_reason) {
                        rowErrors['cancellation_reason'] = 'validation.general.required'
                    };

                    // Handle cancellation remarks validation
                    if (field.name === 'cancellation_remarks') {

                        // Show error when cancellation reason is other and no remarks are given
                        if (updatedData.cancellation_reason === 'other' && !updatedData.cancellation_remarks) {
                            rowErrors['cancellation_remarks'] = 'validation.general.required'
                        }
                    };

                    // Handle cancellation confirmation validation
                    if (field.name === 'cancellation_confirmation') {

                        // Show error when cancellation confirmation is not given
                        if (!updatedData.cancellation_confirmation) {
                            rowErrors['cancellation_confirmation'] = 'validation.general.required'
                        } else if (updatedData.cancellation_confirmation !== 'OPZEGGEN' && updatedData.cancellation_confirmation !== 'CANCEL') {
                            rowErrors['cancellation_confirmation'] = 'validation.subscription.invalid_cancellation_confirmation'
                        };
                    };
                };
                
                if (Object.keys(rowErrors).length > 0) {
                    // Update the error messages state
                    setErrorMessages(rowErrors);

                    // Stop the button loader
                    setButtonLoader(false);

                    return;
                }
            };

            // Post the data in the backend
            const response = await saveData({ 
                apiUrl: `cancel_subscription/${subscription.id}`, 
                method: 'patch', 
                data: updatedData
            })

            // Handle successful response
            if (response?.status === 200) {

                // Stop the button loader
                setButtonLoader(false);

                // Show success alert
                setFloatingAlert({ 'type': 'success' })

                // Call the on success callback
                if (topModal?.props?.onSuccess) {
                    topModal.props.onSuccess(response.data);
                };

                // Close the modal
                closeModal()
            }
        } catch (error) {
            console.error("Error on submit", error);
            setButtonLoader(false);
        };        
    };

    // Close the modal
    const handleClose = () => {
        setErrorMessages({});
        setButtonLoader(false);
        closeModal();
    };

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData }}>
            {(errorMessages.general) &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            <form className='formset add-edit-form'>
                {fieldComponents}
                <div className='buttons-right'>
                    <SecondaryButton
                        onClick={handleClose} 
                        label='general.cancel'
                        size="small"
                    />
                    <PrimaryButton
                        type="button" 
                        label='billing.cancellation.cancellation_submit_button_label'
                        onClick={handleSubmit}
                        size="small"
                        onlyViewRestriction={true}
                        loading={buttonLoader}
                        customClass='danger'
                    />
                </div>
            </form>
        </FormFieldContext.Provider>
    );
};

export default CancelSubscriptionModal;