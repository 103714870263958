import React from 'react';
import { useTranslation } from 'react-i18next';
import { packageOptions } from './packageOptions';
import { SubscriptionData } from './BillingTypes';

interface PaymentDetailsCardProps {
    currentSubscription: SubscriptionData;
    updatedSubscription: SubscriptionData;
}

const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = ({ 
    currentSubscription, updatedSubscription 
}) => {
    const { t } = useTranslation();

    // Calculate the total monthly or yearly recurring price
    const calculateRecurringPrice = (subscriptionPackage: string) => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === subscriptionPackage);

        // Convert the seats into a number
        const seatsNumber = parseInt(updatedSubscription.seats);

        // Initialize the recurring subscription price
        let recurringPrice = 0;

        // Calculate the total recurring price based on the duration and seats
        if (selectedPackage) {
            if (updatedSubscription.duration === 'yearly') {
                recurringPrice = selectedPackage.yearlyPrice * seatsNumber * 12;
            }

            if (updatedSubscription.duration === 'monthly') {
                recurringPrice = selectedPackage.monthlyPrice * seatsNumber;
            }
        };

        return recurringPrice;
    };

    // Calculate the remaining fraction of the current subscription period
    const calculateSubscriptionPeriodFraction = (subscriptionEndDate: string, duration: string) => {

        console.log(subscriptionEndDate)

        // Convert the subscription end date to a date object
        const endDate = new Date(subscriptionEndDate);

        // Initialize and calculate the start date based on the given duration
        const startDate = new Date(endDate);

        // In case of yearly duration, calculate the start date back to one year ago
        if (duration === 'yearly') {
            startDate.setFullYear(startDate.getFullYear() - 1);
        };

        // In case of monthly duration, calculate the start date back to one month ago
        if (duration === 'monthly') {
            startDate.setMonth(startDate.getMonth() - 1);
        };

        console.log("startDate", startDate)
        console.log("endDate", endDate)

        // Set the update date to today
        const upgradeDate = new Date();

        // Subtract one day from the upgrade date, to make it equal to the subscription period
        upgradeDate.setDate(upgradeDate.getDate() - 1);

        // To calculate the difference right, set the time equal to the start- and end dates time
        upgradeDate.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), 0);

        // Convert the dates to milliseconds
        const endDateMilliseconds = endDate.getTime();
        const startDateMilliseconds = startDate.getTime();
        const upgradeDateMilliseconds = upgradeDate.getTime();

        // Calculate the amount of milliseconds in a day
        const dayMilliseconds = 1000 * 60 * 60 * 24;

        // Calculate the total days of the total (original) subscription
        const totalDays = Math.round(endDateMilliseconds - startDateMilliseconds) / dayMilliseconds;

        console.log("totalDays", totalDays)

        // Calculate the remaining days of the original subscription period
        const remainingDays = Math.round(endDateMilliseconds - upgradeDateMilliseconds) / dayMilliseconds;

        console.log("remainingDays", remainingDays)

        // Calculate the remaining subscription period fraction
        const remainingFraction = remainingDays / totalDays;

        console.log("remainingFraction", remainingFraction)

        return remainingFraction;
    };

    console.log(currentSubscription)

    return (
        <div className='card payment-summary'>
            <h3>{t('billing.general.payment_summary_header')}</h3>
            <div className='payment-details'>
                <div className='price-detail-row'>
                    <p>
                        Betaling per jaar
                    </p>
                    <p className='price-amount'>
                        <span className='currency-icon'>€ </span>
                        {calculateRecurringPrice(updatedSubscription.package)}
                    </p>
                </div>
                {!currentSubscription.is_trial && currentSubscription.end_date && (
                    <div className='price-detail-row'>
                        <p>
                            Reeds betaald {currentSubscription.package} pakket
                        </p>
                        <p className='price-amount'>
                            <span className='currency-icon'>-€ </span>
                            {calculateSubscriptionPeriodFraction(currentSubscription.end_date, currentSubscription.duration) * calculateRecurringPrice(currentSubscription.package)}
                        </p>
                    </div>
                )}
                <div className='price-detail-row'>
                    <p>
                        Belasting (21% Btw)
                    </p>
                    <p className='price-amount'>
                        <span className='currency-icon'>€ </span>
                        {calculateRecurringPrice(updatedSubscription.package) * 0.21}
                    </p>
                </div>
                <div className='price-detail-row'>
                    <p>
                        Totale jaarlijkse terugkerende betaling
                    </p>
                    <p className='price-amount'>
                        <span className='currency-icon'>€ </span>
                        {calculateRecurringPrice(updatedSubscription.package) * 1.21}
                    </p>
                </div>
            </div>     
        </div>
    )
};

export default PaymentDetailsCard;