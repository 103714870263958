import React, { useEffect, useState } from 'react';
import { EventsByResourceType, EventsListType, EventType, ResourceGroupType, SchedulerDraggableId, SchedulerDraggingPlaceholder, SchedulerDroppableId, SchedulingViewType, TimeBlock } from './SchedulingTypes';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { DragDropEvent, useDragDropContext } from 'services/dragdrop/DragDropContext';
import { handleDragJobInboxJobToResource } from './functions/handleDragJobInboxJobToResource';
import { handleEventDrag } from './functions/handleEventDrag';
import Resources from './Resources';
import JobInbox from './JobInbox';
import { calculateDurationMinutesFromDecimal } from './functions/schedulingUtils';

interface SchedulingBoardProps {
    view: SchedulingViewType;
    resourceGroups: ResourceGroupType[];
    days: Date[];
    hours: string[] | undefined;
    renderTimeSlots: boolean | undefined;
    timeSlots: string[] | undefined;
    renderTimeBlocks: boolean | undefined;
    timeBlocks: TimeBlock[];
    events: EventsListType;
    eventsByResource: EventsByResourceType;
    eventOverlapMap: Map<string, EventType[]>;
    showInbox: boolean;
    dragResult: DragDropEvent<SchedulerDraggableId, SchedulerDroppableId> | null;
    onSetShowInbox: (showInbox: boolean) => void;
    setEvents: React.Dispatch<React.SetStateAction<EventsListType>>;
    setEventsByResource: React.Dispatch<React.SetStateAction<EventsByResourceType>>;
    setResourceGroups: React.Dispatch<React.SetStateAction<ResourceGroupType[]>>;
}

const SchedulingBoard: React.FC<SchedulingBoardProps> = ({
    view, resourceGroups, days, hours, renderTimeSlots, timeSlots, renderTimeBlocks, 
    timeBlocks, events, eventsByResource, eventOverlapMap, showInbox, dragResult, 
    onSetShowInbox, setEvents, setEventsByResource, setResourceGroups
}) => {
    const { setFloatingAlert } = useGlobalContext();
    const { environmentSettings, userLocale, userTimezone } = useSettings();
    const { dragging, isDragging, dragOver } = useDragDropContext<SchedulerDraggableId, SchedulerDroppableId>();
    const [draggingPlaceholder, setDraggingPlaceholder] = useState<SchedulerDraggingPlaceholder | null>(null);
    const [toScheduleListHeight, setToScheduleListHeight] = useState<number>(0);
    const [showProfilePictures, setShowProfilePictures] = useState(true);
    const [showUnassignedResource, setShowUnassignedResource] = useState(true);
    const [eventColorMethod, setEventColorMethod] = useState<string | null>(null);

    // Get the show profile pictures state from the environment settings
    useEffect(() => {
        const { scheduling_show_profile_pictures, scheduling_show_unassigned_row, scheduling_appointment_colors } = environmentSettings;

        // Set the show profile pictures state
        if (scheduling_show_profile_pictures !== undefined) {
            setShowProfilePictures(scheduling_show_profile_pictures);
        }

        // Set the show unassigned resource state
        if (scheduling_show_unassigned_row) {
            setShowUnassignedResource(scheduling_show_unassigned_row);
        }

        // Set the event color method
        if (scheduling_appointment_colors !== undefined) {
            setEventColorMethod(scheduling_appointment_colors);
        }
    }, [environmentSettings]);

    // Handle the completion of a job or event drag
    useEffect(() => {
        if (dragResult) {
            const handleDragResult = async () => {
                switch (dragResult.draggableId.type) {
                    // Handle drag from the job inbox to a resource daylist or timeslot
                    case 'jobInbox':
                        handleDragJobInboxJobToResource(
                            dragResult, 
                            events,
                            eventsByResource,
                            resourceGroups,
                            userLocale, 
                            userTimezone, 
                            environmentSettings,
                            setFloatingAlert, 
                            setEvents, 
                            setEventsByResource
                        );
                        break;

                    // Handle event drag
                    case 'event':
                        handleEventDrag(
                            dragResult, 
                            events,
                            resourceGroups,
                            userLocale, 
                            userTimezone,
                            setFloatingAlert, 
                            setEvents, 
                            setEventsByResource
                        );

                    default:
                        return null;
                }
            }
            handleDragResult();
        }
    }, [dragResult]);

    // Set the dragging placeholder
    useEffect(() => {
        // Reset dragging placeholder
        if (!isDragging || !dragOver) {
            setDraggingPlaceholder(null);
            return;
        }
    
        // Determine the duration minutes
        let durationMinutes = 60;
        if (dragging?.draggableId?.type === 'event') {
            durationMinutes = dragging.draggableId.event.durationMinutes;
        }

        if (dragging?.draggableId?.type === 'jobInbox') {
            // Get the estimated duration from the job
            const jobEstimatedDuration = dragging.draggableId.job.estimated_duration;
            if (jobEstimatedDuration !== null) {
                durationMinutes = calculateDurationMinutesFromDecimal(jobEstimatedDuration);

            // Fallback to 60 minutes if no estimated duration is set
            } else {
                durationMinutes = 60;
            }
        }
    
        // Set the dragging placeholder for dragging over a timeslot
        if (dragOver.type === 'timeSlot') {
            const { resourceId, day, timeSlot } = dragOver;
            setDraggingPlaceholder({ resourceId, day, timeSlot, durationMinutes });

        // Set the dragging placeholder for dragging over a day list
        } else if (dragOver.type === 'dayList') {
            const { resourceId, day } = dragOver;
            setDraggingPlaceholder({ resourceId, day, durationMinutes });
        }
    }, [isDragging, dragOver, dragging]);

    return (
        <div className={`scheduling-board ${view.direction} ${view.viewType} grid-${days.length}-columns ${showInbox ? 'show-inbox' : ''}`}>

            {/* Render resources */}
            <Resources
                view={view}
                resourceGroups={resourceGroups}
                days={days}
                hours={hours}
                renderTimeSlots={renderTimeSlots}
                timeSlots={timeSlots}
                isDragging={isDragging}
                draggingPlaceholder={draggingPlaceholder}
                renderTimeBlocks={renderTimeBlocks}
                timeBlocks={timeBlocks}
                events={events}
                eventsByResource={eventsByResource}
                eventOverlapMap={eventOverlapMap}
                showInbox={showInbox}
                showProfilePictures={showProfilePictures}
                showUnassignedResource={showUnassignedResource}
                eventColorMethod={eventColorMethod}
                setResourceGroups={setResourceGroups}
                onScheduleListHeightChange={(height) => setToScheduleListHeight(height)}
            />

            {/* Render job inbox */}
            {showInbox && (
                <JobInbox 
                    inboxHeight={toScheduleListHeight}
                    dragging={dragging}
                    isDragging={isDragging}
                    dragOver={dragOver}
                    dragResult={dragResult}
                    onLoaded={() => {}}
                    onClose={() => onSetShowInbox(false)} 
                />
            )}
        </div>
    )
}

export default SchedulingBoard;