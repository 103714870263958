import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const SchedulingBoardForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'scheduling_board'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'scheduling.scheduling_board.board_name_label',
            type: 'text',
            placeholder: 'scheduling.scheduling_board.board_name_placeholder',
            width: '50%',
            group: 'field-row-1',
        },
        {
            type: 'header',
            header: 'scheduling.scheduling_board.resources_label',
        },
        {
            name: 'resource_groups',
            type: 'scheduling-board-resources',
            apiObject: 'user',
            params: { is_active: true }
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default SchedulingBoardForm;