import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside, useSetDropdownWidth, useSetDropdownPosition } from 'services/utils/dropdownBehavior'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/forms.scss';

interface DropdownProps<T> {
    name?: string;
    id?: string;
    options: T[];
    selectedOption?: T | null;
    onChange: (selectedValueOrId: string) => void;
    disabled_selected?: string;
    disabled?: boolean;
    showSearch?: boolean;
    errorMessage?: string | string[] | undefined;
    isInvalid?: boolean;
    backendField?: string;
    selectionFormat?: (option: T) => string;
    optionFormat?: (option: T) => string;
    allowNoneOption?: boolean;
    fixedDropdownWidth?: number;
}

const Dropdown = <T extends { id?: number; [key: string]: any }>({ 
    name, id, options, onChange, disabled_selected, backendField, selectionFormat = (option) => `${option.id}`, optionFormat = (option) => `${option.id}`, 
    selectedOption, allowNoneOption, errorMessage, showSearch = true, disabled, isInvalid, fixedDropdownWidth
}: DropdownProps<T>) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownListRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const fieldRef = useRef<HTMLDivElement>(null);
    const [dropdownWidth, setDropdownWidth] = useState<number>(fixedDropdownWidth ?? 200);
    const [dropdownAbove, setDropdownAbove] = useState(false);
    const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | undefined>(undefined);

    // Use dropdownBehavior.ts to close the dropdown when clicking outside
    useClickOutside(isOpen, dropdownRef, setIsOpen);

    // Use dropdownBehavior.ts to set the dropdown width on open
    useSetDropdownWidth(isOpen, dropdownListRef, fieldRef, setDropdownWidth, fixedDropdownWidth);

    // Use dropdownBehavior.ts to set the dropdown position and max height on open
    useSetDropdownPosition(isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight);

    // Autofocus the search field when opening the dropdown list
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                searchRef.current?.focus();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    return (
        <div className='custom-dropdown-container'>
            <div className={`custom-dropdown-field ${errorMessage || isInvalid ? 'is-invalid' : ''} ${disabled ? 'disabled' : ''}`}
                 onMouseDown={(e) => { if (!disabled) e.stopPropagation(); setIsOpen(!isOpen) }}
                 ref={fieldRef}>
                    {selectedOption
                        ? selectedOption.color ? <span className={`colored-option ${selectedOption.color}`}></span> : <span>{t(selectionFormat(selectedOption) || selectionFormat(selectedOption))}</span>
                        : <span className='custom-dropdown-placeholder'>{t(disabled_selected) || t('forms.general_disabled_selected')}</span>}
                    <FontAwesomeIcon 
                        icon={isOpen && !disabled ? faCaretUp : faCaretDown}
                        className='custom-dropdown-caret' />
            </div>
            {isOpen && !disabled && (
                <div ref={dropdownRef}
                     className={`custom-dropdown ${dropdownAbove ? 'dropdown-above' : ''}`}  
                     style={{width: dropdownWidth, maxHeight: dropdownMaxHeight}}>
                    <div ref={dropdownListRef}
                         className='custom-dropdown-list' 
                         style={{width: dropdownWidth}}>
                        {showSearch && (
                            <input 
                                type="text" 
                                id={id}
                                name={name}
                                value={search} 
                                className='custom-dropdown-search'
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={t('forms.search_placeholder')}
                                ref={searchRef}
                            />
                        )}
                        {allowNoneOption !== false &&
                            <div className="custom-dropdown-item" onClick={() => { onChange('none'); setIsOpen(false); }}>
                                ({t('forms.none_option')})
                            </div>
                        }
                        {options
                            .filter(option => {
                                // If the label of the given or fetched dropdown options are empty, filter them out so they are not mapped in the dropdown
                                if (!optionFormat || (optionFormat && optionFormat(option) && optionFormat(option).trim() !== "")) {
                                    // Checks if one of the values of the option contains the search value
                                    return Object.values(option).some(value => String(value).toLowerCase().includes(search.toLowerCase()));
                                }
                                return false;
                            })
                            .map((option: T) => {
                                // Determines the active value
                                const isActive = selectedOption
                                    ? (
                                        (backendField && option[backendField] && selectedOption[backendField] === option[backendField]) ||
                                        (option.id && selectedOption.id === option.id) || 
                                        (option.value && selectedOption.value === option.value))
                                    : false;

                                return (
                                    <div key={String(option.id || option.value)} 
                                         className={`custom-dropdown-item ${option.color ? `colored-option ${option.color}` : ''} ${isActive ? 'active' : ''}`}
                                         onClick={() => { onChange(String(backendField && option[backendField] || option.id || option.value)); setIsOpen(false); }}>
                                            {!option.color ? t(optionFormat(option)) || optionFormat(option) : undefined}
                                            {isActive && (
                                                <FontAwesomeIcon 
                                                    icon={faCheck}
                                                    className='custom-dropdown-selected-icon' />
                                            )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;