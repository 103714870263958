import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import OtherActivitiesForm from './OtherActivitiesForm';

// Rename the name of the function:
const OtherActivitiesList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_other_activities_timetype_list',
        defaultParams: { 
            'is_active': true, 
        },
        object: 'timetype'
    }
    const objectName = 'scheduling.other_activities_types.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <OtherActivitiesForm />;
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'scheduling.other_activities_types.name_label' },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'scheduling.other_activities_types.active_activities_label',
            params: { 'is_active': true }
        },
        {
            id: 'inactive',
            label: 'scheduling.other_activities_types.inactive_activities_label',
            params: { 'is_active': false }
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default OtherActivitiesList;