import React, { useState, useContext, useEffect } from 'react';
import { UserType } from '../../../views/users/UserTypes';
import { FieldData, SchedulingBoardResourcesRowsFieldType } from 'types/FieldTypes';
import { createNewResource, createNewResourceGroup, ResourceGroupType } from '../../../views/scheduling/SchedulingTypes';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'services/utils/dragDropUtils';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { v4 as uuidv4 } from 'uuid';
import FormFieldContext from '../FormFieldContext';
import Dropdown from '../basefields/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const SchedulingBoardResourcesRowsField: React.FC<SchedulingBoardResourcesRowsFieldType & { data: FieldData, viewKey: string }> = ({
    data, dropdownData
}) => {
    const { t } = useTranslation();
    const { errorMessages } = useGlobalContext();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const [resourceGroups, setResourceGroups] = useState<ResourceGroupType[]>([]);
    const [users, setUsers] = useState<UserType[]>([]);

    // Load the current data from the api
    useEffect(() => {
        // Load the current resource groups
        if (data.resource_groups && data.resource_groups.length > 0) {

            // Sort the resource groups
            const sortedResourceGroups = (data.resource_groups || []).sort(
                (a: ResourceGroupType, b: ResourceGroupType) => a.ordering - b.ordering
            ).map((resource_group: ResourceGroupType) => ({ ...resource_group }));

            // Set the resource groups
            setResourceGroups(sortedResourceGroups);

        // If there are no resource groups, create an empty group
        } else {
            const firstPosition = 1;
            const emptyResourceGroup = createNewResourceGroup(data.id, uuidv4(), firstPosition);
            setResourceGroups([emptyResourceGroup]);
        }
    }, [data]);

    // Set the users from the dropdown data
    useEffect(() => {
        setUsers(dropdownData?.user?.results || []);
    }, [dropdownData]);

    // Handle the input change
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>, 
        fieldName: 'name' | 'remark', 
        type: 'resource_group' | 'resource',
        identifier: string | undefined,
    ) => {
        // Copy the groups and find the group index
        const updatedGroups = [...resourceGroups];
        const groupIndex = updatedGroups.findIndex(group => group.id?.toString() === identifier || group.uuid === identifier);

        // Update the name of the resource group
        if (type === 'resource_group' && fieldName == 'name' && groupIndex !== -1) {
            updatedGroups[groupIndex][fieldName] = event.target.value;
        }

        // Update the remark of the resource
        else if (type === 'resource' && fieldName == 'remark') {

            // Loop through the groups to find the resource
            updatedGroups.forEach(group => {

                // Find the resource index
                const resourceIndex = group.resources?.findIndex(resource => resource.id?.toString() === identifier || resource.uuid === identifier);

                // Update the remark
                if (resourceIndex !== undefined && resourceIndex !== -1) {
                    group.resources[resourceIndex][fieldName] = event.target.value;
                }
            });
        }

        // Update the resource groups and updated data
        setResourceGroups(updatedGroups);
        setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: updatedGroups }));     
    };

    // Handle user change
    const handleDropdownChange = (selectedValue: string, identifier: string) => {

        console.log("selectedValue", selectedValue)

        // Copy the groups
        const updatedGroups = [...resourceGroups];

        // Loop through the groups to find the resource
        updatedGroups.forEach(group => {

            // Find the resource index
            const resourceIndex = group.resources?.findIndex(resource => resource.id?.toString() === identifier || resource.uuid === identifier);

            // Update the user
            if (resourceIndex !== undefined && resourceIndex !== -1) {
                group.resources[resourceIndex]['user_hash'] = selectedValue;
            }
        });

        // Update the resource groups and updated data
        setResourceGroups(updatedGroups);
        setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: updatedGroups }));
    };

    // Handle deletion
    const handleDelete = (type: 'resource_group' | 'resource', identifier: string | undefined) => {
        // Copy the groups and find the group index
        let copiedResourceGroups = [...resourceGroups];
        const groupIndex = copiedResourceGroups.findIndex(group => group.id?.toString() === identifier || group.uuid === identifier);

        // Handle deletion of a resource group
        if (type === 'resource_group' && groupIndex !== -1) {
            // Get the group from the copied resource groups
            const group = copiedResourceGroups[groupIndex];

            // Check if the group is a new group added in the frontend (it has an uuid but no id)
            if (!group.id && group.uuid) {
                // Directly remove it from the resource groups
                copiedResourceGroups = copiedResourceGroups.filter((_, index) => index !== groupIndex)
            } else {
                // Flag the backend known group as deleted without actually delete it
                copiedResourceGroups[groupIndex].deleted = true;
            }
            
            // Update the ordering of the groups which are not marked as deleted
            const nonDeletedGroups = copiedResourceGroups.filter(group => !group.deleted);
            const reorderedNonDeletedGroups = nonDeletedGroups.map((group, index) => ({
                ...group,
                ordering: index + 1,
            }));

            // Add the deleted groups to the reordered list to keep them in the updated data
            const updatedResourceGroups = [...reorderedNonDeletedGroups, ...copiedResourceGroups.filter(group => group.deleted)];

            // Update the resource groups and updated data
            setResourceGroups(updatedResourceGroups);
            setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: updatedResourceGroups }));
        }

        // Handle deletion of a resource row
        if (type === 'resource') {

            // Loop through the groups to find the resource
            copiedResourceGroups = copiedResourceGroups.map(group => {

                // Find the resource index
                const resourceIndex = group.resources?.findIndex(resource => resource.id?.toString() === identifier || resource.uuid === identifier);

                if (resourceIndex !== undefined && resourceIndex !== -1) {
                    // Get the resource row from the copied resource groups
                    const resourceRow = group.resources[resourceIndex];

                    // Check if the resource row is a new row added in the frontend (it has an uuid but no id)
                    if (!resourceRow.id && resourceRow.uuid) {
                        // Directly delete the resource from the group
                        group.resources = group.resources.filter((_, index) => index !== resourceIndex);
                    } else {
                        // Flag the backend known resource as deleted without actually deleting it
                        group.resources[resourceIndex].deleted = true;
                    }

                    // Update the ordering of the resources which are not marked as deleted
                    const nonDeletedResources = group.resources.filter(resource => !resource.deleted);
                    const reorderedResources = nonDeletedResources.map((resource, index) => ({
                        ...resource,
                        ordering: index + 1,
                    }));

                    // Add the deleted resources to the reordered list to keep them in the updated data
                    group.resources = [...reorderedResources, ...group.resources.filter(resource => resource.deleted)];
                }

                return group;
            });

            // Update the resource groups and updated data
            setResourceGroups(copiedResourceGroups);
            setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: copiedResourceGroups }));
        }
    };

    // Handle ordering change
    const handleOrderingChange = (result: any) => {
        if (!result.destination) return;
        const { source, destination, type } = result;

        // Copy the groups
        const copiedResourceGroups = [...resourceGroups];

        // Handle resource group ordering change
        if (type === "resource_group") {

            // Only use the non deleted groups to determine the new ordering
            const nonDeletedGroups = copiedResourceGroups.filter(group => !group.deleted);
            const [reorderedGroup] = nonDeletedGroups.splice(source.index, 1);
            nonDeletedGroups.splice(destination.index, 0, reorderedGroup);
    
            // Determine the ordering for the non deleted groups
            const updatedNonDeletedGroups = nonDeletedGroups.map((group, index) => ({
                ...group,
                ordering: index + 1,
            }));

            // Add the deleted groups to the list without changing their ordering
            const updatedGroups = [...updatedNonDeletedGroups, ...copiedResourceGroups.filter(group => group.deleted)];

            // Update the resource groups and updated data
            setResourceGroups(updatedGroups);
            setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: updatedGroups }));
        }

        // Handle resource row ordering change within a group
        else if (type.startsWith("resource-")) {

            // Get the group identifier from the type
            const groupIdentifier = type.replace("resource-", "");

            // Find the group index
            const groupIndex = copiedResourceGroups.findIndex(group => group.id?.toString() === groupIdentifier || group.uuid === groupIdentifier);

            if (groupIndex !== -1) {
                // Get the group from the copied resource groups
                const group = copiedResourceGroups[groupIndex];
    
                // Only use the non deleted resources to determine the new ordering
                const nonDeletedResources = group.resources.filter(resource => !resource.deleted);
                const [movedResource] = nonDeletedResources.splice(source.index, 1);
                nonDeletedResources.splice(destination.index, 0, movedResource);
    
                // Determine the ordering for the non deleted resources
                const updatedNonDeletedResources = nonDeletedResources.map((resource, index) => ({
                    ...resource,
                    ordering: index + 1
                }));
    
                // Add the deleted resources to the list without changing their ordering
                group.resources = [...updatedNonDeletedResources, ...group.resources.filter(resource => resource.deleted)];
                copiedResourceGroups[groupIndex] = { ...group };
    
                // Update the resource groups and updated data
                setResourceGroups(copiedResourceGroups);
                setUpdatedData((prev: any) => ({ ...prev, ["resource_groups"]: copiedResourceGroups }));
            }
        }
    };

    const handleAddRow = (type: 'resource_group' | 'resource', groupIdentifier?: string) => {
        // Copy the current resource groups
        const copiedResourceGroups = [...resourceGroups];

        // Handle creation of a new resource group
        if (type === 'resource_group') {

            // Split groups in non deleted and deleted groups
            const nonDeletedGroups = copiedResourceGroups.filter(group => !group.deleted);
            const deletedGroups = copiedResourceGroups.filter(group => group.deleted);

            // Determine the position of the new group and create a group uuid
            const newPosition = nonDeletedGroups.length + 1;
            const newGroupUuid = uuidv4();

            // Create a new group with the first resource row
            const newGroup = createNewResourceGroup(data.id, newGroupUuid, newPosition);
            const newFirstResource = createNewResource(newGroupUuid, uuidv4(), 1);

            // Link the new first resource to the new group
            newGroup.resources.push(newFirstResource);
        
            // Add the new group at the end of the list with non deleted groups and add the deleted groups afterwards
            const updatedNonDeletedGroups = [...nonDeletedGroups, newGroup];
            const updatedGroups = [...updatedNonDeletedGroups, ...deletedGroups];

            // Update the resource groups and updated data
            setResourceGroups(updatedGroups);
            setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: updatedGroups }));
        }

        // Handle creation of a new resource
        else if (type === 'resource' && groupIdentifier) {

            // Find the group index to add the new resource
            const groupIndex = copiedResourceGroups.findIndex(group => group.id?.toString() === groupIdentifier || group.uuid === groupIdentifier);

            if (groupIndex !== -1) {
                // Split resources in non deleted and deleted rows
                const nonDeletedRows = copiedResourceGroups[groupIndex].resources.filter(resource => !resource.deleted);
                const deletedRows = copiedResourceGroups[groupIndex].resources.filter(resource => resource.deleted);

                // Determine the position of the new row and create it
                const newPosition = nonDeletedRows.length + 1;
                const newRow = createNewResource(groupIdentifier, uuidv4(), newPosition);

                // Add the new row at the end of the list with non deleted rows and add the deleted rows afterwards
                const updatedNonDeletedRows = [...nonDeletedRows, newRow];
                const updatedResources = [...updatedNonDeletedRows, ...deletedRows];

                // Overwrite the resources of the group with the new updated resources
                copiedResourceGroups[groupIndex] = {
                    ...copiedResourceGroups[groupIndex],
                    resources: updatedResources
                };

                // Update the resource groups and updated data
                setResourceGroups(copiedResourceGroups);
                setUpdatedData((prev: any) => ({ ...prev, ['resource_groups']: copiedResourceGroups }));
            }
        }
    };

    return (
        <div className='rows-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing && (
                // Edit mode
                <div className='edit-mode'>
                    <DragDropContext onDragEnd={handleOrderingChange}>
                        <StrictModeDroppable droppableId="droppable-groups" type="resource_group">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {resourceGroups
                                        .filter(group => !group.deleted)
                                        .sort((a, b) => a.ordering - b.ordering)
                                        .map((group, index) => {
                                            
                                        const groupIdentifier = group.id?.toString() || group.uuid;

                                        return (
                                            <Draggable key={groupIdentifier} draggableId={`group-${groupIdentifier}`} index={index} isDragDisabled={resourceGroups.length <= 1}>
                                                {(provided) => {
                                                    return (
                                                        <div className={`resource-group-rows-field ${resourceGroups.length === 1 ? 'single' : ''}`} 
                                                             ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            {resourceGroups.length > 1 && (
                                                                <div className={`group-order-icon tooltip-icon ${resourceGroups.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                    <FontAwesomeIcon icon={faGripLines} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </div>
                                                            )}
                                                            <div>
                                                                {resourceGroups.length > 1 && (
                                                                    <div className='resource-group-name'>
                                                                        <div className='rows-header resource-group-name-row'>
                                                                            <h6>{t('scheduling.scheduling_board.resource_group_header', { amount: index + 1 })} </h6>
                                                                        </div>
                                                                        <div className='rows-header resource-group-name-row'>
                                                                            <div className='header-item'>{t('scheduling.scheduling_board.resource_group_name_label')}</div>
                                                                        </div>
                                                                        <div className='resource-group-name-row'>
                                                                            <input 
                                                                                type="text"
                                                                                id={`name_${groupIdentifier}`}
                                                                                name={`name_${groupIdentifier}`}
                                                                                value={group.name ?? ''}
                                                                                onChange={event => handleInputChange(event, 'name', 'resource_group', groupIdentifier)}
                                                                                placeholder={t('scheduling.scheduling_board.resource_group_name_placeholder')}
                                                                                autoFocus={false}
                                                                                className={groupIdentifier && errorMessages?.scheduling_board_resources?.[groupIdentifier]?.['name'] ? 'is-invalid' : undefined}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className='resource-group-resources'>
                                                                    {group.resources.length > 0 && (
                                                                        <div className='rows-header resource-group-resources-row'>
                                                                            <div className='header-item'>{t('scheduling.scheduling_board.resource_user_label')}</div>
                                                                            <div className='header-item'>{t('scheduling.scheduling_board.resource_remark_label')}</div>
                                                                            <div className='delete-placeholder'></div>
                                                                            <div className='drag-placeholder'></div>
                                                                        </div>
                                                                    )}
                                                                    <StrictModeDroppable droppableId={`droppable-${groupIdentifier}`} type={`resource-${groupIdentifier}`}>
                                                                        {(provided) => (
                                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                {group.resources
                                                                                    .filter(resource => !resource.deleted)
                                                                                    .sort((a, b) => a.ordering - b.ordering)
                                                                                    .map((resource, index) => {
                                                                                    const identifier = resource.id?.toString() || resource.uuid || '';
                                                                                    return (
                                                                                        <Draggable key={identifier} draggableId={identifier} index={index} isDragDisabled={group.resources.length <= 1}>
                                                                                            {(provided) => {
                                                                                                return (
                                                                                                    <div className='list-item draggable' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                        <div className='resource-group-resources-row'>
                                                                                                            <Dropdown<UserType>
                                                                                                                options={users}
                                                                                                                id={`user_${identifier}`}
                                                                                                                name={`user_${identifier}`}
                                                                                                                disabled_selected={t('scheduling.scheduling_board.resource_user_placeholder')}
                                                                                                                selectedOption={users.find(user => user.user_hash === resource.user_hash)}
                                                                                                                onChange={(selectedValue) => handleDropdownChange(selectedValue, identifier)}
                                                                                                                isInvalid={identifier && errorMessages?.resource_group?.resources?.[identifier]?.['user'] ? true : false}
                                                                                                                backendField='user_hash'
                                                                                                                selectionFormat={(option) => `${option.full_name}`}
                                                                                                                optionFormat={(option) => `${option.full_name}`}
                                                                                                                allowNoneOption={false}                  
                                                                                                            />
                                                                                                            <input 
                                                                                                                type="text"
                                                                                                                id={`remark_${identifier}`}
                                                                                                                name={`remark_${identifier}`}
                                                                                                                value={resource.remark ?? ''}
                                                                                                                onChange={event => handleInputChange(event, 'remark', 'resource', identifier)}
                                                                                                                placeholder={t('scheduling.scheduling_board.resource_remark_placeholder')}
                                                                                                                autoFocus={false}
                                                                                                                className={identifier && errorMessages?.resource_group?.resources?.[identifier]?.['remark'] ? 'is-invalid' : undefined}
                                                                                                            />
                                                                                                            <div className='delete-icon tooltip-icon'>
                                                                                                                <FontAwesomeIcon 
                                                                                                                    icon={faTrash} 
                                                                                                                    onClick={() => handleDelete('resource', identifier)} />
                                                                                                                <span className="tooltip">{t('general.delete')}</span>
                                                                                                            </div>
                                                                                                            <div className={`order-icon tooltip-icon ${group.resources.length === 1 ? 'visibility-hidden' : ''}`}>
                                                                                                                <FontAwesomeIcon icon={faGripLines} />
                                                                                                                <span className="tooltip">{t('general.reorder')}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        </Draggable>
                                                                                    )
                                                                                })}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </StrictModeDroppable>
                                                                    <div onClick={(e) => {e.preventDefault(); handleAddRow('resource', groupIdentifier); }} 
                                                                         className="add-new-button">
                                                                        {t('scheduling.scheduling_board.add_new_resource')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {resourceGroups.length > 1 && (
                                                                <div className='group-delete-icon tooltip-icon'>
                                                                    <FontAwesomeIcon 
                                                                        icon={faTrash} 
                                                                        onClick={() => handleDelete('resource_group', groupIdentifier)} />
                                                                    <span className="tooltip">{t('general.delete')}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                    <div onClick={(e) => {e.preventDefault(); handleAddRow('resource_group'); }} 
                        className="add-new-button">
                        {t('scheduling.scheduling_board.add_new_resource_group')}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SchedulingBoardResourcesRowsField;