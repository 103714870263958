import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import InvoicePdfLayoutForm from './InvoicePdfLayoutForm';

// Rename the name of the function:
const InvoicePdfLayoutList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_layout_for_feature_list/invoice',
        object: 'layout'
    }
    const objectName = 'layout.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deleted'
    const isDraggable = true
    const draggingApiUrl = 'patch_layout_ordering/invoice'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <InvoicePdfLayoutForm />;
    const onRowClick = 'detailPage'
    const detailPageUrl = 'settings/templates/layout/pdf-layout'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'layout.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            detailPageUrl={detailPageUrl}
            isDraggable={isDraggable}
            draggingApiUrl={draggingApiUrl}
        />
    );
};

export default InvoicePdfLayoutList;