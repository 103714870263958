import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import InvoiceTypeForm from './InvoiceTypeForm';

// Rename the name of the function:
const InvoiceTypeList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_workflow_for_feature_list/invoice',
        defaultParams: { 'is_active': true },
        object: 'workflow'
    }
    const objectName = 'invoice.invoice_type.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'
    const isDraggable = true
    const draggingApiUrl = 'patch_workflow_ordering/invoice'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <InvoiceTypeForm />;
    const modalSize = 'medium'
    const onRowClick = 'editModal'

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'invoice.invoice_type.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            modalSize={modalSize}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            isDraggable={isDraggable}
            draggingApiUrl={draggingApiUrl}
        />
    );
};

export default InvoiceTypeList;