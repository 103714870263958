import { EventsByResourceType, EventsListType, EventType, ResourceGroupType } from "../SchedulingTypes";
import { generateDayKey } from "./schedulingUtils";

// Calculate overlapping events
export const calculateOverlappingEvents = (
    resourceGroups: ResourceGroupType[], 
    days: Date[], 
    events: EventsListType, 
    eventsByResource: EventsByResourceType
) => {
    const newEventOverlapMap = new Map<string, EventType[]>();

    // Loop through the resource groups
    resourceGroups.forEach(group => {

        // Loop through the resources of each group
        group.resources.forEach(resource => {
            if (!resource.id) return;

            // Loop through the day of each resource
            days.forEach(day => {

                // Generate a day key
                const dayKey = generateDayKey(day)

                // Get all events for this resource and day
                const resourceEvents = (eventsByResource[resource.id!] || [])
                    .map(eventId => events[eventId])
                    .filter(event => event !== undefined);

                // Sort the events on start time
                resourceEvents.sort((a, b) => {
                    const timeA = a.startDateTime ? new Date(a.startDateTime).getTime() : 0;
                    const timeB = b.startDateTime ? new Date(b.startDateTime).getTime() : 0;
                    return timeA - timeB;
                });

                // Add the events to the map
                newEventOverlapMap.set(`${resource.id}-${dayKey}`, resourceEvents);
            });
        });
    });

    return newEventOverlapMap;
};

// Get the amount of overlapping events and the event width of a certain timeslot
export const getOverlappingEvents = (
    eventOverlapMap: Map<string, EventType[]>,
    resourceId: string, 
    dayKey: string, 
    timeSlot: string
): [EventType[], number] => {
    const allEvents = eventOverlapMap.get(`${resourceId}-${dayKey}`) || [];
    const slotStartTime = new Date(`${dayKey}T${timeSlot}:00`).getTime();
    const slotEndTime = slotStartTime + 15 * 60 * 1000;

    // Filter events to find overlaps with the given day and time
    let overlappingEvents = allEvents.filter(event => {
        if (!event.startDateTime || !event.endDateTime) return false;

        const eventStart = new Date(event.startDateTime).getTime();
        const eventEnd = new Date(event.endDateTime).getTime();

        // Check if the event is in the given day
        const eventStartDay = event.startDateTime.split("T")[0];
        const eventEndDay = event.endDateTime.split("T")[0];

        const isSameDay = eventStartDay === dayKey;
        const overlapsIntoThisDay = eventStartDay < dayKey && eventEndDay >= dayKey;

        // Check if the event overlaps with the given timeslot
        const overlapsInTime =
            // Event starts before the end of the timeslot
            eventStart < slotEndTime && // Event begint vóór het einde van het tijdslot

            // Event ends after the start of the timeslot
            eventEnd > slotStartTime;

        return (isSameDay || overlapsIntoThisDay) && overlapsInTime;
    });

    // Find events which overlap with the found events
    let extraEventsFound;
    do {
        extraEventsFound = false;

        const newOverlappingEvents = allEvents.filter(event => {
            if (!event.startDateTime || !event.endDateTime) return false;

            // Prevent duplicated events
            if (overlappingEvents.includes(event)) return false;

            const eventStart = new Date(event.startDateTime).getTime();
            const eventEnd = new Date(event.endDateTime).getTime();

            return overlappingEvents.some(overlappingEvent => {
                const overlappingStart = overlappingEvent.startDateTime ? new Date(overlappingEvent.startDateTime).getTime() : 0;
                const overlappingEnd = overlappingEvent.endDateTime ? new Date(overlappingEvent.endDateTime).getTime() : 0;

                const isOverlapping =
                    eventStart < overlappingEnd && eventEnd > overlappingStart;

                if (isOverlapping) extraEventsFound = true;
                return isOverlapping;
            });
        });

        if (extraEventsFound) {
            overlappingEvents = [...overlappingEvents, ...newOverlappingEvents];
        }
    } while (extraEventsFound);

    // Calculate the maximum overlap in the same timeslot
    const getMaxSimultaneousOverlap = (events: EventType[]): number => {
        const timestamps: { time: number; type: 'start' | 'end' }[] = [];

        events.forEach(event => {
            if (event.startDateTime && event.endDateTime) {
                timestamps.push({ time: new Date(event.startDateTime).getTime(), type: 'start' });
                timestamps.push({ time: new Date(event.endDateTime).getTime(), type: 'end' });
            }
        });

        timestamps.sort((a, b) => a.time - b.time);

        let maxOverlap = 0;
        let currentOverlap = 0;

        timestamps.forEach(point => {
            if (point.type === 'start') {
                currentOverlap++;
                maxOverlap = Math.max(maxOverlap, currentOverlap);
            } else {
                currentOverlap--;
            }
        });

        return maxOverlap;
    };

    // Calculate the width percentage of the events for this timeslot
    const maxSimultaneousOverlap = getMaxSimultaneousOverlap(overlappingEvents);
    const widthPercentage = maxSimultaneousOverlap > 0 ? 100 / maxSimultaneousOverlap : 100

    return [overlappingEvents, widthPercentage];
};