import { SideBarNavGroupType } from "types/NavigationTypes";
import EnvironmentList from '../environments/EnvironmentList';
import DivisionList from '../environments/DivisionList';
import UserList from '../users/UserList';
import GroupList from '../users/GroupList';
import RoleList from '../users/RoleList';
import LabelList from './LabelList';
import TaskTypeList from './TaskTypeList';
import LedgerAccountList from './LedgerAccountList';
import VatRateList from './VatRateList';
import TimeBlockList from './scheduling/TimeBlockList';
import AttachmentTypeList from './AttachmentTypeList';
import JobTimeTypeList from './jobs/JobTimeTypeList';
import JobSettings from './jobs/JobSettings';
import SchedulingSettings from './scheduling/SchedulingSettings';
import LayoutList from './LayoutList';
import ContactSettings from "./contacts/ContactSettings";
import TrialList from "../environments/TrialList";
import WorkflowList from "./workflows/WorkflowList";
import InvoiceSettings from "./invoices/InvoiceSettings";
import ImportList from "./ImportList";
import ProposalSettings from "./proposals/ProposalSettings";
import DealPipelineList from "./deals/DealPipelineList";
import DealSourceList from "./deals/DealSourceList";
import LostReasonList from "./deals/LostReasonList";
import OrderSettings from "./orders/OrderSettings";
import CompanyDetailsPage from "./CompanyDetailsPage";
import GeneralSettings from "./company_details/GeneralSettings";
import SchedulingBoardList from "./scheduling/SchedulingBoardList";
import InvoiceTypeList from "./invoices/InvoiceTypeList";
import JobTypeList from "./jobs/JobTypeList";
import ProposalTypeList from "./proposals/ProposalTypeList";
import OrderTypeList from "./orders/OrderTypeList";
import ProposalPdfLayoutList from "./proposals/ProposalPdfLayoutList";
import OrderPdfLayoutList from "./orders/OrderPdfLayoutList";
import JobPdfLayoutList from "./jobs/JobPdfLayoutList";
import InvoicePdfLayoutList from "./invoices/InvoicePdfLayoutList";
import BillingOverview from "./billing/billing-overview/BillingOverview";
import TimeSheetSettings from "./timesheets/TimeSheetSettings";
import OtherActivitiesList from "./scheduling/OtherActivitiesList";
import JobAppSettings from "./jobs/JobAppSettings";

// Map the component names to the actual components
export const componentMapping = {
    'EnvironmentList': EnvironmentList,
    'TrialList': TrialList,
    'CompanyDetails': CompanyDetailsPage,
    'GeneralSettings': GeneralSettings,
    'DivisionList': DivisionList,
    'SchedulingSettings': SchedulingSettings,
    'SchedulingBoardList': SchedulingBoardList,
    'OtherActivitiesList': OtherActivitiesList,
    'TimeBlockList': TimeBlockList,
    'JobSettings': JobSettings,
    'JobAppSettings': JobAppSettings,
    'JobTypeList': JobTypeList,
    'JobTimeTypeList': JobTimeTypeList,
    'JobPdfLayoutList': JobPdfLayoutList,
    'TimeSheetSettings': TimeSheetSettings,
    'InvoiceSettings': InvoiceSettings,
    'InvoiceTypeList': InvoiceTypeList,
    'InvoicePdfLayoutList': InvoicePdfLayoutList,
    'ContactSettings': ContactSettings,
    'UserList': UserList,
    'GroupList': GroupList,
    'RoleList': RoleList,
    'LabelList': LabelList,
    'WorkflowList': WorkflowList,
    'LayoutList': LayoutList,
    'TaskTypeList': TaskTypeList,
    'AttachmentTypeList': AttachmentTypeList,
    'LedgerAccountList': LedgerAccountList,
    'VatRatesList': VatRateList,
    'ImportList': ImportList,
    'ProposalSettings': ProposalSettings,
    'ProposalTypeList': ProposalTypeList,
    'ProposalPdfLayoutList': ProposalPdfLayoutList,
    'OrderSettings': OrderSettings,
    'OrderTypeList': OrderTypeList,
    'OrderPdfLayoutList': OrderPdfLayoutList,
    'DealPipelineList': DealPipelineList,
    'DealSourceList': DealSourceList,
    'LostReasonList': LostReasonList,
    'BillingOverview': BillingOverview,
}

// Define the settings menu items here
export const settingsConfig: { [groupName: string]: SideBarNavGroupType } = {
    admin: {
        label: "nav.admin_label",
        permissionCheck: "no_user",
        items: [
            {
                label: "nav.environments_label",
                route: "/settings/admin/environments",
                pageHeader: [
                    {
                        title: "nav.manage_environments_title",
                        tabs: [
                            { 
                                label: "nav.environments_tab",
                                componentName: "EnvironmentList",
                            },
                            { 
                                label: "nav.trials_tab",
                                componentName: "TrialList",
                            },
                        ]
                    },
                ]
            }
        ]
    },
    general: {
        label: "nav.general_label",
        items: [
            {
                label: "nav.company_details_label",
                route: "/settings/general/company-details", 
                pageHeader: [
                    {
                        title: "nav.company_details_label",
                        explanationText: "nav.company_details_explanation",
                        tabs: [
                            {
                                label: "nav.company_details_label",
                                componentName: "CompanyDetails",
                            },
                            {
                                label: "Algemene instellingen",
                                componentName: "GeneralSettings",
                            }
                        ]
                    },
                ]               
            },
            {
                label: "nav.divisions_label",
                route: "/settings/general/divisions",
                requiredFeature: "divisions",
                pageHeader: [
                    {
                        title: "nav.divisions_title",
                        explanationText: "nav.divisions_explanation",
                        tabs: [
                            {
                                label: "nav.divisions_label",
                                componentName: "DivisionList",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.users_label",
                route: "/settings/general/users",
                pageHeader: [
                    {
                        title: "nav.manage_users_title",
                        explanationText: "nav.users_explanation",
                        tabs: [
                            {
                                label: "nav.users_label",
                                componentName: "UserList",
                            },
                            {
                                label: "nav.groups_label",
                                componentName: "GroupList",
                                activeFeature: "groups"
                            },
                            {
                                label: "nav.roles_label",
                                componentName: "RoleList",
                            },
                        ]
                    }
                ]
            },
            {
                label: "nav.security_label",
                route: "/settings/general/security",
                requiredFeature: "enhanced_security",
            },
        ]
    },
    features: {
        label: "nav.features_label",
        items: [
            {
                label: "nav.scheduling_label",
                route: "/settings/features/scheduling",
                pageHeader: [
                    {
                        title: "nav.scheduling_title",
                        switchButton: true,
                        requiredFeature: "scheduling",
                        explanationText: "nav.scheduling_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "SchedulingSettings",
                            },
                            { 
                                label: "nav.scheduling_boards",
                                componentName: "SchedulingBoardList",
                            },
                            {
                                label: "nav.other_activities_label",
                                componentName: "OtherActivitiesList",
                            },
                            // {
                            //     label: "nav.time_blocks_label",
                            //     componentName: "TimeBlockList",
                            //     // activeFeature: 'scheduling_time_blocks'
                            // },
                        ]
                    },
                ]
            },
            {
                label: "nav.jobs_label",
                route: "/settings/features/jobs",
                pageHeader: [
                    {
                        title: "nav.jobs_label",
                        switchButton: true,
                        requiredFeature: "jobs",
                        explanationText: "nav.jobs_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "JobSettings",
                            },
                            { 
                                label: "nav.jobs_app_settings_label",
                                componentName: "JobAppSettings",
                            },
                            { 
                                label: "nav.jobs_type_label",
                                componentName: "JobTypeList",
                            },
                            {
                                label: "nav.jobs_timetype_label",
                                componentName: "JobTimeTypeList",
                            },
                            { 
                                label: "nav.pdf_layout_list_label",
                                componentName: "JobPdfLayoutList",
                            },
                        ]
                    },
                ]
            },
            // {
            //     label: "nav.timesheets_label",
            //     route: "/settings/features/timesheets",
            //     pageHeader: [
            //         {
            //             title: "nav.timesheets_label",
            //             switchButton: true,
            //             requiredFeature: "manage_timesheets",
            //             explanationText: "nav.timesheets_explanation",
            //             tabs: [
            //                 {
            //                     label: "nav.settings_label",
            //                     componentName: "TimeSheetSettings",
            //                 },
            //                 {
            //                     label: "nav.timesheets_timetype_label",
            //                     componentName: "TimeSheetTimeTypeList",
            //                 },
            //             ]
            //         },
            //     ]
            // },
            // {
            //     label: "nav.mobile_app_label",
            //     route: "/settings/features/mobile-app",
            // },
            {
                label: "nav.deals_label",
                route: "/settings/features/deals",
                requiredFeature: 'deals',
                pageHeader: [
                    {
                        title: "nav.deals_label",
                        switchButton: true,
                        requiredFeature: "deals",
                        explanationText: "nav.deals_explanation",
                        tabs: [
                            { 
                                label: "nav.deal_pipelines_label",
                                componentName: "DealPipelineList",
                            },
                            { 
                                label: "nav.deal_sources_label",
                                componentName: "DealSourceList",
                            },
                            { 
                                label: "nav.deal_lost_reasons_label",
                                componentName: "LostReasonList",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.proposals_label",
                route: "/settings/features/proposals",
                pageHeader: [
                    {
                        title: "nav.proposals_label",
                        switchButton: true,
                        requiredFeature: "proposals",
                        explanationText: "nav.proposals_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "ProposalSettings",
                            },
                            {
                                label: "nav.proposals_type_label",
                                componentName: "ProposalTypeList",
                            },
                            { 
                                label: "nav.pdf_layout_list_label",
                                componentName: "ProposalPdfLayoutList",
                            },
                        ]
                    },
                ]
            },
            {
                label: "nav.orders_label",
                route: "/settings/features/orders",
                requiredFeature: 'orders',
                pageHeader: [
                    {
                        title: "nav.orders_label",
                        switchButton: true,
                        requiredFeature: "orders",
                        explanationText: "nav.orders_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "OrderSettings",
                            },
                            {
                                label: "nav.orders_type_label",
                                componentName: "OrderTypeList",
                            },
                            { 
                                label: "nav.pdf_layout_list_label",
                                componentName: "OrderPdfLayoutList",
                            },
                        ]
                    },
                ]
            },
            {
                label: "nav.invoices_label",
                route: "/settings/features/invoices",
                pageHeader: [
                    {
                        title: "nav.invoices_label",
                        switchButton: true,
                        requiredFeature: "invoices",
                        explanationText: "nav.invoices_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "InvoiceSettings",
                            },
                            { 
                                label: "nav.invoice_type_label",
                                componentName: "InvoiceTypeList",
                            },
                            { 
                                label: "nav.pdf_layout_list_label",
                                componentName: "InvoicePdfLayoutList",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.email_label",
                route: "/settings/features/email",
                pageHeader: [
                    {
                        title: "nav.email_label",
                        explanationText: "",
                        tabs: [
                            {
                                label: "",
                                componentName: "",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.contacts_label",
                route: "/settings/features/contacts",
                pageHeader: [
                    {
                        title: "nav.contacts_label",
                        explanationText: "nav.contacts_explanation",
                        tabs: [
                            { 
                                label: "nav.settings_label",
                                componentName: "ContactSettings",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.reporting_label",
                route: "/settings/features/reporting",
                requiredFeature: "reporting",
                pageHeader: [
                    {
                        title: "nav.reporting_label",
                        switchButton: true,
                        requiredFeature: "reporting",
                        explanationText: "nav.reporting_explanation",
                        tabs: [
                            {
                                label: "",
                                componentName: "",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.additional_modules_label",
                route: "/settings/features/additional-modules",
                pageHeader: [
                    {
                        title: "nav.subcontractors_title",
                        switchButton: true,
                        explanationText: "nav.subcontractors_explanation",
                        requiredFeature: "subcontractors",
                    },
                    {
                        title: "nav.inventory_title",
                        switchButton: true,
                        explanationText: "nav.inventory_explanation",
                        requiredFeature: "inventory",
                    },
                    {
                        title: "nav.inbox_title",
                        switchButton: true,
                        explanationText: "nav.inbox_explanation",
                        requiredFeature: "inbox",
                    },
                    {
                        title: "nav.objects_title",
                        switchButton: true,
                        explanationText: "nav.objects_explanation",
                        requiredFeature: "location_objects",
                    },
                    {
                        title: "nav.client_portal_title",
                        switchButton: true,
                        explanationText: "nav.client_portal_explanation",
                        requiredFeature: "client_portal",
                    },
                    {
                        title: "nav.custom_roles_title",
                        switchButton: true,
                        explanationText: "nav.custom_roles_explanation",
                        requiredFeature: "custom_roles",
                    },
                    {
                        title: "nav.contract_management_title",
                        switchButton: true,
                        explanationText: "nav.contract_management_explanation",
                        requiredFeature: "contract_management",
                    },
                    {
                        title: "nav.deals_label",
                        switchButton: true,
                        explanationText: "nav.deals_explanation",
                        requiredFeature: "deals",
                    },
                    {
                        title: "nav.orders_label",
                        switchButton: true,
                        explanationText: "nav.orders_explanation",
                        requiredFeature: "orders",
                    },
                    // {
                    //     title: "nav.workflows_title",
                    //     switchButton: true,
                    //     explanationText: "nav.workflows_explanation",
                    //     requiredFeature: "workflows",
                    // },
                    {
                        title: "nav.servicedesk_tickets_title",
                        switchButton: true,
                        explanationText: "nav.servicedesk_tickets_explanation",
                        requiredFeature: "tickets",
                    },
                    {
                        title: "nav.hrm_title",
                        switchButton: true,
                        explanationText: "nav.hrm_explanation",
                        requiredFeature: "hrm",
                    },
                    {
                        title: "nav.appointment_booking_engine_title",
                        switchButton: true,
                        explanationText: "nav.appointment_booking_engine_explanation",
                        requiredFeature: "appointment_booking_engine",
                    },
                    {
                        title: "nav.divisions_title",
                        switchButton: true,
                        explanationText: "nav.divisions_explanation",
                        requiredFeature: "divisions",
                    },
                    {
                        title: "nav.reporting_title",
                        switchButton: true,
                        explanationText: "nav.reporting_explanation",
                        requiredFeature: "reporting",
                    },
                ]
            }
        ]
    },
    custom_fields: {
        label: "nav.custom_fields_label",
        items: [
            {
                label: "nav.labels_label",
                route: "/settings/custom-fields/labels",
                pageHeader: [
                    {
                        title: "nav.labels_label",
                        explanationText: "nav.labels_explanation",
                        tabs: [
                            {
                                label: "nav.labels_label",
                                componentName: "LabelList",
                            }
                        ]
                    },
                ]
            },
            {
                label: "nav.categories_label",
                route: "/settings/custom-fields/categories",
                pageHeader: [
                    {
                        title: "nav.categories_label",
                        explanationText: "nav.categories_explanation",
                        tabs: [
                            { 
                                label: "nav.task_type_label", 
                                componentName: "TaskTypeList" 
                            },
                            { 
                                label: "nav.attachment_type_label", 
                                componentName: "AttachmentTypeList" 
                            },
                            { 
                                label: "nav.ledger_account_label", 
                                componentName: "LedgerAccountList" 
                            },
                            { 
                                label: "nav.vat_rates_label", 
                                componentName: "VatRatesList" 
                            },
                        ]
                    }
                ]
            },
            // {
            //     label: "nav.workflows_title",
            //     route: "/settings/custom-fields/workflows",
            //     pageHeader: [
            //         {
            //             title: "nav.workflows_label",
            //             explanationText: "nav.workflows_explanation",
            //             tabs: [
            //                 {
            //                     label: "nav.workflows_label",
            //                     componentName: "WorkflowList",
            //                 }
            //             ]
            //         },
            //     ]
            // },
            {
                label: "nav.imports_label",
                route: "/settings/custom-fields/data-imports",
                pageHeader: [
                    {
                        title: "nav.imports_label",
                        explanationText: "nav.imports_explanation",
                        tabs: [
                            {
                                label: "nav.imports_label",
                                componentName: "ImportList",
                            }
                        ]
                    },
                ]
            },
        ]
    },
    templates: {
        label: "nav.templates_label",
        items: [
            { 
                label: "nav.forms_label", 
                route: "/settings/templates/forms",
                requiredFeature: 'mobile_forms'
            },
            { 
                label: "nav.email_sms_templates_label", 
                route: "/settings/templates/email-sms-templates" 
            },
            { 
                label: "nav.layouts_label", 
                route: "/settings/templates/layouts",
                pageHeader: [
                    {
                        title: "nav.layouts_label",
                        explanationText: "nav.layouts_explanation",
                        tabs: [
                            {
                                label: "nav.layouts_label",
                                componentName: "LayoutList",
                            }
                        ]
                    },
                ]
            },
        ]
    },
    // integrations: {
    //     label: "nav.integrations_label",
    //     items: [
    //         // { label: "nav.calendar_integration_label", route: "/settings/integrations/calendar" },
    //         // { label: "nav.email_integration_label", route: "/settings/integrations/email-integration" },
    //         { label: "nav.marketplace_app_store_label", route: "/settings/integrations/marketplace-app-store" },
    //         { 
    //             label: "nav.api_keys_label", 
    //             route: "/settings/integrations/api-keys",
    //             requiredFeature: 'api_keys'
    //         },
    //     ]
    // },
    billing: {
        label: "nav.billing_label",
        items: [
            { 
                label: "nav.plan_label", 
                route: "/settings/billing/subscription",
                pageHeader: [
                    {
                        title: "nav.billing_label",
                        tabs: [
                            {
                                label: "nav.subscription",
                                componentName: "BillingOverview",
                            }
                        ]
                    },
                ]
            },
            { 
                label: "nav.payments_label", 
                route: "/settings/billing/invoices" 
            },
        ]
    }
}    