import React, { useState, useEffect, useContext } from 'react';
import { TimeFieldType, FieldData } from 'types/FieldTypes';
import { convert24HourTo12HourFormat } from 'services/utils/dateTimeUtils';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import TimeInput from '../basefields/TimeInput';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const TimeField: React.FC<TimeFieldType & { data: FieldData, viewKey: string }> = ({
    viewKey, name, label, data, convertTimezone, utcDatetime, localDatetime, defaultValue, 
    helperText, tooltipText, viewInEditMode, alignment, disabled = false, saveOnBlur, 
    isEditable
}) => {
    const { setUnsavedChanges } = useGlobalContext();
    const { getTimeFormat } = useSettings();
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const [selectedTime, setSelectedTime] = useState<string | null>(null);
    
    // If the object already has a time on loading, set the time
    useEffect(() => {
        if (data && data[name]) {
            let currentTime;

            // Get the current time value
            currentTime = data[name]

            // Only display minutes and hours (and no seconds)
            currentTime = currentTime.substr(0, 5);
            
            // If the time should be in 12-hours format, convert it
            if (getTimeFormat() === '12h') {
                currentTime = convert24HourTo12HourFormat(currentTime);
            }

            // Set the current time
            setSelectedTime(currentTime);

        } else if (defaultValue) {
            let defaultTime: string;

            // Get the default time value
            defaultTime = defaultValue;

            // Only display minutes and hours (and no seconds)
            defaultTime = defaultTime.substr(0, 5);

            // If the time should be in 12-hours format, convert it
            if (getTimeFormat() === '12h') {
                defaultTime = convert24HourTo12HourFormat(defaultTime);
            }

            // Set the default time as selected time
            setSelectedTime(defaultTime);

            // Set the default time in the updated data
            setUpdatedData((prevData: any) => ({
                ...prevData,
                [name]: defaultTime,
            }));
        }
    }, [data, name, defaultValue])

    // Handle the time change
    const handleChange = (newTime: string | null) => {
        setSelectedTime(newTime);
        setUpdatedData({ ...updatedData, [name]: newTime });
        setUnsavedChanges(viewKey, newTime !== data[name]);
    }

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            viewInEditMode={viewInEditMode}
            isEditable={isEditable}
            disabled={disabled}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <TimeInput
                    type='time'
                    name={name}
                    value={selectedTime || ''}
                    convertTimezone={convertTimezone}
                    utcDatetime={utcDatetime}
                    localDatetime={localDatetime}
                    saveOnBlur={saveOnBlur}
                    onChange={handleChange}
                    disabled={disabled}                
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={selectedTime} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default TimeField;