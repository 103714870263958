// Set the package options
export const packageOptions = [
    {
        package: 'starter',
        features: [
            'workorder_app',
            'online_calendar',
            'contact_management',
            'product_management',
        ],
        yearlyPrice: 17,
        monthlyPrice: 22
    },
    {
        package: 'essential',
        features: [
            'scheduling',
            // 'checklists',
            // 'timesheets',
            'projects',
            'proposals',
            'invoices',
            'contact_history'
            // 'tasks',
            // 'email-sms',
        ],
        yearlyPrice: 29,
        monthlyPrice: 37
    },
    {
        package: 'advanced',
        isBestChoice: true,
        features: [
            'distance_scheduler',
            'custom_roles',
            'deal_pipelines',
            'line_discounts',
            'multiple-scheduling-boards',
            // 'task_workflow_builder',
            // 'automated_emails',
            // 'extended_products',
            // 'custom_fields_and_roles',
            // 'hrm_module',
        ],
        yearlyPrice: 49,
        monthlyPrice: 64
    },
    {
        package: 'enterprise',
        features: [
            'multi-tenants',
            // 'custom_reports',
            // 'security_alerts',
            'two_factor_authentication',
            'local_backup'
        ],
        yearlyPrice: 79,
        monthlyPrice: 99
    },
];

// Set the features per package for the comparison table
export const featureComparison: Record<string, { features: Record<string, string[]> }> = {
    'max-seats': {
        features: {
            'starter': [
                'max-5-users'
            ],
            'essential': [],
            'advanced': [],
            'enterprise': [],
        }
    },
    'scheduling': {
        features: {
            'starter': [
                'personal-calendar', 
            ],
            'essential': [
                'scheduling', 
                'schedule-days-off', 
                'planlist', 
                'custom-colors', 
                'plangroups', 
                // 'subcontractors'
            ],
            'advanced': [
                'scheduling', 
                'schedule-days-off', 
                'planlist', 
                'custom-colors', 
                'plangroups', 
                // 'subcontractors', 
                // 'project-scheduler', 
                'distance-scheduler', 
                'multiple-scheduling-boards', 
                // 'employee-timetable'
            ],
            'enterprise': [ 
                'scheduling', 
                'schedule-days-off', 
                'planlist', 
                'custom-colors', 
                'plangroups', 
                // 'subcontractors', 
                // 'project-scheduler', 
                'distance-scheduler', 
                'multiple-scheduling-boards', 
                // 'employee-timetable', 
                'multi-tenants-scheduling'
            ],
        }
    },
    'jobs': {
        features: {
            'starter': [
                'create-jobs',
                'single-jobs', 
                'labels'
            ],
            'essential': [
                'create-jobs',
                'multiple-jobs', 
                'labels', 
                'extended-descriptions', 
                'verify-jobs',
                'custom-job-types', 
                // 'checklists'
            ],
            'advanced': [
                'create-jobs',
                'multiple-jobs', 
                'labels', 
                'extended-descriptions', 
                'verify-jobs',
                'custom-job-types', 
                // 'checklists', 
                // 'extended-checklists', 
                // 'service-contracts', 
                // 'custom-job-fields', 
                // 'custom-job-statuses', 
                // 'custom-job-tasks'
            ],
            'enterprise': [
                'create-jobs',
                'multiple-jobs', 
                'labels', 
                'extended-descriptions',
                'verify-jobs', 
                'custom-job-types', 
                // 'checklists', 
                // 'extended-checklists', 
                // 'service-contracts', 
                // 'custom-job-fields', 
                // 'custom-job-statuses', 
                // 'custom-job-tasks'
            ],
        }
    },
    // 'projects': {
    //     features: {
    //         'starter': [],
    //         'essential': [
    //             'project-management'
    //         ],
    //         'advanced': [
    //             'project-management', 
    //             'project-scheduler', 
    //             'custom-project-tasks'
    //         ],
    //         'enterprise': [
    //             'project-management', 
    //             'project-scheduler', 
    //             'custom-project-tasks'
    //         ],
    //     }
    // },
    // 'timesheets': {
    //     features: {
    //         'starter': [
    //             'hours-on-jobs'
    //         ],
    //         'essential': [
    //             'hours-on-jobs', 
    //             'personal-timesheets', 
    //             'custom-time-types'
    //         ],
    //         'advanced': [
    //             'hours-on-jobs', 
    //             'personal-timesheets', 
    //             'custom-time-types'
    //         ],
    //         'enterprise': [
    //             'hours-on-jobs', 
    //             'personal-timesheets', 
    //             'custom-time-types'
    //         ],
    //     }
    // },
    'field-service-app': {
        features: {
            'starter': [
                'jobs', 
                'description', 
                'materials', 
                'documents', 
                'photos', 
                'hours', 
                'timer', 
                'signatures',
                'create-new-job'
            ],
            'essential': [
                'jobs', 
                'description', 
                'materials', 
                'documents', 
                'photos', 
                'hours', 
                'timer', 
                'signatures', 
                'barcode-scanner', 
                'create-new-job',
                'mandatory-fields',
                // 'personal-timesheet', 
                // 'install-base', 
                // 'job-week-view', 
                // 'job-storage' 
            ],
            'advanced': [
                'jobs', 
                'description', 
                'materials', 
                'documents', 
                'photos', 
                'hours', 
                'timer', 
                'signatures', 
                'barcode-scanner', 
                'create-new-job',
                'mandatory-fields',
                // 'personal-timesheet', 
                // 'install-base', 
                // 'job-week-view', 
                // 'job-storage', 
                // 'job-storage-distance', 
                // 'custom-job-fields', 
                // 'job-install-base-fields', 
                // 'field-inventory', 
                // 'leave-request', 
                // 'expense-registration'
            ],
            'enterprise': [
                'jobs', 
                'description', 
                'materials', 
                'documents', 
                'photos', 
                'hours', 
                'timer', 
                'signatures', 
                'barcode-scanner',
                'create-new-job', 
                'mandatory-fields',
                // 'personal-timesheet', 
                // 'install-base', 
                // 'job-week-view', 
                // 'job-storage', 
                // 'job-storage-distance', 
                // 'custom-job-fields', 
                // 'job-install-base-fields', 
                // 'field-inventory', 
                // 'leave-request', 
                // 'expense-registration', 
                // 'mobile-scheduler'
            ],
        }
    },
    'sales': {
        features: {
            'starter': [],
            'essential': [
                'create-proposals', 
                'customize-pdf-layout', 
                'proposal-type-templates', 
                // 'online-signing'
            ],
            'advanced': [
                'create-proposals', 
                'customize-pdf-layout', 
                'proposal-type-templates', 
                // 'online-signing', 
                'line-discounts', 
                // 'sub-product-lines', 
                // 'proposal-templates', 
                'deal-pipelines', 
                // 'proposal-view-notification'
            ],
            'enterprise': [
                'create-proposals', 
                'customize-pdf-layout', 
                'proposal-type-templates', 
                // 'online-signing', 
                'line-discounts', 
                // 'sub-product-lines', 
                // 'proposal-templates', 
                'deal-pipelines', 
                // 'proposal-view-notification'
            ],
        }
    },
    'orders': {
        features: {
            'starter': [],
            'essential': [
                'create-orders', 
                'customize-pdf-layout', 
                'order-type-templates', 
                // 'purchase-orders'
            ],
            'advanced': [
                'create-orders', 
                'customize-pdf-layout', 
                'order-type-templates', 
                // 'purchase-orders', 
                // 'order-statuses', 
                // 'order-task-workflows'
            ],
            'enterprise': [
                'create-orders', 
                'customize-pdf-layout', 
                'order-type-templates', 
                // 'purchase-orders', 
                // 'order-statuses', 
                // 'order-task-workflows'
            ],
        }
    },
    'invoices': {
        features: {
            'starter': [],
            'essential': [
                'create-invoices', 
                'customize-pdf-layout', 
                'invoice-type-templates', 
                'payment-registration', 
                // 'automated-reminder-emails'
            ],
            'advanced': [
                'create-invoices', 
                'customize-pdf-layout', 
                'invoice-type-templates', 
                'payment-registration', 
                // 'automated-reminder-emails', 
                // 'line-discounts', 
                // 'sub-product-lines', 
                // 'recurring-invoices'
            ],
            'enterprise': [
                'create-invoices', 
                'customize-pdf-layout', 
                'invoice-type-templates', 
                'payment-registration', 
                // 'automated-reminder-emails', 
                // 'line-discounts', 
                // 'sub-product-lines', 
                // 'recurring-invoices'
            ],
        }
    },
    // 'email-sms': {
    //     features: {
    //         'starter': [
    //             'email-workorder', 
    //             'crafter-branding'
    //         ],
    //         'essential': [
    //             'send-emails', 
    //             'no-branding', 
    //             'custom-templates', 
    //             'automated-appointment-reminders', 
    //             'send-sms-reminders'
    //         ],
    //         'advanced': [
    //             'send-emails', 
    //             'no-branding', 
    //             'custom-templates', 
    //             'automated-appointment-reminders', 
    //             'send-sms-reminders', 
    //             'automated-email-workflows', 
    //             'custom-email-layouts', 
    //             'office-365-email-integration'
    //         ],
    //         'enterprise': [
    //             'send-emails', 
    //             'no-branding', 
    //             'custom-templates', 
    //             'automated-appointment-reminders', 
    //             'send-sms-reminders', 
    //             'automated-email-workflows', 
    //             'custom-email-layouts', 
    //             'office-365-email-integration'
    //         ],
    //     }
    // },
    'contacts': {
        features: {
            'starter': [
                'create-contacts'
            ],
            'essential': [
                'create-contacts',
                'contact-job-history', 
                'changelog', 
                // 'install-base'
            ],
            'advanced': [
                'create-contacts', 
                'contact-job-history', 
                'changelog', 
                // 'install-base', 
                // 'install-base-fields', 
                // 'contact-discounts', 
                // 'client-portal'
            ],
            'enterprise': [
                'create-contacts', 
                'contact-job-history', 
                'changelog', 
                // 'install-base', 
                // 'install-base-fields', 
                // 'contact-discounts', 
                // 'client-portal'
            ],
        }
    },
    // 'tasks': {
    //     features: {
    //         'starter': [],
    //         'essential': [
    //             'add-tasks', 
    //             'add-notes', 
    //             'timeline-changelog'
    //         ],
    //         'advanced': [
    //             'add-tasks', 
    //             'add-notes', 
    //             'timeline-changelog', 
    //             'automated-task-reminders', 
    //             'custom-task-workflows'
    //         ],
    //         'enterprise': [
    //             'add-tasks', 
    //             'add-notes', 
    //             'timeline-changelog', 
    //             'automated-task-reminders', 
    //             'custom-task-workflows'
    //         ],
    //     }
    // },
    'product-management': {
        features: {
            'starter': [
                'add-products', 
                'add-hour-rates'
            ],
            'essential': [
                'add-products', 
                'add-hour-rates',
                // 'services', 
                'product-categories'
            ],
            'advanced': [
                'add-products', 
                'add-hour-rates',
                // 'services', 
                'product-categories', 
                // 'combination-products', 
                'client-rates'
            ],
            'enterprise': [
                'add-products', 
                'add-hour-rates',
                // 'services', 
                'product-categories', 
                // 'combination-products', 
                'client-rates'
            ],
        }
    },
    // 'inventory': {
    //     features: {
    //         'starter': [],
    //         'essential': [
    //             'inventory', 
    //             'one-stockroom', 
    //             'purchase-orders'
    //         ],
    //         'advanced': [
    //             'inventory', 
    //             'multiple-stockrooms', 
    //             'purchase-orders', 
    //             'stock-minimum', 
    //             'field-stockrooms'
    //         ],
    //         'enterprise': [
    //             'inventory', 
    //             'multiple-stockrooms', 
    //             'purchase-orders', 
    //             'stock-minimum', 
    //             'field-stockrooms'
    //         ],
    //     }
    // },
    'team-management': {
        features: {
            'starter': [
                'fixed-roles'
            ],
            'essential': [
                'fixed-roles', 
                'groups'
            ],
            'advanced': [
                'custom-roles', 
                'groups', 
                // 'employee-page', 
                // 'overtime-registration', 
                // 'leave-requests', 
                // 'expense-declarations'
            ],
            'enterprise': [
                'custom-roles', 
                'groups', 
                // 'employee-page', 
                // 'overtime-registration', 
                // 'leave-requests', 
                // 'expense-declarations'
            ],
        }
    },
    'customization': {
        features: {
            'starter': [],
            'essential': [
                // '5-custom-fields', 
                // 'custom-forms',
                'customize-pdf-layouts',
                'custom-labels', 
                // 'cost-centers', 
            ],
            'advanced': [
                // '50-custom-fields',
                // 'custom-forms', 
                'customize-pdf-layouts', 
                'custom-labels',
                // 'cost-centers',
                'custom-document-types', 
                 
            ],
            'enterprise': [
                // 'unlimited-custom-fields', 
                // 'custom-forms',
                'customize-pdf-layouts',
                'custom-labels',
                 // 'cost-centers', 
                'custom-document-types', 
                // 'custom-field-api-access',
                'multi-tenants'
            ],
        }
    },
    // 'reporting': {
    //     features: {
    //         'starter': [
    //             'default-exports', 
    //             'default-filters'
    //         ],
    //         'essential': [
    //             'default-exports', 
    //             'default-filters', 
    //             'default-dashboard'
    //         ],
    //         'advanced': [
    //             'custom-exports', 
    //             'custom-filters', 
    //             'custom-dashboard', 
    //             'default-reports', 
    //             'default-role-kpis'
    //         ],
    //         'enterprise': [
    //             'custom-exports', 
    //             'custom-filters', 
    //             'custom-dashboard', 
    //             'default-reports', 
    //             'default-role-kpis', 
    //             'custom-reports'
    //         ],
    //     }
    // },
    // 'security': {
    //     features: {
    //         'starter': [],
    //         'essential': [],
    //         'advanced': [],
    //         'enterprise': [
    //             '2fa', 
    //             'security-alerts', 
    //             'local-data-backup'
    //         ],
    //     }
    // },
    'support': {
        features: {
            'starter': [
                'email', 
                'knowledge-base', 
                'tutorial-videos'
            ],
            'essential': [
                'email', 
                'knowledge-base', 
                'tutorial-videos'
            ],
            'advanced': [
                'telephone', 
                'knowledge-base', 
                'tutorial-videos', 
            ],
            'enterprise': [
                'telephone', 
                'knowledge-base', 
                'tutorial-videos', 
                // 'dedicated-account-manager', 
                // 'quarterly-check-ins'
            ],
        }
    },
}