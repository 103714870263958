import { JobType } from "../../views/jobs/JobTypes";

export type SchedulingViewType = {
    viewType: string; // The type of view of the scheduler. For example: 'employee_list', 'employee_timeline' or 'project_planner'
    direction: string; // The direction of the scheduler: 'stacked' or 'columns'.
    showDays: string; // The days to display in the scheduler. For example: 'mon-fri', 'mon-sat' or 'all'
    showTimes?: { startTime: string, endTime: string } | null; 
    displayDate: Date; // The date on which the scheduling is shown
    isDayView?: boolean; // Determines wheter the day view is active. Otherwise the week view is shown.
}

export type SchedulingBoardType = {
    id: number;
    name: string;
    ordering: number;
    is_active: boolean;
    division: number | null;
}

export type ResourceGroupType = {
    id: number | string | null;
    uuid?: string;
    scheduling_board: number;
    name: string;
    ordering: number;
    resources: ResourceType[];
    deleted: boolean;
    division: number | null;
};

export const createNewResourceGroup = (scheduling_board: number, uuid: string, ordering: number): ResourceGroupType => ({
    id: null,
    uuid: uuid,
    scheduling_board: scheduling_board,
    name: '',
    ordering: ordering,
    resources: [],
    deleted: false,
    division: null,
});

export type ResourceType = {
    id: number | string | null;
    uuid?: string;
    resource_group: number | string;
    user_hash: string | null;
    name: string;
    image: string | null;
    remark: string | null;
    ordering: number;
    deleted: boolean;
    division: number | null;
};

export const createNewResource = (resource_group: number | string, uuid: string, ordering: number): ResourceType => ({
    id: null,
    uuid: uuid,
    resource_group: resource_group,
    user_hash: null,
    name: '',
    image: null,
    remark: null,
    ordering: ordering,
    deleted: false,
    division: null,
});

export interface SchedulingAppointmentType {
    id: number | null;
    uuid?: string;
    start_datetime: string | null;
    end_datetime: string | null;
    start_date: string | null;
    end_date: string | null;
    start_time: string | null;
    end_time: string | null;
    duration: number | null;
    duration_string: string | null;
    timezone: string | null;
    status: string;
    custom_color: string | null;
    resource_ids: number[];
    job: {
        id: number;
        client_name?: string;
        workflow_name?: string;
        work_location_street?: string;
        work_location_house_number?: string;
        work_location_postal_code?: string;
        work_location_city?: string;
        internal_reference: string | null;
        external_reference: string | null;
        number: string | null;
        status_name: string | null;
        resolution: string | null;
        main_description: string | null;
    } | null;
    deleted: boolean;
}

export interface EventType {
    key: string; // Composed non existing key, combination of appointment.id + resource.id
    appointmentUuid?: string;
    appointmentId: number | null;
    resourceId: number | string;
    startDateTime?: string;
    endDateTime?: string;
    dayKey: string;
    timeSlot?: string;
    startTime?: string;
    endTime?: string;
    durationMinutes: number;
    status: string;
    custom_color: string | null;
    job: {
        id: number;
        client_name?: string;
        workflow_name?: string;
        work_location_street?: string;
        work_location_house_number?: string;
        work_location_postal_code?: string;
        work_location_city?: string;
        internal_reference: string | null;
        external_reference: string | null;
        number: string | null;
        status_name: string | null;
        resolution: string | null;
        main_description: string | null;
    } | null;
    deleted: boolean;
}

export interface EventsListType {
    [eventKey: string]: EventType;
}

export interface EventsByResourceType {
    [resourceId: string]: string[];
}

export interface TimeBlock {
    name: string;
    start_time: string;
    end_time: string;
    division: number;
}

export type SchedulerDraggableId =
    | { 
        type: 'jobInbox'; 
        job: JobType;
        weekKey?: string;
    } 
    | { 
        type: 'event'; 
        event: EventType, 
    };

export type SchedulerDroppableId =
    | { 
        type: 'timeSlot'; 
        resourceId: string | number; 
        day: string; 
        timeSlot: string;
    }
    | { 
        type: 'timeBlock'; 
        resourceId: string | number; 
        day: string; 
        timeBlock: string;
    }
    | { 
        type: 'dayList'; 
        resourceId: string | number; 
        day: string; 
    }
    | { 
        type: 'jobInbox';
        section: 'pastJobs' | 'weekJobs';
        weekKey?: string;
    };

export type SchedulerDraggingPlaceholder = {
    resourceId: number | string, 
    day: string, 
    timeSlot?: string, 
    durationMinutes?: number
}

export interface SchedulerDragEventDetails {
    sourceType?: string;
    sourceSection?: string;
    sourceWeekKey?: string;
    destinationSection?: string;
    destinationWeekKey?: string;
    draggableItemType?: string;
    draggableItemId?: number;
    draggableJob?: JobType;
}