import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from 'components/modals/ModalContext';
import { EventsByResourceType, EventsListType, EventType, ResourceGroupType, SchedulerDraggingPlaceholder, SchedulingViewType, TimeBlock } from './SchedulingTypes';
import { Droppable } from 'services/dragdrop/Droppable';
import { generateDayKey } from './functions/schedulingUtils';
import useAdjustSchedulerSize from './functions/useAdjustSchedulerSize';
import JobForm from '../jobs/JobForm';
import Event from './Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretLeft, faCaretRight, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { getOverlappingEvents } from './functions/calculateOverlappingEvents';
import { saveData } from 'services/api/saveData';

interface ResourceProps {
    view: SchedulingViewType;
    resourceGroups: ResourceGroupType[];
    days: Date[];
    hours: string[] | undefined;
    renderTimeSlots: boolean | undefined;
    timeSlots: string[] | undefined;
    isDragging: boolean;
    draggingPlaceholder: SchedulerDraggingPlaceholder | null;
    renderTimeBlocks: boolean | undefined;
    timeBlocks: TimeBlock[];
    events: EventsListType;
    eventsByResource: EventsByResourceType;
    eventOverlapMap: Map<string, EventType[]>;
    showInbox: boolean;
    showProfilePictures: boolean;
    showUnassignedResource: boolean;
    eventColorMethod: string | null;
    setResourceGroups: React.Dispatch<React.SetStateAction<ResourceGroupType[]>>;
    onScheduleListHeightChange: (height: number) => void;
}

const Resources: React.FC<ResourceProps> = ({ 
    view, resourceGroups, days, hours, renderTimeSlots, timeSlots, isDragging, draggingPlaceholder, renderTimeBlocks, 
    timeBlocks, events, eventsByResource, eventOverlapMap, showInbox, showProfilePictures, showUnassignedResource, 
    eventColorMethod, setResourceGroups, onScheduleListHeightChange,
}) => {
    const { t } = useTranslation();
    const { isTopNavbarEnabled, setFloatingAlert } = useGlobalContext();
    const { userLocale } = useSettings();
    const { initializeModal } = useModal();
    const calendarRef = useRef<HTMLDivElement | null>(null);
    const { scheduleListHeight, pixelsPerMinute, dayColumnHeight } = useAdjustSchedulerSize(calendarRef, isTopNavbarEnabled, view.direction, view.viewType, days.length, timeSlots, showInbox, events);

    // Adjust the rows height to fill the screen
    useAdjustSchedulerSize(calendarRef, isTopNavbarEnabled, view.direction, view.viewType, days.length, timeSlots, showInbox, events);

    // Pass the calculated schedule list height to the parent component
    useEffect(() => {
        onScheduleListHeightChange(scheduleListHeight);
    }, [scheduleListHeight]);

    // Get todays date
    const today = new Date().toLocaleDateString(userLocale, { weekday: 'short', day: 'numeric', month: 'numeric' });

    // Generate time slot class name, to show divider borders
    const generateTimeSlotClass = (
        timeSlot: string, 
        startTime: string, 
        endTime: string, 
        dragOver: boolean, 
        isDragging: boolean,
        hasEvent: boolean
    ): string => {
        // Set the base class
        let className = 'time-slot';

        // If the timeslot is the same as the view start- and end time, return base class
        if (timeSlot === startTime || timeSlot === endTime) {
            return className;
        }

        // Determine the timeslot class based on the minutes
        const minutes = timeSlot.split(':')[1];
        if (minutes === '30') className += ' half-hour';
        if (minutes === '00') className += ' whole-hour';

        // Add no hover if dragging over this timeslot
        if (dragOver || isDragging) className += ' no-hover';

        // Add classname when the timeslot has an event
        if (hasEvent) className += ' has-event';

        return className;
    };

    // Handle add job
    const handleAddJob = (e: React.MouseEvent, resourceUserHash: string | null, dayKey: string, timeSlot?: string) => {
        // Prevent default
        e.preventDefault();

        // Configure the selected calendar position data
        const selectedCalendarPosition = {
            selectedResource: resourceUserHash,
            selectedDate: dayKey,
            selectedTime: timeSlot
        }

        // Show the job form
        initializeModal(
            React.cloneElement(<JobForm />, { linkedItem: selectedCalendarPosition }), {
                title: t('job.general.direct_schedule_label')
            } 
        );
    }

    // Determine the resource row classname
    const determineResourceRowClass = (isUnassignedRow: boolean, isFirstResourceInFirstGroup: boolean): string => {
        let className = isUnassignedRow ? 'unassigned-row' : 'resource-row';
    
        if (view.direction === 'stacked') {
            className += isUnassignedRow ? ` grid-${days.length}-columns` : ` adjust-height grid-${days.length}-columns`;
        } else {
            className += ' adjust-width';
        }

        // Add class to remove the border if the unassigned resource is not visible
        if (!showUnassignedResource && !isUnassignedRow && view.direction === 'columns' && isFirstResourceInFirstGroup) {
            className += ' no-border-left';
        }
    
        return className;
    };

    // Filter out the unassigned row from the resource groups
    const nonUnassignedGroupAmount = () => {
        const filteredGroups = resourceGroups
            // Filter out deleted resources groups
            .filter(group => !group.deleted)

            // Filter out the unassigned row
            .filter(group => group.name !== 'Unassigned')
        
        return filteredGroups.length;
    }

    // Determine the dragging placeholder style
    const determineDraggingPlaceholderStyle = (viewType: string, resourceDirection: string, durationMinutes?: number, pixelsPerMinute?: number): React.CSSProperties | undefined => {

        // Set the size of the event
        let eventSize;
        if (pixelsPerMinute && durationMinutes) {
            eventSize = durationMinutes * pixelsPerMinute;
        
        // For scheduling list view, set a hardcoded event size
        } else {
            eventSize = 1.75;
        }
    
        // For stacked direction, the events are positioned from left to right
        if (resourceDirection === 'stacked') {
            return {
                position: viewType === 'employee_timeline' ? 'absolute' : 'relative',
                top: '0px',
                left: `0px`,
                width: viewType === 'employee_timeline' ? `${eventSize}px` : undefined,
                height: viewType === 'employee_timeline' ? '99%' : `${eventSize}rem`,
                marginTop: viewType === 'employee_timeline' ? undefined : '0.25rem',
                marginLeft: viewType === 'employee_timeline' ? undefined : '0.25rem',
                marginRight: viewType === 'employee_timeline' ? undefined : '0.25rem',
                backgroundColor: '#ffd1a3',
                border: '1px dashed #df4203',
                borderRadius: '0.25rem',
                zIndex: 1000
            };
        }
    
        // For columns direction, the events are positioned from top to bottom
        if (resourceDirection === 'columns') {
            return {
                position: viewType === 'employee_timeline' ? 'absolute' : 'relative',
                top: `0px`,
                left: '0px',
                width: '99%',
                height: viewType === 'employee_timeline' ? `${eventSize}px` : `${eventSize}rem`,
                marginTop: viewType === 'employee_timeline' ? undefined : '0.25rem',
                backgroundColor: '#ffd1a3',
                border: '1px dashed #df4203',
                borderRadius: '0.25rem',
                zIndex: 1000
            };
        }
    
        return {};
    };

    // Move resource to reorder
    const moveResource = async (
        groupIndex: number, 
        resourceIndex: number, 
        direction: 'up' | 'down' | 'left' | 'right'
    ) => {
        const group = resourceGroups[groupIndex];
        const resources = [...group.resources];
    
        if (!resources || resources.length < 2) return;
    
        let newIndex = direction === 'up' || direction === 'left' ? resourceIndex - 1 : resourceIndex + 1;
    
        if (newIndex < 0 || newIndex >= resources.length) return;
    
        // Update the ordering of the resources
        [resources[resourceIndex].ordering, resources[newIndex].ordering] =
            [resources[newIndex].ordering, resources[resourceIndex].ordering];

        // Configure the submit data
        const submitData = resources.map(r => ({ 
            id: r.id, 
            name: r.name,
            deleted: r.deleted,
            ordering: r.ordering
        }));
    
        try {
            // Save the ordering
            await saveData({ apiUrl: 'patch_resource_batch', method: 'patch', data: submitData });

            // Update the resource group state
            setResourceGroups(prev => {
                const updatedGroups = [...prev];
                updatedGroups[groupIndex] = { ...group, resources };
                return updatedGroups;
            });

            // Show success alert
            setFloatingAlert({ 'type': 'success', 'message': 'validation.scheduling.reorder_resource_successful' })            
        } catch (error) {
            // Show error alert
            setFloatingAlert({ 'type': 'danger', 'message': 'validation.scheduling.reorder_resource_failed' })
        }
    };
    
    return (
        <div ref={calendarRef} 
             className='calendar'>

            {/* Day names */}
            <div className={`header grid-${days.length}-columns`}>
                <div className={`name-column ${nonUnassignedGroupAmount() > 1 ? 'show-resource-group-header-div' : ''}`}></div>
                {days.map((day, index) => {
                    return (
                        <div className='day-column' 
                             key={index}
                             style={view.direction === 'columns' ? { height: dayColumnHeight || 'auto' } : undefined}>
                            <div className='date-name'>
                                <span className={day.toLocaleDateString(userLocale, { weekday: 'short', day: 'numeric', month: 'numeric' }) === today ? 'today' : ''}>
                                    {day.toLocaleDateString(userLocale, { weekday: 'short', day: 'numeric', month: 'numeric' }) === today ? (
                                        'Vandaag'
                                    ): (
                                        day.toLocaleDateString(userLocale, { weekday: 'short', day: 'numeric', month: 'numeric' })
                                    )}
                                </span>
                            </div>
                            {renderTimeSlots && hours && (
                                <div className='header-hours'>
                                    {hours.map((hour, hourIndex) => {
                                        return (
                                            <div key={hourIndex} className={`header-hour ${hourIndex === 0 ? 'invisible' : ''}`}>
                                                {hour}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>

            {/* Render resource groups */}
            {resourceGroups
                .filter(group => !group.deleted)
                .sort((a, b) => a.ordering - b.ordering)
                .map((group, groupIndex) => (

                <div key={group.id} className="resource-group">

                    {/* Render group name */}
                    {nonUnassignedGroupAmount() > 1 && (
                        view.direction === 'columns' ? (
                            // For columns view, render resource group header div for all resource groups
                            <div className={`resource-group-header ${groupIndex > 0 ? 'border' : ''}`}>
                                {group.name !== 'Unassigned' && (
                                    group.name
                                )}
                            </div>
                        ) : (group.name !== 'Unassigned' && (
                            // For stacked view, only render resource group header div for non unassigned groups
                            <div className='resource-group-header'>
                                {group.name}
                            </div>
                        ))                            
                    )}
                    
                    {/* Render resource rows */}
                    <div className='resources'>
                        {group.resources
                            .filter(resource => !resource.deleted)
                            .sort((a, b) => a.ordering - b.ordering)
                            .map((resource, resourceIndex) => {

                            // Determine if this resource row is the unassigned row
                            const isUnassigned = resource.resource_group === 'unassigned';

                            // Determine if this is the first resource in the first group
                            const isFirstResourceInFirstGroup = groupIndex === 0 && resourceIndex === 0;

                            // Set the resource id
                            const resourceId = isUnassigned ? 'unassigned' : resource.id;

                            return (
                                <div className={determineResourceRowClass(isUnassigned, isFirstResourceInFirstGroup)}>

                                    {/* Resource name */}
                                    <div className='name-column'>
                                        {isUnassigned ? (
                                            t('scheduling.scheduling_board.unassigned_name_label')
                                        ) : (
                                            <>
                                                {resource.image && showProfilePictures && (
                                                    <div style={{ backgroundImage: `url(${resource.image})` }} 
                                                        className='resource-image' />
                                                )}

                                                <div className='resource-details'>
                                                    <div>{resource.name}</div>
                                                    {resource.remark && <div className='remark'>{resource.remark}</div>}
                                                </div>

                                                <div className={`order-icon tooltip-icon ${group.resources.length === 1 ? 'visibility-hidden' : ''}`}>
                                                    {view.direction === 'stacked' && (
                                                        <>
                                                            {resourceIndex > 0 && (
                                                                <>
                                                                    <FontAwesomeIcon 
                                                                        icon={faCaretUp} 
                                                                        onClick={() => moveResource(groupIndex, resourceIndex, 'up')} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </>
                                                            )}
                                                            {resourceIndex < group.resources.length - 1 && (
                                                                <>
                                                                    <FontAwesomeIcon 
                                                                        icon={faCaretDown} 
                                                                        onClick={() => moveResource(groupIndex, resourceIndex, 'down')} />
                                                                    <span className="tooltip">{t('general.reorder')}</span>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {view.direction === 'columns' && (
                                                        <>
                                                            {resourceIndex > 0 && (
                                                                <>
                                                                    <FontAwesomeIcon 
                                                                        icon={faCaretLeft} 
                                                                        onClick={() => moveResource(groupIndex, resourceIndex, 'left')} />
                                                                    <span className="tooltip tooltip-left">{t('general.reorder')}</span>
                                                                </>
                                                            )}
                                                            {resourceIndex < group.resources.length - 1 && (
                                                                <>
                                                                    <FontAwesomeIcon 
                                                                        icon={faCaretRight} 
                                                                        onClick={() => moveResource(groupIndex, resourceIndex, 'right')} />
                                                                    <span className="tooltip tooltip-left">{t('general.reorder')}</span>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                
                                    {/* Render days */}
                                    {days.map((day) => {
                                        const dayKey = generateDayKey(day);
                                        const resourceEvents = (eventsByResource[resourceId!] || [])
                                            .map(eventId => events[eventId])
                                            .filter(event => event !== undefined);
                                        const dayEvents = resourceEvents.filter(event => event.dayKey === dayKey);

                                        // Determine if the dragging is over the current day list
                                        const dragOverCurrentDayList =
                                            draggingPlaceholder?.resourceId === resourceId &&
                                            draggingPlaceholder?.day === dayKey &&
                                            !renderTimeSlots;

                                        

                                        return (
                                            <div className='day-column' 
                                                    key={`${resource.id}-${dayKey}`}
                                                    style={view.direction === 'columns' ? { height: dayColumnHeight || 'auto' } : undefined}>
                                                {renderTimeSlots && timeSlots ? (
                                                    timeSlots.map((timeSlot) => {
                                                        // Determine if the dragging is over the current timeslot
                                                        const dragOverCurrentTimeSlot =
                                                            draggingPlaceholder?.resourceId === resourceId &&
                                                            draggingPlaceholder?.day === dayKey &&
                                                            draggingPlaceholder?.timeSlot === timeSlot;

                                                        // Determine if an event is rendered within the timeslot
                                                        const hasEvent = dayEvents.some(event => event.timeSlot === timeSlot);

                                                        // Generate timeslot class name, to show divider borders
                                                        const timeSlotClass = generateTimeSlotClass(timeSlot, view.showTimes!.startTime, view.showTimes!.endTime, dragOverCurrentTimeSlot, isDragging, hasEvent);

                                                        return (
                                                            <Droppable 
                                                                droppableId={{ type: 'timeSlot', resourceId: resourceId, day: dayKey, timeSlot }} 
                                                                key={`${resource.id}-${dayKey}-${timeSlot}`}
                                                                showPlaceholder={false}>
                                                                <div className={`${timeSlotClass}`}
                                                                     onClick={(e) => {e.preventDefault(); handleAddJob(e, resource.user_hash, dayKey, timeSlot)}}>

                                                                    {dragOverCurrentTimeSlot && (
                                                                        <div className={`tooltip ${view.direction === 'columns' ? 'tooltip-left' : ''}`}
                                                                             style={dragOverCurrentTimeSlot ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                                                                            {timeSlot}
                                                                        </div>
                                                                    )}

                                                                    {dragOverCurrentTimeSlot && (
                                                                        <div className="dragging-placeholder" 
                                                                            style={determineDraggingPlaceholderStyle(
                                                                                view.viewType,
                                                                                view.direction,
                                                                                draggingPlaceholder.durationMinutes,
                                                                                pixelsPerMinute!,
                                                                            )}>
                                                                        </div>
                                                                    )}

                                                                    {dayEvents.filter(event => event.timeSlot === timeSlot).map((event) => (
                                                                        <Event
                                                                            event={event}
                                                                            view={view}
                                                                            currentResource={resourceId!}
                                                                            currentDay={dayKey}
                                                                            currentTimeSlot={timeSlot}
                                                                            pixelsPerMinute={pixelsPerMinute}
                                                                            resourceDirection={view.direction}
                                                                            colorMethod={eventColorMethod}
                                                                            overlappingEvents={getOverlappingEvents(eventOverlapMap, String(resourceId!), dayKey, timeSlot)}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </Droppable>
                                                        )
                                                    })
                                                ) : (
                                                    renderTimeBlocks && timeBlocks?.length ? (
                                                        timeBlocks.map((timeBlock) => {
                                                            return (
                                                                <Droppable droppableId={{ 
                                                                    type: 'timeBlock', 
                                                                    resourceId: resource.id,
                                                                    day: dayKey,
                                                                    timeBlock: timeBlock.start_time
                                                                }} 
                                                                    key={`${resource.id}-${dayKey}-${timeBlock.start_time}`}
                                                                    showPlaceholder={false}>
                                                                    <div className=''
                                                                         onClick={(e) => handleAddJob(e, resource.user_hash, dayKey)}>
                                                                        {dayEvents.filter(event => event.startTime === timeBlock.start_time).map((event) => (
                                                                            <Event
                                                                                event={event}
                                                                                view={view}
                                                                                currentResource={resourceId!}
                                                                                currentDay={dayKey}
                                                                                // currentTimeBlock={event.startTime}
                                                                                resourceDirection={view.direction}
                                                                                colorMethod={eventColorMethod}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </Droppable>
                                                            )
                                                        })
                                                    ) : (
                                                        <Droppable droppableId={{ type: 'dayList', resourceId: resourceId, day: dayKey }} 
                                                                    key={`${resource.id}-${dayKey}`}
                                                                    showPlaceholder={false}>
                                                            <div className='day-list'
                                                                    onClick={(e) => handleAddJob(e, resource.user_hash, dayKey)}>
                                                                        
                                                                {dayEvents.map((event) => (
                                                                    <Event 
                                                                        event={event}
                                                                        view={view}
                                                                        currentResource={resourceId!}
                                                                        currentDay={dayKey}
                                                                        resourceDirection={view.direction}
                                                                        colorMethod={eventColorMethod}
                                                                    />
                                                                ))}
                                                                {dragOverCurrentDayList && (
                                                                        <div className="dragging-placeholder" 
                                                                            style={determineDraggingPlaceholderStyle(
                                                                                view.viewType,
                                                                                view.direction
                                                                            )}>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </Droppable>
                                                    )
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div> 
            ))}
        </div>
    )
}

export default Resources;