import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const JobPdfLayoutForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    // Set the api object
    const apiObject = 'layout'

    // Set the linked item
    const linkedItem = { 
        connected_feature: 'job',  
        layout_type: 'pdf'
    }

    // Set the redirect to after saving the item
    const redirectTo = {
        url: 'settings/templates/layout/pdf-layout',
        itemIdField: 'pdf_layout_id'
    }

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'radio', 
            name: 'layout_type',
            label: 'layout.general.layout_type_label',
            dependentOn: { fieldName: '', value: [''] }, // Hacky solution to hide field in the form
            defaultValue: 'pdf', 
            options: [
                { value: 'pdf', name: 'layout.general.layout_type_pdf_label'},
            ],
        },
        {
            name: 'name',
            label: 'layout.general.name_label',
            type: 'text',
            placeholder: 'layout.general.name_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'connected_feature', 
            dependentOn: [
               { fieldName: 'layout_type', value: 'pdf' },
               { fieldName: '', value: [''] }, // Hacky solution to hide field in the form
            ],
            label: 'layout.general.connected_feature_label',
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'job' },
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: ([
                { value: 'job', name: 'layout.connected_feature.job', requiredFeature: 'jobs' },
            ]),
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
            linkedItem={linkedItem}
            redirectTo={redirectTo}
        />
    );
};

export default JobPdfLayoutForm;