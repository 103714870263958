import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { saveData } from 'services/api/saveData';
import { EnvironmentSettingsType } from 'services/settings/SettingsTypes';
import { FieldData } from 'types/FieldTypes';
import '../../style/scss/button.scss';

interface SwitchButtonType {
    featureSwitch?: boolean;
    name?: string;
    value?: boolean;
    defaultValue?: 'on' | 'off';
    apiUrl?: string;
    isDisabled?: boolean;
    onToggle?: (isOn: boolean) => void;
}

/* 
 * SwitchButton.tsx
 * The switch button may be used turn off or turn on features and settings. In case of a feature switch, the 
 * button checks it initial on state based of the name of the feature is included in the active features of 
 * the authentication context, and it sends changes directly as 'active' or 'in_active' strings wrapped inside 
 * a 'features' key to the backend. In case of a normal settings switch, it sets the on state based on the given
 * data and sends the changed state back as boolean value.
 */

const SwitchButton: React.FC<SwitchButtonType & { data?: EnvironmentSettingsType | FieldData }> = ({ 
    featureSwitch, name, data, value, defaultValue, apiUrl, isDisabled = false, onToggle 
}) => {
    const { activeFeatures } = useAuthContext();
    const { setFloatingAlert } = useGlobalContext();

    // Determine the initial state of the switch based on the feature status in active features
    const initialIsOn = featureSwitch && name ? activeFeatures.includes(name) : false;
    const [isOn, setIsOn] = useState(initialIsOn);
    const [hasMounted, setHasMounted] = useState(false);

    // Track if the switch button is mounted on first render
    useEffect(() => {
        const timer = setTimeout(() => setHasMounted(true), 50);
        return () => clearTimeout(timer);
    }, []);

    // Set the initial state of the switch
    useEffect(() => {
        // If the switch button is a feature switch, determine the state by checking if the feature is active
        if (featureSwitch) {
            const isActive = name ? activeFeatures.includes(name) : false;
            setIsOn(isActive);

        // If fetched data is provided, determine the switch state by passing in the current value
        } else if (data && name) {
            const value = data[name as keyof EnvironmentSettingsType];
            if (typeof value === 'boolean') {
                setIsOn(value);
            }
        } else if (value) {
            if (defaultValue === 'on') setIsOn(true);
            if (defaultValue === 'off') setIsOn(false);
        }
    }, [data, featureSwitch, activeFeatures]);

    // Set the value if given by the parent component
    useEffect(() => {
        if (value !== undefined) {
            setIsOn(value)
        }
    }, [value]);

    // Give the initial is on state to the parent component
    useEffect(() => {
        if (onToggle && value === undefined) onToggle(isOn);
    }, [isOn]);

    // Handle the switch toggle
    const toggleSwitch = async () => {
        if (!isDisabled) {
            const newIsOn = !isOn;
            setIsOn(newIsOn);

            // Send the state to the parent component
            if (onToggle) {
                onToggle(newIsOn);
            }

            // If api url is given, send the data to the server directly from this component
            if (apiUrl && name) {
                // Configure the data to send to the api
                let submitData = {};
                if (featureSwitch) {
                    // For feature switches, send active or inactive strings wrapped in a 'features' key
                    submitData = { 
                        features: {
                            [name]: newIsOn ? "active" : "in_active" 
                        }
                    };
                } else {
                    // For the rest, just send a boolean value
                    submitData = { [name]: newIsOn ? true : false}
                }

                // Execute the save data function
                try {
                    await saveData({ apiUrl: apiUrl, method: 'patch', data: submitData });
                    setFloatingAlert({ type: 'success' })
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    return (
        <div className={`switch-button ${isOn ? 'on' : ''} ${isDisabled ? 'disabled' : ''} ${hasMounted ? 'animate' : 'no-transition'}`} 
             onClick={toggleSwitch}>
            <div className='switch-button-handle'></div>
        </div>
    );
};

export default SwitchButton;