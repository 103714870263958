import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { saveData } from 'services/api/saveData';
import { encryptData } from 'services/authentication/encryptData';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CheckboxCheck from 'assets/CheckboxCheck.svg';
import '../../style/scss/wizard.scss';

const TrialWizard: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { environmentHash, setRedirectTo } = useAuthContext();
    const { step } = useParams<{ step: string }>();
    const [currentStep, setCurrentStep] = useState<number>(parseInt(step || '1', 10));
    const [industry, setIndustry] = useState<string>('');
    const [companySize, setCompanySize] = useState<number | null>(null);
    const [companyStructure, setCompanyStructure] = useState<string>('');
    const [objectives, setObjectives] = useState<string[]>([]);
    const [features, setFeatures] = useState<string[]>([]);
    const [buttonLoader, setButtonLoader] = useState(false);

    // Set the industry options
    const industryOptions = [
		{ value: 'installation_plumbing', label: 'trial.industry.installation_plumbing' },
        { value: 'security_systems', label: 'trial.industry.security_systems' },
        { value: 'blinds', label: 'trial.industry.blinds'},
        { value: 'interior', label: 'trial.industry.interior' },
        { value: 'construction', label: 'trial.industry.construction' },
        { value: 'equipment_machinery', label: 'trial.industry.equipment_machinery' },
        { value: 'signage', label: 'trial.industry.signage' },
        { value: 'landscaping', label: 'trial.industry.landscaping' },
        { value: 'facility_management', label: 'trial.industry.facility_management' },
        { value: 'other', label: 'trial.industry.other' },
	];

    // Set the company structure options
	const companyStructureOptions = [
		{ value: 'freelance', label: 'trial.company_structure.freelance' },
		{ value: 'business_no_planner', label: 'trial.company_structure.business_no_planner' },
		{ value: 'business_office_managed', label: 'trial.company_structure.business_office_managed' },
        { value: 'business_multi_location', label: 'trial.company_structure.business_multi_location' },
	];

    // Set the objective options
	const objectiveOptions = [
		{ value: 'digitize', label: 'trial.objective.digitize' },
        { value: 'overview', label: 'trial.objective.overview' },
		{ value: 'structure', label: 'trial.objective.structure' },
		{ value: 'client_journey', label: 'trial.objective.client_journey' },
        { value: 'increase_revenue', label: 'trial.objective.increase_revenue' },
	];

    // Set the objective options
	const featureOptions = [
		{ value: 'workorders_scheduling', label: 'trial.feature.workorders_scheduling' },
        { value: 'timesheets', label: 'trial.feature.timesheets' },
        { value: 'invoicing', label: 'trial.feature.invoicing' },
        { value: 'sales', label: 'trial.feature.sales' },
        { value: 'inventory', label: 'trial.feature.inventory' },
        { value: 'subscriptions', label: 'trial.feature.subscriptions' },
        { value: 'asset_management', label: 'trial.feature.asset_management' },
        { value: 'inspections', label: 'trial.feature.inspections' },
        { value: 'reporting', label: "trial.feature.reporting" },
	];

    // Handle industry selection
	const handleIndustrySelection = (value: string) => {
		setIndustry(value);
		nextStep();
	};

    // Handle company structure selection
    const handleCompanyStructureSelection = (value: string) => {
		setCompanyStructure(value);
		nextStep();
	};

    // Handle objective selection
	const handleObjectiveSelection = (value: string) => {
		setObjectives(prev =>
			prev.includes(value) ? prev.filter(obj => obj !== value) : [...prev, value]
		);
	};

    // Handle feature selection
	const handleFeatureSelection = (value: string) => {
		setFeatures(prev =>
			prev.includes(value) ? prev.filter(obj => obj !== value) : [...prev, value]
		);
	};

    // Navigate to the next step
	const nextStep = () => {
		if (currentStep < 4) {
			const nextStep = currentStep + 1;
			setCurrentStep(nextStep);
			history.push(`/trial-wizard/${nextStep}`);
		}
	};

	// Navigate to the previous step
	const prevStep = () => {
		if (currentStep > 1) {
			const previousStep = currentStep - 1;
			setCurrentStep(previousStep);
			history.push(`/trial-wizard/${previousStep}`);
		}
	};

    // Renders the checkbox options of a wizard step
    const renderCheckboxOptions = (
        optionValue: string, 
        optionLabel: string,
        selectedValues: string[],
        handleSelection: (value: string) => void
    ) => (
        <div className='checkbox-item right'
             onClick={() => handleSelection(optionValue)}
             key={optionValue}>
            <div className="label-description-container">
                <label htmlFor={optionValue} className='checkbox-label'>
                    {t(optionLabel)}
                </label>
            </div>
            <div className="checkbox-wrapper">
                <input
                    type="checkbox"
                    id={optionValue}
                    name={optionValue}
                    checked={objectives.includes(optionValue)}
                    onChange={() => handleSelection(optionValue)}
                    className='hidden-checkbox'
                />
                <label htmlFor={optionValue}
                    className={`custom-checkbox ${selectedValues.includes(optionValue) ? 'checked' : ''}`}>
                    {selectedValues.includes(optionValue) && 
                        <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                    }
                </label>
            </div>
        </div>
    );

    // Renders the step count indicator
    const renderStepCountIndicator = () => (
        <div className='step-counter'>
            {t('wizard.general.step_counter', { currentStep: currentStep, totalSteps: 4 })}
        </div>
    )

    // Handle the final submit
	const handleSubmit = async () => {
        // Start the button loader
        setButtonLoader(true);

		// Send the data to the backend here
		const trialData = { industry, 'company_structure': companyStructure, objectives, features, 'wizard_done': true };

        try {
            const response = await saveData({ 
                apiUrl: `patch_trial/${environmentHash}`, method: 'patch', data: trialData
            });

            // On successful save, redirect to trial homepage
            if (response?.status === 200) {
                
                // Store the changed redirect in the local storage for hard refreshes
                setRedirectTo('trial-homepage');
                localStorage.setItem('redirect_to', encryptData(JSON.stringify('trial-homepage')));

                // Redirect to the trial home page
                history.push('/trial-homepage');
            }
        } catch (error) {
            console.error('Saving not possible', error);
            throw error;
        } finally {
            setButtonLoader(false);
        }
	};

    return (
        <div className='container-small'>
            <div className='wizard trial-wizard'>
                {/* Step Indicators */}
                <div className="step-indicator">
                    <div className={`step ${currentStep >= 1 ? 'completed' : ''}`}></div>
                    <div className={`step ${currentStep >= 2 ? 'completed' : ''}`}></div>
                    <div className={`step ${currentStep >= 3 ? 'completed' : ''}`}></div>
                    <div className={`step ${currentStep >= 4 ? 'completed' : ''}`}></div>
                </div>

                {/* Step 1: Industry Selection */}
                {currentStep === 1 && (
                    <div className='wizard-step'>
                        <h2>{t('trial.general.industry_header')}</h2>
                        <h5>{t('trial.general.industry_subtitle')}</h5>
                        <div className='step-content'>
                            {industryOptions.map(option => (
                                <div className='option-row'
                                     key={option.value}
                                     onClick={() => handleIndustrySelection(option.value)}>
                                    <span className='label'>{t(option.label)}</span>
                                    <span className='icon'><FontAwesomeIcon icon={faAngleRight} /></span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Step 2: Company Structure Selection */}
                {currentStep === 2 && (
                    <div className='wizard-step'>
                        <h2>{t('trial.general.company_structure_header')}</h2>
                        <h5>{t('trial.general.company_structure_subtitle')}</h5>
                        <div className='step-content'>
                            {companyStructureOptions.map(option => (
                                <div className='option-row'
                                     key={option.value}
                                     onClick={() => handleCompanyStructureSelection(option.value)}>
                                    <span className='label'>{t(option.label)}</span>
                                    <span className='icon'><FontAwesomeIcon icon={faAngleRight} /></span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Step 3: Objectives Selection */}
                {currentStep === 3 && (
                    <div className='wizard-step'>
                        <h2>{t('trial.general.objectives_header')}</h2>
                        <h5>{t('trial.general.objectives_subtitle')}</h5>
                        <div className='step-content'>
                            {objectiveOptions.map(option => (
                                renderCheckboxOptions(option.value, option.label, objectives, handleObjectiveSelection)  
                            ))}
                        </div>
                    </div>
                )}

                {/* Step 4: Features Selection */}
                {currentStep === 4 && (
                    <div className='wizard-step'>
                        <h2>{t('trial.general.features_header')}</h2>
                        <h5>{t('trial.general.features_subtitle')}</h5>
                        <div className='step-content'>
                            {featureOptions.map(option => (
                                renderCheckboxOptions(option.value, option.label, features, handleFeatureSelection)
                            ))}
                        </div>
                    </div>
                )}

                {/* Navigation buttons */}
                <div className="button-footer">
                    {currentStep === 1 ? (
                        renderStepCountIndicator()
                    ) : (
                        <>
                            <SecondaryButton
                                label='general.previous'
                                size='small'
                                onClick={() => prevStep()} />
                            {renderStepCountIndicator()}
                            {currentStep < 4 ? (
                                <PrimaryButton
                                    label='general.next'
                                    size='small'
                                    icon={<FontAwesomeIcon icon={faAngleRight} />}
                                    onClick={() => nextStep()} />
                            ) : (
                                <PrimaryButton
                                    label='general.next'
                                    size='small'
                                    icon={<FontAwesomeIcon icon={faAngleRight} />}
                                    loading={buttonLoader}
                                    onClick={() => handleSubmit()} />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrialWizard;