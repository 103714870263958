import React, { useContext, useState, useEffect } from 'react';
import { CheckboxFieldType, FieldData } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import FormFieldContext from '../FormFieldContext';
import { useGlobalContext } from 'GlobalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import CheckboxCheck from 'assets/CheckboxCheck.svg';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

/*
 * CheckboxField.tsx
 * Handles the logic and functions of a checkbox. The complicated part of this component is that there are 
 * two types of checkboxes. The first type is a single checkbox next to the label with a boolean value (a 
 * true or false value). The second type is a multi checkbox field with multiple options / checkboxes under 
 * one label. The type of checkbox field is determined by if there is an options prop given by the specific
 * field config file. Single checkboxes don't have options but have trueValue and falseValue. In case of the 
 * multi checkbox, each option has it's own value. This is not a boolean value but is a string, for example: 
 * 'office'. When the checkbox is checked, 'office' is added as a string to the selectedValues array. When 
 * the checkbox is unchecked, the value 'office' is removed from the selectedValues array. Options may have 
 * their own description, label and tooltip. Last but not least, checkboxes can have a left or right alignment.
 */

const CheckboxField: React.FC<CheckboxFieldType & { data?: FieldData, viewKey: string }> = ({
    name, label, data, options, tooltipText, saveOnBlur, alignment, align = 'right', isEditable,
    disabled = false, trueValue = true, falseValue = false, viewKey, helperText, viewInEditMode,
    singleCheckbox = false
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { setUnsavedChanges } = useGlobalContext();
    const [booleanValue, setBooleanValue] = useState<boolean>(data ? data[name] : false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [initialValues, setInitialValues] = useState<string[]>([]);

    // Set the current fetched backend data in the field(s)
    useEffect(() => {
        if (data && data[name]) {
            // If no options are given, set the current data for the single checkbox field
            if (!options) {
                setBooleanValue(data[name]);
            // If options are given, set the current data as multi answer checkbox field
            } else {
                // Sort the initial loaded values alphabetical
                const sortedValues = Array.isArray(data[name]) ? [...data[name]].sort() : [data[name]];

                // Set the sorted initial values inside a state to compare
                setInitialValues(sortedValues);

                // Set the loaded values as selected values to fill the current checked checkboxes
                setSelectedValues(Array.isArray(data[name]) ? data[name] : [data[name]]);
            }
        }
    }, [data, name]);

    // Handle single boolean checkbox change
    const handleBooleanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setBooleanValue(checked);
        setUpdatedData({ ...updatedData, [name]: checked ? trueValue : falseValue });

        if (data && updatedData[name] !== (data[name])) {
            setUnsavedChanges(viewKey, true);
        } else {
            setUnsavedChanges(viewKey, false);
        }
    }

    // Handle multiple options checkbox field selections
    const handleValueSelection = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        let updatedValues;
        let equalValues;
        if (event.target.checked) {
            // Add the value to the array when a checkbox is checked
            updatedValues = [...selectedValues, value];
            equalValues = arraysEqual(updatedValues, initialValues)
            setUnsavedChanges(viewKey, !equalValues);
        } else {
            // Delete the value from the array when a checkbox is unchecked
            updatedValues = selectedValues.filter(item => item !== value);
            equalValues = arraysEqual(updatedValues, initialValues)
            setUnsavedChanges(viewKey, equalValues);
        }

        // Update de state en de context
        setSelectedValues(updatedValues);
        setUpdatedData({ ...updatedData, [name]: updatedValues });
    }

    // Check if the values in both arrays are equal
    const arraysEqual = (a: string[], b: string[]) => {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    // Submits the value after clicking outside the field
    const handleBlur = saveOnBlur ? (e: React.FocusEvent<HTMLInputElement>) => {
        if (document.activeElement !== e.target) {
            e.preventDefault();
            if (handleSubmit) handleSubmit();
        }
    } : undefined;

    // Set the right alignment classname
    const alignmentClassName = () => {
        if (alignment === 'horizontal') {
            return 'horizontal-alignment'
        } else {
            return 'stacked-alignment'
        }
    };

    return (
        <FieldWrapper
            name={name}
            label={singleCheckbox ? label : undefined}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className={`${alignmentClassName()} checkbox-field ${align} ${disabled ? 'disabled' : ''}`}>
                    {options ? (
                        <>
                            {!singleCheckbox && label && <label className='checkbox-label'>{t(label)}</label>}
                            <div className={`checkbox-container`}>
                                {options.map((option, index) => (
                                    // Generate the checkbox field with multiple options/checkboxes with single values, such as 'office' instead of true/false
                                    <div key={index} className={`checkbox-item ${align}`}>
                                        <div className='label-description-container'>
                                            <label htmlFor={`${name}-${option.value}`}
                                                className={`option-label ${option.description ? 'header' : ''} `}>
                                                    {t(option.label)}
                                                    {option.tooltipText && 
                                                        <span className="tooltip-icon">
                                                            <FontAwesomeIcon icon={faCircleQuestion} />
                                                            <span className="tooltip">{t(option.tooltipText)}</span>
                                                        </span>
                                                    }
                                            </label>
                                            {option.description && 
                                                <p className='option-description'>{t(option.description)}</p>
                                            }
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                name={name}
                                                id={`${name}-${option.value}`}
                                                checked={selectedValues.includes(option.value)}
                                                onChange={(event) => handleValueSelection(event, option.value)}
                                                disabled={disabled}
                                                onBlur={handleBlur}
                                                className='hidden-checkbox'
                                            />
                                            <label htmlFor={`${name}-${option.value}`}
                                                className={`custom-checkbox ${align} ${selectedValues.includes(option.value) ? 'checked' : ''}`}>
                                                {selectedValues.includes(option.value) && 
                                                    <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                                                }
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        // Generate the single checkbox field with boolean true/false value
                        <div className={`checkbox-item ${align}`}>
                            <div className="label-description-container">
                                {!singleCheckbox && label && 
                                    <label htmlFor={name} className='checkbox-label'>
                                        {t(label)}
                                        {tooltipText && 
                                            <span className="tooltip-icon">
                                                <FontAwesomeIcon icon={faCircleQuestion} />
                                                <span className="tooltip">{t(tooltipText)}</span>
                                            </span>
                                        }
                                    </label>
                                }
                            </div>
                            <div className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    id={name}
                                    name={name}
                                    checked={booleanValue}
                                    onChange={(event) => handleBooleanChange(event)}
                                    disabled={disabled}
                                    onBlur={handleBlur}
                                    className='hidden-checkbox'
                                />
                                <label htmlFor={name}
                                    className={`custom-checkbox ${align} ${booleanValue === trueValue ? 'checked' : ''}`}>
                                    {booleanValue === trueValue && 
                                        <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                                    }
                                </label>
                            </div>
                        </div>
                    )}
                </div> 
            ) : (
                // View mode
                <FieldViewMode
                    value={singleCheckbox && booleanValue === true ? 'forms.true_value' : 'forms.false_value'} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default CheckboxField;