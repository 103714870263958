import { useGlobalContext } from 'GlobalContext';
import React, { createContext, useContext, useState, ReactNode, ReactElement, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

/*
 * ModalContext.tsx
 * Contains the status of opening and closing of modals, to share across the 
 * application. It is possible to have multiple modals open on top of each
 * other. This provides a lot of overview for the user, because it is clear
 * from where the modal is opened from. Each modal is stored as a ModalStackItem
 * within the modalStack prop of the ModalContext.
 * 
 * Each modal stack item has its own viewkey (an uuid), which makes it possible 
 * to keep track of changes per modal and ensures that the modal can only be 
 * closed with a next click if there are no unsaved changes.
 * 
 * To open/create a new modal, first call the initializeModal function which starts
 * the loading of the modal data. Then call the revealModal function when the data
 * is successfully loaded to reveal the modal.
 */

// Type definition for modal props
export type ModalProps = {
    viewKey?: string;
    title?: string;
    itemId?: number | null;
    modalSize?: 'extra-small' | 'smaller' | 'small' | 'small-medium' | 'medium' | 'medium-large' | 'large' | 'xtra-large';
    showSideTab?: boolean;
    hasUnsavedChanges?: boolean;
    setShakeModalFunction?: (shakeModalFunction: () => void) => void;
    onClose?: () => void;
    onCloseWithoutUnsavedChangesCheck?: () => void;
    onSuccess?: (response?: Record<string, any>) => void
}

// Type definition for a modal item
export interface ModalStackItem {
    content: ReactElement<any> | null;
    props: ModalProps | null;
    isOpen: boolean;
    initialize: 'start' | 'finished';
    loading: boolean;
    itemId?: number | null;
    modalRef: React.RefObject<HTMLDivElement>;
    key: string,
}

// Type definition for the props of the modal context
interface ModalContextType {
    modalStack: ModalStackItem[];
    initializeModal: (content: ReactElement<any>, props?: ModalProps, itemId?: number | null) => void; 
    modalLoading: boolean;
    revealModal: () => void;
    closeModal: () => void;
}

// Create the context with the initial empty state
const ModalContext = createContext<ModalContextType | undefined>(undefined);

// Modal provider component
export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // State to store the current stack of modals
    const { removeUnsavedChange, removePreventClose } = useGlobalContext();
    const [modalStack, setModalStack] = useState<ModalStackItem[]>([]);
    const [modalLoading, setModalLoading] = useState(false);
    const loadingTimerRef = useRef<NodeJS.Timeout | null>(null)

    // Functiom to initialize the opening of a new modal. Creates a new modal item and puts it on top of the modal stack.
    const initializeModal = (content: ReactElement<any>, props?: ModalProps) => {
        if (!props) {
            throw new Error("ModalProps 'props' is required");
        }

        // Set general loading state only for the first modal, to show loader icon in the parent component
        if (modalStack.length === 0) {
            if (loadingTimerRef.current) {
                clearTimeout(loadingTimerRef.current);
            }
            loadingTimerRef.current = setTimeout(() => {
                if (modalStack.length === 0 || modalStack[0].loading) {
                    setModalLoading(true);
                }
            }, 250);            
        }

        // Generate a unique key and add it to the modal component
        const modalKey = uuidv4();
        const contentWithKey = React.cloneElement(content, { ...content.props, viewKey: modalKey, ...props });
        
        // Create a new modal item
        const newModal: ModalStackItem = {
            content: contentWithKey,
            props,
            isOpen: false,
            initialize: 'start',
            loading: true, 
            itemId: content.props.itemId,
            modalRef: React.createRef<HTMLDivElement>(),
            key: modalKey,
        };

        // Put the new modal item on top of the modal stack
        setModalStack(prevStack => [...prevStack, newModal]);
    };

    // Function to reveal the latest opened modal after loading the data
    const revealModal = () => {
        setModalStack(prevStack => prevStack.map((modal, index) => {
            if (index === prevStack.length - 1) {
                if (loadingTimerRef.current) {
                    clearTimeout(loadingTimerRef.current);
                    loadingTimerRef.current = null;
                }

                setModalLoading(false);            

                return {
                    ...modal,
                    loading: false,
                    isOpen: true
                }
            }
            return modal;            
        }))
    }

    // Function to close the upper opened modal. It deletes the modal item
    const closeModal = () => {
        let modalKey: string | null = null;

        // Update the modal stack
        setModalStack(prevStack => {
            if (prevStack.length > 0) {
                // Get the view key of the modal to be closed
                modalKey = prevStack[prevStack.length -1].key;
            }
            
            // Slice (remove) the modal
            return prevStack.slice(0, -1);
        }); 

        // Remove the unsaved change and prevent close property for this modal from the global state
        if (modalKey) {
            removeUnsavedChange(modalKey);
            removePreventClose(modalKey);
        }
    }

    // Provide the modal stack and functions to children
    return (
        <ModalContext.Provider value={{ modalStack, initializeModal, modalLoading, revealModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

// Custom hook to use the context
export const useModal = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};