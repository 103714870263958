interface QuantityAndUnit {
    quantity: number | null;
    unit: string | null;
}

export const parseQuantityAndUnit = (input: string): QuantityAndUnit => {
    // Delete spaces
    const trimmedInput = input.trim()

    // Check for percentage
    if (trimmedInput.endsWith('%')) {
        const percentage = parseFloat(trimmedInput.slice(0, -1).replace(',', '.'));
        if (!isNaN(percentage)) {
            return { quantity: percentage / 100, unit: '%' }
        }
    }

    // Split based on first non numeric character after a series of numbers
    const match = trimmedInput.match(/^(-?\d+[\.,]?\d*)\s*(\D*)$/);

    if (match) {
        let quantity = match[1].replace(',', '.');
        let unit = match[2] ? match[2].trim() : null;
        return { quantity: parseFloat(quantity), unit };
    }

    return { quantity: null, unit: null };
}