import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const JobTypeForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'workflow'

    const linkedItem = { connected_feature: 'job' }

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'workflow.general.name_label',
            type: 'text',
            placeholder: 'job.job_type.general.name_placeholder',
            // width: '50%',
            group: 'field-row-1',
        },
        { 
            type: 'dropdown', 
            name: 'connected_feature', 
            label: 'workflow.general.connected_feature_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            dependentOn: { fieldName: '', value: [''] }, // Hacky solution to hide field in the form
            defaultValue: { value: 'job'},
            allowNoneOption: false,
            showSearch: false,
            // width: '50%',
            group: 'field-row-2',
            hardcodedOptions: ([
                { value: 'job', name: 'workflow.connected_feature.job', requiredFeature: 'jobs' },
            ]),
        },
        {
            name: 'is_default',
            label: 'workflow.general.is_default_label',
            type: 'checkbox',
            // width: '50%',
            group: 'field-row-3',
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
            linkedItem={linkedItem}
        />
    );
};

export default JobTypeForm;