import { DragDropEvent } from "services/dragdrop/DragDropContext";
import { EventsByResourceType, EventsListType, EventType, ResourceGroupType, SchedulerDraggableId, SchedulerDroppableId } from "../SchedulingTypes";
import { EnvironmentSettingsType } from "services/settings/SettingsTypes";
import { JobType } from "../../../views/jobs/JobTypes";
import { t } from "i18next";
import { v4 as uuidv4 } from 'uuid';
import { calculateDurationMinutesFromDecimal, convertDayKeyAndTimeSlotToEndDateTimeString, convertDayKeyAndTimeSlotToStartDateTimeString, generateTimeKeyFromDateTimeString } from "./schedulingUtils";
import { convertLocalDateTimeToUTC } from "internationalization/timezoneConversions";
import { saveData } from "services/api/saveData";

export const handleDragJobInboxJobToResource = async (
    dragResult: DragDropEvent<SchedulerDraggableId, SchedulerDroppableId>,
    events: EventsListType,
    eventsByResource: EventsByResourceType,
    resourceGroups: ResourceGroupType[],
    userLocale: string,
    userTimezone: string,
    environmentSettings: EnvironmentSettingsType,
    setFloatingAlert: Function,
    setEvents: React.Dispatch<React.SetStateAction<EventsListType>>,
    setEventsByResource: React.Dispatch<React.SetStateAction<EventsByResourceType>>
) => {

    // Get the values from the drag result
    const { source, destination, draggableId } = dragResult;

    if (!destination) return;

    // Get the source type, destination type and draggable type
    const sourceType = source?.droppableId?.type
    const destinationType = destination.droppableId.type;
    const draggableType = draggableId.type;

    // Handle drag from the job inbox
    if (sourceType === 'jobInbox' && draggableType === 'jobInbox') {

        // Handle drag to a timeslot
        if (destinationType === 'timeSlot') {

            // Get the destination values
            const { resourceId: destinationResourceId, day: destinationDay, timeSlot: destinationTimeSlot } = destination.droppableId;

            // Convert the estimated duration of the job into duration minutes
            let durationMinutes;
            if (draggableId.job.estimated_duration !== null) {
                durationMinutes = calculateDurationMinutesFromDecimal(draggableId.job.estimated_duration);
            } else {
                // Fallback to 1 hour if no estimated duration is set
                durationMinutes = 60;
            }

            // Create the event and save the appointment
            await createEventAndSaveAppointmentAfterDrag(
                draggableId.job,
                resourceGroups,
                destinationResourceId,
                destinationDay,
                destinationTimeSlot,
                durationMinutes,
                userLocale,
                userTimezone,
                setEvents,
                setEventsByResource,
                setFloatingAlert,
            );
        }

        // Handle drag to a day list
        if (destinationType === 'dayList') {

            // Get the destination values
            const { resourceId: destinationResourceId, day: destinationDay } = destination.droppableId;

            // Get the existing events for this day and resource
            const existingEventKeys = eventsByResource[destinationResourceId] || [];
            const existingEvents = existingEventKeys
                .map(eventKey => events[eventKey])
                .filter(event => event && event.dayKey === destinationDay);

            // If there is already an event for this day, set the end time of the last event as the start time
            let startTime;

            // If there is already an event for this day, set the end time of the last event as the start time
            if (existingEvents.length > 0) {

                // Sort the events on start time, fallback to the default start time from the settings
                existingEvents.sort((a, b) => 
                    new Date(a.startTime ?? (environmentSettings.scheduling_list_view_start_time || "08:00")).getTime() -
                    new Date(b.startTime ?? (environmentSettings.scheduling_list_view_start_time || "08:00")).getTime()
                );
            
                // Get the end time of the last event
                startTime = existingEvents[existingEvents.length - 1]?.endTime ?? (environmentSettings.scheduling_list_view_start_time || "08:00"); 

            // When no events exist, set the default start time from the settings
            } else {
                startTime = environmentSettings.scheduling_list_view_start_time ?? "08:00";
            }

            // If an estimated duration for the job is set, use it as duration
            let durationMinutes;
            if (draggableId.job.estimated_duration !== null) {
                durationMinutes = calculateDurationMinutesFromDecimal(draggableId.job.estimated_duration);

            // Otherwise, use the default duration from the settings
            } else {

                // Get the default duration from the settings
                const defaultDuration = environmentSettings.scheduling_list_view_duration;

                // Convert the default duration into minutes
                let defaultDurationMinutes;
                if (defaultDuration) {

                    // Convert the default duration into minutes
                    const [hours, minutes] = defaultDuration.split(":").map(Number);
                    defaultDurationMinutes = (hours * 60) + minutes;
                }
                
                // Set the default duration, fallback to 60 minutes
                durationMinutes = defaultDurationMinutes || 60
            }

            // Create the event and save the appointment
            await createEventAndSaveAppointmentAfterDrag(
                draggableId.job,
                resourceGroups,
                destinationResourceId,
                destinationDay,
                startTime,
                durationMinutes,
                userLocale,
                userTimezone,
                setEvents,
                setEventsByResource,
                setFloatingAlert,
            );
        }
    }
};

// Creates an event and saves the appointment after the drag
const createEventAndSaveAppointmentAfterDrag = async (
    draggableJob: JobType,
    resourceGroups: ResourceGroupType[],
    destinationResourceId: string | number,
    destinationDay: string,
    destinationStartTime: string,
    durationMinutes: number,
    userLocale: string,
    userTimezone: string,
    setEvents: React.Dispatch<React.SetStateAction<EventsListType>>,
    setEventsByResource: React.Dispatch<React.SetStateAction<EventsByResourceType>>,
    setFloatingAlert: Function
) => {
    // Create a temporarily appointment uuid and temporarily event key
    const appointmentUuid = uuidv4();
    const temporarilyEventKey = `${appointmentUuid}-${destinationResourceId}`;

    // Convert the day keys and timeslots to local datetime objects
    const convertedStartDateTime = convertDayKeyAndTimeSlotToStartDateTimeString(destinationDay, destinationStartTime, userLocale, userTimezone);
    const convertedEndDateTime = convertDayKeyAndTimeSlotToEndDateTimeString(destinationDay, destinationStartTime, durationMinutes, userLocale, userTimezone);

    // Create a new event
    const temporarilyEvent: EventType = {
        key: temporarilyEventKey,
        appointmentUuid: appointmentUuid,
        appointmentId: null,
        job: {
            id: draggableJob.id!,
            client_name: draggableJob.client_name ?? undefined,
            work_location_street: draggableJob.work_location?.street ?? undefined,
            work_location_house_number: draggableJob.work_location?.house_number ?? undefined,
            work_location_postal_code: draggableJob.work_location?.postal_code ?? undefined,
            work_location_city: draggableJob.work_location?.city ?? undefined,
            internal_reference: draggableJob.internal_reference ?? null,
            external_reference: draggableJob.external_reference ?? null,
            number: draggableJob.number ?? null,
            status_name: draggableJob.status_name ?? null,
            resolution: draggableJob.resolution ?? null,
            main_description: draggableJob.main_description ? draggableJob.main_description.slice(0, 30) : null,
        },
        resourceId: destinationResourceId,
        dayKey: destinationDay,
        timeSlot: destinationStartTime,
        startDateTime: convertedStartDateTime,
        endDateTime: convertedEndDateTime,
        durationMinutes: durationMinutes,
        status: 'scheduled',
        custom_color: null,
        deleted: false,
    };

    // Add the temporarily event to the events state
    setEvents(prev => ({
        ...prev,
        [temporarilyEventKey]: temporarilyEvent,
    }));

    // Add the event to the destination resource state
    setEventsByResource(prev => {
        const newEventsByResource = { ...prev };

        // Create a new array for the destination resource if it doesn't exists
        if (!newEventsByResource[destinationResourceId]) {
            newEventsByResource[destinationResourceId] = [];
        }

        // Add the temporarily event key to the resource
        newEventsByResource[destinationResourceId].push(temporarilyEventKey);

        return newEventsByResource;
    });

    // Convert the local datetimes to utc datetimes
    const utcStartDateTime = convertLocalDateTimeToUTC(convertedStartDateTime, userLocale, userTimezone);
    const utcEndDateTime = convertLocalDateTimeToUTC(convertedEndDateTime, userLocale, userTimezone);

    // Configure the submit data for the appointment
    const newAppointment = {
        id: null,
        uuid: appointmentUuid,
        job: draggableJob.id,
        resource_ids: [destinationResourceId],
        timezone: userTimezone,
        start_datetime: utcStartDateTime,
        end_datetime: utcEndDateTime,
    };

    // Save the new appointment
    const response = await saveData({ apiUrl: 'post_appointment', data: newAppointment });

    // Handle successful response, update temporarily event key with new appointment id to prevent duplicates
    if (response?.status === 201) {
        
        // Set the new appointment id and updated event key
        const newAppointmentId = response.data.id;
        const updatedEventKey = `${newAppointmentId}-${destinationResourceId}`;

        // Update the event key in de events state
        setEvents(prev => {
            const currentEvents = { ...prev };

            // Find the temporarily event with the temporarily key
            const temporarilyEvent = currentEvents[temporarilyEventKey];

            // Create the new updated event with the updated key
            const updatedEvent: EventType = {
                ...temporarilyEvent,
                key: updatedEventKey,
                appointmentId: newAppointmentId,
                startTime: generateTimeKeyFromDateTimeString(convertedStartDateTime),
                endTime: generateTimeKeyFromDateTimeString(convertedEndDateTime),
                status: response.data.status,
            };

            // Remove the old temporarily event key from the state
            delete currentEvents[temporarilyEventKey];

            // Add the updated event with the right backend key
            currentEvents[updatedEventKey] = updatedEvent;

            return currentEvents;
        });

        // Update the events by resource state, remove the temporarily event and add the updated event met the final key
        setEventsByResource(prev => {
            const currentEventsByResource = { ...prev };

            // Get the list of the appropriate resource
            const resourceEvents = currentEventsByResource[destinationResourceId];

            // Get the event index of the temporarily event
            const eventIndex = resourceEvents.indexOf(temporarilyEventKey);

            // Replace the temporarily event key with the new key
            if (eventIndex > -1) {
                resourceEvents[eventIndex] = updatedEventKey;
            }

            return currentEventsByResource;
        });

        // Find the name of the resource based on the destination resource id
        const findResourceName = (resourceId: number | string): string | null => {
            for (const group of resourceGroups) {
                const resource = group.resources.find(res => res.id === resourceId);
                if (resource) {
                    return resource.name
                }
            }

            return '';
        };

        // Show a floating alert on success
        setFloatingAlert({
            type: 'success',
            message: t('scheduling.scheduling_board.inbox_job_dragged_to_resource_alert', { resource_name: findResourceName(destinationResourceId), date: destinationDay, time: destinationStartTime })
        });
    }
};