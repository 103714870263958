import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const ProposalTypeForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'workflow'

    const linkedItem = { connected_feature: 'proposal' }

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'workflow.general.name_label',
            type: 'text',
            placeholder: 'proposal.proposal_type.general.name_placeholder',
            width: '50%',
            group: 'field-row-1',
        },
        { 
            type: 'dropdown', 
            name: 'connected_feature', 
            label: 'workflow.general.connected_feature_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            dependentOn: { fieldName: '', value: [''] }, // Hacky solution to hide field in the form
            defaultValue: { value: 'proposal'},
            allowNoneOption: false,
            showSearch: false,
            width: '50%',
            group: 'field-row-2',
            hardcodedOptions: ([
                { value: 'proposal', name: 'workflow.connected_feature.proposal', requiredFeature: 'proposals' },
            ]),
        },
        {
            name: 'is_default',
            label: 'workflow.general.is_default_label',
            type: 'checkbox',
            width: '50%',
            group: 'field-row-3',
        },

        // Proposal workflow details
        {
            type: 'divider',
        },
        {
            type: 'header',
            header: 'workflow.general.proposal_settings_header',
        },
        {
            name: 'proposal_expiration_days',
            type: 'expiration-days',
            featureName: 'proposal',
            placeholder: 'workflow.general.expiration_placeholder',
            width: '50%',
        },
        {
            name: 'proposal_default_vat_method',
            type: 'dropdown',
            label: 'workflow.general.vat_method_label',
            tooltipText: 'workflow.general.proposal_vat_method_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.general.vat_excluded_label', value: 'vat_excluded' },
                { name: 'document_lines.general.vat_included_label', value: 'vat_included' },
            ],
            defaultValue: { value: 'vat_excluded' },
            allowNoneOption: false,
            showSearch: false,
            group: 'field-row-5',
            width: '33%',
        },
        {
            name: 'proposal_default_price_display_mode',
            type: 'dropdown',
            label: 'workflow.general.price_display_mode_label',
            tooltipText: 'workflow.general.proposal_price_display_mode_tooltipText',
            hardcodedOptions: [
                { name: 'document_lines.price_display_mode.line_prices', value: 'line_prices' },
                { name: 'document_lines.price_display_mode.total_price', value: 'total_price' },
            ],
            defaultValue: { value: 'line_prices' },
            allowNoneOption: false,
            showSearch: false,
            group: 'field-row-6',
            width: '33%',
        },
        {
            type: 'header',
            header: 'workflow.general.default_proposal_introduction_header',
            description: 'workflow.general.default_proposal_introduction_description',
        },
        {
            name: 'default_proposal_introduction',
            type: 'textarea',
            placeholder: 'workflow.general.default_proposal_introduction_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
        },
        {
            type: 'header',
            header: 'workflow.general.default_proposal_conditions_header',
            description: 'workflow.general.default_proposal_conditions_description',
        },
        {
            name: 'default_proposal_conditions',
            type: 'textarea',
            placeholder: 'workflow.general.default_proposal_conditions_placeholder',
            rows: 3,
            enableStyling: 'simple',
            showToolbarInitially: true,
        },
    ];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
            linkedItem={linkedItem}
        />
    );
};

export default ProposalTypeForm;