import React from 'react';
import { SubscriptionData } from '../BillingTypes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSettings } from 'services/settings/SettingsContext';
import { formatDate } from 'services/utils/dateTimeUtils';
import { packageOptions } from '../packageOptions';

interface SubscriptionDetailsCardProps {
    loading: string;
    subscription: SubscriptionData;
    subscriptionType: 'current' | 'pending';
}

const SubscriptionDetailsCard: React.FC<SubscriptionDetailsCardProps> = ({
    loading, subscription, subscriptionType
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userLocale } = useSettings();

    // Get the active additional modules of this subscription
    const getAdditionalModules = () => {
        return subscription.subscription_lines?.filter(line => line.is_active && line.type === 'additional_module') || [];
    };

    // Get the active discount lines of this subscription
    const getDiscountLines = () => {
        return subscription.subscription_lines?.filter(line => line.is_active && line.type === 'discount') || [];
    };

    // Get the seat price based on the duration of the given subscription
    const getSeatPrice = () => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === subscription.package);

        // Initialize the seat price
        let seatPrice = 0;

        // Get the right seat price based on the duration
        if (selectedPackage) {
            if (subscription.duration === 'yearly') {
                seatPrice = selectedPackage.yearlyPrice
            }

            if (subscription.duration === 'monthly') {
                seatPrice = selectedPackage.monthlyPrice
            }

            if (subscription.duration === 'trial') {
                seatPrice = 0
            }
        }

        return seatPrice
    };

    return (
        <div className={`card subscription-details ${subscriptionType === 'pending' && 'pending-subscription'}`}>
            <div className='subscription-details-header'>
                <h6>
                    {subscriptionType === 'current' && t('billing.general.current_subscription_title')}
                    {subscriptionType === 'pending' && t('billing.general.pending_subscription_short_title')}
                </h6>
                {subscription.start_date && subscription.end_date && (
                    <p>{formatDate(subscription.start_date, userLocale)} - {formatDate(subscription.end_date, userLocale)}</p>
                )}
            </div>
            {loading === 'show-loader' ? (
                <div className="loader"></div>
            ) : loading === 'success' && (
                <div className='subscription-details-content'>
                    <div>
                        <div>
                            <div>
                                <p>
                                    {t('billing.general.current_package_label', { currentPackage: t(`billing.package.${subscription.package}.label`) })}
                                </p>
                                <p>
                                    {t('billing.general.current_seats_and_price_label', { currentSeats: subscription.seats, currentSeatPrice: getSeatPrice() })}
                                </p>
                                {(subscription.duration === 'yearly' || subscription.duration === 'monthly') && (
                                    <p>{t(`billing.general.${subscription.duration}_payment_label`)}</p>
                                )}
                            </div>

                            {/* Additional modules */}
                            {getAdditionalModules().length > 0 && (
                                <div className='module-details'>
                                    <h6>{t('billing.general.additional_modules_label')}</h6>
                                    {getAdditionalModules().map((line, index) => (
                                        <p key={index}>
                                            {t('billing.general.additional_module_line_label', { productName: line.product_name, modulePrice: line.price })}
                                        </p>
                                    ))}
                                </div>
                            )}
                            
                            {/* Discounts */}
                            {getDiscountLines().length > 0 && (
                                <div className='module-details'>
                                    <h6>{t('billing.general.discount_label')}</h6>
                                    {getDiscountLines().map((line, index) => (
                                        <p key={index}>
                                            {t('billing.general.discount_line_label', { productName: line.product_name, discountPercentage: line.price })}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        {subscriptionType === 'current' && subscription.status !== 'cancelled' && (
                            <div className='text-button'
                                 onClick={() => history.push(`subscription/wizard/1`)}>
                                {t('billing.general.edit_subscription_label')}
                            </div>
                        )}
                    </div>
                    <div className='monthly-price'>
                        <div className='price-big'>€ {parseInt(subscription.seats) * getSeatPrice()}</div>
                        <p>{t(`billing.general.per_month_label`)}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SubscriptionDetailsCard;