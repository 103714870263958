import React, { ReactElement } from 'react';
import { saveData } from "services/api/saveData";
import { ModalProps } from "../ModalContext";
import OrderModal from '../../../views/order/OrderModal';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';

export const handleCreateOrder = async ({
    itemId,
    initializeModal,
    setFloatingAlert,
    setButtonLoader,
    buttonAction,
    userLocale,
    userTimezone,
    workflowId,
}: {
    itemId: number;
    initializeModal: (content: ReactElement<any>, props?: ModalProps, itemId?: number | null) => void; 
    setFloatingAlert: Function;
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    buttonAction: string;
    userLocale: string;
    userTimezone: string;
    workflowId?: number;
}) => {
    // If no layout id is given, return
    if (!workflowId) return;

    try {
        // Start the button loader
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: true }));

        // Add the current date to the data to set the order date
        const currentDateTime = new Date();
        const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

        // Configure the data object from the item data
        const submitData: Record<string, any> = {
            proposal_id: itemId,
            current_date: utcDateTime
        }

        // If a workflow id is given, add it to the submit data
        if (workflowId) {
            submitData.workflow = workflowId;
        }

        // Create the new order
        const response = await saveData({
            apiUrl: `post_order`,
            method: 'post',
            data: submitData
        });

        // Open the new order after succesfully creating
        if (response && response.status === 201) {
            const newOrderId = response.data.id;

            if (newOrderId) {
                initializeModal(
                    <OrderModal itemId={newOrderId} />,
                    { modalSize: 'large', itemId: newOrderId }
                )
            }

            // Show success alert
            setFloatingAlert({
                type: 'success',
                message: 'validation.order.created_successfully',
            });
        }
    } catch (error) {
        setFloatingAlert({
            type: 'danger',
            message: 'validation.order.creation_failed',
        });
    } finally {
        // Stop the button loader
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: false }));
    }
};