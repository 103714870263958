/*
 * handleDragRow.tsx
 * Handle the dragging of a row in the list.
 */

import axios from 'axios';
import { DropResult } from "react-beautiful-dnd";

export async function handleDragRow(
    result: DropResult, 
    data: any[], 
    handleDragOrdering: Function, 
    apiObject: string, 
    saveData: Function,
    saveBatchData: Function, 
    setFloatingAlert: Function,
    draggingApiUrl?: string,
) {
    const { destination, source } = result;

    // If the item is not dropped into a destination or the ordering is the same, do nothing
    if (!destination || (source.index === destination.index)) {
        return;
    }

    // Copy the current list data
    let updatedRows = [...data];
    
    // Remove the dragged row from its old position and insert it in the new place
    const [removedItem] = updatedRows.splice(source.index, 1);
    updatedRows.splice(destination.index, 0, removedItem);

    // Update the ordering value of all rows in the list
    const orderedRows = updatedRows.map((row, index) => {
        return { ...row, ordering: index };
    });

    // Reorder the rows in the frontend by calling the handle drag ordering function
    handleDragOrdering(orderedRows);

    // Create the cancel token source
    const cancelTokenSource = axios.CancelToken.source();

    // Update the ordered rows to the server
    try {

        // When a dragging api url is provided, handle save as patch
        if (draggingApiUrl) {

            // Only provide the id and ordering value
            const minimalData = orderedRows.map(({ id, ordering }) => ({ id, ordering }));

            // Save the data in the server
            const response = await saveData({
                apiUrl: draggingApiUrl,
                method: 'patch',
                data: minimalData,
                source: cancelTokenSource
            });

            // Show floating alert on successful save
            if (response?.status === 204) {
                setFloatingAlert({ type: 'success' });
            }

        // Otherwise handle save as batch put
        } else {
            // Save the data in the server
            const response = await saveBatchData({ apiObject, data: orderedRows, source: cancelTokenSource });

            // Show floating alert on successful save
            if (response.status === 200 || response.status === 204) {
                setFloatingAlert({ 'type': 'success' })
            }
        }
    } catch (error) {
        console.error("Batch update failed: ", error);
    }
}