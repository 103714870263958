import React, { useEffect, useMemo, useState } from 'react';
import { BillingData, createBillingData, createPaymentMethod, createSubscriptionData, PaymentMethod, SubscriptionData, SubscriptionLine } from '../BillingTypes';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';
import { useSettings } from 'services/settings/SettingsContext';
import { useFetchData } from 'services/api/useFetchData';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import SubscriptionDetailsCard from './SubscriptionDetailsCard';
import CancelSubscriptionCard from './CancelSubscriptionCard';
import BillingDetailsCard from './BillingDetailsCard';
import PaymentMethodCard from './PaymentMethodCard';
import NextInvoiceCard from './NextInvoiceCard';
import '../../../../style/scss/billing.scss';

const BillingOverview: React.FC = () => {
    const { environmentHash } =  useAuthContext();
    const { userLocale, userTimezone } = useSettings();
    const [subscription, setSubscription] = useState<SubscriptionData>(createSubscriptionData);
    const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethod>(createPaymentMethod);
    const [billingData, setBillingData] = useState<BillingData>(createBillingData);

    // Fetch the current subscription data
    const currentDateTime = new Date();
    const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);
    const { response: fetchedSubscription, loading: subscriptionLoading, refetch: refetchSubscription } = useFetchData({ 
        apiUrl: `get_subscription/${environmentHash}`,
        params: useMemo(() => ({ current_date: utcDateTime }), [])
    });

    // Fetch the company details
    const { response: fetchedCompanyDetails, loading: companyDetailsLoading, refetch: refetchCompanyDetails } = useFetchData({ 
        apiUrl: `get_company_details/${environmentHash}`
    });

    // // Fetch the current payment method
    // const { response: fetchedPaymentMethod, loading: paymentMethodLoading, refetch: refetchPaymentMethod } = useFetchData({ 
    //     apiUrl: `get_payment_method/${environmentHash}`, 
    //     params: useMemo(() => ({ is_active: true }), []) 
    // });

    // Set the current subscription data
    useEffect(() => {
        if (fetchedSubscription && Object.keys(fetchedSubscription).length > 0) {
            // Transform the current subscription data
            let transformedSubscription: SubscriptionData = {
                id: fetchedSubscription.id,
                is_trial: fetchedSubscription.is_trial,
                duration: fetchedSubscription.duration === 'trial' ? 'yearly' : fetchedSubscription.duration,
                seats: fetchedSubscription.total_seats,
                package: fetchedSubscription.package,
                start_date: fetchedSubscription.start_date,
                end_date: fetchedSubscription.end_date,
                status: fetchedSubscription.status,
                subscription_lines: fetchedSubscription.subscription_lines
                    ? fetchedSubscription.subscription_lines.map((line: SubscriptionLine) => ({
                        start_date: line.start_date,
                        end_date: line.end_date,
                        type: line.type,
                        product_name: line.product_name ?? null,
                        quantity: line.quantity,
                        price: line.price,
                        price_type: line.price_type,
                        is_active: line.is_active
                    }))
                    : null,
            };

            // Add the pending subscription data if it is given
            if (fetchedSubscription.pending_subscription) {
                transformedSubscription = {
                    ...transformedSubscription,
                    pending_subscription: {
                        id: fetchedSubscription.id,
                        is_trial: false,
                        duration: fetchedSubscription.pending_subscription.duration,
                        seats: fetchedSubscription.pending_subscription.total_seats,
                        package: fetchedSubscription.pending_subscription.package,
                        start_date: fetchedSubscription.pending_subscription.start_date,
                        end_date: fetchedSubscription.pending_subscription.end_date,
                        status: fetchedSubscription.pending_subscription.status,
                        subscription_lines: fetchedSubscription.pending_subscription.subscription_lines
                            ? fetchedSubscription.pending_subscription.subscription_lines.map((line: SubscriptionLine) => ({
                                start_date: line.start_date,
                                end_date: line.end_date,
                                type: line.type,
                                product_name: line.product_name ?? null,
                                quantity: line.quantity,
                                price: line.price,
                                price_type: line.price_type,
                                is_active: line.is_active
                            }))
                            : null,
                    },
                };
            };

            // Add the cancellation date if it exists
            if (fetchedSubscription.cancellation_date) {
                transformedSubscription = {
                    ...transformedSubscription,
                    cancellation_date: fetchedSubscription.cancellation_date ?? null,
                }
            };

            // Add the cancellation reason if it exists
            if (fetchedSubscription.cancellation_reason) {
                transformedSubscription = {
                    ...transformedSubscription,
                    cancellation_reason: fetchedSubscription.cancellation_reason ?? null,
                }
            };

            // Add the cancellation remarks if it exists
            if (fetchedSubscription.cancellation_remarks) {
                transformedSubscription = {
                    ...transformedSubscription,
                    cancellation_remarks: fetchedSubscription.cancellation_remarks ?? null,
                }
            };

            setSubscription(transformedSubscription);
        }
    }, [fetchedSubscription]);

    // Set the company details as billing data
    useEffect(() => {
        if (fetchedCompanyDetails) {
            const transformedCompanyDetails: BillingData = {
                company_name: fetchedCompanyDetails.company_name ?? null,
                first_name: fetchedCompanyDetails.first_name ?? null,
                last_name: fetchedCompanyDetails.last_name ?? null,
                email: fetchedCompanyDetails.email ?? null,
                invoice_email: fetchedCompanyDetails.invoice_email ?? null,
                street: fetchedCompanyDetails.street ?? null,
                house_number: fetchedCompanyDetails.house_number ?? null,
                postal_code: fetchedCompanyDetails.postal_code ?? null,
                city: fetchedCompanyDetails.city ?? null,
                country: fetchedCompanyDetails.country ?? null,
                vat_number: fetchedCompanyDetails.vat_number ?? null,
                stripe_customer_id: fetchedCompanyDetails.stripe_customer_id ?? null
            };

            setBillingData(transformedCompanyDetails);
        }
    }, [fetchedCompanyDetails]);

    // // Set the current payment method if it exists
    // useEffect(() => {
    //     if (fetchedPaymentMethod) {
    //         setPaymentMethodData(fetchedPaymentMethod);
    //     }
    // }, [fetchedPaymentMethod]);

    return (
        <div className='container-filled billing-page'>
            <div>
                <SubscriptionDetailsCard
                    loading={subscriptionLoading}
                    subscription={subscription}
                    subscriptionType='current'
                />
                {subscription.pending_subscription && (
                    <SubscriptionDetailsCard
                        loading={subscriptionLoading}
                        subscription={subscription.pending_subscription}
                        subscriptionType='pending'
                    />
                )}
                <CancelSubscriptionCard
                    subscription={subscription}
                    refetchSubscription={refetchSubscription}
                />
            </div>
            <div>
                <BillingDetailsCard
                    loading={companyDetailsLoading}
                    billingData={billingData}
                    refetchBillingDetails={refetchCompanyDetails}
                />
                {/* <PaymentMethodCard
                    loading={paymentMethodLoading}
                    billingData={billingData}
                    paymentMethodData={paymentMethodData}
                    refetchPaymentMethod={refetchPaymentMethod}
                /> */}
                <NextInvoiceCard
                    loading={subscriptionLoading}
                    subscription={subscription}
                />
            </div>
        </div>
    )
};

export default BillingOverview;