import { useGlobalContext } from 'GlobalContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';

const TopBar: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setIsTopNavbarEnabled } = useGlobalContext();
    const { environmentType, subscriptionEndDate, subscriptionShowNoPaymentMethodBanner } = useAuthContext();

    // Determine the amount of days remaining for a trial
    const getTrialRemainingDays = (endDate: string): number => {
        
        // Convert todays date and the end date into milliseconds
        const endDateMilliseconds = new Date(endDate).getTime();
        const todaysMilliseconds = new Date().getTime();

        // Calculate the amount of milliseconds in a day
        const dayMilliseconds = 1000 * 60 * 60 * 24;

        // Calculate the remaining days of the trial
        const remainingDays = Math.round(endDateMilliseconds - todaysMilliseconds) / dayMilliseconds;

        // Fix the days to a single digit number
        const remainingDaysInt = parseInt(remainingDays.toFixed(0))

        return remainingDaysInt;
    }

    // Determine to render the topbar
    const renderTopBar = () => {

        // Show warning topbar in production admin environment
        if (environmentType === 'admin' && process.env.NODE_ENV === 'production') {

            // Set top bar enabled property
            setIsTopNavbarEnabled(true);

            return (
                <div className='topbar warning'>
                    {t('nav.admin_environment_topbar_label', { environmentType: environmentType.charAt(0).toUpperCase() + environmentType.slice(1) })}
                </div>
            )
        }

        // Show warning topbar in production demo environments
        else if (environmentType === 'demo' && process.env.NODE_ENV === 'production') {

            // Set top bar enabled property
            setIsTopNavbarEnabled(true);

            return (
                <div className='topbar warning'>
                    {t('nav.admin_environment_topbar_label', { environmentType: environmentType.charAt(0).toUpperCase() + environmentType.slice(1) })}
                </div>
            )
        }

        // Show trial topbar
        else if (environmentType === 'trial' && subscriptionEndDate) {

            // Set top bar enabled property
            setIsTopNavbarEnabled(true);

            return (
                <div className='topbar subtile'>  
                    <span className='link'
                          onClick={() => history.push(`/settings/billing/subscription/wizard/1`)}>
                        {t('nav.remaining_trial_days_topbar_label', { remainingDays: getTrialRemainingDays(subscriptionEndDate) })}
                    </span>
                </div>
            )           
        }

        // Handle client topbars
        else if (environmentType === 'client') {

            // Show no payment method topbar
            if (subscriptionShowNoPaymentMethodBanner) {
                // Set top bar enabled property
                setIsTopNavbarEnabled(true);

                return (
                    <div className='topbar danger'>
                        <span className='link'
                            onClick={() => history.push(`/settings/billing/subscription/wizard/billing-details`)}>
                            {t('nav.no_payment_method_topbar_label')}
                        </span>
                    </div>
                )
            }

        // Otherwise dont render and enable the topbar
        } else {
            setIsTopNavbarEnabled(false);
        }

        return null;
    };

    return (
        renderTopBar()
    )
};

export default TopBar;