/*
 * packageFeatures.ts
 * This file contains an overview of the packages with the functions available in them.
 * Add features to the package they belong to.
 */

export interface FeaturesPerPackage {
    starter: string[];
    essential: string[];
    advanced: string[];
    enterprise: string[];
}

// Add new features here to the package they belong to:
export const FeaturesPerPackage: FeaturesPerPackage = {
    starter: [
        'contacts',
        'products',
        'scheduling',
        'jobs',
        'subcontractors',
        'manage_timesheets',
        'invoices',
        'mobile_jobs',
        'mobile_timesheet',
        'mobile_create_jobs',
    ],
    essential: [
        'contacts',
        'products',
        'inventory',
        'proposals',
        'orders',
        'scheduling',
        'jobs',
        'manage_timesheets',
        'invoices',
        'inbox',
        'mobile_jobs',
        'mobile_timesheet',
        'mobile_tasks',
        'mobile_create_jobs',
        'mobile_calendar',
        'mobile_payments',
        'product_categories',
        'default_duration',
        'purchase_orders',
        'related_organizations',
        'location_objects',
        'job_multiple_appointments',
        'scheduling_timeline_views',
        'scheduling_time_blocks',
        'gps_job_filters',
        'job_storage_filters',
        'equipment_planning',
        // 'employee_scheduling',
        'required_mobile_fields',
        'mobile_job_storage',
        'mobile_forms',
        'groups',
        'subcontractors',
        'custom_email_messages',
        'custom_sms_messages',
        'custom_job_pdf',
    ],
    advanced: [
        'contacts',
        'products',
        'inventory',
        'proposals',
        'scheduling',
        'jobs',
        'manage_timesheets',
        'contract_management',
        'invoices',
        'inbox',
        'tickets',
        'workflows',
        'hrm',
        'deals',
        'orders',
        'mobile_jobs',
        'mobile_timesheet',
        'mobile_tasks',
        'mobile_leave_requests',
        'mobile_expenses',
        'mobile_create_jobs',
        'mobile_calendar',
        'mobile_payments',
        'product_categories',
        'default_duration',
        'purchase_orders',
        'related_organizations',
        'location_objects',
        'job_multiple_appointments',
        'scheduling_timeline_views',
        'scheduling_time_blocks',
        'gps_job_filters',
        'job_storage_filters',
        'equipment_planning',
        // 'employee_scheduling',
        'multiple_scheduling_boards',
        'required_mobile_fields',
        'mobile_job_storage',
        'mobile_forms',
        'groups',
        'subcontractors',
        'custom_email_messages',
        'custom_sms_messages',
        'custom_job_pdf',
        'product_rules',
        'suggested_duration',
        'stockrooms',
        'stock_minimum',
        'custom_roles',
        'custom_exports',
        'custom_views',
        'custom_filters',
        'custom_column_selection',
        'custom_widgets',
        'custom_to_dos',
        'custom_statusses',
        'proposal_view_notification',
        'route_planner',
        'route_optimalization',
        'appointment_booking_engine',
        'client_portal',
        'field_worker_profile',
    ],
    enterprise: [
        'contacts',
        'products',
        'inventory',
        'proposals',
        'scheduling',
        'jobs',
        'manage_timesheets',
        'contract_management',
        'invoices',
        'inbox',
        'tickets',
        'workflows',
        'hrm',
        'deals',
        'orders',
        'reporting',
        'mobile_jobs',
        'mobile_timesheet',
        'mobile_tasks',
        'mobile_leave_requests',
        'mobile_expenses',
        'mobile_create_jobs',
        'mobile_calendar',
        'mobile_payments',
        'product_categories',
        'default_duration',
        'purchase_orders',
        'related_organizations',
        'location_objects',
        'single_pipeline',
        'job_multiple_appointments',
        'scheduling_timeline_views',
        'scheduling_time_blocks',
        'gps_job_filters',
        'job_storage_filters',
        'equipment_planning',
        // 'employee_scheduling',
        'multiple_scheduling_boards',
        'required_mobile_fields',
        'mobile_job_storage',
        'mobile_forms',
        'groups',
        'subcontractors',
        'custom_email_messages',
        'custom_sms_messages',
        'custom_job_pdf',
        'product_rules',
        'suggested_duration',
        'stockrooms',
        'stock_minimum',
        'custom_roles',
        'custom_exports',
        'custom_views',
        'custom_filters',
        'custom_column_selection',
        'custom_widgets',
        'custom_to_dos',
        'custom_statusses',
        'multiple_pipelines',
        'proposal_view_notification',
        'route_planner',
        'route_optimalization',
        'appointment_booking_engine',
        'client_portal',
        'field_worker_profile',
        'divisions',
        'enhanced_security',
        'local_backup',
    ]
};