import React from 'react';
import DetailModal, { DetailModalProps } from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtons, HeaderButtonsConfig } from 'types/ButtonTypes';
import { FieldOption } from 'types/FieldTypes';
import ContactForm from '../../views/contacts/ContactForm';
import PersonForm from '../../views/contacts/PersonForm';
import LocationForm from '../../views/contacts/LocationForm';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (client: any) => {
    if (client && client['entity_type'] && client['id']) {
        const baseUrl = client['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${client['id']}`;
    }
    return null;
}

// Rename the name of the function:
// const JobModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {
const JobModal: React.FC<DetailModalProps> = ({ viewKey, itemId, toggleSideTab, refetchList }) => {

    // useEffect(() => {
    //     if (toggleSideTab) toggleSideTab(true);
    // }, [toggleSideTab])

    // Specify the parameters of this detail modal:
    const apiObject = 'job';
    const object = 'job';
    const showStatusBar = true;
    const showSummary = true;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtonsConfig = [
        {   
            type: 'secondary',
            label: 'button.download_pdf_button_label',
            action: 'download_pdf',
            objectType: 'job',
            allowedRight: ['can_download'],
            query: {
                endpoint: 'get_pdf_layout_list/job',
                object: 'pdf_layout',
                defaultParams: { 'deleted': false },
            }
        },
        // {   
        //     type: 'secondary',
        //     label: 'button.send_email_button_label',
        //     action: 'send_email',
        // },
        {   
            type: 'dropdown',
            label: 'button.more_button_label',
            buttons: [
                // {
                //     label: 'button.convert_to_project_button_label',
                //     action: 'convert_project',
                //     restrictedRight: ['only_view'],
                // },
                // {
                //     label: 'button.convert_to_proposal_button_label',
                //     action: 'convert_proposal',
                //     restrictedRight: ['only_view'],
                //     allowedFeature: ['proposals'],
                // },
                {
                    label: 'button.reopen_verified_job_button_label',
                    action: 'reopen',
                    restrictedRight: ['only_view'],
                    condition: [{ field: 'status_condition', values: ['finished'] }]
                },
                // {
                //     label: 'button.duplicate_job_button_label',
                //     action: 'duplicate',
                //     restrictedRight: ['only_view'],
                // },
                {
                    label: 'button.delete_job_button_label',
                    action: 'delete',
                    onSuccess: 'closeModal',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                }
            ]
        },
        {   
            type: 'primary',
            label: 'button.schedule_button_label',
            action: 'schedule',
            restrictedRight: ['only_view'],
            allowedFeature: ['scheduling'],
            customClass: 'scheduled_appointment',
            condition: [{ field: 'status_condition', values: ['job_created', 'all_purchases_delivered'] }]
        },
        {   
            type: 'primary',
            label: 'button.reschedule_button_label',
            action: 'schedule',
            restrictedRight: ['only_view'],
            allowedFeature: ['scheduling'],
            customClass: 'scheduled_appointment',
            condition: [{ field: 'status_condition', values: ['incomplete'] }]
        },
        {   
            type: 'primary',
            label: 'button.verify_job_button_label',
            action: 'verify',
            restrictedRight: ['only_view'],
            customClass: 'finished',
            condition: [{ field: 'status_condition', values: ['completed'] }]
        },
        {   // Jobs may only be invoiced when verified
            type: 'primary',
            label: 'button.create_invoice_button_label',
            action: 'create_invoice',
            restrictedRight: ['only_view'],
            allowedFeature: ['invoices'],
            condition: [
                { field: 'status_condition', values: ['finished'] },
                // { field: 'order', values:  }
            ]
        }
    ];

    // Define the sections of the modal
    const sections: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'job.general.client_card_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'searchselect', 
                        name: 'client',
                        label: 'job.general.client_label',
                        placeholder: 'job.general.client_placeholder',
                        objectName: 'client.general.object_name.singular',
                        query: {
                            // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                            endpoint: 'get_contact_search',
                            params: {
                                contact_type: ['contact', 'prospect', 'client'] 
                            }
                        },
                        viewModeLinkUrl: { function: determineLinkUrl },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                            subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
                        },
                        addNewItem: { method: 'modal-form', modalForm: <ContactForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'reporting_person',
                        label: 'job.general.reporting_person_label',
                        placeholder: 'job.general.reporting_person_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Only get persons and when a client is selected, get the persons connected to that contact
                            endpoint: 'get_person_search',
                            params: {
                                
                            }
                        },
                        dataCondition: [{'client.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        tooltipText: 'job.general.reporting_person_tooltipText',
                        addNewItem: { method: 'modal-form', modalForm: <PersonForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'invoice_address',
                        label: 'job.general.invoice_address_label',
                        placeholder: 'job.general.invoice_address_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all locations and when a client is selected, get the primary locations connected to that contact
                            endpoint: 'get_location_search',
                            params: {

                            }
                        },
                        dataCondition: [{'client.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <LocationForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'work_location',
                        label: 'job.general.work_location_label',
                        placeholder: 'job.general.work_location_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all locations
                            endpoint: 'get_location_search',
                            params: {
                            }
                        },
                        viewModeLinkUrl: { baseUrl: '/contacts/work-locations' },
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <LocationForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'location_person',
                        label: 'job.general.contact_at_location_label',
                        placeholder: 'job.general.contact_at_location_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Get all persons
                            endpoint: 'get_person_search',
                        },
                        dataCondition: [
                            {'client.entity_type': 'organization'}
                        ],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        tooltipText: 'job.general.contact_at_location_tooltipText',
                        addNewItem: { method: 'modal-form', modalForm: <PersonForm /> }
                    },
                ],
                [
                    // { 
                    //     type: 'dropdown', 
                    //     name: 'workflow', 
                    //     label: 'job.general.type_label', 
                    //     apiObject: 'workflow',
                    //     params: { is_active: true, connected_feature: 'job' },
                    //     selectionFormat: 'name',
                    //     optionFormat: (option: FieldOption) => option.name,
                    //     backendField: 'id',
                    //     // activeFeature: 'workflows',
                    //     disabled_selected: 'job.general.type_disabled_selected',
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    // },
                    // { 
                    //     type: 'dropdown', 
                    //     name: 'division', 
                    //     label: 'job.general.division_label', 
                    //     activeFeature: 'divisions',
                    //     disabled_selected: 'job.general.division_disabled_selected',
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    // },
                    // { 
                    //     type: 'reference', 
                    //     name: 'reference',
                    //     label: 'job.general.reference_label',
                    //     tooltipText: 'job.general.reference_tooltip',
                    // },
                    // { 
                    //     type: 'multiselect', 
                    //     name: 'labels', 
                    //     label: 'job.general.labels_label', 
                    //     apiObject: 'label',
                    //     apiRequest: [{
                    //         endpoint: 'get_label_list',
                    //         object: 'label',
                    //         params: { is_active: true, connected_feature: 'jobs' }
                    //     }],
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    //     disabled_selected: 'job.general.labels_disabled_selected',
                    // },
                    // { 
                    //     type: 'dropdown', 
                    //     name: 'billing_type', 
                    //     label: 'job.general.billing_type_label',
                    //     disabled_selected: 'forms.general_disabled_selected',
                    //     // dataCondition: [{ 'order' }],
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    //     hardcodedOptions: [
                    //         { value: 'calculation', name: 'job.billing_type.calculation'}, 
                    //         { value: 'fixed', name: 'job.billing_type.fixed'}, 
                    //         { value: 'non_billing', name: 'job.billing_type.non_billing'}, 
                    //     ],
                    // },
                    // {
                    //     type: 'target-date', 
                    //     name: 'target_date', 
                    //     label: 'job.general.target_date_label'
                    // },
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.detail_card_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'dropdown', 
                        name: 'workflow', 
                        label: 'job.general.type_label', 
                        apiObject: 'workflow',
                        apiRequest: [{
                            endpoint: 'get_workflow_list',
                            object: 'workflow',
                            params: { is_active: true, connected_feature: 'job' }
                        }],
                        selectionFormat: 'name',
                        optionFormat: (option: FieldOption) => option.name,
                        orderOptions: true,
                        backendField: 'id',
                        disabled_selected: 'job.general.type_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'dropdown', 
                        name: 'division', 
                        label: 'job.general.division_label', 
                        activeFeature: 'divisions',
                        disabled_selected: 'job.general.division_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'reference', 
                        name: 'reference',
                        label: 'job.general.reference_label',
                        tooltipText: 'job.general.reference_tooltip',
                    },
                    { 
                        type: 'dropdown', 
                        name: 'billing_type', 
                        label: 'job.general.billing_type_label',
                        disabled_selected: 'forms.general_disabled_selected',
                        // dataCondition: [{ 'order' }],
                        allowNoneOption: false,
                        showSearch: false,
                        hardcodedOptions: [
                            { value: 'calculation', name: 'job.billing_type.calculation'}, 
                            { value: 'fixed', name: 'job.billing_type.fixed'}, 
                            { value: 'non_billing', name: 'job.billing_type.non_billing'}, 
                        ],
                    },
                ],
                [
                    { 
                        type: 'multiselect', 
                        name: 'labels', 
                        label: 'job.general.labels_label', 
                        apiObject: 'label',
                        apiRequest: [{
                            endpoint: 'get_label_list',
                            object: 'label',
                            params: { is_active: true, connected_feature: 'jobs' }
                        }],
                        allowNoneOption: false,
                        showSearch: false,
                        disabled_selected: 'job.general.labels_disabled_selected',
                    },
                    // { 
                    //     type: 'dropdown', 
                    //     name: 'custom_color', 
                    //     label: 'job.general.custom_color_label',
                    //     disabled_selected: 'forms.general_disabled_selected',
                    //     // allowNoneOption: false,
                    //     showSearch: false,
                    //     hardcodedOptions: [
                    //         { value: "soft_dark_blue", name: "scheduling.color.soft-dark-blue" },
                    //         { value: "soft_blue", name: "scheduling.color.soft-blue" },
                    //         { value: "soft_dark_green", name: "scheduling.color.soft-dark-green" },
                    //         { value: "soft_green", name: "scheduling.color.soft-green" },
                    //         { value: "soft_yellow", name: "scheduling.color.soft-yellow" },
                    //         { value: "soft_orange", name: "scheduling.color.soft-orange" },
                    //         { value: "soft_red", name: "scheduling.color.soft-red" },
                    //         { value: "soft_gray", name: "scheduling.color.soft-gray" },
                    //         { value: "soft_dark_gray", name: "scheduling.color.soft-dark-gray" },
                    //         { value: "soft_purple", name: "scheduling.color.soft-purple" },
                    //     ],
                    // },
                    {
                        type: 'target-date', 
                        name: 'target_date', 
                        label: 'job.general.target_date_label'
                    },
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.activities_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'main_description', 
                    emptyLabel: 'job.general.main_description_emptyLabel',
                    placeholder: 'job.general.main_description_placeholder', 
                    enableStyling: 'extended',
                    showToolbarInitially: true,
                    rows: 2,
                },
                { 
                    type: 'textarea', 
                    name: 'internal_description', 
                    emptyLabel: 'job.general.internal_description_emptyLabel',
                    placeholder: 'job.general.internal_description_placeholder', 
                    rows: 2,
                    isNote: true,
                    enableStyling: 'extended',
                    showToolbarInitially: true,
                },
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.attachments_header',
            fields: [
                {
                    type: 'attachments',
                    name: 'attachments',
                    apiRequest: [{ 
                        endpoint: 'get_attachmenttype_list', 
                        object: 'attachmenttype', 
                        params: { 'deleted': false }
                    }]
                }
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.general.appointments_header',
            fields: [
                { 
                    name: 'appointments',
                    type: 'appointments',
                    apiRequest: [{
                        endpoint: 'get_user_list',
                        object: 'user',
                        params: { is_active: true, field_access: true }
                    }]
                },
            ]
        },
        // {
        //     component: 'FieldsCard',
        //     title: 'job.items.items_label',
        //     fields: [
        //         { 
        //             name: 'items',
        //             type: 'fieldset',
        //             draggable: true,
        //             fields: [
        //                 {
        //                     name: 'quantity_registrated',
        //                     type: 'text',
        //                     label: 'job.items.quantity_label',
        //                     placeholder: 'job.items.quantity_placeholder'
        //                     width: '2fr',
        //                 }
        //                 // Other fields within the fieldset
        //             ]
        //         }
        //     ]
        // },
        {
            component: 'FieldsCard',
            title: 'job.items.items_label',
            fields: [
                { 
                    name: 'items',
                    type: 'job-items',
                    jobId: itemId,
                    apiRequest: [{
                        endpoint: 'get_vatrate_list',
                        object: 'vatrate',
                        params: { is_active: true }
                    }]
                }
            ]
        },
        {
            component: 'FieldsCard',
            title: 'job.timerows.timerows_label',
            fields: [
                { 
                    name: 'timerows',
                    type: 'timerows',
                    jobId: itemId,
                    apiRequest: [
                        {
                            endpoint: 'get_timetype_list',
                            object: 'timetype',
                            params: { is_active: true, type: 'productive' }
                        },
                        {
                            endpoint: 'get_hourlyrate_list',
                            object: 'hourlyrate',
                            params: { is_active: true }
                        },
                        {
                            endpoint: 'get_user_list',
                            object: 'user',
                            params: { is_active: true, field_access: true }
                        }   
                    ]                 
                }
            ]
        },
        {
            component: 'PhotosCard'
        },
        // {
        //     component: 'MessagesCard'
        // },
    ]
    
    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            sections={sections}
            summary={showSummary}
            refetchList={refetchList}
        />
    );
};

export default JobModal;