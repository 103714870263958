import React from 'react';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtonsConfig } from 'types/ButtonTypes';
import { FieldOption } from 'types/FieldTypes';
import ContactForm from '../../views/contacts/ContactForm';
import PersonForm from '../../views/contacts/PersonForm';
import LocationForm from '../../views/contacts/LocationForm';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (recipient: any) => {
    if (recipient && recipient['entity_type'] && recipient['id']) {
        const baseUrl = recipient['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${recipient['id']}`;
    }
    return null;
}

// Rename the name of the function:
const OrderModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {

    // Specify the parameters of this detail modal:
    const apiObject = 'order';
    const object = 'order';
    const showStatusBar = false;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtonsConfig = {
        leftButtons: [
            {
                type: 'secondary',
                label: 'button.download_pdf_button_label',
                action: 'download_pdf',
                objectType: 'order',
                allowedRight: ['can_download'],
                query: {
                    endpoint: 'get_pdf_layout_list/order',
                    object: 'pdf_layout',
                    defaultParams: { deleted: false },
                },
            },
            {   
                type: 'dropdown',
                label: 'button.more_button_label',
                buttons: [
                    // {
                    //     label: 'button.duplicate_order_button_label',
                    //     action: 'duplicate',
                    //     restrictedRight: ['only_view'],
                    // },
                    {
                        label: 'button.delete_order_button_label',
                        action: 'delete',
                        restrictedRight: ['only_view'],
                        allowedRight: ['can_delete'],
                    }
                ]
            },
        ],
        rightButtons: [
            {
                type: 'secondary',
                label: 'button.create_invoice_button_label',
                action: 'create_invoice',
                restrictedRight: ['only_view'],
                allowedFeature: ['invoices'],
                query: {
                    endpoint: 'get_workflow_for_feature_list/invoice',
                    object: 'invoice_workflow',
                    defaultParams: { is_active: true },
                },
            },
            {
                type: 'secondary',
                label: 'button.create_job_button_label',
                action: 'create_job',
                restrictedRight: ['only_view'],
                allowedFeature: ['jobs'],
                query: {
                    endpoint: 'get_workflow_for_feature_list/job',
                    object: 'job_workflow',
                    defaultParams: { is_active: true },
                },
            },
        ]
    };

    // Define the summary of the modal
    const summary = true;

    // Define the base fields of the modal
    const fields: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'order.general.client_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'searchselect', 
                        name: 'client',
                        label: 'order.general.client_label',
                        placeholder: 'order.general.client_placeholder',
                        objectName: 'contact.general.object_name.singular',
                        query: {
                            // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                            endpoint: 'get_contact_search',
                            params: {
                                contact_type: ['contact', 'prospect', 'client'] 
                            }
                        },
                        viewModeLinkUrl: { function: determineLinkUrl },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                            subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
                        },
                        addNewItem: { method: 'modal-form', modalForm: <ContactForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'client_person',
                        label: 'order.general.client_person_label',
                        placeholder: 'order.general.client_person_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Only get persons and when a recipient is selected, get the persons connected to that contact
                            endpoint: 'get_person_search',
                            params: {
                                
                            }
                        },
                        postEndpoint: 'post_contact',
                        dataCondition: [{'client.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <PersonForm /> }
                    },
                    { 
                        type: 'searchselect', 
                        name: 'address',
                        label: 'order.general.address_label',
                        placeholder: 'order.general.address_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all active locations
                            // TODO: Get all locations and when a client is selected, get the primary locations connected to that contact
                            endpoint: 'get_location_search',
                            params: { }
                        },
                        postEndpoint: 'post_location',
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                        addNewItem: { method: 'modal-form', modalForm: <LocationForm /> }
                    },
                ],
                [
                    { 
                        type: 'dropdown', 
                        name: 'workflow', 
                        label: 'order.general.type_label', 
                        apiObject: 'workflow',
                        apiRequest: [{
                            endpoint: 'get_workflow_list',
                            object: 'workflow',
                            params: { 
                                is_active: true, 
                                connected_feature: 'order' 
                            }
                        }],
                        selectionFormat: 'name',
                        optionFormat: (option: FieldOption) => option.name,
                        orderOptions: true,
                        backendField: 'id',
                        disabled_selected: 'order.general.type_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'reference', 
                        name: 'reference',
                        label: 'order.general.reference_label',
                    },
                    {
                        type: 'sent-date', 
                        name: 'order_date', 
                        label: 'order.general.order_date_label',
                        tooltipText: 'order.general.order_date_tooltipText',
                        isEditable: { editability: false },
                    },
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'order.general.introduction_text_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'introduction_text', 
                    emptyLabel: 'order.general.introduction_text_emptyLabel',
                    placeholder: 'order.general.introduction_text_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        },
        {
            component: 'FieldsCard',
            title: 'order.general.lines_header',
            fields: [
                { 
                    name: 'lines',
                    type: 'document-lines',
                    featureName: 'order',
                    documentId: itemId,
                    apiRequest: [
                        {
                            endpoint: 'get_vatrate_list',
                            object: 'vatrate',
                            params: { is_active: true }
                        },
                        {
                            endpoint: 'get_ledgeraccount_list',
                            object: 'ledgeraccount',
                            params: { is_active: true, ledger_account_type: 'revenue' }
                        },
                        {
                            endpoint: 'get_currency_list',
                            object: 'currency',
                            params: {}
                        }
                    ]
                }
            ]
        },
        {
            component: 'FieldsCard',
            title: 'order.general.terms_and_conditions_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'terms_and_conditions', 
                    emptyLabel: 'order.general.terms_and_conditions_emptyLabel',
                    placeholder: 'order.general.terms_and_conditions_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        }
    ]

    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            sections={fields}
            summary={summary}
        />
    );
};

export default OrderModal;