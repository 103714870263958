import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const JobAppSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Werkbonnen aanmaken via de app',
            explanationText: 'Bepaal of je buitendienst medewerkers ook zelf via de app nieuwe werkbonnen kunnen aanmaken.',
            switchButton: {
                enabled: true,
                name: 'mobile_create_jobs',
            }
        },
        {
            title: 'Afspraak zichtbaarheid',
            explanationText: 'Bepaal tot hoe ver in de toekomst de afspraken in de app worden getoond. Door dit te beperken kun je bijvoorbeeld discussies voorkomen over toekomstig ingeplande opdrachten.',
            fields: [
                {
                    type: 'dropdown',
                    name: 'mobile_appointment_visibility',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'today', name: 'Alleen vandaag' },
                        { value: 'today_tomorrow', name: 'Vandaag en morgen' },
                        { value: 'always', name: 'Toon altijd' }
                    ]
                }
            ]
        },
        {
            title: 'Uren afronden',
            explanationText: 'Bepaal of de geregistreerde uren in de app naar boven moeten worden afgerond.',
            fields: [
                {
                    type: 'dropdown',
                    name: 'mobile_round_off_hours',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'none', name: 'Niet afronden' },
                        { value: '5min', name: 'Afronden op 5 minuten' },
                        { value: '15min', name: 'Afronden per kwartier' },
                        { value: '30min', name: 'Afronden op halve uren' },
                        { value: 'hour', name: 'Afronden op hele uren' }
                    ]
                }
            ]
        },
        {
            title: 'Nieuwe artikelen aanmaken in de app',
            explanationText: 'Bepaal of je buitendienst medewerkers zelf nieuwe artikelen mogen toevoegen. Als je dit uitzet, kunnen ze alleen bestaande artikelen selecteren.',
            switchButton: {
                enabled: true,
                name: 'mobile_create_products',
            }
        },
        {
            title: 'Toon mobiele werkvoorraad',
            explanationText: 'Toon de werkvoorraad met nog in de plannen opdrachten in de app. Op deze manier kan de buitendienst zelf opdrachten oppakken als ze tijd over hebben.',
            switchButton: {
                enabled: true,
                name: 'mobile_show_job_storage',
            }
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default JobAppSettings;