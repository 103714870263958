import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCheck from 'assets/CheckboxCheck.svg';

interface CheckboxProps {
    name: string;
    label: string;
    align: 'left' | 'right';
    value: boolean;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ 
    name, label, align, value, disabled = false, onChange
}) => {
    const { t } = useTranslation();

    return (                        
        <div className={`checkbox-item ${align}`}>
            <div className="checkbox-wrapper">
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    checked={value}
                    onChange={onChange}
                    disabled={disabled}
                    className='hidden-checkbox'
                />
                <label htmlFor={name}
                       className={`custom-checkbox ${align} ${value === true ? 'checked' : ''}`}>
                    {value === true && 
                        <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                    }
                </label>
                <label htmlFor={name}
                       className='checkbox-label'>
                    {t(label)}
                </label>
            </div>
        </div>
    )
};

export default Checkbox