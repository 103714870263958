import React, { useEffect, useState } from 'react';
import { useModal } from 'components/modals/ModalContext';
import { EventType, SchedulingViewType } from './SchedulingTypes';
import JobModal from '../jobs/JobModal';
import { Draggable } from 'services/dragdrop/Draggable';

interface EventProps {
    view: SchedulingViewType;
    event: EventType,
    colorMethod: string | null,
    currentResource: string | number,
    currentDay: string,
    currentTimeSlot?: string,
    pixelsPerMinute?: number,
    resourceDirection?: string,
    overlappingEvents?: [EventType[], number];
};

const Event: React.FC<EventProps> = ({
    view, event, colorMethod, currentResource, currentDay, currentTimeSlot, pixelsPerMinute,
    resourceDirection, overlappingEvents
}) => {
    const { initializeModal } = useModal();
    const [renderOnTimeSlot] = useState<boolean>(!!currentTimeSlot);
    const [isDraggable, setIsDraggable] = useState<boolean>(event.status !== 'done');

    // Determine if the event is draggable based on its status
    useEffect(() => {
        if (event && event.status) {
            if (event.status === 'done') {
                setIsDraggable(false);
            } else {
                setIsDraggable(true);
            }
        }
    });

    // Calculate the event start time offset in pixels from the start of the timeslot
    const calculateStartOffset = () => {
        // Return if the needed values are missing
        if (!event.startDateTime || !currentTimeSlot || !pixelsPerMinute) return;

        // Get the minutes of the timeslot and the start time (e.g. '45' of '07:45')
        const startTimeString = event.startDateTime.split('T')[1];
        const startTimeMinutes = Number(startTimeString.split(':')[1]);
        const timeSlotStartMinutes = Number(currentTimeSlot.split(':')[1]);
        
        // Calculate the start offset in minutes and pixels
        const offsetMinutes = startTimeMinutes - timeSlotStartMinutes;
        const offsetPixels = offsetMinutes * pixelsPerMinute;

        return offsetPixels;
    };

    // Calculate the event duration in pixels
    const calculateDurationPixels = () => {
        // Return if the needed values are missing
        if (!event.durationMinutes || !pixelsPerMinute) return;

        // Calculate the duration in pixels
        const durationPixels = event.durationMinutes * pixelsPerMinute;

        return durationPixels;
    };

    // Sets the absolute position of timeline rendered events
    const timeRenderedPosition = () => {

        // Return if the needed values are missing
        if (!pixelsPerMinute || !event.startDateTime) return {};
    
        // Determine the overlap and width percentage of the event
        const [overlappingEventList, widthPercentage] = overlappingEvents ?? [[], 100];  
        const index = overlappingEventList.findIndex(e => e.key === event.key) ?? 0;
    
        // Calculate the positioning values of the appointment
        const startTimeOffset = calculateStartOffset();
        const durationPixels = calculateDurationPixels();
    
        let timeRenderedStyle: React.CSSProperties = {};
    
        // For stacked direction, the appointments are positioned from left to right
        if (resourceDirection === 'stacked') {
            timeRenderedStyle = {
                position: 'absolute',
                top: overlappingEventList.length > 1 ? `calc(${(index * widthPercentage)}% + 1px)` : '1px',
                left: `${startTimeOffset}px`,
                width: `${durationPixels}px`,
                height: `calc(${widthPercentage}% - 2px)`,
            };
        }

        // For columns direction, the appointments are positioned from top to bottom
        if (resourceDirection === 'columns') {
            timeRenderedStyle = {
                position: 'absolute',
                top: `${startTimeOffset}px`,
                left: overlappingEventList.length > 1 ? `calc(${(index * widthPercentage)}% + 1px)` : '1px',
                width: `calc(${widthPercentage}% - 2px)`,
                height: `${durationPixels}px`,
            };
        }
    
        return timeRenderedStyle;
    };

    // Determine event classname
    const determineEventClassName = () => {
        let className = 'event';

        // If the color method is custom, add custom color if it exist, otherwise fallback to default custom color
        if (colorMethod === 'custom') {
            className += event.custom_color ? ` ${event.custom_color}` : ` default-custom-color`;
        } 
        
        // If color method is based on status, add the status name of the job
        else if (event.job) {
            className += ` ${event.job.status_name}`;
        }

        return className;
    }

    // Handle clicking on the event
    const handleEventClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // If the event belongs to an appointment of a job, open the job modal
        if (event.job) {
            initializeModal(
                React.cloneElement(<JobModal />, { itemId: event.job.id }), { itemId: event.job.id, modalSize: 'large' }
            );
        };

        // To be implemented: open timesheet or leave events
    };

    return (
        <Draggable 
            key={event.key}
            draggableId={{ type: 'event', event }}
            sourceDroppableArea={{ 
                ...view.viewType === 'employee_timeline' ? { type: 'timeSlot' } : { type: 'dayList'},
                resourceId: currentResource, 
                day: currentDay,
                ...currentTimeSlot ? { timeSlot: currentTimeSlot } : {}
            }}>

            {view.viewType === 'employee_timeline' ? (
                <div className={determineEventClassName()} 
                     onClick={(e) => {e.preventDefault(); handleEventClick(e)}}
                     style={renderOnTimeSlot ? timeRenderedPosition() : {}}>
                    <span>{event.startTime}</span>

                    {event.job?.client_name && (
                        <span> • {event.job.client_name}</span>
                    )}

                    {event.job?.work_location_city && (
                        <span>
                            <span style={{ margin: '0 5px' }}>•</span>
                            <span>{event.job.work_location_city}</span>
                        </span>
                    )}
                </div>
            ) : (
                <div className={determineEventClassName()} 
                     onClick={(e) => {e.preventDefault(); handleEventClick(e)}}>
                    <div className='list-event'>
                        <span>{event.startTime}</span>

                        {event.job?.client_name && (
                            <span> • {event.job.client_name}</span>
                        )}

                        {event.job?.work_location_city && (
                            <span>
                                <span style={{ margin: '0 5px' }}>•</span>
                                <span>{event.job.work_location_city}</span>
                            </span>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default Event;