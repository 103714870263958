import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import FormFieldContext from '../FormFieldContext';
import { FieldData, AttachmentsFieldsetType } from 'types/FieldTypes';
import { AttachmentType, AttachmentTypeType, createNewAttachment } from '../../../views/attachment/AttachmentTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'components/modals/ModalContext';
import Dropdown from '../basefields/Dropdown';
import Dropzone from '../basefields/Dropzone';
import AttachmentViewer from './AttachmentViewer';
import FormAlert from 'components/alerts/FormAlert';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { AlertType } from 'types/AlertTypes';
import { saveData } from 'services/api/saveData';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

const AttachmentsFieldset: React.FC<AttachmentsFieldsetType & { data: FieldData, viewKey: string }> = ({ 
    name, data, itemId, objectType, dropdownData, isEditable
}) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { errorMessages } = useGlobalContext();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const hasRightCheck = useAllowedRight;
    const [rows, setRows] = useState<AttachmentType[]>([]);
    const [attachmentTypes, setAttachmentTypes] = useState<AttachmentTypeType[]>([]);
    const [visibilityOptions] = useState<{ value: string, name: string }[]>([ 
        { value: 'office_and_field', name: 'attachment.visibility.office_and_field' }, 
        { value: 'only_office', name: 'attachment.visibility.only_office' }]);
    const [showAlert, setShowAlert] = useState<AlertType | null >(null);    
    const acceptedFileTypes = ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.png', '.jpeg', '.jpg', '.heic', '.heif'];
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));

    // Set the current attachments from the fetched data
    useEffect(() => {
        if (data && name && data[name] && data[name].length > 0) {
            const mappedAttachments = data[name].map((attachment: AttachmentType) => ({
                id: attachment.id,
                file: attachment.file,
                filename: attachment.filename,
                attachment_type: attachment.attachment_type,
                visibility: attachment.visibility,
                deleted: attachment.deleted
            }))
            setRows(mappedAttachments);
        }
    }, [data]);

    // Set the attachment types from the dropdown data
    useEffect(() => {
        if (dropdownData?.attachmenttype?.results) {
            // Sort the attachment types on their ordering
            const orderedItems = [...dropdownData.attachmenttype.results].sort((a, b) => a.ordering - b.ordering);
            setAttachmentTypes(orderedItems);
        } else {
            setAttachmentTypes([]);
        }
    }, [dropdownData]);

    // Upload the selected or dragged file
    const uploadFile = async(file: File | null) => {

        // Validate if a file is selected
        if (!file) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.no_file_selected')})
            return;
        }
    
        // Validate if the selected file is in the accepted file types
        const fileExtension = '.' + file.name.split('.').pop();
        if (!acceptedFileTypes.includes(fileExtension.toLowerCase())) {
            setShowAlert({ type: 'warning', message: t('validation.attachment.unsupported_file_type')});
            return;
        }

        // Try upload new file
        try {
            // setProgressBar(true);

            const newFile = new FormData();
            newFile.append('filename', file.name);
            newFile.append('file', file);

            if (objectType && itemId) {
                newFile.append(objectType, itemId.toString());
            }

            // if (jobId) newFile.append('job', jobId.toString());

            const response = await saveData({ apiUrl: 'post_attachment', method: 'post', data: newFile });

            if (response) {
                // Destructure the fields from the response data
                const { id, file, filename: fileName, proposal: proposalId, order: orderId, job: jobId} = response.data;

                // Create a new row for the attachment
                const newRow = createNewAttachment({ 
                    id: id,
                    file: file,
                    fileName: fileName,
                    proposalId: proposalId ?? undefined,
                    orderId: orderId ?? undefined,
                    jobId: jobId ?? undefined,
                })

                // Add the row to the current list of rows
                const updatedRows = (prevRows: AttachmentType[]) => [...prevRows, newRow];
    
                // Update the rows and updated data
                setRows(updatedRows);
                setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }));
            }

            // setProgressBar(false);
        } catch (error) {
            console.error('Error during file upload:', error);
            setShowAlert({ type: 'danger', message: t('validation.attachment.file_upload_error')});
        }
    };

    // Handle viewing of an attachment
    const handleAttachmentView = (event: React.MouseEvent, attachment: AttachmentType) => {
        // Prevent edit-view mode toggling
        event.preventDefault();
        event.stopPropagation();

        // Open the attachment view modal
        if (attachment.file && attachment.filename) {
            initializeModal(<AttachmentViewer file={attachment.file} filename={attachment.filename} type='attachment' />, { modalSize: 'medium-large' } )
        }
    }

    // Handle dropdown change
    const handleDropdownChange = (selectedValue: string, fieldName: 'visibility' | 'attachment_type', identifier: string) => {
        // Copy the attachments and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier);

        if (rowIndex !== -1) {
            // Update the value for the fields
            if (fieldName === 'visibility') {
                updatedRows[rowIndex][fieldName] = selectedValue;
            }

            if (fieldName === 'attachment_type') {
                updatedRows[rowIndex][fieldName] = parseFloat(selectedValue);
            }

            // Update the attachments
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }));
        }
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        let updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row from the updated rows
            const row = updatedRows[rowIndex]

            // Check if the row is a new row added in the frontend (it has an uuid but no id)
            if (!row.id && row.uuid) {
                // Directly remove it from the updated rows
                updatedRows = updatedRows.filter((_, index) => index !== rowIndex)
        
            } else {
                // Flag the backend known row as deleted without actually delete it
                updatedRows[rowIndex].deleted = true;
            }
    
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        } 
    }

    return (
        <div className='rows-field attachments-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general } )}
                </div>
            }
            {showAlert && (
                <FormAlert
                    type={showAlert.type}
                    message={showAlert.message}
                    onClose={() => setShowAlert(null)}
                />
            )}
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {rows.length > 0 && (
                        <div className='rows-header attachments-row'>
                            <div className='attachment-icon-column'></div> 
                            <div className='header-item attachment-name-column'>{t('attachment.general.name_label')}</div>
                            <div className='header-item'>{t('attachment.general.visibility_label')}</div>
                            <div className='header-item'>{t('attachment.general.attachment_type_label')}</div>
                            <div className='delete-placeholder'></div>
                        </div>
                    )}
                    {rows && rows.filter(row => row.deleted === false).map((row) => {
                        const identifier = row.id?.toString() || row.uuid;
                        
                        return (
                            <div key={identifier} className='list-item'>
                                <div className='attachments-row'>
                                    <div className='attachment-icon'>
                                        <FontAwesomeIcon icon={faPaperclip} />
                                    </div>
                                    <div className='attachment-name'>
                                        <span className='attachment-link' 
                                              onClick={e => handleAttachmentView(e, row)}>
                                                {row.filename}
                                        </span>
                                    </div>
                                    <Dropdown<{ value: string, name: string }>
                                        options={visibilityOptions}
                                        id={`visibility_${identifier}`}
                                        name={`visibility_${identifier}`}
                                        disabled_selected={t('attachment.general.visibility_disabled_selected')}
                                        selectedOption={visibilityOptions.find(option => option.value === row.visibility)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'visibility', identifier || '')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        showSearch={false}
                                        allowNoneOption={false}                            
                                    />
                                    <Dropdown<AttachmentTypeType>
                                        options={attachmentTypes}
                                        id={`attachment_type_${identifier}`}
                                        name={`attachment_type_${identifier}`}
                                        disabled_selected={t('attachment.general.attachment_type_disabled_selected')}
                                        selectedOption={attachmentTypes.find(attachmentType => attachmentType.id === row.attachment_type)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'attachment_type', identifier || '')}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        showSearch={false}
                                        allowNoneOption={true}                            
                                    />
                                    {hasRightCheck('can_delete') &&
                                        <div className='delete-icon tooltip-icon'>
                                            <FontAwesomeIcon 
                                                icon={faTrash} 
                                                onClick={() => handleDeleteRow(identifier || '')} />
                                            <span className="tooltip">{t('general.delete')}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <Dropzone
                        acceptedFileTypes={acceptedFileTypes}
                        placeholder={'attachment.general.drag_drop_placeholder'}
                        onFileSelect={(file) => uploadFile(file)}
                    />
                </div>
            ) : (
                // View mode
                <>
                    <div className={`full-width-alignment ${isEditable?.editability && !userHasOnlyViewRights ? 'editable' : ''}`}>
                        <div className="view-mode">
                            <span className='p'>
                                <div className='item-list'>
                                    {rows.map((row, index) => {

                                        // Get the attachment type of the attachment
                                        const attachmentType = attachmentTypes.find(type => type.id === row.attachment_type);

                                        return (
                                            // Show attachment list in view mode
                                            <div className='item attachments-row' key={index}>
                                                <div className='attachment-icon'>
                                                    <FontAwesomeIcon icon={faPaperclip} />
                                                </div>
                                                <div className='attachment-name'>
                                                    <span className='attachment-link' 
                                                          onClick={e => handleAttachmentView(e, row)}>
                                                            {row.filename}
                                                    </span>
                                                </div>
                                                {attachmentType && 
                                                    <span className='attachment-type'>
                                                        ({attachmentType.name}) 
                                                    </span>
                                                }
                                            </div>
                                        )
                                    })}
                                    {isEditable?.editability && !userHasOnlyViewRights && (
                                        // Only show add item label when the object is editable
                                        <div className='add-item'>
                                            {t('attachment.general.add_attachment')}
                                        </div>
                                    )}
                                </div>
                            </span>
                            <span className='edit-icon'>
                                <FontAwesomeIcon icon={faPen} />
                            </span> 
                        </div>
                    </div>
                    {/*  // TODO in later stage: add dropzone when hovering over attachments
                    {dragOver && (
                        // Show dropzone on drag over in view mode
                        <Dropzone
                            acceptedFileTypes={acceptedFileTypes}
                            placeholder={'attachment.general.drag_drop_placeholder'}
                            onFileSelect={(file) => uploadFile(file)}
                        />
                    )} */}
                </>
            )}
        </div>
    )
};

export default AttachmentsFieldset;