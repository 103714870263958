import { useEffect, useState } from 'react';
import { EventsListType } from '../SchedulingTypes';

/*
 * useAdjustSchedulerSize.ts
 * A custom hook to use in the scheduling component to maximize
 * the screen height or width.
 */

const useAdjustSchedulerSize = (
    calendarRef: React.RefObject<HTMLDivElement>,
    isTopNavbarEnabled: boolean,
    viewDirection: string,
    viewType: string,
    daysAmount: number,
    timeSlots: string[] | undefined,
    showInbox: boolean,
    events: EventsListType,
) => {
    const [resourceSize, setResourceSize] = useState<number | undefined>(undefined);
    const [scheduleListHeight, setScheduleListHeight] = useState<number>(0);
    const [pixelsPerMinute, setPixelsPerMinute] = useState<number | undefined>(undefined);
    const [dayColumnHeight, setDayColumnHeight] = useState<number | null>(null);

    const adjustSchedulerSize = () => {

        // Return if no calendar ref current is known
        if (!calendarRef.current) return;

        // Get the height of the window of the user
        const windowHeight = window.innerHeight;

        // If the top navbar is enabled, get its height
        let topNavbarHeight = 0;
        if (isTopNavbarEnabled) {
            const topNavbar = document.querySelector('.topbar') as HTMLElement;
            topNavbarHeight = topNavbar ? topNavbar.offsetHeight : 0;
        }

        // Get the navbar height
        const navBar = document.querySelector('.navbar') as HTMLElement;
        const subNavBar = document.querySelector('.subnavbar') as HTMLElement;
        const navBarHeight = navBar ? navBar.offsetHeight : 0;
        const subNavBarHeight = subNavBar ? subNavBar.offsetHeight : 0;
    
        // Get the height of the page header and scheduling header
        const mainElement = document.querySelector('main') as HTMLElement;
        const mainElementMarginTop = document.querySelector('main') ? parseInt(window.getComputedStyle(mainElement).marginTop) : 0;
        const pageHeader = document.querySelector('.scheduling-header') as HTMLElement;
        const pageHeaderMarginBottom = pageHeader ? parseInt(window.getComputedStyle(pageHeader).marginBottom) : 0;
        
        // Calculate the height of the page header
        const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight + pageHeaderMarginBottom : 0;

        // Calculate the height of the area above the scheduler component
        const heightAboveScheduler = topNavbarHeight + navBarHeight + subNavBarHeight + mainElementMarginTop + pageHeaderHeight;

        // Adjust row height for stacked view
        if (viewDirection === 'stacked') {

            /*
             * Set the height per resource row
             */
        
            // Get the height of the board header and the to schedule row
            const boardHeader = document.querySelector('.scheduling-board .header') as HTMLElement;
            const toScheduleRow = document.querySelector('.scheduling-board .unassigned-row') as HTMLElement;
            
            const boardHeaderHeight = boardHeader ? boardHeader.offsetHeight : 0;
            const toScheduleRowHeight = toScheduleRow ? toScheduleRow.offsetHeight : 0;

            // Get the height of the first resource group header row
            const firstResourceGroupHeaderRow = document.querySelector('.scheduling-board .resource-group-header') as HTMLElement;
            const resourceGroupHeaderRowHeight = firstResourceGroupHeaderRow ? (firstResourceGroupHeaderRow.offsetHeight + 1) : 0;

            // Get the number of resource group header rows
            const resourceGroupHeaderRows = document.querySelectorAll('.scheduling-board .resource-group-header');
            const resourceGroupHeaderRowsHeight = resourceGroupHeaderRows.length * resourceGroupHeaderRowHeight;
            
            // Get the number of resource rows
            const resourceRows = document.querySelectorAll('.scheduling-board .adjust-height');
        
            // Calculate the height of resource rows borders (1px per resource row)
            const totalBorderHeight = resourceRows.length * 1;
        
            // Calculate the height above the resource rows
            const estimatedOtherElementsHeight = heightAboveScheduler + boardHeaderHeight + toScheduleRowHeight + resourceGroupHeaderRowsHeight + totalBorderHeight;
    
            // Calculate the height available for the scheduling rows
            const totalHeightAvailable = windowHeight - estimatedOtherElementsHeight;

            // Set the minimal row height to 44px
            const minRowHeight = 44;

            // Calculate the height per row
            const heightPerRow = Math.max(totalHeightAvailable / resourceRows.length, minRowHeight);
            
            // Devide the available height per row
            if (resourceRows.length > 0){
        
                // Reset unassigned row width (if set in columns direction adjust width function)
                const unassignedRow = document.querySelector('.scheduling-board .unassigned-row');
                if (unassignedRow) {
                    (unassignedRow as HTMLElement).style.width = 'auto'
                };
        
                // Update the height of the resource rows
                resourceRows.forEach(row => {
                    const element = row as HTMLElement;

                    // Reset resource rows width (if set in columns direction adjust width function)
                    element.style.width = 'auto';

                    // Set the height of the rows
                    element.style.height = `${heightPerRow}px`;
                });
            }

            // Set the resource size
            setResourceSize(heightPerRow);

            // Set the schedule list height
            const calendarHeight = windowHeight - heightAboveScheduler - boardHeaderHeight;
            setScheduleListHeight(calendarHeight);


            /*
             * Reset header day columns height
             */

            // Get the header day columns elements
            const headerDayColumns = document.querySelectorAll('.scheduling-board .calendar .header .day-column');

            // Loop through the header day columns and update the height
            if (headerDayColumns.length > 0) {
                headerDayColumns.forEach(row => {
                    const element = row as HTMLElement;

                    // Reset the height of the header day columns to the initial value
                    element.style.height = `2.5rem`;
                });
            }


            /*
             * Reset day columns height to auto
             */

            // Get all day column elements
            const resourceDayColumns = document.querySelectorAll('.scheduling-board .calendar .resources .day-column');

            // Loop through the day columns and update the height
            if (resourceDayColumns.length > 0) {
                resourceDayColumns.forEach(row => {
                    const element = row as HTMLElement;

                    // Reset the height of the day columns
                    element.style.height = `auto`;
                });
            }


            /*
             * Calculate the pixels per minute
             */

            if (viewType === 'employee_timeline' && timeSlots) {

                // Get the width of the window of the user
                const windowWidth = window.innerWidth;

                // Define the inbox width if shown
                const inboxWidth = showInbox ? (windowWidth * 0.25) : 0;

                // Get the name column width
                const nameColumnWidth = 200;

                // Determine the number of timeslots per day
                const timeSlotsPerDay = timeSlots.length;

                // Calculate the total width of all calendar columns
                const calendarColumnsWidth = windowWidth - nameColumnWidth - inboxWidth;

                // Calculate the width of a single day column
                const dayColumnWidth = calendarColumnsWidth / daysAmount;

                // Calculate the width of a timeslot
                const timeSlotWidth = dayColumnWidth / timeSlotsPerDay;

                // Calculate the width of a minute. Timeslots are 15 minutes.
                const pixelsPerMinute = timeSlotWidth / 15;

                setPixelsPerMinute(pixelsPerMinute);
            }


        // Adjust row width for columns view
        } else if (viewDirection === 'columns') {

            /*
             * Set the width per resource element
             */

            // Get the width of the window of the user
            const windowWidth = window.innerWidth;

            // Define the inbox width if shown
            const inboxWidth = showInbox ? (windowWidth * 0.25) : 0;
            
            // Set the width of the day name element (48 px + 1 px border)
            const dayNameWidth = 49;

            // Get the rows to adjustable the width for
            const resourceElements = document.querySelectorAll('.scheduling-board .adjust-width');

            // Calculate the width of resource rows borders (1px per resource row)
            const borderWidth = resourceElements.length * 1;

            // Calculate the total width to be used by the rows
            const totalAvailableWidth = windowWidth - inboxWidth - dayNameWidth - borderWidth;

            // Set the minimal element width to 200px
            const minElementWidth = 170;

            // Determine the number of elements which need to scale
            const numberOfElements = resourceElements.length;

            // Calculate the width per element
            const widthPerElement = Math.max(totalAvailableWidth / numberOfElements, minElementWidth);

            // Loop through the resource elements to set the width
            if (resourceElements.length > 0) {
                resourceElements.forEach(resource => {
                    const element = resource as HTMLElement;

                    // Reset resource rows height (if set in stacked direction adjust height function)
                    element.style.height = 'auto';

                    // Set the width of the rows
                    element.style.width = `${widthPerElement}px`;
                }); 
            }

            // Set the resource size
            setResourceSize(widthPerElement);

            
            /*
             * Adjust the height of the day rows to fit the screen height
             */

            // Get the height of the first resource group header element
            const resourceGroupHeaderElement = document.querySelector('.scheduling-board .resource-group-header') as HTMLElement;
            const resourceGroupHeaderHeight = resourceGroupHeaderElement ? resourceGroupHeaderElement.offsetHeight : 0;

            // Get the height of the resource name element
            const resourceNameElement = document.querySelector('.scheduling-board .resource-row .name-column') as HTMLElement;
            const resourceNameHeight = resourceNameElement ? resourceNameElement.offsetHeight : 0;

            // Calculate the height of the day borders
            const borderHeights = daysAmount * 1;

            // Calculate the available height for the day rows
            const totalAvailableHeight = windowHeight - heightAboveScheduler - resourceGroupHeaderHeight - resourceNameHeight - borderHeights;

            // Set the minimal row height to 150px
            const minDayHeight = 150;

            // Calculate the height per day column
            const heightPerDay = Math.max(totalAvailableHeight / daysAmount, minDayHeight);

            // Set the day column height
            setDayColumnHeight(heightPerDay);

            // Set the schedule list height
            const calendarHeight = windowHeight - heightAboveScheduler - resourceNameHeight;
            setScheduleListHeight(calendarHeight);    
            

            /*
             * Calculate the pixels per minute
             */

            if (viewType === 'employee_timeline' && timeSlots) {

                // Determine the number of timeslots per day
                const timeSlotsPerDay = timeSlots.length;

                // Calculate the height of a timeslot
                const timeSlotHeight = heightPerDay / timeSlotsPerDay;

                // Calculate the height of a minute. Timeslots are 15 minutes.
                const pixelsPerMinute = timeSlotHeight / 15;

                setPixelsPerMinute(pixelsPerMinute);
            }
        }
    }

    useEffect(() => {
        adjustSchedulerSize();

        const handleResize = () => {
            adjustSchedulerSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [calendarRef, isTopNavbarEnabled, viewDirection, daysAmount, showInbox, events]);

    return { resourceSize, scheduleListHeight, pixelsPerMinute, dayColumnHeight };
};

export default useAdjustSchedulerSize;