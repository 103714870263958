import axios from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from './csrfFunctions';
import { encryptData } from "./encryptData";
import { FeaturesPerPackage } from 'services/permissions/packageFeatures';
import { filterReleasedFeatures } from 'services/permissions/releasedFeatures';
import i18n from 'internationalization/i18n';

interface EnvironmentData {
    first_name: string;
    last_name: string;
    environment_hash: string;
    package: 'starter' | 'essential' | 'advanced' | 'enterprise';
    type: 'trial' | 'beta' | 'client' | 'demo' | 'admin';
    status: 'active' | 'expired' | 'suspended' | 'cancelled';
    active_features: string[];
    access_to: string[];
    allowed_features: string[];
    allowed_rights: string[];
    redirect_to: 'trial-wizard' | 'trial-homepage' | 'beta-homepage' | 'dashboard' | 'admin-dashboard';
}

interface SubscriptionData {
    is_trial: boolean | null;
    start_date: string | null;
    end_date: string | null;
    no_payment_method: boolean;
};

interface LoginResponse {
    environment_data: EnvironmentData;
    subscription_data: SubscriptionData;
    user_hash: string;
    language: string | null;
}

interface LoginResult {
    status: 'idle' | 'success' | 'error';
    redirect?: 'trial-wizard' | 'trial-homepage' | 'beta-homepage' | 'dashboard' | 'admin-dashboard';
    data?: LoginResponse;
    error?: string;
}

interface LoginParams {
    email: string;
    password: string;
    navigator_language: string;
    navigator_locale: string;
    navigator_timezone: string | null;
    remember_login: boolean;
    utc_datetime: string;
    callbacks: LoginCallbacks;
}

interface LoginCallbacks {
    setIsLoggedIn: (loggedIn: boolean | null) => void;
    setUserHash: (userHash: string | undefined) => void;
    setFirstName: (firstName: string | null) => void;
    setLastName: (lastName: string | null) => void;
    setEnvironmentHash: (value: string) => void;
    setEnvironmentPackage: (value: 'starter' | 'essential' | 'advanced' | 'enterprise') => void;
    setEnvironmentType: (value: 'trial' | 'beta' | 'client' | 'demo' | 'admin') => void;
    setEnvironmentStatus: (value: 'active' | 'expired' | 'suspended' | 'cancelled') => void;
    setPackageFeatures: (value: string[]) => void;
    setActiveFeatures: (value: string[]) => void;
    setAccessTo: (value: string[]) => void;
    setAllowedFeatures: (value: string[]) => void;
    setAllowedRights: (value: string[]) => void;
    setRedirectTo: (value: 'trial-wizard' | 'trial-homepage' | 'beta-homepage' | 'dashboard' | 'admin-dashboard' | null) => void;
    setSubscriptionIsTrial: (value: boolean | null) => void;
    setSubscriptionStartDate: (value: string | null) => void;
    setSubscriptionEndDate: (value: string | null) => void;
    setSubscriptionShowNoPaymentMethodBanner: (value: boolean) => void;
    setStatesLoaded: (isLoaded: boolean) => void;
}

// Log in to the server
export const login = async ({ 
    email, password, navigator_language, navigator_locale, navigator_timezone, utc_datetime, remember_login, callbacks 
}: LoginParams): Promise<LoginResult> => {
    const csrfToken = getCsrfToken();

    try {
        const response = await axios.post<LoginResponse>(`${apiBaseUrl}/login/`, {
            email, 
            password, 
            navigator_language, 
            navigator_locale, 
            navigator_timezone, 
            utc_datetime,
            remember_login
        }, {
            withCredentials: true,
            headers: { 
                'X-CSRFToken': csrfToken 
            }
        });

        // Set the language if it's given
        if (response.data.language) {
            i18n.changeLanguage(response.data.language)
        }

        // Get the data from the api response
        const { environment_data, subscription_data, user_hash } = response.data;

        // Get the package features from the environment package response
        const packageName = environment_data.package as keyof typeof FeaturesPerPackage;
        const packageFeatures = FeaturesPerPackage[packageName] || [];

        // Use the callbacks to set the information in the authentication context
        callbacks.setIsLoggedIn(true);
        callbacks.setUserHash(user_hash);
        callbacks.setFirstName(environment_data.first_name);
        callbacks.setLastName(environment_data.last_name);
        callbacks.setEnvironmentHash(environment_data.environment_hash);
        callbacks.setEnvironmentPackage(environment_data.package);
        callbacks.setEnvironmentType(environment_data.type);
        callbacks.setEnvironmentStatus(environment_data.status);
        callbacks.setPackageFeatures(packageFeatures);
        callbacks.setActiveFeatures(filterReleasedFeatures(environment_data.active_features));
        callbacks.setAccessTo(environment_data.access_to);
        callbacks.setAllowedFeatures(filterReleasedFeatures(environment_data.allowed_features));
        callbacks.setAllowedRights(environment_data.allowed_rights);
        callbacks.setRedirectTo(environment_data.redirect_to);
        callbacks.setSubscriptionIsTrial(subscription_data.is_trial);
        callbacks.setSubscriptionStartDate(subscription_data.start_date);
        callbacks.setSubscriptionEndDate(subscription_data.end_date);
        callbacks.setSubscriptionShowNoPaymentMethodBanner(subscription_data.no_payment_method);
        callbacks.setStatesLoaded(true);

        // Encrypt environment data and put it in the local storage
        for (const [key, value] of Object.entries(environment_data)) {
            const encryptedValue = encryptData(JSON.stringify(value));
            localStorage.setItem(`${key}`, encryptedValue);
        }

        // Encrypt subscription data and put it in the local storage
        for (const [key, value] of Object.entries(subscription_data)) {
            const encryptedValue = encryptData(JSON.stringify(value));
            localStorage.setItem(`${key}`, encryptedValue);
        }

        // Encrypt user hash and put it in the local storage
        const encryptedUserHash = encryptData(user_hash)
        localStorage.setItem('user_hash', encryptedUserHash);

        // Set flag to start session checks in the local storage
        localStorage.setItem('currentSession', 'true');

        // Return success
        return { 
            status: 'success', 
            redirect: environment_data.redirect_to,
            data: response.data 
        };
    } catch (error: any) {
        // Configure the error message
        const errorMessage = error.response?.data?.general || error.message || 'Error while logging in';

        // Return the error
        return { 
            status: 'error', 
            error: errorMessage }
    }
}