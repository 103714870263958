import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useFetchData } from 'services/api/useFetchData';
import { v4 as uuidv4 } from 'uuid';
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeIbanElement } from '@stripe/stripe-js';
import { createStripeCustomer } from './functions/createStripeCustomer';
import { createStripePaymentMethod } from './functions/createStripePaymentMethod';
import { BillingData, createBillingData, createPaymentMethod, createSubscriptionData, PaymentMethod, SubscriptionData, SubscriptionLine } from './BillingTypes';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import PackageSelection from './PackageSelection';
import SubscriptionPriceSubtotal from './PriceSummary';
import BillingDetails, { BillingDetailsRef } from './BillingDetails';
import SubscriptionOverview from './SubscriptionOverview';
import { saveData } from 'services/api/saveData';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight} from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/detailpage.scss';
import '../../../style/scss/billing.scss';
import CheckboxField from 'components/forms/fields/CheckboxField';
import Checkbox from 'components/forms/basefields/Checkbox';

const SubscriptionWizard: React.FC = () => {
    const { environmentHash } =  useAuthContext();
    const viewKey = useMemo(() => uuidv4(), []);
    const { userLocale, userTimezone } = useSettings();
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [currentSubscription, setCurrentSubscription] = useState<SubscriptionData>(createSubscriptionData);
    const [updatedSubscription, setUpdatedSubscription] = useState<SubscriptionData>(createSubscriptionData);
    const [billingData, setBillingData] = useState<BillingData>(createBillingData);
    const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethod>(createPaymentMethod);
    const [acceptConditions, setAcceptConditions] = useState<boolean>(false);
    const billingDetailsRef = useRef<BillingDetailsRef>(null);
    const [activeUsers, setActiveUsers] = useState<number>(1);
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    // const cardElementRef = useRef<StripeCardElement | null>(null);
    // const ibanElementRef = useRef<StripeIbanElement | null>(null);
    // const stripe = useStripe();
    // const elements = useElements();

    // useEffect(() => {
    //     if (elements) {
    //         cardElementRef.current = elements.getElement(CardElement);
    //         ibanElementRef.current = elements.getElement(IbanElement);
    //     }
    // }, [elements]);

    // Fetch the current active users
    const { response: fetchedUsers } = useFetchData({ 
        apiUrl: 'get_user_list', 
        params: useMemo(() => ({ is_active: true }), []) 
    });

    // Fetch the current subscription data
    const currentDateTime = new Date();
    const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);
    const { response: fetchedSubscription, loading: subscriptionLoading } = useFetchData({ 
        apiUrl: `get_subscription/${environmentHash}`,
        params: useMemo(() => ({ current_date: utcDateTime }), [])
    });

    useEffect(() => {
        if (fetchedSubscription) {
            console.log("fetchedSubscription", fetchedSubscription);
        }
    }, [fetchedSubscription])

    // Fetch the company details
    const { response: fetchedCompanyDetails } = useFetchData({ 
        apiUrl: `get_company_details/${environmentHash}`
    });

    // Fetch the current payment method
    const { response: fetchedPaymentMethod } = useFetchData({ 
        apiUrl: `get_payment_method/${environmentHash}`, 
        params: useMemo(() => ({ is_active: true }), []) 
    });

    // Set the amount of active users
    useEffect(() => {
        if ((!fetchedSubscription || Object.keys(fetchedSubscription).length === 0) && fetchedUsers && fetchedUsers.count > 0) {
            setActiveUsers(fetchedUsers.count);
            setUpdatedSubscription(prev => ({ ...prev, seats: String(fetchedUsers.count) }));
        }
    }, [fetchedUsers, fetchedSubscription]);

    // Set the company details as billing data
    useEffect(() => {
        if (fetchedCompanyDetails) {
            const transformedCompanyDetails: BillingData = {
                company_name: fetchedCompanyDetails.company_name ?? null,
                first_name: fetchedCompanyDetails.first_name ?? null,
                last_name: fetchedCompanyDetails.last_name ?? null,
                email: fetchedCompanyDetails.email ?? null,
                invoice_email: fetchedCompanyDetails.invoice_email ?? null,
                street: fetchedCompanyDetails.street ?? null,
                house_number: fetchedCompanyDetails.house_number ?? null,
                postal_code: fetchedCompanyDetails.postal_code ?? null,
                city: fetchedCompanyDetails.city ?? null,
                country: fetchedCompanyDetails.country ?? null,
                vat_number: fetchedCompanyDetails.vat_number ?? null,
                stripe_customer_id: fetchedCompanyDetails.stripe_customer_id ?? null
            };

            setBillingData(transformedCompanyDetails);
        }
    }, [fetchedCompanyDetails]);

    // Set the current payment method if it exists
    useEffect(() => {
        if (fetchedPaymentMethod) {
            setPaymentMethodData(fetchedPaymentMethod);
        }
    }, [fetchedPaymentMethod]);

    // Set the current subscription data
    useEffect(() => {
        if (fetchedSubscription && Object.keys(fetchedSubscription).length > 0) {
            // Transform the current subscription data
            let transformedSubscription: SubscriptionData = {
                id: fetchedSubscription.id,
                is_trial: fetchedSubscription.is_trial,
                duration: fetchedSubscription.duration === 'trial' ? 'yearly' : fetchedSubscription.duration,
                seats: String(fetchedSubscription.total_seats),
                package: fetchedSubscription.package,
                start_date: fetchedSubscription.start_date,
                end_date: fetchedSubscription.end_date,
                status: fetchedSubscription.status,
                subscription_lines: fetchedSubscription.subscription_lines
                    ? fetchedSubscription.subscription_lines.map((line: SubscriptionLine) => ({
                        start_date: line.start_date,
                        end_date: line.end_date,
                        type: line.type,
                        product_name: line.product_name ?? null,
                        quantity: line.quantity,
                        price: line.price,
                        price_type: line.price_type,
                        is_active: line.is_active
                    }))
                    : null,
            };

            // Add the pending subscription data if it is given
            if (fetchedSubscription.pending_subscription) {
                transformedSubscription = {
                    ...transformedSubscription,
                    pending_subscription: {
                        id: fetchedSubscription.id,
                        is_trial: false,
                        duration: fetchedSubscription.pending_subscription.duration,
                        seats: String(fetchedSubscription.pending_subscription.total_seats),
                        package: fetchedSubscription.pending_subscription.package,
                        start_date: fetchedSubscription.pending_subscription.start_date,
                        end_date: fetchedSubscription.pending_subscription.end_date,
                        status: fetchedSubscription.pending_subscription.status,
                        subscription_lines: fetchedSubscription.pending_subscription.subscription_lines
                            ? fetchedSubscription.pending_subscription.subscription_lines.map((line: SubscriptionLine) => ({
                                start_date: line.start_date,
                                end_date: line.end_date,
                                type: line.type,
                                product_name: line.product_name ?? null,
                                quantity: line.quantity,
                                price: line.price,
                                price_type: line.price_type,
                                is_active: line.is_active
                            }))
                            : null,
                    },
                };
            }

            // Transform the updated subscription data, without subscription lines or pending subscription
            const transformedUpdatedSubscription: Omit<SubscriptionData, 'pending_subscription' | 'subscription_lines'> = {
                id: null,
                is_trial: false,
                duration: fetchedSubscription.duration === 'trial' ? 'yearly' : fetchedSubscription.duration,
                seats: String(fetchedSubscription.total_seats),
                package: fetchedSubscription.package,
                start_date: fetchedSubscription.start_date,
                end_date: fetchedSubscription.end_date,
                status: fetchedSubscription.status,
            };

            setCurrentSubscription(transformedSubscription);
            setUpdatedSubscription(transformedUpdatedSubscription);
        }
    }, [fetchedSubscription]);

    // Handle the billing data change
    const handleBillingDataChange = (updatedData: Partial<BillingData & PaymentMethod>) => {

        // Copy the current billing data and payment method data
        const updatedBillingData: Record<string, any> = { ...billingData };
        const updatedPaymentMethodData: Record<string, any> = { ...paymentMethodData };
    
        // Loop through the fields in the updated data object
        Object.entries(updatedData).forEach(([key, value]) => {

            // Update the billing data state
            if (key in billingData) {
                updatedBillingData[key] = value;

            // Update the payment method state
            } else if (key in paymentMethodData) {
                updatedPaymentMethodData[key] = value;
            }
        });
    
        // Set the changed data in the states
        setBillingData(updatedBillingData as BillingData);
        setPaymentMethodData(updatedPaymentMethodData as PaymentMethod);
    };
    
    // Navigate to the next step
	const nextStep = () => {        
		if (currentStep < 3) {
			const nextStep = currentStep + 1;
			setCurrentStep(nextStep);
			history.push(`/settings/billing/subscription/wizard/${nextStep}`);
		}
	};

	// Navigate to the previous step
	const prevStep = () => {
		if (currentStep > 1) {
			const previousStep = currentStep - 1;
			setCurrentStep(previousStep);
			history.push(`/settings/billing/subscription/wizard/${previousStep}`);
		}
	};

    // Handle the submit of the subscription
    const handleSubmit = async () => {
        try {            
            // Start the button loader
            setButtonLoader(true);

            // Get the current stripe customer id from the billing data
            let stripeCustomerId = billingData.stripe_customer_id;

            // If no stripe customer id exists, create it
            if (!stripeCustomerId) {
                stripeCustomerId = await createStripeCustomer(environmentHash, billingData, setBillingData)
            }

            // Get the current stripe payment method id from the payment method
            let stripePaymentMethodId = paymentMethodData.stripe_payment_method_id;

            // If no stripe payment method id exists, create it and save it in the database
            // if (!stripePaymentMethodId && stripeCustomerId) {
            //     stripePaymentMethodId = await createStripePaymentMethod(
            //         stripe,
            //         elements,
            //         stripeCustomerId,
            //         billingData,
            //         paymentMethodData,
            //         billingDetailsRef
            //     )
            // }

            // Add the current date to look for a current subscription
            const currentDateTime = new Date();
            const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

            // Configure the submit data
            const submitData = {
                ...updatedSubscription,
                billingData,
                paymentMethodData,
                ['stripe_payment_method_id']: stripePaymentMethodId,
                ['current_date']: utcDateTime
            }

            // Post the data in the backend
            const response = await saveData({ 
                apiUrl: `patch_subscription/${environmentHash}`, 
                method: 'patch', 
                data: submitData
            })

            if (response?.status === 200 || response?.status === 201) {

                setButtonLoader(false);

                history.push(`/settings/billing/subscription`)


                console.log("Subscription response", response)
            }
            
            
        } catch (error) {
            console.error("Error on submit", error);
            setButtonLoader(false);
        };        
	};

    return (
        <div className='container-filled'>      
            <div className='wizard subscription-wizard'>

                {/* Step 1: Select package */}
                {currentStep === 1 && (
                    <PackageSelection
                        loading={subscriptionLoading}
                        currentSubscription={currentSubscription}
                        updatedSubscription={updatedSubscription}
                        setUpdatedSubscription={setUpdatedSubscription}
                    />
                )}

                {/* Step 2: Enter payment details */}
                {/* <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
                    <BillingDetails 
                        ref={billingDetailsRef}
                        billingData={billingData}
                        paymentMethodData={paymentMethodData}
                        onUpdateData={handleBillingDataChange}
                    />
                </div> */}

                {/* Step 3: Purchase overview */}
                {currentStep === 3 && (
                    <SubscriptionOverview
                        currentSubscription={currentSubscription}
                        updatedSubscription={updatedSubscription}
                    />
                )}

                {/* Navigation footer buttons */}
                <div className="button-footer-fixed">
                    <div className="container-filled">
                        {currentStep === 1 && (
                            <div></div>
                        )}
                        {currentStep > 1 && (
                            <SecondaryButton
                                label='general.previous'
                                size='small'
                                onClick={() => prevStep()} />
                        )}
                        {currentStep < 3 && (
                            <SubscriptionPriceSubtotal 
                                updatedSubscription={updatedSubscription} />
                        )}
                        {currentStep === 3 && (
                            <div className='accept-conditions-checkbox'>
                                <Checkbox
                                    name='allow_sepa'
                                    value={acceptConditions}
                                    onChange={(event) => setAcceptConditions(event.target.checked)}
                                    align='left'
                                    label='Ik ga akkoord met de gebruikersvoorwaarden van Crafter'
                                />
                            </div>
                            
                        )}
                        {currentStep < 2 && (
                            <PrimaryButton
                                label='general.next'
                                size='small'
                                icon={<FontAwesomeIcon icon={faAngleRight} />}
                                onClick={() => nextStep()} />
                        )}
                        {currentStep === 2 && (
                            <PrimaryButton
                                label='billing.general.subscription_overview_button'
                                size='small'
                                icon={<FontAwesomeIcon icon={faAngleRight} />}
                                onClick={() => nextStep()} />
                        )}
                        {/* {currentStep === 3 && (
                            <PrimaryButton
                                label='Bestellen'
                                size='small'
                                loading={buttonLoader}
                                onClick={() => handleSubmit()} />
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionWizard;