import React, { useMemo } from 'react';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { getNavigatorLanguageOrFallback } from 'internationalization/i18n';
import InlineForm from 'components/forms/InlineForm';
import { FieldType } from 'types/FieldTypes';
import { FormButtonType } from 'types/ButtonTypes';
import { v4 as uuidv4 } from 'uuid';
import { saveData } from 'services/api/saveData';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';

const CreateAccountForm: React.FC = () => {
    const { handleLogin } = useAuthContext();
    const viewKey = useMemo(() => uuidv4(), []);

    const formHeader = 'account.create_account_header_label'
    const formDescription = 'account.create_account_header_description'

    const handleTrialSubmitAndLogin = async (submitData: Record<string, any>) => {
        // Get the navigator language of the requester
        const navigator_language = getNavigatorLanguageOrFallback(navigator.language, 'language');

        // Create the trial
        const response = await saveData({ apiUrl: 'post_trial', method: 'post', data: { ...submitData, navigator_language } });

        // Handle login after the trial is created
        if (response && response.status === 201) {
            const { email, password } = submitData;

            // Get the navigator locale and timezone
            const navigator_locale = getNavigatorLanguageOrFallback(navigator.language, 'locale');
            const navigator_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

            // Get the current datetime and convert to utc (the backend only handles utc)
            const currentDateTime = new Date();
            const utc_datetime = convertLocalDateTimeToUTC(currentDateTime, navigator_locale, navigator_timezone);

            // Set remember login to false
            const remember_login = false;

            await handleLogin(
                email, 
                password, 
                navigator_language, 
                navigator_locale, 
                navigator_timezone, 
                utc_datetime,
                remember_login
            );
        } else {
            throw new Error('validation.trial.creation_failed');
        }
    };

    const fields: FieldType[] = [
        {
            type: 'text',
            name: 'extra_field',
            placeholder: 'account.extra_field_placeholder',
            honeypotField: true,
        },
        {
            type: 'text',
            name: 'first_name',
            placeholder: 'account.first_name_placeholder',
        },
        {
            type: 'text',
            name: 'last_name',
            placeholder: 'account.last_name_placeholder',
        },
        {
            type: 'text',
            name: 'company_name',
            placeholder: 'account.company_name_placeholder',
        },
        {
            type: 'email',
            name: 'email',
            placeholder: 'account.email_placeholder',
        },
        {
            type: 'password',
            name: 'password',
            placeholder: 'account.password_placeholder',
        },
        
    ];

    const buttons: FormButtonType[] = [
        {
            label: 'account.create_account_button_label',
            position: 'fullwidth',
            hierarchy: 'primary',
            onClick: 'handleSubmit',
            size: 'large',
            showLoader: true,
        },
    ];

    return (
        <InlineForm
            viewKey={viewKey}
            customApiFunction={handleTrialSubmitAndLogin}
            formHeader={formHeader}
            formDescription={formDescription}
            fields={fields}
            buttons={buttons}
        />
    );
};

export default CreateAccountForm;