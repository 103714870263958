import React from 'react';
import { SubscriptionData, SubscriptionLine } from '../BillingTypes';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';

interface NextInvoiceCardProps {
    loading: string;
    subscription: SubscriptionData,
};

const NextInvoiceCard: React.FC<NextInvoiceCardProps> = ({ loading, subscription }) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();

    // Get the next invoice date
    const getNextInvoiceDate = (subscription: SubscriptionData) => {

        // Convert the end date of the subscription to a date
        const nextInvoiceDate = new Date(subscription.end_date!);

        // Add one day to the date
        nextInvoiceDate.setDate(nextInvoiceDate.getDate() + 1);

        // Format the date based on the user locale
        const formattedDate = nextInvoiceDate.toLocaleDateString(userLocale, { 
            day: 'numeric', 
            month: 'long', 
            year: 'numeric' 
        });

        return formattedDate;
    };

    // Count the active seats within the subscription
    const countActiveSeats = (subscription_lines: SubscriptionLine[]) => {
        // Initialize the seats variable
        let seats = 0;

        // Loop through the subscription lines
        subscription_lines.forEach((line: SubscriptionLine) => {

            // Sum the quantity of the active seat subscription lines
            if (line.is_active && line.type === 'seats' && line.quantity) {
                seats += line.quantity;
            };
        });

        return seats;
    };

    // Calculate the invoice amount with vat based on the given subscription lines
    const calculateInvoiceAmountInclVat = (subscription_lines: SubscriptionLine[], duration: string) => {

        // Get the active seats of the subscription
        const activeSeats = countActiveSeats(subscription_lines);
        
        // Initialize the total value
        let total = 0;

        // Loop through the subscription lines
        subscription_lines.forEach((line: SubscriptionLine) => {

            // Only sum active subscription lines
            if (line.is_active && line.quantity && line.price) {

                // Handle seat lines
                if (line.type === 'seats') {

                    // Calculate the line total
                    const lineTotal = parseFloat(line.price) * line.quantity;

                    // Sum the line total to the total
                    total += lineTotal;
                };

                // Handle additional module lines with 'single price' price type
                if (line.type === 'additional_module' && line.price_type === 'single_price') {
                    
                    // Calculate the line total
                    const lineTotal = parseFloat(line.price) * line.quantity;

                    // Sum the line total to the total
                    total += lineTotal;
                }

                // Handle additional module lines with 'per seat' price type
                if (line.type === 'additional_module' && line.price_type === 'per_seat') {

                    // Calculate the line total
                    const linePrice = parseFloat(line.price) * line.quantity;

                    // Multiply the line price by the amount of active seats
                    const lineTotal = linePrice * activeSeats;

                    // Sum the line total to the total
                    total += lineTotal;
                };
            }
        });

        // For yearly amounts, multiply the total by 12 months
        if (duration === 'yearly') {
            total *= 12;
        };

        // Add vat to the total
        total *= 1.21;

        // Format the price based on the user locale
        const formatPrice = new Intl.NumberFormat(userLocale, {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(total);

        return formatPrice;
    };

    // Get the next invoice amount
    const getNextInvoiceAmount = (subscription: SubscriptionData) => {

        // Initialize the invoice amount variable
        let invoiceAmount;

        // If a pending subscription is given, calculate the invoice amount based on the pending subscription
        if (subscription.pending_subscription?.subscription_lines) {
            invoiceAmount = calculateInvoiceAmountInclVat(subscription.pending_subscription.subscription_lines, subscription.pending_subscription.duration);
        }
        
        // Otherwise, calculate the invoice amount based on the current subscription
        else if (subscription.subscription_lines) {
            invoiceAmount = calculateInvoiceAmountInclVat(subscription.subscription_lines, subscription.duration);
        };

        return invoiceAmount;
    };

    return (
        subscription.status !== 'cancelled' && !subscription.is_trial ? (
            <div className='card next-invoice'>
                <h6>{t('billing.general.next_invoice_label')}</h6>
                {loading === 'show-loader' ? (
                    <div className="loader"></div>
                ) : loading === 'success' && (
                    <div className='next-invoice-details'>
                        <div className=''>
                            <p>{getNextInvoiceDate(subscription)}</p>
                            <p>{t('billing.general.next_invoice_estimated_price_label')}</p>
                        </div>
                        <div className='price-big'>
                            € {getNextInvoiceAmount(subscription)}
                        </div>
                    </div>
                )}
            </div>
        ) : null
    )
};

export default NextInvoiceCard;