import React from 'react';
import { useTranslation } from 'react-i18next';
import { packageOptions } from './packageOptions';
import { SubscriptionData } from './BillingTypes';
import SubscriptionDetailsCard from './billing-overview/SubscriptionDetailsCard';
import SelectedSubscriptionCard from './SelectedSubscriptionCard';
import PaymentDetailsCard from './PaymentDetailsCard';
// import '../../../style/scss/billing.scss';

interface SubscriptionOverviewProps {
    currentSubscription: SubscriptionData;
    updatedSubscription: SubscriptionData;
}

const SubscriptionOverview: React.FC<SubscriptionOverviewProps> = ({ 
    currentSubscription, updatedSubscription
}) => {
    const { t } = useTranslation();

    const getSeatPrice = () => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === updatedSubscription.package);

        // Initialize the seat price
        let seatPrice = 0;

        // Get the right seat price based on the duration
        if (selectedPackage) {
            if (updatedSubscription.duration === 'yearly') {
                seatPrice = selectedPackage.yearlyPrice
            }

            if (updatedSubscription.duration === 'monthly') {
                seatPrice = selectedPackage.monthlyPrice
            }
        }

        return seatPrice
    };

    // Calculate the total monthly or yearly recurring price
    const calculateRecurringPrice = () => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === updatedSubscription.package);

        // Convert the seats into a number
        const seatsNumber = parseInt(updatedSubscription.seats);

        // Initialize the recurring subscription price
        let recurringPrice = 0;

        // Calculate the total recurring price based on the duration and seats
        if (selectedPackage) {
            if (updatedSubscription.duration === 'yearly') {
                recurringPrice = selectedPackage.yearlyPrice * seatsNumber * 12;
            }

            if (updatedSubscription.duration === 'monthly') {
                recurringPrice = selectedPackage.monthlyPrice * seatsNumber;
            }
        };

        return recurringPrice;
    };

    const calculatePaidAmount = () => {
        // Get the current subscription package
        const currentPackage = packageOptions.find(option => option.package === currentSubscription.package);

        // Convert the seats into a number
        const seatsNumber = parseInt(currentSubscription.seats || "0");

        // Initialize the paid amount
        let paidAmount = 0;

        // Calculate the remaining period in months


        if (currentPackage && seatsNumber > 0) {
            if (currentSubscription.duration === 'yearly') {
                paidAmount = currentPackage.yearlyPrice * seatsNumber * 12;
            }


        }



    }

    return (
        <div className='wizard-step'>
            <div className='header-title'>
                <h2>{t('billing.general.billing_overview_header')}</h2>
            </div>
            <div className='step-content'>
                <div className='subscription-overview'>
                    <div>
                        <SelectedSubscriptionCard 
                            updatedSubscription={updatedSubscription}
                        />
                    </div>
                    <div>

                        <PaymentDetailsCard
                            currentSubscription={currentSubscription}
                            updatedSubscription={updatedSubscription}
                        />
                    
                        <div className='card'>
                            <h3>{t('billing.general.to_pay_today_header')}</h3>
                            <div className='payment-details'>
                                <div className='price-detail-row'>
                                    <p style={{ marginBottom: 'unset' }}>
                                        Te betalen
                                    </p>
                                    <p className='price-amount'>
                                        € {calculateRecurringPrice() * 1.21}
                                    </p>
                                </div>
                            </div>     
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default SubscriptionOverview;