import React from 'react';
import { FieldOption, FieldType } from 'types/FieldTypes';
import { languageOptions } from 'internationalization/i18n';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const EnvironmentForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'environment_as_superuser'

    // Function to convert hardcoded options into dropdown options
    function convertOptionsToDropdownOptions(options: Record<string, { label: string }>): FieldOption[] {
        return Object.entries(options).map(([value, { label }]) => ({
            value,
            name: label,
        }))
    };
    
    // Convert language and locale options into dropdown options
    const languageDropdownOptions: FieldOption[] = convertOptionsToDropdownOptions(languageOptions);

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'environment.general.environment_name_label',
            type: 'text',
            placeholder: 'environment.general.environment_name_placeholder',
        },
        {
            name: 'type',
            label: 'environment.general.type_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            hardcodedOptions: [
                { value: 'trial', name: 'environment.type.trial'},
                { value: 'beta', name: 'environment.type.beta'},
                { value: 'client', name: 'environment.type.client'},
            ],
        },
        {
            name: 'package',
            label: 'environment.general.package_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            hardcodedOptions: [
                { value: 'starter', name: 'environment.package.starter'},
                { value: 'essential', name: 'environment.package.essential'},
                { value: 'advanced', name: 'environment.package.advanced'},
                { value: 'enterprise', name: 'environment.package.enterprise'},
            ],
        },
        {
            name: 'seats',
            label: 'environment.general.seats_label',
            type: 'number',
            placeholder: 'environment.general.seats_placeholder',
        },
        {
            name: 'duration',
            label: 'environment.general.duration_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            dependentOn: { fieldName: 'type', value: 'client' },
            hardcodedOptions: [
                { value: 'monthly', name: 'environment.duration.monthly'},
                { value: 'yearly', name: 'environment.duration.yearly'},
            ],
        },
        {
            type: 'dropdown',
            name: 'language',
            label: 'environment.general.language_label',
            disabled_selected: 'environment.general.language_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: languageDropdownOptions,
            defaultValue: { value: 'nl-NL' },
            showOnEdit: false,
        },
        {
            name: 'status',
            label: 'environment.general.status_label',
            type: 'dropdown',
            showOnEdit: true,
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            hardcodedOptions: [
                { value: 'active', name: 'environment.status.active'},
                { value: 'suspended', name: 'environment.status.suspended'},
                { value: 'cancelled', name: 'environment.status.cancelled'},
            ],
        },
        {
            type: 'header',
            header: 'environment.general.trial_settings_header',
            dependentOn: { fieldName: 'type', value: 'trial' },
            showOnEdit: false,
        },
        {
            name: 'company_size',
            label: 'trial.general.company_size_label',
            type: 'number',
            placeholder: 'trial.general.company_size_placeholder',
            dependentOn: { fieldName: 'type', value: 'trial' },
            showOnEdit: false,
        },
        {
            name: 'industry',
            label: 'trial.general.industry_label',
            type: 'dropdown',
            disabled_selected: 'forms.select_type_disabled_selected',
            showSearch: false,
            allowNoneOption: false,
            hardcodedOptions: [
                { value: 'installation_plumbing', name: 'trial.industry.installation_plumbing' },
                { value: 'security_systems', name: 'trial.industry.security_systems' },
                { value: 'blinds', name: 'trial.industry.blinds'},
                { value: 'interior', name: 'trial.industry.interior' },
                { value: 'construction', name: 'trial.industry.construction' },
                { value: 'equipment_machinery', name: 'trial.industry.equipment_machinery' },
                { value: 'signage', name: 'trial.industry.signage' },
                { value: 'landscaping', name: 'trial.industry.landscaping' },
                { value: 'facility_management', name: 'trial.industry.facility_management' },
                { value: 'other', name: 'trial.industry.other' },
            ],
            dependentOn: { fieldName: 'type', value: 'trial' },
            showOnEdit: false,
        },
        {
            type: 'header',
            header: 'environment.general.main_user_header',
            showOnEdit: false,
        },
        {
            name: 'email',
            label: 'user.general.email_label',
            type: 'email',
            placeholder: 'user.general.email_placeholder',
            showOnEdit: false,
        },
        {
            name: 'first_name',
            label: 'user.general.first_name_label',
            type: 'text',
            placeholder: 'user.general.first_name_placeholder',
            width: '50%',
            group: 'field-row',
            showOnEdit: false,
        },
        {
            name: 'last_name',
            label: 'user.general.last_name_label',
            type: 'text',
            placeholder: 'user.general.last_name_placeholder',
            width: '50%',
            group: 'field-row',
            showOnEdit: false,
        },
        {
            name: 'password',
            label: 'user.general.password_label',
            type: 'password',
            placeholder: 'user.general.password_placeholder',
            showOnEdit: false,
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default EnvironmentForm;