import { EnvironmentSettingsType } from "services/settings/SettingsTypes";
import { SchedulingViewType } from "../SchedulingTypes";
import dayjs from 'dayjs';

// Generates a time key from a datetime string
export const generateTimeKeyFromDateTimeString = (start_datetime: string): string => {
    // Split the start time from the start datetime string
    const startTimeString = start_datetime.split('T')[1];

    // Add 0 prefix for hours/minutes below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the hour and minutes from the starttime string
    const hour = pad(Number(startTimeString.split(':')[0]));
    const minutes = pad(Number(startTimeString.split(':')[1]))

    // Return the generated the time key
    return `${hour}:${minutes}`;
}

// Generates a time key from a time sting
export const generateTimeKeyFromTimeString = (time_string: string): string => {
    // Add 0 prefix for hours/minutes below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the hour and minutes from the starttime string
    const hour = pad(Number(time_string.split(':')[0]));
    const minutes = pad(Number(time_string.split(':')[1]))

    // Return the generated the time key
    return `${hour}:${minutes}`;
}

// For events rendered on timeslots, determine the nearest timeslot to render the event in
export const determineNearestTimeSlot = (start_datetime: string): string => {
    // Split the start time from the start datetime string
    const startTimeString = start_datetime.split('T')[1];

    // Get the hour and minutes from the starttime string
    const hour = parseInt(startTimeString.split(':')[0], 10);
    const minutes = parseInt(startTimeString.split(':')[1], 10);

    // Determine the nearest timeslot start time slot by rounding down the minutes
    const roundedMinutes = minutes - (minutes % 15);

    // Format the hour and minutes to strings with leading zero if necessary
    const formattedHour = hour < 10 ? '0' + hour : '' + hour;
    const formattedMinutes = roundedMinutes < 10 ? '0' + roundedMinutes : '' + roundedMinutes;

    // Return the generated time slot key
    return `${formattedHour}:${formattedMinutes}`;
}

// Converts a day key and timeslot back to a local start datetime string
export const convertDayKeyAndTimeSlotToStartDateTimeString = (dayKey: string, timeSlot: string, userLocale: string, userTimezone: string): string => {
    // Combine the daykey and timeslot into a date string
    const combinedDateTimeString = `${dayKey}T${timeSlot}:00`;

    // Create a date object from the combined string
    const date = new Date(combinedDateTimeString);

    // Format the datetime to the users locale and timezone
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Extract formatted parts
    const formattedParts = dateTimeFormatter.formatToParts(date).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Construct the iso-like datetime string
    const localDateTimeString = `${formattedParts.year}-${formattedParts.month}-${formattedParts.day}T${formattedParts.hour}:${formattedParts.minute}:${formattedParts.second}`;
    
    return localDateTimeString;
};

// Converts a day key, timeslot and duration back to a local end datetime string
export const convertDayKeyAndTimeSlotToEndDateTimeString = (dayKey: string, startTime: string, durationMinutes: number, userLocale: string, userTimezone: string): string => {
    // Combine the daykey and starttime into a date object
    const startDateTimeString = `${dayKey}T${startTime}:00`;
    const startDate = new Date(startDateTimeString);

    // Calculate the duration in milliseconds
    const durationInMilliseconds = durationMinutes * 60 * 1000;

    // Create the enddate by adding the duration to the startdate
    const endDate = new Date(startDate.getTime() + durationInMilliseconds);

    // Formatter for the users locale and timezone
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Format the enddate into a string
    const formattedParts = dateTimeFormatter.formatToParts(endDate).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    return `${formattedParts.year}-${formattedParts.month}-${formattedParts.day}T${formattedParts.hour}:${formattedParts.minute}:${formattedParts.second}`;
};

// Calculates the duration of an event in minutes from start- and end datetimes
export const calculateDurationMinutesFromDateTimes = (startDateTime: string, endDateTime: string): number => {

    // Get the start datetime and end datetime in unix epoch
    const startEpoch = new Date(startDateTime).getTime();
    const endEpoch = new Date(endDateTime).getTime();

    // Calculate the duration in minutes and pixels
    const durationMinutes = (endEpoch - startEpoch) / 60000;

    return durationMinutes;
};

// Calculates the duration of an event in minutes from a decimal field
export const calculateDurationMinutesFromDecimal = (durationDecimal: string): number => {

    // Convert the decimal to a float
    const durationFloat = parseFloat(durationDecimal);

    // Calculate the duration in minutes
    const durationMinutes = durationFloat * 60;

    return durationMinutes;
};

// Get the scheduling settings from the environment settings
export const getSchedulingSettings = (settings: EnvironmentSettingsType): SchedulingViewType | null => {

    // Get the settings
    const { scheduling_default_view, scheduling_direction, scheduling_display_mode, scheduling_default_view_period,
        scheduling_display_start_time, scheduling_display_end_time } = settings;

    // If the settings are unknown, return null
    if (!scheduling_default_view || !scheduling_direction || !scheduling_display_mode) {
        return null;
    }

    // Return the settings
    return {
        viewType: scheduling_default_view,
        direction: scheduling_direction,
        showDays: scheduling_display_mode,
        isDayView: scheduling_default_view_period === 'day',
        showTimes: {
            startTime: generateTimeKeyFromTimeString(scheduling_display_start_time || '06:00:00'),
            endTime: generateTimeKeyFromTimeString(scheduling_display_end_time || '18:00:00')
        },
        displayDate: new Date()
    };
};

// Generate the days to display in the current scheduling view
export const generateDisplayDays = (view: SchedulingViewType): Date[] => {

    // If the day view is active, show the selected day
    if (view.isDayView) {
        return [view.displayDate];
    }

    // For week views, generate the display days
    else {
        // Get the display date and the days to show setting from the current view
        const { displayDate, showDays } = view;

        // Determine the the current day number of the display date
        const displayDateDay = displayDate.getDay();

        // Calculate the offset from the display date day to monday
        let offsetToMonday;
        if (displayDateDay === 0) {
            offsetToMonday = - 6;
        } else {
            offsetToMonday = 1 - displayDateDay;
        };

        // Revert the display date day to monday to generate the start date of the week 
        const startDateOfWeek = displayDate.setDate(displayDate.getDate() + offsetToMonday);

        // Convert the start date into a date object
        const startDate = new Date(startDateOfWeek);

        // Determine the number of days to show based of the show days scheduling setting
        let daysToShow = showDays === 'all' ? 7 : showDays === 'mon-sat' ? 6 : 5;

        // Generate an array with the number of days determined in the days to show
        const generatedDisplayDays = Array.from({ length: daysToShow }, (_, i) => {
            // Create a new date object from the start date
            const day = new Date(startDate);

            // Multiply the date + 1
            day.setDate(startDate.getDate() + i);

            // Add the date to the array
            return day;
        });

        return generatedDisplayDays;
    }
};

// Generate the time slots to display in the current scheduling view
export const generateTimeSlots = (view: SchedulingViewType): string[] | undefined => {

    // Get the showable times from the current view. If no times are set, return undefined
    const { showTimes } = view;
    const viewStartTime = showTimes?.startTime;
    const viewEndTime = showTimes?.endTime;

    // If no view start- or end time is set, return undefined
    if (!viewStartTime || !viewEndTime) {
        return undefined;
    };
    
    // Parse the start and end time components
    const [startHour, startMinute] = viewStartTime.split(':').map(Number);
    const [endHour, endMinute] = viewEndTime.split(':').map(Number);

    // Create startTime and endTime with day.js
    let startTime = dayjs().hour(startHour).minute(startMinute);
    const endTime = dayjs().hour(endHour).minute(endMinute);

    // Create a new array to store the times
    const generatedTimes = [];

    // Generate times (in quarters) in the specified range
    while (startTime.isBefore(endTime)) {
        generatedTimes.push(startTime.format('HH:mm'));
        startTime = startTime.add(15, 'minutes');
    }

    return generatedTimes;
}

// Generate the hour slots to display in the scheduling header
export const generateHours = (view: SchedulingViewType): string[] | undefined => {

    // Get the showable times from the current view. If no times are set, return undefined
    const { showTimes } = view;
    const viewStartTime = showTimes?.startTime;
    const viewEndTime = showTimes?.endTime;

    // If no view start- or end time is set, return undefined
    if (!viewStartTime || !viewEndTime) {
        return undefined;
    };
    
    // Parse the start and end hour components
    const [startHour] = viewStartTime.split(':').map(Number);
    const [endHour] = viewEndTime.split(':').map(Number);

    // Create a new array to store the times
    let currentHour = startHour;
    const generatedHours: string[] = [];

    // Generate times (in quarters) in the specified range
    while (currentHour < endHour) {
        generatedHours.push(currentHour.toString());
        currentHour += 1;
    }

    return generatedHours;
}

// Convert JavaScript date objects to a date string without timezone conversion
export const convertDateToDateStringWithoutTimezoneConversion = (date: Date): string => {
    // Add 0 prefix for months/days below 10
    const pad = (number: number) => number < 10 ? '0' + number : number;

    // Get the year, month and day from the date object
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-indexed in JavaScript
    const day = pad(date.getDate());

    // Return as formatted iso date string
    return `${year}-${month}-${day}`
}

// Generate a day key from the given JavaScript date object
export const generateDayKey = (day: Date): string => {
    const generatedDayKey = convertDateToDateStringWithoutTimezoneConversion(day);
    return generatedDayKey;
}

// Determine the appointment fetch params based on the current display days 
export const determineAppointmentFetchParams = (
    displayDays: Date[], schedulingBoardId: number | null
): { from?: string, to?: string, scheduling_board_id?: number } => {

    // Return if no scheduling board id is given
    if (!schedulingBoardId) return {};  

    // Get the first and last dates from the display days array
    const firstDisplayDateObject = displayDays[0];
    const lastDisplayDateObject = displayDays[displayDays.length - 1];

    // Convert the date objects into iso string date values
    const firstDate = convertDateToDateStringWithoutTimezoneConversion(firstDisplayDateObject);
    const lastDate = convertDateToDateStringWithoutTimezoneConversion(lastDisplayDateObject);

    return { from: firstDate, to: lastDate, scheduling_board_id: schedulingBoardId };
}

// Handle navigation to another date period
export const navigatePeriod = (view: SchedulingViewType, direction: 'prev' | 'next'): SchedulingViewType => {

    // Convert the display date into a date object
    const newDate = new Date(view.displayDate);

    // Handle day view navigation, just move one day
    if (view.isDayView) {
        if (direction === 'next') {
            newDate.setDate(newDate.getDate() + 1);
        } else {
            newDate.setDate(newDate.getDate() - 1);
        }  
    }

    // Handle week view navigation, take show days into account
    else {
        // Determine the amount of days to move
        let daysToMove = view.showDays === 'all' ? 7 : view.showDays === 'mon-sat' ? 6 : 5;

        if (direction === 'next') {
            newDate.setDate(newDate.getDate() + daysToMove);
    
            // Make sure we don't end up in the weekend
            if (view.showDays === 'mon-fri' || view.showDays === 'mon-sat') {
                if (newDate.getDay() === 6 && view.showDays === 'mon-fri') {
                    // In case of saturday, go to monday
                    newDate.setDate(newDate.getDate() + 2);
                } else if (newDate.getDay() === 0) {
                    // In case of sunday, go to monday
                    newDate.setDate(newDate.getDate() + 1);
                }
            }
        } else {
            newDate.setDate(newDate.getDate() - daysToMove);
        }
    }

    return { ...view, displayDate: newDate };
};