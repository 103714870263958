import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSettings } from 'services/settings/SettingsContext';
import { packageOptions } from './packageOptions';
import { SubscriptionData } from './BillingTypes';

interface SubscriptionDetailsCardProps {
    updatedSubscription: SubscriptionData;
}

const SelectedSubscriptionCard: React.FC<SubscriptionDetailsCardProps> = ({
    updatedSubscription
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userLocale } = useSettings();

    // Get the active additional modules of this subscription
    // const getAdditionalModules = () => {
    //     return updatedSubscription.subscription_lines?.filter(line => line.is_active && line.type === 'additional_module') || [];
    // };

    // Get the active discount lines of this subscription
    // const getDiscountLines = () => {
    //     return updatedSubscription.subscription_lines?.filter(line => line.is_active && line.type === 'discount') || [];
    // };

    // Get the seat price based on the duration of the given subscription
    const getSeatPrice = () => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === updatedSubscription.package);

        // Initialize the seat price
        let seatPrice = 0;

        // Get the right seat price based on the duration
        if (selectedPackage) {
            if (updatedSubscription.duration === 'yearly') {
                seatPrice = selectedPackage.yearlyPrice
            }

            if (updatedSubscription.duration === 'monthly') {
                seatPrice = selectedPackage.monthlyPrice
            }

            if (updatedSubscription.duration === 'trial') {
                seatPrice = 0
            }
        }

        return seatPrice
    };

    // Calculate the total monthly or yearly recurring price
    const calculateRecurringPrice = () => {
        // Get the selected package data
        const selectedPackage = packageOptions.find(option => option.package === updatedSubscription.package);

        // Convert the seats into a number
        const seatsNumber = parseInt(updatedSubscription.seats);

        // Initialize the recurring subscription price
        let recurringPrice = 0;

        // Calculate the total recurring price based on the duration and seats
        if (selectedPackage) {
            if (updatedSubscription.duration === 'yearly') {
                recurringPrice = selectedPackage.yearlyPrice * seatsNumber * 12;
            }

            if (updatedSubscription.duration === 'monthly') {
                recurringPrice = selectedPackage.monthlyPrice * seatsNumber;
            }
        };

        return recurringPrice;
    };

    return (
        <div className='card subscription-details'>
            <h3>
                {t('billing.general.your_subscription_header')}
            </h3>
            <div className='subscription-details-content'>
                <div>
                    <div>
                        <div>
                            <p>
                                {t('billing.general.current_package_label', { currentPackage: t(`billing.package.${updatedSubscription.package}.label`) })}
                            </p>
                            <p>
                                {t('billing.general.current_seats_and_price_label', { currentSeats: parseInt(updatedSubscription.seats), currentSeatPrice: getSeatPrice() })}
                            </p>
                            {(updatedSubscription.duration === 'yearly' || updatedSubscription.duration === 'monthly') && (
                                <p>{t(`billing.general.${updatedSubscription.duration}_payment_label`)}</p>
                            )}
                        </div>

                        {/* Additional modules */}
                        {/* {getAdditionalModules().length > 0 && (
                            <div className='module-details'>
                                <h6>{t('billing.general.additional_modules_label')}</h6>
                                {getAdditionalModules().map((line, index) => (
                                    <p key={index}>
                                        {t('billing.general.additional_module_line_label', { productName: line.product_name, modulePrice: line.price })}
                                    </p>
                                ))}
                            </div>
                        )} */}
                        
                        {/* Discounts */}
                        {/* {getDiscountLines().length > 0 && (
                            <div className='module-details'>
                                <h6>{t('billing.general.discount_label')}</h6>
                                {getDiscountLines().map((line, index) => (
                                    <p key={index}>
                                        {t('billing.general.discount_line_label', { productName: line.product_name, discountPercentage: line.price })}
                                    </p>
                                ))}
                            </div>
                        )} */}
                    </div>
                </div>
                <div className='recurring-price'>
                    <div className='price-big'>
                        <span className='currency-icon'>€</span>
                        {calculateRecurringPrice()}
                    </div>
                    {updatedSubscription.duration === 'yearly' && (
                        t(`billing.general.per_year_label`)
                    )}
                    {updatedSubscription.duration === 'monthly' && (
                        t(`billing.general.per_month_label`)
                    )}
                </div>
            </div>
        </div>
    )
}

export default SelectedSubscriptionCard;