import React, { useState, useEffect, useContext } from 'react';
import { MultiSelectFieldType, FieldOptionFetchResponse, FieldData, FieldOption } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import FormFieldContext from '../FormFieldContext';
import CustomMultiSelect from './CustomMultiSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface MuliSelectFieldProps extends MultiSelectFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    groupOptions?: FieldOption[];
    errorMessage?: string | string[] | undefined;
    showIfFilled?: boolean;
}

const MultiSelectField: React.FC<MuliSelectFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, disabled_selected, data, hardcodedOptions, helperText, tooltipText, apiObject, onChange, dropdownData, 
    viewInEditMode, allowNoneOption, showIfFilled, selectionFormat = 'name', optionFormat, defaultValue, alignment, showSearch,
    groupOptions, groupByField, isEditable, backendField
}) => {
    const { t } = useTranslation();
    const { errorMessages, setUnsavedChanges, unsavedChangesMap } = useGlobalContext();
    const { editing, updatedData, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const hasRightCheck = useAllowedRight;
    const [value, setValue] = useState<string[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<FieldOption[] | null>(null);
    const [options, setOptions] = useState<FieldOption[]>([]);
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));
    const hasUnsavedChanges = unsavedChangesMap[viewKey];

    // Set dropdown options from the fetched dropdown data array
    useEffect(() => {
        if (apiObject && dropdownData && dropdownData[apiObject]) {
            setOptions(dropdownData[apiObject].results);
            const selectedOptions = dropdownData[apiObject].results.filter(option => Array.isArray(data[name]) ? data[name].includes(option.id) : option.id === data[name]);
            if (selectedOptions.length > 0) {
                setSelectedOptions(selectedOptions);
            }
        } else if (hardcodedOptions) {
            setOptions(hardcodedOptions);
            const selectedOptions = hardcodedOptions.filter(option => Array.isArray(data[name]) ? data[name].includes(option.value) : option.value === data[name]);
            if (selectedOptions.length > 0) {
                setSelectedOptions(selectedOptions);
            }
        }
    }, [dropdownData, hardcodedOptions, apiObject, data, name])

    // If a default value is given, set it as the selected option
    useEffect(() => {
        if (defaultValue && options.length > 0 && !data[name]) {

            let defaultOption;
            // If the options are hardcoded, determine the defaultoption on the value of the option
            if (hardcodedOptions) {
                defaultOption = hardcodedOptions.filter(option => option.value === defaultValue);
                if (defaultOption) {
                    setSelectedOptions(defaultOption);
                    setValue([defaultValue])
                    setUpdatedData((prevData: any) => ({
                        ...prevData,
                        [name]: [parseInt(defaultValue)],
                    }));
                }
            // If the options are fetched, determine the defaultoption on the id of the option
            } else if (options.length > 0) {

                // If a backend field is given, get the default value from the backend field
                if (backendField) {
                    defaultOption = options.filter((option: FieldOption) => option[backendField] === defaultValue);
                    if (defaultOption) {
                        setSelectedOptions(defaultOption);
                        setValue([defaultValue]);
                        setUpdatedData((prevData: any) => ({
                            ...prevData,
                            [name]: [defaultValue],
                        }));
                    }

                // Otherwise, get the default value from the id
                } else {
                    defaultOption = options.filter((option: FieldOption) => option.id === parseInt(defaultValue));
                    if (defaultOption) {
                        setSelectedOptions(defaultOption);
                        setValue([defaultValue]);
                        setUpdatedData((prevData: any) => ({
                            ...prevData,
                            [name]: [parseInt(defaultValue)],
                        }));
                    }
                }
            }
        }
    }, [defaultValue, options, hardcodedOptions, data, name]);

    // Updates the current selected option and shows it in the dropdown field
    useEffect(() => {
        // Updates the selected option(s) when the page is loaded
        if (name in data && !hasUnsavedChanges) {
            if (Array.isArray(data[name]) ? !arraysAreEqual(data[name], value) : data[name] !== value) {
                setValue(data[name]);
                const selectedOptions = options.filter((option: FieldOption) => Array.isArray(data[name]) ? data[name].includes(option.id) : option.id === data[name]);
                if (selectedOptions.length > 0) {
                    setSelectedOptions(selectedOptions);
                }
            }
        // Updates the selected option(s) when the user selects another item
        } else if (hasUnsavedChanges && updatedData[name]) {
            setValue(updatedData[name]);
            const selectedOptions = options.filter((option: FieldOption) => Array.isArray(updatedData[name]) ? updatedData[name].includes(option.id) : option.id === Number(updatedData[name]));
            if (selectedOptions.length > 0) {
                setSelectedOptions(selectedOptions);
            }
        }
    }, [data, name, options, updatedData, value, hasUnsavedChanges]);

    // Utility function to check if two arrays are equal
    const arraysAreEqual = (arr1: any[], arr2: any[]) => {
        return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    }

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!value || value.length === 0)) {
        return null;
    }

    // Updates the value after changing the dropdown selection
    const handleSelect = (newValue: string) => {
        // Use an empty array as fallback if the first option is selected and the array does not yet exist
        let valueArray = Array.isArray(value) ? value : [];
        let updatedValues: string[] = valueArray.map(v => String(v));
        let selectedItems: FieldOption[] = [...(selectedOptions || [])];
        
        // Checks if the value is already selected
        const alreadySelected = updatedValues.includes(String(newValue));

        if (alreadySelected) {
            // Deletes the value if it's already selected
            updatedValues = updatedValues.filter(v => String(v) !== String(newValue));
            selectedItems = selectedItems.filter(option => {
                const optionId = option.id?.toString();
                const optionBackend = backendField ? option[backendField] : undefined;
            
                return String(newValue) !== optionId && String(newValue) !== optionBackend;
            });
        } else {
            // Add the new value if it's not already selected
            updatedValues.push(String(newValue));
            const newSelectedOption = options.find(option => {
                const optionId = option.id?.toString();
                const optionValue = option.value;
                const optionBackend = backendField ? option[backendField] : undefined;

                return String(newValue) === optionId || String(newValue) === optionValue || String(newValue) === optionBackend;
            });

            if (newSelectedOption) {
                selectedItems.push(newSelectedOption);
            }
        }
        
        setValue(updatedValues);
        setSelectedOptions(selectedItems);
        setUnsavedChanges(viewKey, JSON.stringify(updatedValues) !== JSON.stringify(data[name]));
        setUpdatedData({...updatedData, [name]: updatedValues});
        
        if (onChange) {
            onChange({
                target: {
                    value: updatedValues
                }
            } as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    // Helper function to check if the options are coloured options
    const hasColouredOptions = (options: FieldOption[]) => {
        return options.some(option => option.color);
    };
    
    // Get the error message from the errorState
    const errorMessage = errorMessages[name];

    const content = (
        <>
            <label htmlFor={name}
                   className={selectedOptions ? (hasColouredOptions(selectedOptions) ? 'coloured-options-label' : '') : ''}>
                {t(label)}
                {tooltipText && 
                    <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span className="tooltip">{t(tooltipText)}</span>
                    </span>
                }
                {alignment === 'horizontal' && !editing && !viewInEditMode && 
                    <span className='edit-icon'>
                        <FontAwesomeIcon icon={faPen} />
                    </span>
                }
            </label>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='edit-mode'>
                    <CustomMultiSelect 
                        options={options}
                        selectedOption={selectedOptions || null}
                        value={value}
                        onChange={handleSelect}
                        disabled_selected={disabled_selected}
                        showSearch={showSearch}
                        groupOptions={groupOptions}
                        groupByField={groupByField?.groupByField}
                        groupTitleField={groupByField?.apiField}
                        selectionFormat={selectionFormat}
                        optionFormat={optionFormat}
                        errorMessage={errorMessage}
                        allowNoneOption={allowNoneOption}
                        backendField={backendField}
                    />
                    {errorMessage && 
                        <div className='error-message'>
                            {t(errorMessage, { defaultValue: errorMessage })}
                        </div>
                    }
                    {helperText &&
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                </div>
            ) : (
                // View mode
                <div className="view-mode">
                    <div className={selectedOptions ? (hasColouredOptions(selectedOptions) ? 'coloured-option-wrapper' : '') : ''}>
                        {selectedOptions && selectedOptions.length > 0 ? (
                            selectedOptions.map((option, index) => {
                                const content = selectionFormat && selectionFormat in option
                                    ? t(option[selectionFormat as keyof FieldOption] || option[selectionFormat])
                                    : "Undefined selectionFormat name";
                                return option.color ? (
                                    <span key={index}
                                          className={`p coloured-option ${option.color}`}>
                                        {t(content)}
                                    </span>
                                ) : (
                                    <span key={index}
                                          className='p'>
                                        {t(content)}{index < selectedOptions.length - 1 ? ', ' : ''}
                                    </span>
                                );
                            })
                        ) : (
                            <span className="no-value">
                                <span className='minus-sign'>-</span>
                                <span className="hover-text">{t('forms.add_value')}</span>
                            </span>
                        )}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <div className='widget-field'>
            { alignment === 'horizontal' 
                ? <div className={`horizontal-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''} ${editing ? 'editing' : ''}`}>{content}</div> 
                : <div className={`stacked-alignment ${isEditable && !userHasOnlyViewRights ? 'editable' : ''}`}>{content}</div> 
            }
        </div>
    );
};

export default MultiSelectField;