import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { packageOptions } from './packageOptions';
import { SubscriptionData } from './BillingTypes';

const SubscriptionPriceSubtotal: React.FC<{ updatedSubscription: SubscriptionData }> = ({ updatedSubscription }) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();

    // Get the selected package object
    const selectedPackageData = packageOptions.find(option => option.package === updatedSubscription.package);

    // Convert the seats into a number, and a minimum of 1
    const seatsNumber = parseInt(updatedSubscription.seats || "1");

    // Check if the selected package exists
    if (!selectedPackageData) return null;

    // Calculate the price
    const monthlyPricePerUser = selectedPackageData.monthlyPrice;
    const yearlyPricePerUser = selectedPackageData.yearlyPrice;
    const totalMonthlyPrice = monthlyPricePerUser * seatsNumber;
    const totalYearlyPrice = yearlyPricePerUser * seatsNumber * 12;

    // Calculate yearly savings
    const yearlySavings = (totalMonthlyPrice * 12) - totalYearlyPrice;

    // Format the price based on the user locale
    const formatPrice = (amount: number) => {
        return new Intl.NumberFormat(userLocale, {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(Math.round(amount));
    }

    return (
        <div className="price-summary">
            {updatedSubscription.duration === "monthly" ? (
                <div className="monthly-summary">
                    <span className="savings-info">
                        {t('billing.general.yearly_save_label', { price: formatPrice(yearlySavings) })}
                    </span>
                    <span className='savings-info-divider'>•</span>
                    <span className="total-price">
                        {t('billing.general.total_price_month', { price: formatPrice(totalMonthlyPrice) })}
                    </span>
                </div>
            ) : (
                <div className="yearly-summary">
                    <span className="total-price">
                        <span>
                            {t('billing.general.total_price_year', { price: formatPrice(totalYearlyPrice) })}
                        </span> 
                        <span> </span>
                        <span className="total-price"> 
                            {t('billing.general.total_price_year_per_month', { price: formatPrice(totalYearlyPrice / 12) })}
                        </span>
                    </span>
                </div>
            )}
        </div>
    );
};

export default SubscriptionPriceSubtotal;