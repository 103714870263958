import React from 'react';
import { SubscriptionData } from '../BillingTypes';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { formatDate } from 'services/utils/dateTimeUtils';
import { useSettings } from 'services/settings/SettingsContext';
import RevertCancellationModal from './RevertCancellingModal';

interface CancelSubscriptionCardProps {
    subscription: SubscriptionData;
    refetchSubscription: () => void;
}

const CancelSubscriptionCard: React.FC<CancelSubscriptionCardProps> = ({ subscription, refetchSubscription }) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();
    const { userLocale } = useSettings();

    // Initialize the cancel subscription modal
    const initializeCancelSubscriptionModal = () => {
        initializeModal(
            <CancelSubscriptionModal subscription={subscription} />, { 
                title: t('billing.cancellation.cancellation_modal_header'),
                modalSize: 'small-medium', 
                onSuccess: () => refetchSubscription(),
            }
        )
    };

    // Initialize the revert subscription cancellation modal
    const initializeRevertCancellationModal = () => {
        initializeModal(
            <RevertCancellationModal subscription={subscription} />, { 
                title: t('billing.general.revert_subscription_cancellation_header'),
                modalSize: 'small', 
                onSuccess: () => refetchSubscription(),
            }
        )
    };

    return (
        <div className='card cancel-subscription'>
            {subscription.status !== 'cancelled' ? (
                <>
                    <h6>{t('billing.general.cancel_subscription_header')}</h6>
                    <p>{t('billing.general.cancel_subscription_description')}</p>
                    <div className='text-button'
                        onClick={() => initializeCancelSubscriptionModal()}>
                        {t('billing.general.cancel_subscription_button')}
                    </div>
                </>
            ) : (
                <>
                    <h6>{t('billing.general.subscription_cancelled_header')}</h6>
                    <p>{t('billing.general.subscription_cancelled_description', { endDate: formatDate(subscription.end_date!, userLocale, 'writtenMonth') })}</p>
                    <div className='text-button'
                         onClick={() => initializeRevertCancellationModal()}>
                        {t('billing.general.revert_subscription_cancellation_button')}
                    </div>
                </>
            )}
        </div>
    )
};

export default CancelSubscriptionCard;