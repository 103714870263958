import React, { useState } from 'react';
import { PageHeaderType } from 'types/PageHeaderTypes';
import { TabBar } from './TabBar';
import SwitchButton from 'components/buttons/SwitchButton';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useTranslation } from 'react-i18next';

const PageHeader: React.FC<PageHeaderType> = ({ 
    viewKey, title, switchButton, switchButtonApiUrl, explanationText, explanationVideoUrl, 
    requiredFeature, disabled, tabs
}) => {
    const { t } = useTranslation();
    const { activeFeatures } = useAuthContext();
    const [pageHeaderFeatureIsActive, setPageHeaderFeatureIsActive] = useState(true)
    
    // Filter to tabs based on their active feature
    const activeTabs = tabs ? tabs.filter(tab => !tab.activeFeature || activeFeatures.includes(tab.activeFeature)) : []

    // Get the tab label and tab component from the active tabs
    const { tabLabel, tabComponent } = tabs ? TabBar({tabs: activeTabs, viewKey}) : { tabLabel: null, tabComponent: null };

    return (
        <>
            <div className='container-filled'>
                <div className={`pageheader ${disabled ? 'disabled' : ''}`}>
                    <div className='row'>
                        {switchButton && requiredFeature && switchButtonApiUrl && (
                            <div className='switch-button-wrapper'>
                                <SwitchButton
                                    key={requiredFeature}
                                    featureSwitch={true}
                                    name={requiredFeature}
                                    apiUrl={switchButtonApiUrl}
                                    isDisabled={disabled}
                                    onToggle={(isOn) => setPageHeaderFeatureIsActive(isOn)}
                                />
                            </div>
                        )}
                        <h3>{t(title)}</h3>
                        {disabled && (
                            <Link className='upgrade-badge' 
                                  to="/settings/billing/subscription/wizard">
                                    {t('billing.upgrade_badge_label')}
                            </Link>
                        )}
                    </div>
                    {explanationText && (
                        <div className='col-100'>
                            <div className='col-70'>
                                <p className='explanation-text'>
                                    {t(explanationText)}
                                </p>
                            </div>
                            <div className='col-30'>
                                {explanationVideoUrl}
                            </div>
                        </div>
                    )}
                    {/* Only render the tabs if the main feature of the page header is active: if the switch button is on */}
                    {pageHeaderFeatureIsActive && tabs && tabs.length > 1 && (
                        <ul className='tabbar'>
                            {tabLabel}
                        </ul>
                    )}
                </div>
            </div>
            {/* Only render the tabs if the main feature of the page header is active: if the switch button is on */}
            {pageHeaderFeatureIsActive && tabComponent && React.Children.count(tabComponent) > 0 && (
                <div style={{ paddingTop: "0.5rem" }}>
                    {tabComponent}
                </div>
            )}
        </>
    );
};

export default PageHeader;