import React, { useEffect, useState } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useModal } from 'components/modals/ModalContext';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { saveData } from 'services/api/saveData';
import { SubscriptionData } from '../BillingTypes';
import { useTranslation } from 'react-i18next';

const RevertCancellationModal: React.FC<{ subscription: SubscriptionData }> = ({ subscription }) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { revealModal, closeModal, modalStack } = useModal();
    const [buttonLoader, setButtonLoader] = useState(false);
    const topModal = modalStack[modalStack.length - 1];

    // Reveal the modal on first render
    useEffect(() => {
        revealModal();
    }, []);
    
    // Handle the submit of the updated billing details
    const handleSubmit = async () => {
        try {            
            // Start the button loader
            setButtonLoader(true);

            // Post the data in the backend
            const response = await saveData({ 
                apiUrl: `revert_subscription_cancellation/${subscription.id}`, 
                method: 'patch',
                data: {}
            })

            // Handle successful response
            if (response?.status === 200) {

                // Stop the button loader
                setButtonLoader(false);

                // Show success alert
                setFloatingAlert({ 'type': 'success' })

                // Call the on success callback
                if (topModal?.props?.onSuccess) {
                    topModal.props.onSuccess(response.data);
                };

                // Close the modal
                closeModal()
            }
        } catch (error) {
            console.error("Error on submit", error);
            setButtonLoader(false);
        };        
    };

    // Close the modal
    const handleClose = () => {
        setButtonLoader(false);
        closeModal();
    };

    return (
        <div className='formset add-edit-form'>
            <p style={{ marginBottom: '0.75rem' }}>
                {t('billing.general.revert_subscription_cancellation_description')}
            </p>
            <div className='buttons-right'>
                <SecondaryButton
                    onClick={handleClose} 
                    label='general.cancel'
                    size="small"
                />
                <PrimaryButton
                    type="button" 
                    label='billing.general.revert_subscription_cancellation_submit_button'
                    onClick={handleSubmit}
                    size="small"
                    onlyViewRestriction={true}
                    loading={buttonLoader}
                />
            </div>
        </div>
    );
};

export default RevertCancellationModal;