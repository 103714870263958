import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickOutside, useSetDropdownWidth, useSetDropdownPosition } from 'services/utils/dropdownBehavior'
import { FieldOption } from 'types/FieldTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../../../style/scss/forms.scss';

interface CustomDropdownProps {
    name?: string;
    id?: string;
    options: FieldOption[];
    value: string | number | null;
    selectedOption?: FieldOption | null;
    onChange: (selectedValueOrId: string) => void;
    disabled_selected?: string;
    disabled?: boolean;
    showSearch?: boolean;
    errorMessage?: string | string[] | undefined;
    selectionFormat?: string;
    optionFormat?: (option: FieldOption) => string;
    allowNoneOption?: boolean;
    backendField?: string;
    orderOptions?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ 
    name, id, options, onChange, disabled_selected, selectionFormat = 'name', optionFormat = (option) => option.name, 
    selectedOption, allowNoneOption, errorMessage, showSearch = true, disabled, backendField, orderOptions
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownListRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const fieldRef = useRef<HTMLDivElement>(null);
    const [dropdownWidth, setDropdownWidth] = useState<number>(200);
    const [dropdownAbove, setDropdownAbove] = useState(false);
    const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | undefined>(undefined);

    // Use dropdownBehavior.ts to close the dropdown when clicking outside
    useClickOutside(isOpen, dropdownRef, setIsOpen);

    // Use dropdownBehavior.ts to set the dropdown width on open
    useSetDropdownWidth(isOpen, dropdownListRef, fieldRef, setDropdownWidth);

    // Use dropdownBehavior.ts to set the dropdown position and max height on open
    useSetDropdownPosition(isOpen, fieldRef, setDropdownAbove, setDropdownMaxHeight);

    // Autofocus the search field when opening the dropdown list
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                searchRef.current?.focus();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    return (
        <div className='custom-dropdown-container dropdown-container'>
            <div className={`custom-dropdown-field ${errorMessage ? 'is-invalid' : ''} ${disabled ? 'disabled' : ''}`}
                 onMouseDown={(e) => { if (!disabled) e.stopPropagation(); setIsOpen(!isOpen) }}
                 ref={fieldRef}>
                    {selectedOption 
                        ? t(selectedOption[selectionFormat] || selectedOption[selectionFormat])
                        : <span className='custom-dropdown-placeholder'>{t(disabled_selected) || t('forms.general_disabled_selected')}</span>}
                    <FontAwesomeIcon 
                        icon={isOpen && !disabled ? faCaretUp : faCaretDown}
                        className='custom-dropdown-caret' />
            </div>
            {isOpen && !disabled && (
                <div ref={dropdownRef}
                     className={`custom-dropdown ${dropdownAbove ? 'dropdown-above' : ''}`}  
                     style={{width: dropdownWidth, maxHeight: dropdownMaxHeight}}>
                    <div ref={dropdownListRef}
                         className='custom-dropdown-list' 
                         style={{width: dropdownWidth}}>
                        {showSearch && (
                            <input 
                                type="text" 
                                id={id}
                                name={name}
                                value={search} 
                                className='custom-dropdown-search'
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={t('forms.search_placeholder')} 
                                ref={searchRef}
                            />
                        )}
                        {allowNoneOption !== false &&
                            <div className="custom-dropdown-item" onClick={() => { onChange('none'); setIsOpen(false); }}>
                                ({t('forms.none_option')})
                            </div>
                        }
                        {options
                            .filter(option => {
                                // If the label of the given or fetched dropdown options are empty, filter them out so they are not mapped in the dropdown
                                if (!optionFormat || (optionFormat && optionFormat(option) && optionFormat(option).trim() !== "")) {
                                    // Checks if one of the values of the option contains the search value
                                    return Object.values(option).some(value => String(value).toLowerCase().includes(search.toLowerCase()));
                                }
                                return false;
                            })
                            // Sort the options if order options prop is true
                            .sort((a, b) => orderOptions ? a.ordering - b.ordering : 0)
                            .map((option: FieldOption) => {
                                // Determines the active value
                                const isActive = selectedOption
                                    ? (option.id && selectedOption.id === option.id) || (option.value && selectedOption.value === option.value)
                                    : false;

                                return (
                                    <div key={String(option.id || option.value)} 
                                         className={`custom-dropdown-item ${isActive ? 'active' : ''}`}
                                         onClick={() => { onChange(String(backendField ? option[backendField] : (option.id || option.value))); setIsOpen(false); }}>
                                            {t(optionFormat(option)) || optionFormat(option)}
                                            {isActive && (
                                                <FontAwesomeIcon 
                                                    icon={faCheck}
                                                    className='custom-dropdown-selected-icon' />
                                            )}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;