export type EnvironmentPackage = 'starter' | 'essential' | 'advanced' | 'enterprise';

export type SubscriptionLine = {
    start_date: string;
    end_date: string;
    type: "seats" | "additional_module" | "discount";
    product_name: string | null;
    quantity: number;
    price: string;
    price_type: "per_seat" | "single_price" | "discount_percentage";
    is_active: boolean;
}

export type Subscription = {
    id: number | null,
    is_trial: boolean;
    duration: 'trial' | 'monthly' | 'yearly';
    seats: string;
    package: EnvironmentPackage;
    start_date: string | null;
    end_date: string | null;
    status: string | null;
    cancellation_date?: string;
    cancellation_reason?: string;
    cancellation_remarks?: string;
    subscription_lines?: SubscriptionLine[];
};

export type SubscriptionData = Subscription & {
    pending_subscription?: Subscription;
};

export const createSubscriptionData = (currentPackage?: EnvironmentPackage, seats?: string): SubscriptionData => ({
    id: null,
    is_trial: false,
    duration: 'yearly',
    seats: seats ?? '1',
    package: currentPackage ?? 'essential',
    start_date: null,
    end_date: null,
    status: null,
});

export type BillingData = {
    company_name: string | null;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    invoice_email: string | null;
    street: string | null;
    house_number: string | null;
    postal_code: string | null;
    city: string | null;
    country: string | null;
    vat_number: string | null;
    stripe_customer_id: string | null;
};

export const createBillingData = () => ({
    company_name: null,
    first_name: null,
    last_name: null,
    email: null,
    invoice_email: null,
    street: null,
    house_number: null,
    postal_code: null,
    city: null,
    country: null,
    vat_number: null,
    stripe_customer_id: null,
});

export type PaymentMethodType = 
    | 'card' 
    // | 'ideal' 
    | 'sepa_debit';

export type PaymentMethod = {
    stripe_payment_method_id: string | null;
    payment_type: PaymentMethodType;
    card_brand: string | null;
    card_owner_name: string | null;
    card_last_digits: string | null;
    card_exp_month: number | null;
    card_exp_year: number | null;
    sepa_bank_name: string | null;
    sepa_account_holder: string | null;
    sepa_iban_last4: string | null;
    allow_sepa: [];
};

export const createPaymentMethod = (): PaymentMethod => ({
    stripe_payment_method_id: null,
    payment_type: 'card',
    card_brand: null,
    card_owner_name: null,
    card_last_digits: null,
    card_exp_month: null,
    card_exp_year: null,
    sepa_bank_name: null,
    sepa_account_holder: null,
    sepa_iban_last4: null,
    allow_sepa: [],
});