import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useFetchData } from 'services/api/useFetchData';
import { useWebSocket } from 'services/api/useWebSocket';
import { v4 as uuidv4 } from 'uuid';
import { EventsListType, EventsByResourceType, SchedulingViewType, EventType, SchedulerDraggableId, SchedulerDroppableId, TimeBlock, ResourceGroupType, createNewResource, SchedulingBoardType, SchedulingAppointmentType } from './SchedulingTypes';
import { generateDisplayDays, determineAppointmentFetchParams, getSchedulingSettings, generateDayKey, generateTimeKeyFromTimeString, generateTimeSlots, generateTimeKeyFromDateTimeString, determineNearestTimeSlot, generateHours, calculateDurationMinutesFromDateTimes } from './functions/schedulingUtils';
import { convertRowsToLocalDateTimes } from 'services/utils/sortDates';
import { calculateOverlappingEvents } from './functions/calculateOverlappingEvents';
import { DragDropEvent, DragDropProvider } from 'services/dragdrop/DragDropContext';
import SchedulingNavigation from './SchedulingNavigation';
import SchedulingBoard from './SchedulingBoard';
import '../../style/scss/scheduling.scss'

const Scheduler: React.FC = () => {
    const { setShowFooterLogo } = useGlobalContext();
    const { environmentSettings, userLocale, userTimezone } = useSettings();
    const [currentSchedulingBoard, setCurrentSchedulingBoard] = useState<SchedulingBoardType | null>(null);
    const [showInbox, setShowInbox] = useState(false);
    const [view, setView] = useState<SchedulingViewType | null>(null);
    const [days, setDays] = useState<Date[]>([]);
    const [hours, setHours] = useState<string[] | undefined>(undefined)
    const [timeSlots, setTimeSlots] = useState<string[] | undefined>(undefined);
    const [resourceGroups, setResourceGroups] = useState<ResourceGroupType[]>([]);
    const [events, setEvents] = useState<EventsListType>({});
    const [eventsByResource, setEventsByResource] = useState<EventsByResourceType>({});
    const [eventOverlapMap, setEventOverlapMap] = useState(new Map<string, EventType[]>());
    const [timeBlocks, setTimeBlocks] = useState<TimeBlock[]>([]);
    const [renderTimeSlots, setRenderTimeSlots] = useState<boolean | undefined>(undefined);
    const [renderTimeBlocks, setRenderTimeBlocks] = useState<boolean>(false);
    const [dragEvent, setDragEvent] = useState<DragDropEvent<SchedulerDraggableId, SchedulerDroppableId> | null>(null);

    const appointmentFetchParams = useMemo(() => {
        if (!days || days.length === 0 || !currentSchedulingBoard) return {};
        return determineAppointmentFetchParams(days, currentSchedulingBoard.id);
    }, [JSON.stringify(days), currentSchedulingBoard?.id]);

    // Fetch the list of scheduling boards
    const { response: fetchedSchedulingBoards, loading: schedulingBoardsLoading } = useFetchData({ 
        apiUrl: 'get_scheduling_board_list', 
        params: useMemo(() => ({ is_active: true }), []) 
    });

    // Fetch the resources of the current scheduling board
    const { response: fetchedResources } = useFetchData({ 
        apiUrl: currentSchedulingBoard ? `get_scheduling_board/${currentSchedulingBoard.id}` : '', 
        params: useMemo(() => ({ is_active: true }), []),
        useGuard: true,
        guardValue: currentSchedulingBoard
    }); 

    // Fetch the time blocks
    const { response: fetchedTimeBlocks } = useFetchData({ 
        apiUrl: 'get_timeblock_list', 
        params: useMemo(() => ({ deleted: false }), []) 
    });

    // Fetch the appointments
    const { response: fetchedAppointments, loading: appointmentsLoading } = useFetchData({ 
        apiUrl: Object.keys(appointmentFetchParams).length > 0 ? 'get_appointment_by_scheduling_board_list' : '', 
        params: appointmentFetchParams,
    });

    // const { message: appointmentsWebSocket } = useWebSocket({ url: `appointments_update/${environmentHash}/` });
    
    // Disable footer logo on first render
    useEffect(() => {
        setShowFooterLogo(false);
    }, []);

    // Set the scheduling view and other states when environment settings change
    useEffect(() => {
        if (!environmentSettings || !currentSchedulingBoard) return;

        const { scheduling_default_view, scheduling_direction, scheduling_display_mode, scheduling_display_start_time,
            scheduling_display_end_time, scheduling_automatic_open_job_inbox, scheduling_time_blocks, scheduling_default_view_period
        } = environmentSettings;

        // Set the view with the scheduling settings
        if (scheduling_default_view && scheduling_direction && scheduling_display_mode && scheduling_default_view_period) {
            setView({
                viewType: scheduling_default_view,
                direction: scheduling_direction,
                showDays: scheduling_display_mode,
                displayDate: new Date(),
                isDayView: scheduling_default_view_period === 'day' ? true : false,
            });
        }

        // Set render on timeline state based on the scheduling view
        if (scheduling_default_view) {
            setRenderTimeSlots(scheduling_default_view === 'employee_timeline');

            // Set show timeblocks if they exist and the view is employee list
            if (scheduling_time_blocks) {
                setRenderTimeBlocks(scheduling_default_view === 'employee_list');
            }
        }

        // Set the display times if they exist in the scheduling settings (and convert it to time keys ('07:00' instead of '07:00:00'))
        if (scheduling_default_view === 'employee_timeline' && scheduling_display_start_time && scheduling_display_end_time) {
            setView(prev => prev ? ({
                ...prev,
                showTimes: {
                    startTime: generateTimeKeyFromTimeString(scheduling_display_start_time),
                    endTime: generateTimeKeyFromTimeString(scheduling_display_end_time)
                }
            }) : prev);

            // Generate the timeslots
            if (view) {
                setTimeSlots(generateTimeSlots(view));
            }
        }

        // Set states based on the scheduling settings
        if (scheduling_automatic_open_job_inbox) setShowInbox(true);
    }, [environmentSettings, currentSchedulingBoard]);

    // Get the scheduling settings from the environment settings
    useEffect(() => {
        const newView = getSchedulingSettings(environmentSettings);
        if (newView) {
            setView(newView);
        }
    }, [environmentSettings]);
    
    // Set the current scheduling board
    useEffect(() => {
        if (fetchedSchedulingBoards && fetchedSchedulingBoards.results.length > 0) {
            // Determine the first ordered scheduling board
            const firstBoard = fetchedSchedulingBoards.results.sort((a: SchedulingBoardType, b: SchedulingBoardType) => a.ordering - b.ordering)[0];

            // Set the first ordered scheduling board as current
            setCurrentSchedulingBoard(firstBoard);
        }
    }, [fetchedSchedulingBoards]);

    // Show the right scheduling days, times and header hours after changing the view
    useEffect(() => {
        if (!view) return;

        setDays(generateDisplayDays(view));
        setHours(generateHours(view));
        setTimeSlots(generateTimeSlots(view));
    }, [view]);

    // Set the fetched resources
    useEffect(() => {
        if (fetchedResources && environmentSettings) {

            // Sort the resource groups
            const sortedResourceGroups = fetchedResources.resource_groups.sort((a: ResourceGroupType, b: ResourceGroupType) => a.ordering - b.ordering);

            // Get the show unassigned row setting
            const { scheduling_show_unassigned_row } = environmentSettings;

            if (scheduling_show_unassigned_row) {
                // Create the unassigned resource and add it to an unassigned group
                const unassignedResource = createNewResource('unassigned', uuidv4(), 1);
                
                // Create the unassigned resource group
                const unassignedResourceGroup = { 
                    id: 'unassigned', 
                    name: 'Unassigned', 
                    resources: [unassignedResource] 
                };

                // Set the resource groups
                setResourceGroups([unassignedResourceGroup, ...sortedResourceGroups]);

            } else {
                // Set the resource groups
                setResourceGroups([...sortedResourceGroups]);
            }
        }
    }, [fetchedResources, environmentSettings]);

    // Set the fetched timeblocks
    useEffect(() => {
        if (fetchedTimeBlocks) {
            setTimeBlocks(fetchedTimeBlocks);
        };
    }, [fetchedTimeBlocks]);

    // Set the fetched data on page load
    useEffect(() => {
        if (fetchedAppointments) {

            // Convert appointment dates to local date times
            const { rowsWithLocalDatetimes } = convertRowsToLocalDateTimes<SchedulingAppointmentType>(
                fetchedAppointments, 
                'start_datetime', 
                'end_datetime', 
                userLocale, 
                userTimezone
            );

            // Get the show unassigned row setting
            const { scheduling_show_unassigned_row } = environmentSettings;

            // Initialize the appointment state maps
            const createdEventsList: EventsListType = {};
            const createdResourceEvents: EventsByResourceType = {};

            // Loop through the received appointments to convert them to events
            rowsWithLocalDatetimes.forEach((appointment: SchedulingAppointmentType) => {

                const resourceIds = appointment.resource_ids || [];

                if (resourceIds.length > 0) {
                    resourceIds.forEach(resourceId => { 
                        if (appointment.id !== null && appointment.start_datetime && appointment.end_datetime && appointment.job) {

                            // Configure the event id. Combination of appointment.id and resource.id
                            const eventKey = `${appointment.id}-${resourceId}`;

                            // Map the appointment details to a new event
                            const event: EventType = {
                                key: eventKey,
                                appointmentId: appointment.id,
                                resourceId: resourceId.toString(),
                                startDateTime: appointment.start_datetime,
                                endDateTime: appointment.end_datetime,
                                dayKey: generateDayKey(new Date(appointment.start_datetime)),
                                startTime: generateTimeKeyFromDateTimeString(appointment.start_datetime),
                                endTime: generateTimeKeyFromDateTimeString(appointment.end_datetime),
                                durationMinutes: calculateDurationMinutesFromDateTimes(appointment.start_datetime, appointment.end_datetime),
                                status: appointment.status,
                                custom_color: appointment.custom_color ?? null,
                                deleted: appointment.deleted,
                                job: {
                                    id: appointment.job.id,
                                    client_name: appointment.job.client_name ?? undefined,
                                    workflow_name: appointment.job.workflow_name ?? undefined,
                                    work_location_street: appointment.job.work_location_street ?? undefined,
                                    work_location_house_number: appointment.job.work_location_house_number ?? undefined,
                                    work_location_postal_code: appointment.job.work_location_postal_code ?? undefined,
                                    work_location_city: appointment.job.work_location_city ?? undefined,
                                    internal_reference: appointment.job.internal_reference ?? null,
                                    external_reference: appointment.job.external_reference ?? null,
                                    number: appointment.job.number ?? null,
                                    status_name: appointment.job.status_name ?? null,
                                    resolution: appointment.job.resolution ?? null,
                                    main_description: appointment.job.main_description ?? null,
                                },

                                // When render timeslots, determine the nearest timeslot
                                ...(renderTimeSlots && { 
                                    timeSlot: determineNearestTimeSlot(appointment.start_datetime) 
                                }),

                                // // When viewing as employee list and showing of timeblocks, set the timeblock key
                                // ...(view.viewType === 'employee_list' && showTimeBlocks && {
                                //     timeBlock: appointment.start_time
                                // })
                            };

                            // Add the new event to the created events state
                            createdEventsList[eventKey] = event;

                            // Create an array per resource id in the events by resource state
                            if (!createdResourceEvents[resourceId]) {
                                createdResourceEvents[resourceId] = [];
                            }

                            // Add the event to the right resource
                            if (!createdResourceEvents[resourceId].includes(eventKey)) {
                                createdResourceEvents[resourceId].push(eventKey);
                            }
                        }
                    });
                } else if (scheduling_show_unassigned_row) {

                    if (appointment.id !== null && appointment.start_datetime && appointment.end_datetime && appointment.job) {

                        // Configure the event id. Combination of appointment.id and resource id (in this case 'unassigned')
                        const eventKey = `${appointment.id}-unassigned`

                        // Map the appointment details to a new event
                        const event: EventType = {
                            key: eventKey,
                            appointmentId: appointment.id,
                            resourceId: 'unassigned',
                            startDateTime: appointment.start_datetime,
                            endDateTime: appointment.end_datetime,
                            dayKey: generateDayKey(new Date(appointment.start_datetime)),
                            startTime: generateTimeKeyFromDateTimeString(appointment.start_datetime),
                            endTime: generateTimeKeyFromDateTimeString(appointment.end_datetime),
                            durationMinutes: calculateDurationMinutesFromDateTimes(appointment.start_datetime, appointment.end_datetime),
                            status: appointment.status,
                            custom_color: appointment.custom_color ?? null,
                            deleted: appointment.deleted,
                            job: {
                                id: appointment.job.id,
                                client_name: appointment.job.client_name ?? undefined,
                                workflow_name: appointment.job.workflow_name ?? undefined,
                                work_location_street: appointment.job.work_location_street ?? undefined,
                                work_location_house_number: appointment.job.work_location_house_number ?? undefined,
                                work_location_postal_code: appointment.job.work_location_postal_code ?? undefined,
                                work_location_city: appointment.job.work_location_city ?? undefined,
                                internal_reference: appointment.job.internal_reference ?? null,
                                external_reference: appointment.job.external_reference ?? null,
                                number: appointment.job.number ?? null,
                                status_name: appointment.job.status_name ?? null,
                                resolution: appointment.job.resolution ?? null,
                                main_description: appointment.job.main_description ?? null,
                            },

                            // When render timeslots, determine the nearest timeslot
                            ...(renderTimeSlots && { 
                                timeSlot: determineNearestTimeSlot(appointment.start_datetime) 
                            }),

                            // // When viewing as employee list and showing of timeblocks, set the timeblock key
                            // ...(view.viewType === 'employee_list' && showTimeBlocks && {
                            //     timeBlock: appointment.start_time
                            // })
                        };

                        // Add the new event to the created events state
                        createdEventsList[eventKey] = event;

                        // Create an array for the unassigned row in the events by resource state
                        if (!createdResourceEvents['unassigned']) {
                            createdResourceEvents['unassigned'] = [];
                        }

                        // Add the event to the unassigned resource
                        if (!createdResourceEvents['unassigned'].includes(eventKey)) {
                            createdResourceEvents['unassigned'].push(eventKey);
                        }
                    }

                }
            });

            setEvents(createdEventsList);
            setEventsByResource(createdResourceEvents);
        }
    }, [fetchedAppointments]);

    // Calculate events overlap
    useEffect(() => {
        const newOverlapMap = calculateOverlappingEvents(resourceGroups, days, events, eventsByResource);
        setEventOverlapMap(newOverlapMap);
    }, [resourceGroups, days, events, eventsByResource]);
    
    // Determine if the scheduling board is ready to render
    const isReadyToRender = currentSchedulingBoard !== null && view !== null;

    if (!view) return null;

    return (
        <>
            {/* Render scheduling navigation */}
            <SchedulingNavigation
                days={days}
                view={view}
                showInbox={showInbox}
                onViewChange={(value) => setView(value)}
                onSetRenderTimeSlots={(value) => setRenderTimeSlots(value === 'employee_timeline')}
                onSetShowInbox={(value) => setShowInbox(value)}
                onSetDays={(value) => setDays(value)}
            />

            {/* Initialize the scheduler drag drop provider */}
            {isReadyToRender && (
                <DragDropProvider<SchedulerDraggableId, SchedulerDroppableId>
                    onDragEnd={(event) => setDragEvent(event)}>

                    {/* Render the scheduling board */}
                    <SchedulingBoard
                        view={view}
                        resourceGroups={resourceGroups}
                        days={days}
                        hours={hours}
                        renderTimeSlots={renderTimeSlots}
                        timeSlots={timeSlots}
                        renderTimeBlocks={renderTimeBlocks}
                        timeBlocks={timeBlocks}
                        events={events}
                        eventsByResource={eventsByResource}
                        eventOverlapMap={eventOverlapMap}
                        showInbox={showInbox}
                        dragResult={dragEvent}
                        onSetShowInbox={(value) => setShowInbox(value)}
                        setEvents={setEvents}
                        setEventsByResource={setEventsByResource}
                        setResourceGroups={setResourceGroups}
                    />
                </DragDropProvider>
            )}
        </>
    )
}

export default Scheduler;