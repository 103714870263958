import React, { useEffect, useMemo, useState } from 'react';
import { FieldType } from 'types/FieldTypes';
import { generateFieldComponents } from 'components/forms/GenerateFieldComponents';
import FormFieldContext from 'components/forms/FormFieldContext';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { BillingData } from '../BillingTypes';
import { useModal } from 'components/modals/ModalContext';
import { saveData } from 'services/api/saveData';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useGlobalContext } from 'GlobalContext';
import { useTranslation } from 'react-i18next';

interface BillingDetailsFormProps {
    viewKey?: string;
    billingData: BillingData;
}

const BillingDetailsForm: React.FC<BillingDetailsFormProps> = ({ viewKey, billingData }) => {
    const { t } = useTranslation();
    const { environmentHash } =  useAuthContext();
    const { setFloatingAlert, errorMessages, setErrorMessages } = useGlobalContext();
    const { revealModal, closeModal, modalStack } = useModal();
    const [editing, setEditing] = useState(true);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [buttonLoader, setButtonLoader] = useState(false);
    const topModal = modalStack[modalStack.length - 1];

    const fields: FieldType[] = [
        {
            type: 'header',
            header: 'billing.general.company_details_header',
        },
        {
            type: 'text',
            name: 'company_name',
            label: 'billing.general.company_name_label',
            placeholder: 'billing.general.company_name_label',
        },
        {
            type: 'text',
            name: 'first_name',
            label: 'billing.general.first_name_label',
            placeholder: 'billing.general.first_name_label',
            width: '50%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'last_name',
            label: 'billing.general.last_name_label',
            placeholder: 'billing.general.last_name_label',
            width: '50%',
            group: 'field-row',
        },
        {
            type: 'email',
            name: 'email',
            label: 'billing.general.correspondence_email_label',
            placeholder: 'billing.general.email_placeholder',
            helperText: 'billing.general.correspondence_email_helperText'
        },
        {
            type: 'text',
            name: 'invoice_email',
            label: 'billing.general.invoice_email_label',
            placeholder: 'billing.general.email_placeholder',
            helperText: 'billing.general.invoice_email_helperText'
        },
        {
            type: 'header',
            header: 'billing.general.invoice_address_header'
        },
        {
            type: 'text',
            name: 'street',
            label: 'billing.general.street_label',
            placeholder: 'billing.general.street_label',
            width: '70%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'house_number',
            label: 'billing.general.house_number_label',
            placeholder: 'billing.general.house_number_label',
            width: '30%',
            group: 'field-row',
        },
        {
            type: 'text',
            name: 'postal_code',
            label: 'billing.general.postal_code_label',
            placeholder: 'billing.general.postal_code_label',
            width: '40%',
            group: 'field-row-next',
        },
        {
            type: 'text',
            name: 'city',
            label: 'billing.general.city_label',
            placeholder: 'billing.general.city_label',
            width: '60%',
            group: 'field-row-next',
        },
        {
            type: 'text',
            name: 'country',
            label: 'billing.general.country_label',
            placeholder: 'billing.general.country_label',
        },
        {
            type: 'text',
            name: 'vat_number',
            label: 'billing.general.vat_number_label',
            placeholder: 'billing.general.vat_number_placeholder',
        },
    ];

    // Reveal the modal on first render
    useEffect(() => {
        revealModal();
    }, []);

    // Generate the field component for the form
    const fieldComponents = useMemo(() => {
        if (!viewKey) return;
        return generateFieldComponents(
            viewKey, 
            fields, 
            undefined, 
            undefined, 
            billingData, 
            updatedData
        );
    }, [updatedData, billingData]);

    // Handle the submit of the updated billing details
    const handleSubmit = async () => {
        try {            
            // Start the button loader
            setButtonLoader(true);

            // Initialize object to store the row errors
            const rowErrors: Record<string, string> = {};

            // Validate the required fields
            for (const field of fields) {
                if ('name' in field && field.name !== undefined) {

                    // Show error when company name is not given
                    if (field.name === 'company_name' && !updatedData.company_name && !billingData.company_name) {
                        rowErrors['company_name'] = 'validation.general.required'
                    };

                    // Show error when email is not given
                    if (field.name === 'email' && !updatedData.email && !billingData.email) {
                        rowErrors['email'] = 'validation.general.required'
                    };
                };
                
                if (Object.keys(rowErrors).length > 0) {
                    // Update the error messages state
                    setErrorMessages(rowErrors);

                    // Stop the button loader
                    setButtonLoader(false);

                    return;
                }
            };

            // Post the data in the backend
            const response = await saveData({ 
                apiUrl: `patch_company_details/${environmentHash}`, 
                method: 'patch', 
                data: updatedData
            })

            // Handle successful response
            if (response?.status === 200) {

                // Stop the button loader
                setButtonLoader(false);

                // Show success alert
                setFloatingAlert({ 'type': 'success' })

                // Call the on success callback
                if (topModal?.props?.onSuccess) {
                    topModal.props.onSuccess();
                };

                // Close the modal
                closeModal()

                // Remove the error messages
                setErrorMessages({});
            }
        } catch (error) {
            console.error("Error on submit", error);
            setButtonLoader(false);
        };        
    };

    // Close the modal
    const handleClose = () => {
        setButtonLoader(false);
        setErrorMessages({});
        closeModal();
    }

    return (
        <FormFieldContext.Provider value={{ editing, setEditing, updatedData, setUpdatedData }}>
            {(errorMessages.general) &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            <form className='formset add-edit-form'>
                {fieldComponents}
                <div className='buttons-right'>
                    <SecondaryButton
                        onClick={handleClose} 
                        label='general.cancel'
                        size="small"
                    />
                    <PrimaryButton
                        type="button" 
                        label='general.save'
                        onClick={handleSubmit}
                        size="small"
                        onlyViewRestriction={true}
                        loading={buttonLoader}
                    />
                </div>
            </form>
        </FormFieldContext.Provider>
    )
};

export default BillingDetailsForm;