import React from 'react';
import { useSettings } from 'services/settings/SettingsContext';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';
import ContactForm from '../../views/contacts/ContactForm';
import LocationForm from '../../views/contacts/LocationForm';
import PersonForm from '../../views/contacts/PersonForm';

// Rename the name of the function:
const JobForm: React.FC<{ viewKey?: string, linkedItem?: { [key: string]: number } }> = ({ viewKey, linkedItem }) => {
    const { environmentSettings } = useSettings();

    const apiObject = 'job'

    // Get the appointment colors setting
    const { scheduling_appointment_colors } = environmentSettings;

    // Configure the base fields for the job form
    const baseFields: FieldType[] = [

        // Switch form context
        // { 
        //     type: 'tabs', 
        //     name: 'context', 
        //     defaultValue: 'job', 
        //     fullWidth: true,
        //     options: [
        //         { value: 'job', name: 'Opdracht' },
        //         { value: 'other', name: 'Andere' },
        //     ],
        // },

        // Job form fields
        { 
            type: 'searchselect', 
            name: 'client',
            label: 'job.general.client_label',
            placeholder: 'job.general.client_placeholder',
            objectName: 'client.general.object_name.singular',
            query: {
                // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                endpoint: 'get_contact_search',
                params: {
                    contact_type: ['contact', 'prospect', 'client'] 
                }
            },
            selectionFormat: 'name',
            optionFormat: {
                title: { field: 'name', format: (value) => value },
                subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
            },
            addNewItem: { method: 'modal-form', modalForm: <ContactForm /> },
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        { 
            type: 'searchselect', 
            name: 'work_location',
            label: 'job.general.work_location_label',
            placeholder: 'job.general.work_location_placeholder',
            objectName: 'location.general.object_name.singular',
            query: {
                // Get all active locations
                endpoint: 'get_location_search',
            },
            selectionFormat: 'address',
            optionFormat: {
                title: { field: 'address', format: (value) => value },
            },
            addNewItem: { method: 'modal-form', modalForm: <LocationForm /> },
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        {
            type: 'header',
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        { 
            type: 'dropdown', 
            name: 'workflow', 
            label: 'job.general.type_label', 
            apiObject: 'workflow',
            disabled_selected: 'job.general.type_disabled_selected',
            params: { 
                is_active: true, 
                connected_feature: 'job'
            },
            allowNoneOption: false,
            showSearch: false,
            orderOptions: true,
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        {
            type: 'textarea',
            name: 'main_description',
            label: 'job.general.main_description_label',
            rows: 3,
            placeholder: 'job.general.main_description_placeholder',
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        {
            type: 'header',
            // dependentOn: { fieldName: 'context', value: 'job' },
        },
        
        // Other activities form fields
        // { 
        //     type: 'dropdown', 
        //     name: 'time_type', 
        //     label: 'scheduling.other_activity.activity_type_label', 
        //     apiObject: 'other_activities_timetype',
        //     disabled_selected: 'forms.select_type_disabled_selected',
        //     params: { is_active: true },
        //     allowNoneOption: false,
        //     showSearch: false,
        //     dependentOn: { fieldName: 'context', value: 'other' },
        // },
        // {
        //     type: 'textarea',
        //     name: 'notes',
        //     label: 'scheduling.other_activity.notes_label',
        //     rows: 5,
        //     placeholder: 'scheduling.other_activity.notes_placeholder',
        //     dependentOn: { fieldName: 'context', value: 'other' },
        // },

        // Generic scheduling fields
        { 
            type: 'multiselect', 
            name: 'appointment_assignees', 
            label: 'job.schedule_job.assignees_label',
            apiObject: 'user',
            apiRequest: [{
                endpoint: `get_user_list`,
                object: 'user',
                params: { is_active: true }
            }],
            disabled_selected: 'job.schedule_job.assignees_disabled_selected',
            defaultValue: String(linkedItem?.selectedResource) ?? undefined,
            backendField: 'user_hash',
            allowNoneOption: false,
            optionFormat: (option) => option.full_name,
            selectionFormat: 'full_name',
            params: {
                is_active: true,
            },
        },
        {
            type: 'date',
            name: 'appointment_date',
            label: 'job.schedule_job.date_label',
            defaultValue: String(linkedItem?.selectedDate) ?? undefined, 
            width: '40%',
            group: 'field-row',
        },
        {
            type: 'time',
            name: 'appointment_start_time',
            label: 'job.schedule_job.start_time_label',
            defaultValue: String(linkedItem?.selectedTime) ?? undefined,
            width: '30%',
            group: 'field-row',
        },
        {
            type: 'time',
            name: 'appointment_end_time',
            label: 'job.schedule_job.end_time_label',
            width: '30%',
            group: 'field-row',
        },
    ];

    // Configure the optional color field
    const colorField: FieldType[] = [
        {
            name: 'appointment_custom_color',
            label: 'job.schedule_job.color_label',
            type: 'tabs',
            showIconOnly: true,
            customClass: "label",
            options: [
                { value: "soft-dark-blue", name: "soft-dark-blue" },
                { value: "soft-blue", name: "soft-blue" },
                { value: "soft-dark-green", name: "soft-dark-green" },
                { value: "soft-green", name: "soft-green" },
                { value: "soft-yellow", name: "soft-yellow" },
                { value: "soft-orange", name: "soft-orange" },
                { value: "soft-red", name: "soft-red" },
                { value: "soft-gray", name: "soft-gray" },
                { value: "soft-dark-gray", name: "soft-dark-gray" },
                { value: "soft-purple", name: "soft-purple" },
            ]
        },
    ];

    // Combine the base fields and the optional color field
    const fields: FieldType[] = [...baseFields, ...(scheduling_appointment_colors === "custom" ? colorField : [])];

    return (
        <FormModal
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields} 
            linkedItem={linkedItem}      
        />
    );
};

export default JobForm;