import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { v4 as uuidv4 } from 'uuid';
import { fetchData } from 'services/api/fetchData';
import { AppointmentType, JobType, TimeBlockType, createNewAppointment } from './JobTypes';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import CheckboxCheck from 'assets/CheckboxCheck.svg';
import FormFieldContext from 'components/forms/FormFieldContext';
import { saveData } from 'services/api/saveData';
import { useGlobalContext } from 'GlobalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import DateInput from 'components/forms/basefields/DateInput';
import TimeInput from 'components/forms/basefields/TimeInput';
import Dropdown from 'components/forms/basefields/Dropdown';
import MultiSelect from 'components/forms/basefields/MultiSelect';
import { UserType } from '../../views/users/UserTypes';
import IconButton from 'components/buttons/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSettings } from 'services/settings/SettingsContext';
import { convertLocalDateTimeToUTC, extractDateFromLocalDateTime } from 'internationalization/timezoneConversions';
import { convertRowsToLocalDateTimes, sortRowsByDate } from 'services/utils/sortDates';
import '../../style/scss/forms.scss';
import '../../style/scss/modals.scss';
import { combineDateAndTimeInputsToDatetimeString, convertTimeStringToDate } from 'services/utils/dateTimeUtils';
import TabsField from 'components/forms/fields/TabsField';
import { formatDecimalToHoursAndMinutes } from 'services/utils/convertDecimalToHoursAndMinutes';
import axios, { CancelTokenSource } from 'axios';
import { shakeElement } from 'services/utils/shakeElement';

interface ScheduleJobModalProps {
    viewKey?: string;
    job: JobType;
    setLoading: (loading: boolean) => void;
}

const ScheduleJobModal: React.FC<ScheduleJobModalProps> = ({ viewKey, job, setLoading }) => {
    const { t } = useTranslation()
    const { environmentSettings, userLocale, userTimezone } = useSettings();
    const { setFloatingAlert, errorMessages, setErrorMessages, setUnsavedChanges } = useGlobalContext()
    const { handleLogout } = useAuthContext();
    const { modalStack, revealModal, closeModal } = useModal();
    const [rows, setRows] = useState<AppointmentType[]>([]);    
    const [assignees, setAssignees] = useState<UserType[]>([]);
    const [updatedData, setUpdatedData] = useState<Record<string, any>>({});
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null);  
    const [scheduleMultipleAppointments, setScheduleMultipleAppointments] = useState('multiple');
    const [showColorSelection] = useState<boolean>(environmentSettings.scheduling_appointment_colors === 'custom' ? true : false);
    const [emailCheckboxValue, setEmailCheckboxValue] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const topModal = modalStack[modalStack.length - 1];

    // To do: Pass handle submit when updated data is empty (gives an error)
    // To do: Test handle submit, environment id error, no start_date

    // Fetch the current appointments and dropdown options
    useEffect(() => {

        // Show empty form fields
        setRows([createNewAppointment(job, uuidv4())]);

        // Fetch the time blocks options
        // const fetchTimeBlocks = async () => {
        //     const response = await fetchData({ apiUrl: 'get_timeblock_list', handleLogout });
        //     setTimeBlocks(response?.results || []);
        // };

        // Fetch the assignee options
        const fetchAssignees = async () => {
            const response = await fetchData({ apiUrl: 'get_user_list', params: { 'is_active': true, 'field_worker': true }, handleLogout });
            setAssignees(response?.results || []);
        };

        setLoading(true);

        // Execute the fetches
        (async () => {
            try {
                await Promise.all([
                    // fetchTimeBlocks(), 
                    fetchAssignees()
                ]);
            } finally {
                // Reveal the modal after loading
                setLoading(false);
                revealModal();
            }
        })();
    }, []);

    // Set the show schedule time blocks setting on render
    useEffect(() => {
        if (environmentSettings.job_appointment_method) setScheduleMultipleAppointments(environmentSettings.job_appointment_method);
    }, [environmentSettings]);

    // Function to add a new row
    const handleAddRow = () => {
        // Create a new row
        const newRow = createNewAppointment(job, uuidv4());
    
        // Add the row to the list of rows
        const updatedRows = [...rows, newRow];
    
        // Update the rows and updated data
        setRows(updatedRows);
        setUpdatedData(updatedRows);
        // setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
    };

    // Handle the date change
    const handleDateChange = (selectedDate: string | null, fieldName: 'start_date' | 'end_date', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the selected date and time values into datetime fields
            let combinedDatetime, utcDatetime;
            if (selectedDate !== null) {

                // Handle datetime conversion when start date field is changed
                if (fieldName === 'start_date') {

                    // If a start time exists, combine the start datetime
                    if (row.start_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.start_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        updatedRows[rowIndex].start_datetime = utcDatetime;
                    }
                    
                    // If the start date and end date are equal, and end time exists, combine the start datetime
                    if ((row.end_date === null || (row.start_date === row.end_date)) && row.end_time) {

                        // Set the end date to the selected date
                        updatedRows[rowIndex].end_date = selectedDate;

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }
                }

                // Handle datetime conversion when end date field is changed
                if (fieldName === 'end_date' && row.end_time) {
                    // Combine the date input and time input into a local datetime string
                    combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                    // Convert the local datetime into an utc datetime string for the backend
                    utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                    // Update the end datetime
                    updatedRows[rowIndex].end_datetime = utcDatetime;
                }
            } else {
                // Handle in case the start date is removed
                if (fieldName === 'start_date') {
                    // If the start date and end date are equal, remove both start- and end-datetimes
                    if (row.start_date && row.end_date && (row.start_date === row.end_date)) {
                        updatedRows[rowIndex].start_datetime = null;
                        updatedRows[rowIndex].end_datetime = null;

                    // Otherwise only remove the start-datetime
                    } else {
                        updatedRows[rowIndex].start_datetime = null;
                    }
                }

                // Handle in case the end date is removed
                if (fieldName === 'end_date') {
                    // If the start date still exists and end time, set the end date to the start date
                    if (row.start_date && row.end_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;

                    // Otherwise remove the end-datetime
                    } else {
                        updatedRows[rowIndex].end_datetime = null;
                    }
                }
            }

            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedDate;

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
            // setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle time change
    const handleTimeChange = (selectedTime: string | null, fieldName: 'start_time' | 'end_time', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedTime;

            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the time value to a datetime string if the start_date value exists
            let combinedDatetime, utcDatetime;
            if (selectedTime !== null && selectedTime !== '') {

                // Check if the start date exists
                if (row.start_date !== null) {

                    // Handle if the end time changed and the end date differs from the start date
                    if (row.end_date !== null && (row.start_date !== row.end_date) && fieldName === 'end_time') {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.end_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }

                    // Handle if both the end- or start-time changed, but the start- and end-dates are equal
                    if ((row.end_date !== null && (row.start_date === row.end_date)) || row.end_date === null) {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        if (fieldName === 'start_time') {
                            updatedRows[rowIndex].start_datetime = utcDatetime;
                        }
            
                        // Update the end datetime
                        if (fieldName === 'end_time') {
                            updatedRows[rowIndex].end_datetime = utcDatetime;
                        }    
                    }
                }
            
            } else {
                // If the selected time is set to null, remove the start- or end-datetimes
                if (fieldName === 'start_time') {
                    updatedRows[rowIndex].start_datetime = null;
                }
    
                // Update the end datetime
                if (fieldName === 'end_time') {
                    updatedRows[rowIndex].end_datetime = null;
                }
            }

            // Update the duration after changing the time
            if (row.start_time && row.end_time) {

                // Calculate the duration if start- and end-datetime exists
                if (row.start_datetime && row.end_datetime) {

                    // Convert date time strings to date objects
                    const startDate = new Date(row.start_datetime);
                    const endDate = new Date(row.end_datetime);

                    // Check if both date objects are valid
                    if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endDate.getTime() - startDate.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                
                // Calculate the duration only on start- and end-time fields
                } else {
                    // Convert start- and end-time strings to date objects
                    const startTime = convertTimeStringToDate(row.start_time);
                    const endTime = convertTimeStringToDate(row.end_time);

                    if (startTime && endTime && !isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endTime.getTime() - startTime.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                }
            } else {
                // Fallback to 0 if one of the times is missing
                const fallback = formatDecimalToHoursAndMinutes(0);

                // Update the duration string with the fallback
                updatedRows[rowIndex].duration_string = fallback;
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
            // setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle the dropdown change
    const handleDropdownChange = (selectedValue: string, fieldName: 'status' | 'custom_color', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedValue

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
            // setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // Handle the multiselect change
    const handleMultiSelectChange = (selectedValue: string, fieldName: 'assignees', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            let updatedAssignees = rows[rowIndex][fieldName] || [];
            
            // Get the index of the assignee in the current list
            const assigneeIndex = updatedAssignees.findIndex(assignee => assignee.id.toString() === selectedValue);
    
            if (assigneeIndex > -1) {
                // Delete the assignee if it already exists
                updatedAssignees.splice(assigneeIndex, 1);
            } else {
                // Add the assignee if it doesn't exist
                const assigneeToAdd = assignees.find(assignee => assignee.id.toString() === selectedValue);
                if (assigneeToAdd) {
                    updatedAssignees.push(assigneeToAdd);
                }
            }

            // Update assignees for the specific row
            updatedRows[rowIndex][fieldName] = updatedAssignees;
            updatedRows[rowIndex]['assignee_ids'] = updatedAssignees.map(assignee => assignee.id);

            // Update the status based on whether there are assignees
            if (updatedAssignees.length === 0) {
                updatedRows[rowIndex].status = 'to_be_assigned';
            } else {
                updatedRows[rowIndex].status = 'scheduled';
            }
    
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
            // setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // Function to delete an existing row
    const handleDeleteRow = (identifier: string) => {
        if (viewKey) setUnsavedChanges(viewKey, true);

        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            if (updatedRows[rowIndex].id === null && updatedRows[rowIndex].uuid !== null) {
                // Newly added rows have no id and don't delete in the database, so just delete the row
                updatedRows.splice(rowIndex)
            } else {
                // Existing items have an id but no uuid, and have to set to deleted
                updatedRows[rowIndex].deleted = true;
            }
            
            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: 'whole_day' | 'status', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...rows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value in the row
            if (fieldName === 'whole_day') {
                updatedRows[rowIndex].whole_day = event.target.checked;
            };

            if (fieldName === 'status') {
                if (event.target.checked === true) {
                    updatedRows[rowIndex].status = 'reserved';
                } else if (event.target.checked === false) {
                    updatedRows[rowIndex].status = 'scheduled';
                }
            };

            // Update the rows and updated data
            setRows(updatedRows);
            setUpdatedData(updatedRows);
        }
    }

    // Handle email checkbox change
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setEmailCheckboxValue(checked);
    }

    // Closes the modal when clicking the close or cancel button
    const handleClose = () => {
        // Removes the current data, so the form will be empty at reopening
        setUpdatedData([]);

        // Removes errors from fields, hides error alert and closes the modal
        setErrorMessages({});
        setShowErrorAlert(false);
        closeModal();

        // If a request is still pending when closing, cancel the request
        if (cancelSource) {
            cancelSource.cancel();
        }

        // Stops the button loader icon
        setButtonLoader(false);
    }

    const handleSubmit = async () => {
        try {
            setButtonLoader(true);

            // Create the cancel token source
            const source = axios.CancelToken.source();
            setCancelSource(source);

            // Save the data in the backend
            const response = await saveData({ 
                apiUrl: `post_appointment_batch`, 
                method: 'post', 
                data: updatedData,
                source
            });

            // Handle successful response
            if (response?.status === 201) {
                setButtonLoader(false);
                setFloatingAlert({ 'type': 'success' });

                // Call the on success callback
                if (topModal?.props?.onSuccess) {
                    topModal.props.onSuccess(response.data);
                };

                closeModal();
                setErrorMessages({});
            }
        } catch (error) {
            setButtonLoader(false);
            // setErrorMessages(errorData);

            // Shake the modal in any case of an error
            if (topModal?.modalRef) shakeElement(topModal.modalRef.current as HTMLElement);
        }
    };

    return (
        <FormFieldContext.Provider value={{ updatedData, setUpdatedData }}>
            <div className='schedule-job-modal'>
                <h3 className='header text-center'>{t('job.schedule_job.schedule_job_header')}</h3>
                {rows && rows.filter(row => !row.deleted && row.status !== 'done').map((row, index) => {
                    const identifier = row.id?.toString() || row.uuid;

                    // Determine to show the end date field if the end date differs from the start date
                    let showEndDateField;
                    if (row.start_datetime && row.end_datetime) {
                        const rowStartDateTime = extractDateFromLocalDateTime(row.start_datetime, userLocale);
                        const rowEndDateTime = extractDateFromLocalDateTime(row.end_datetime, userLocale)

                        showEndDateField = rowStartDateTime !== rowEndDateTime;
                    };

                    return (
                        <div className='appointment-row' key={index}>
                            <div className='fields'>
                                <div className={`date-time-row ${row.whole_day ? 'whole-day' : ''}`}>
                                    <div>
                                        <label>
                                            {t('job.schedule_job.date_label')}
                                        </label>
                                        <DateInput
                                            id={`start_date_${identifier}`}
                                            name={`start_date_${identifier}`}
                                            localDatetime={row.start_datetime ?? undefined}
                                            onChange={(selectedValue) => handleDateChange(selectedValue, 'start_date', identifier || '')} />
                                    </div>
                                    {!row.whole_day && !row.time_block && (
                                        // Only show time fields when its not a whole day or time block appointment
                                        <>
                                            <div>
                                                <label>
                                                    {t('job.schedule_job.start_time_label')}
                                                </label>
                                                <TimeInput
                                                    type='time'
                                                    id={`start_time_${row.id || row.uuid}`}
                                                    name={`start_time_${row.id || row.uuid}`}
                                                    localDatetime={row.start_datetime ?? undefined}
                                                    onChange={(selectedTime) => handleTimeChange(selectedTime, 'start_time', row.id?.toString() || row.uuid || '')} />
                                            </div>
                                            <div>
                                                <label>
                                                    {t('job.schedule_job.end_time_label')}
                                                </label>
                                                <TimeInput
                                                    type='time'
                                                    id={`end_time_${row.id || row.uuid}`}
                                                    name={`end_time_${row.id || row.uuid}`}
                                                    localDatetime={row.end_datetime ?? undefined}
                                                    onChange={(selectedTime) => handleTimeChange(selectedTime, 'end_time', row.id?.toString() || row.uuid || '')} />
                                            </div>
                                        </>
                                    )}
                                    {showEndDateField && (
                                        // Only show the end date field when the appointment end-time ends on the next day
                                        <div>
                                            <label>
                                                {t('job.schedule_job.end_date_label')}
                                            </label>
                                            <DateInput
                                                id={`end_date_${identifier}`}
                                                name={`end_date_${identifier}`}
                                                localDatetime={row.end_datetime ?? undefined}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, 'end_date', row.id?.toString() || row.uuid || '')} />
                                        </div>
                                    )}
                                    {/* {!row.time_block && !showEndDateField && (
                                        // Only whole day checkbox if its no time block appointment
                                        <div className='whole-day'>
                                            <div className='checkbox-item left'>
                                                <div className="label-description-container">
                                                    <label htmlFor={`whole_day_${row.id || row.uuid}`} className={`checkbox-label ${rowIsDone ? 'disabled' : ''}`}>
                                                        {t('job.schedule_job.whole_day_label')}
                                                    </label>
                                                </div>
                                                <div className='checkbox-wrapper'>
                                                    <input 
                                                        type='checkbox'
                                                        id={`whole_day_${row.id || row.uuid}`}
                                                        checked={row.whole_day || undefined}
                                                        disabled={row.status === 'done'}
                                                        onChange={(event) => handleRowCheckboxChange(event, 'whole_day', row.id?.toString() || row.uuid || '')}
                                                        className='hidden-checkbox' />
                                                    <label htmlFor={`whole_day_${row.id || row.uuid}`}
                                                        className={`custom-checkbox left ${row.whole_day === true ? 'checked' : ''} ${rowIsDone ? 'disabled' : ''}`}>
                                                        {row.whole_day === true && <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                {errorMessages['start_time'] || errorMessages['end_time'] && (
                                    <div className='error-message'>
                                        'Validation'
                                    </div>
                                )}
                                {/* {scheduleTimeBlocks === true && (
                                    // Only schedule on a time block when the setting is on
                                    <div className='timeblock-row'>
                                        <div>
                                            <label>
                                                {t('job.schedule_job.time_block_label')}
                                            </label>
                                            <Dropdown<TimeBlockType>
                                                options={timeBlocks}
                                                id={`time_block_${identifier}`}
                                                name={`time_block_${identifier}`}
                                                disabled_selected={t('job.schedule_job.time_block_disabled_selected')}
                                                selectedOption={timeBlocks.find(timeBlock => timeBlock.id === row.time_block)}
                                                onChange={(selectedValue) => handleDropdownChange(selectedValue, 'time_block', row.id?.toString() || row.uuid || '')}
                                                selectionFormat={(option) => `${option.name}`}
                                                optionFormat={(option) => `${option.name}`}
                                                allowNoneOption={true} 
                                                showSearch={false}
                                                disabled={row.status === 'done'}
                                            />
                                        </div>
                                    </div> 
                                )}          */}
                                <div className='employee-row'>
                                    <label>
                                        {t('job.schedule_job.assignees_label')}
                                    </label>
                                    <MultiSelect<UserType> 
                                        options={assignees}
                                        id={`assignees_${identifier}`}
                                        name={`assignees_${identifier}`}
                                        disabled_selected={t('job.schedule_job.assignees_disabled_selected')}
                                        selectedOption={row.assignees || []}
                                        allowNoneOption={false}
                                        showSearch={true}
                                        selectionFormat={(option) => `${option.full_name}`}
                                        optionFormat={(option) => `${option.full_name}`}
                                        onChange={(selectedValue) => handleMultiSelectChange(selectedValue, 'assignees', row.id?.toString() || row.uuid || '')}
                                        disabled={row.status === 'done'}
                                    />
                                </div>
                                {showColorSelection && viewKey && (
                                    <div className='custom-colors-row'>
                                        <TabsField
                                            name={`custom_color_${identifier}`}
                                            viewInEditMode={true}
                                            type='tabs' 
                                            viewKey={viewKey}          
                                            showIconOnly={true}  
                                            customClass={"label"}
                                            label={'job.schedule_job.color_label'} 
                                            onChange={(event) => handleDropdownChange(event.target.value, 'custom_color', identifier || '')}
                                            options={[
                                                { value: "soft-dark-blue", name: "soft-dark-blue" },
                                                { value: "soft-blue", name: "soft-blue" },
                                                { value: "soft-dark-green", name: "soft-dark-green" },
                                                { value: "soft-green", name: "soft-green" },
                                                { value: "soft-yellow", name: "soft-yellow" },
                                                { value: "soft-orange", name: "soft-orange" },
                                                { value: "soft-red", name: "soft-red" },
                                                { value: "soft-gray", name: "soft-gray" },
                                                { value: "soft-dark-gray", name: "soft-dark-gray" },
                                                { value: "soft-purple", name: "soft-purple" },
                                            ]}
                                        />
                                    </div>
                                )}                                
                                <div className='checkbox-item left'>
                                    <div className="label-description-container">
                                        <label htmlFor={`status_${row.id || row.uuid}`} className='checkbox-label'>
                                            {t('job.schedule_job.schedule_reserved_label')}
                                            <span className="tooltip-icon">
                                                <FontAwesomeIcon icon={faCircleQuestion} />
                                                <span className="tooltip">{t('job.schedule_job.schedule_reserved_tooltip')}</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className='checkbox-wrapper'>
                                        <input 
                                            type='checkbox'
                                            id={`status_${row.id || row.uuid}`}
                                            checked={row.status === 'reserved' || undefined}
                                            onChange={(event) => handleRowCheckboxChange(event, 'status', row.id?.toString() || row.uuid || '')}
                                            className='hidden-checkbox' />
                                        <label htmlFor={`status_${row.id || row.uuid}`}
                                            className={`custom-checkbox left ${row.status === 'reserved' ? 'checked' : ''}`}>
                                            {row.status === 'reserved' && <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='separator'></div>
                            <div className='buttons'>
                                {/* <SecondaryButton
                                    label='job.schedule_job.pick_from_calendar_button_label'
                                    onClick={() => {}}
                                    size='large' 
                                /> */}
                                <IconButton
                                    tooltipText='job.schedule_job.delete_appointment_tooltip'
                                    onClick={() => handleDeleteRow(row.id?.toString() || row.uuid || '')}
                                    size='small'
                                    icon={<FontAwesomeIcon icon={faTrash} />} 
                                />
                            </div>
                        </div>
                    )
                })}
                {(rows.filter(row => !row.deleted).length === 0 || scheduleMultipleAppointments === 'multiple') && (
                    // Only allow to add new appointments based on the job appointment setting
                    <div className='add-appointment' onClick={handleAddRow}>
                        {t('job.schedule_job.another_appointment_label')}
                    </div>
                )}
                <div className='footer'>
                    <div className='checkbox-item left'>
                        {/* <div className="label-description-container">
                            <label htmlFor='confirmation-email' className='checkbox-label'>
                                {t('job.schedule_job.confirmation_email_label')}
                            </label>
                        </div>
                        <div className='checkbox-wrapper'>
                            <input 
                                type='checkbox'
                                id='confirmation-email'
                                checked={emailCheckboxValue}
                                onChange={(event) => handleCheckboxChange(event)}
                                className='hidden-checkbox'
                            />
                            <label htmlFor='confirmation-email'
                                className={`custom-checkbox left ${emailCheckboxValue === true ? 'checked' : ''}`}>
                                {emailCheckboxValue === true && 
                                    <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />
                                }
                            </label>
                        </div> */}
                    </div>
                    <div className='buttons-right'>
                        <SecondaryButton
                            onClick={handleClose} 
                            label="general.cancel"
                            size="small"/>
                        <PrimaryButton
                            onClick={handleSubmit}
                            label="general.save"
                            size="small"
                            loading={buttonLoader}/>
                    </div>
                </div>
            </div>
        </FormFieldContext.Provider>
    );
};

export default ScheduleJobModal;