import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import SchedulingBoardForm from './SchedulingBoardForm';
import { usePackageFeature } from 'services/permissions/permissionChecks';

// Rename the name of the function:
const SchedulingBoardList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_scheduling_board_list',
        defaultParams: { 'is_active': true },
        object: 'scheduling_board'
    }
    const objectName = 'scheduling.scheduling_board.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'
    const isDraggable = true;

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <SchedulingBoardForm />;
    const onRowClick = 'editModal'
    const modalSize = 'medium'

    // Check wheter the feature/module is enabled, otherwise hide the add button
    // When the feature is not enabled, editing of the existing first scheduling
    // board should still be possible
    const isEnabled = usePackageFeature('multiple_scheduling_boards');
    const showAddButton = isEnabled

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'scheduling.scheduling_board.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            isDraggable={isDraggable}
            formModal={formModal}
            // showAddButton={showAddButton}
            modalSize={modalSize}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default SchedulingBoardList;