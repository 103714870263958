import React, { useEffect, useState } from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';
import { useSettings } from 'services/settings/SettingsContext';

// Rename the name of the function:
const ContactForm: React.FC<{ viewKey?: string, linkedItem?: { [key: string]: number }, searchValue?: string }> = ({ 
    viewKey, linkedItem, searchValue
}) => {
    const { environmentSettings } = useSettings();
    const [defaultEntityType, setDefaultEntityType] = useState<string>(environmentSettings.contact_default_entity_type || 'organization');
    const apiObject = 'contact'

    // Get the default entity type from the environment settings
    useEffect(() => {
        setDefaultEntityType(environmentSettings.contact_default_entity_type || 'organization');
    }, [environmentSettings]);

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'tabs', 
            name: 'entity_type', 
            label: 'contact.general.entity_type_label', 
            defaultValue: defaultEntityType.toString(), 
            fullWidth: true,
            options: [
                { value: 'organization', name: 'contact.entity_type.organization' },
                { value: 'person', name: 'contact.entity_type.person' },
            ],
        },
        // {
        //     type: 'header',
        //     header: 'Klant',
        // },
        {
            name: 'name',
            label: 'contact.general.name_label',
            type: 'text',
            placeholder: 'contact.general.name_placeholder',
            dependentOn: { fieldName: 'entity_type', value: 'organization' },
        },
        // {
        //     name: 'tav',
        //     label: 'Ter attentie van',
        //     type: 'text',
        //     placeholder: '',
        //     dependentOn: { fieldName: 'entity_type', value: 'organization' },
        // },
        {
            name: 'title',
            label: 'person.general.salutation_label',
            type: 'text',
            placeholder: 'person.general.salutation_placeholder',
            dependentOn: { fieldName: 'entity_type', value: 'person' },
            group: 'name-row',
            width: '30%'
        },
        {
            name: 'first_name',
            label: 'person.general.first_name_label',
            type: 'text',
            placeholder: 'person.general.first_name_placeholder',
            dependentOn: { fieldName: 'entity_type', value: 'person' },
            group: 'name-row',
            width: '70%'
        },
        {
            name: 'last_name',
            label: 'person.general.last_name_label',
            type: 'text',
            placeholder: 'person.general.last_name_placeholder',
            dependentOn: { fieldName: 'entity_type', value: 'person' },
        },
        // {
        //     type: 'header',
        //     header: 'Contactpersoon',
        //     dependentOn: { fieldName: 'entity_type', value: 'organization' },
        // },
        // {
        //     name: 'title',
        //     label: 'person.general.salutation_label',
        //     type: 'text',
        //     placeholder: 'person.general.salutation_placeholder',
        //     dependentOn: { fieldName: 'entity_type', value: 'organization' },
        //     group: 'name-row',
        //     width: '30%'
        // },
        // {
        //     name: 'first_name',
        //     label: 'person.general.first_name_label',
        //     type: 'text',
        //     placeholder: 'person.general.first_name_placeholder',
        //     dependentOn: { fieldName: 'entity_type', value: 'organization' },
        //     group: 'name-row',
        //     width: '70%'
        // },
        // {
        //     name: 'last_name',
        //     label: 'person.general.last_name_label',
        //     type: 'text',
        //     placeholder: 'person.general.last_name_placeholder',
        //     dependentOn: { fieldName: 'entity_type', value: 'organization' },
        // },
        // {
        //     name: 'email',
        //     label: 'email',
        //     type: 'text',
        //     placeholder: '',
        //     // dependentOn: { fieldName: 'entity_type', value: 'organization' },
        // },
        // {
        //     name: 'phone',
        //     label: 'phone',
        //     type: 'text',
        //     placeholder: '',
        //     // dependentOn: { fieldName: 'entity_type', value: 'organization' },
        // },
        // {
        //     type: 'header',
        //     header: 'Adres'
        // },
        // {
        //     name: 'street',
        //     label: 'location.general.street_label',
        //     type: 'text',
        //     placeholder: 'location.general.street_placeholder',
        //     group: 'street-row',
        //     width: '70%'
        // },
        // {
        //     name: 'house_number',
        //     label: 'location.general.house_number_label_short',
        //     type: 'text',
        //     placeholder: 'location.general.house_number_placeholder',
        //     group: 'street-row',
        //     width: '30%'
        // },
        // {
        //     name: 'postal_code',
        //     label: 'location.general.postal_code_label',
        //     type: 'text',
        //     placeholder: 'location.general.postal_code_placeholder',
        //     group: 'city-row',
        //     width: '35%'
        // },
        // {
        //     name: 'city',
        //     label: 'location.general.city_label',
        //     type: 'text',
        //     placeholder: 'location.general.city_placeholder',
        //     group: 'city-row',
        //     width: '65%'
        // },
        // {
        //     name: 'country',
        //     label: 'location.general.country_label',
        //     type: 'text',
        //     placeholder: 'location.general.country_placeholder',
        // },
        // {
        //     name: 'name',
        //     label: 'location.general.location_name_label',
        //     type: 'text',
        //     placeholder: 'location.general.location_name_placeholder',
        //     tooltipText: 'location.general.location_name_tooltipText',
        // },
        // TODO: Make this field optional, show it in the general contact add form, but not when creating a contact from a proposal, order or job
        // { 
        //     type: 'dropdown', 
        //     name: 'contact_type', 
        //     label: 'contact.general.contact_type_label', 
        //     disabled_selected: 'forms.select_type_disabled_selected',
        //     defaultValue: { value: 'contact' },
        //     allowNoneOption: false,
        //     showSearch: false,
        //     hardcodedOptions: [
        //         { value: 'contact', name: 'contact.contact_type.contact'},
        //         { value: 'prospect', name: 'contact.contact_type.prospect'},
        //         { value: 'client', name: 'contact.contact_type.client'},
        //         { value: 'supplier', name: 'contact.contact_type.supplier'},
        //     ],
        // },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default ContactForm;