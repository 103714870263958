import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const JobTimeTypeForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'timetype'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'timetype.general.name_label',
            type: 'text',
            placeholder: 'timetype.general.name_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'type', 
            label: 'timetype.general.type_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'productive' },
            disabled: true,
            allowNoneOption: false,
            showSearch: false,
            tooltipText: 'timetype.general.job_timetype_type_tooltip',
            hardcodedOptions: [
                { value: 'productive', name: 'timetype.type.productive'},
                { value: 'internal', name: 'timetype.type.internal'},
                { value: 'leave', name: 'timetype.type.leave'},
                { value: 'sickness', name: 'timetype.type.sickness'},
            ],
        },
        { 
            type: 'radio', 
            name: 'productive_subtype',
            label: 'timetype.general.productive_subtype_label', 
            tooltipText: 'timetype.general.productive_subtype_tooltip',
            dependentOn: { fieldName: 'type', value: 'productive' },
            options: [
                { value: 'work_time', name: 'timetype.productive_subtype.work_time'},
                { value: 'travel_time', name: 'timetype.productive_subtype.travel_time'},
            ],
        },
        {
            name: 'is_active',
            label: 'timetype.general.active_label',
            type: 'checkbox',
            showOnEdit: true,
        }
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default JobTimeTypeForm;