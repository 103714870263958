import React, { useContext, useState, useEffect } from 'react';
import { AuthenticationContextProviderProps, AuthenticationContext } from 'services/authentication/AuthenticationTypes';
import { useGlobalContext } from 'GlobalContext';
import { setCsrfToken } from './csrfFunctions';
import { login } from './loginFunction';
import { websocketBaseUrl } from 'App';
import { logout } from './logoutFunction';
import { useLocation } from 'react-router-dom';
import { refreshStatesFromLocalStorage } from './refreshStates';
import { FeaturesPerPackage } from 'services/permissions/packageFeatures';
import { encryptData, getDecryptedLocalStorageItem } from "./encryptData";
import { fetchData } from 'services/api/fetchData';
import { filterReleasedFeatures } from 'services/permissions/releasedFeatures';

/*
 * AuthenticationContext.tsx
 * This context is created to contain states and information about the 
 * authenticated user and environments, such as login state but also 
 * environment features. The context also handles the initialization
 * of the csrf token, handling of the login, updates to the environment
 * features by websocket and logout functions.
 */ 

export const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = ({ children }) => {
    const currentSession = localStorage.getItem('currentSession') ? true : null;
    const [statesLoaded, setStatesLoaded] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(currentSession);
    const [userHash, setUserHash] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [environmentHash, setEnvironmentHash] = useState<string>('');
    const [environmentPackage, setEnvironmentPackage] = useState<'starter' | 'essential' | 'advanced' | 'enterprise'>('starter');
    const [environmentType, setEnvironmentType] = useState<'trial' | 'beta' | 'client' | 'demo' | 'admin'>('trial');
    const [environmentStatus, setEnvironmentStatus] = useState<'active' | 'expired' | 'suspended' | 'cancelled'>('active');
    const [packageFeatures, setPackageFeatures] = useState<string[]>([]);
    const [activeFeatures, setActiveFeatures] = useState<string[]>([]);
    const [accessTo, setAccessTo] = useState<string[]>([]);
    const [allowedFeatures, setAllowedFeatures] = useState<string[]>([]);
    const [allowedRights, setAllowedRights] = useState<string[]>([]);
    const [redirectTo, setRedirectTo] = useState<string | null>(() => {
        const encryptedValue = localStorage.getItem('redirect_to');
        return encryptedValue ? getDecryptedLocalStorageItem(encryptedValue) : null;
    });
    const [subscriptionIsTrial, setSubscriptionIsTrial] = useState<boolean | null>(null);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState<string | null>(null);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState<string | null>(null);
    const [subscriptionShowNoPaymentMethodBanner, setSubscriptionShowNoPaymentMethodBanner] = useState<boolean>(false);
    const [pageReloaded, setPageReloaded] = useState(false);
    const { setFormAlert, setErrorMessages } = useGlobalContext();
    const location = useLocation();

    // Set a csrf token in the browser
    useEffect(() => {
        setCsrfToken();
    }, []);

    // Handle the login
    const handleLogin = async (
        email: string, 
        password: string, 
        navigator_language: string, 
        navigator_locale: string, 
        navigator_timezone: string | null, 
        utc_datetime: string,
        remember_login: boolean
    ): Promise<{status: string, error?: string}> => {
        const loginResult = await login({ email, password, navigator_language, navigator_locale, navigator_timezone, utc_datetime, remember_login, callbacks: {
            setIsLoggedIn, setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage, setEnvironmentType,
            setEnvironmentStatus, setPackageFeatures, setActiveFeatures, setAccessTo, setAllowedFeatures, 
            setAllowedRights, setRedirectTo, setSubscriptionIsTrial, setSubscriptionStartDate, setSubscriptionEndDate,
            setSubscriptionShowNoPaymentMethodBanner, setStatesLoaded
        }})

        if (loginResult.status === 'success') {
            // Set the redirect from the login response
            if (loginResult.redirect) {
                setRedirectTo(loginResult.redirect)
            }

            setFormAlert(null);
            setErrorMessages({});
            return { status: 'success' };
        } else {
            throw new Error(loginResult.error)
        }
    }

    // Continiously renew allowed rights on page change when no_user flag is set
    useEffect(() => {
        if (Array.isArray(allowedRights) && allowedRights.length > 0 && allowedRights.includes('no_user')) {
            const fetchAllowedRights = async () => {
                try {
                    // Fetch the allowed rights
                    const fetchedRights = await fetchData({ apiUrl: 'refresh_allowed_rights', handleLogout });

                    // Set the refreshed allowed rights in the context
                    setAllowedRights(fetchedRights);

                    // Encrypt the refreshed rights and put them in the localStorage
                    const encryptedRights = encryptData(JSON.stringify(fetchedRights));
                    localStorage.setItem('allowed_rights', encryptedRights)
                } catch (error: any) {
                    console.error(error);
                }
            };
            fetchAllowedRights();
        }
    }, [location])

    // Set page reloaded flag on page reload
    useEffect(() => {
        setPageReloaded(true);
    }, [])

    // Refresh states from local storage on hard page reload
    useEffect(() => {
        if (currentSession === true) {
            // Refresh the following states from the local storage
            const setStateFunctions = {
                setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage, setEnvironmentType, 
                setEnvironmentStatus, setActiveFeatures, setAccessTo, setAllowedFeatures, setAllowedRights, setRedirectTo,
                setSubscriptionIsTrial, setSubscriptionStartDate, setSubscriptionEndDate, setSubscriptionShowNoPaymentMethodBanner
            };
            refreshStatesFromLocalStorage(setStateFunctions);

            if (pageReloaded) {
                // Refresh the package features from packageFeatures.ts
                const features = FeaturesPerPackage[environmentPackage as keyof typeof FeaturesPerPackage] || [];
                setPackageFeatures(features);
            }

            setStatesLoaded(true);
        }
    }, [setUserHash, setFirstName, setLastName, setEnvironmentHash, setEnvironmentPackage, setPackageFeatures, setEnvironmentType,
        setEnvironmentStatus, setActiveFeatures, setAccessTo, setAllowedFeatures, setAllowedRights, setRedirectTo, 
        setSubscriptionIsTrial, setSubscriptionStartDate, setSubscriptionEndDate, setSubscriptionShowNoPaymentMethodBanner,
        pageReloaded]);

    // Receive inactive changes of the session by websocket
    // useEffect(() => {
    //     let webSocket: WebSocket | null = null;
    //     let shouldReconnect = true;

    //     const setupWebSocket = () => {
    //         if (userHash !== undefined) {
    //             webSocket = new WebSocket(`${websocketBaseUrl}/sessionstatus/${userHash}/`);
    
    //             webSocket.onmessage = (event) => {
    //                 const message = JSON.parse(event.data);

    //                 // If session is expired, logout the user
    //                 if (message.type === 'SESSION_EXPIRED') {
    //                     console.log("Session expired, logging out");
    //                     handleLogout();
    //                     setSessionExpiredFormAlert();
    //                 }
    //             };
    
    //             webSocket.onclose = (event) => {
    //                 if (shouldReconnect) {
    //                     // Try to reconnect in 10 seconds
    //                     setTimeout(setupWebSocket, 10000);
    //                 }
    //             };
    //         }
    //     };

    //     // Listen to the websocket when the check session flag is set
    //     if (currentSession != null) setupWebSocket();

    //     // Clear websocket by unmount
    //     return () => {
    //         shouldReconnect = false;
    //         if (webSocket) {
    //             webSocket.close();
    //         }
    //     };
    // }, [currentSession, userHash, setSessionExpiredFormAlert]);

    // Receive package and features updates by websocket
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            if (environmentHash !== '') {
                webSocket = new WebSocket(`${websocketBaseUrl}/features_update/${environmentHash}/`);
    
                webSocket.onmessage = (event) => {
                    const message = JSON.parse(event.data);

                    if (message.type === 'features_update') {
                        // Update the environment package state and put it encrypted in the local storage
                        setEnvironmentPackage(message.package)
                        const encryptedPackage = encryptData(message.package)
                        localStorage.setItem('package', encryptedPackage);

                        // Update the package included features from the hardcoded packageFeatures.ts list
                        const features = FeaturesPerPackage[message.package as keyof typeof FeaturesPerPackage] || [];
                        setPackageFeatures(features);

                        // Update the active features state and put it encrypted in the local storage
                        const availableFeatures = filterReleasedFeatures(message.active_features)
                        setActiveFeatures(availableFeatures)
                        const encryptedFeatures = encryptData(JSON.stringify(availableFeatures));
                        localStorage.setItem('active_features', encryptedFeatures);
                    }
                };

                webSocket.onclose = () => {
                    if (shouldReconnect) {
                        // Try to reconnect in 10 seconds
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            }
        };

        // Listen to the websocket when the check session flag is set
        if (currentSession != null) setupWebSocket();

        // Clear websocket by unmount
        shouldReconnect = false;
        return () => {
            if (webSocket) {
                webSocket.close();
            }
        };
    }, [currentSession, environmentHash, setEnvironmentPackage, setActiveFeatures]);

    // Receive role updates by websocket
    useEffect(() => {
        let webSocket: WebSocket | null = null;
        let shouldReconnect = true;

        const setupWebSocket = () => {
            if (userHash !== undefined) {
                webSocket = new WebSocket(`${websocketBaseUrl}/role_update/${userHash}/`);

                webSocket.onmessage = (event) => {
                    const message = JSON.parse(event.data);

                    if (message.type === 'role_update') {
                        // Update the access to state and put it encrypted in the local storage
                        setAccessTo(message.access_to)
                        const encryptedAccessTo = encryptData(JSON.stringify(message.access_to));
                        localStorage.setItem('access_to', encryptedAccessTo);

                        // Update the allowed features state and put it encrypted in the local storage
                        const availableFeatures = filterReleasedFeatures(message.allowed_features)
                        setAllowedFeatures(availableFeatures)
                        const encryptedAllowedFeatures = encryptData(JSON.stringify(availableFeatures));
                        localStorage.setItem('allowed_features', encryptedAllowedFeatures);

                        // Update the allowed rights state and put it encrypted in the local storage
                        setAllowedRights(message.allowed_rights)
                        const encryptedAllowedRights = encryptData(JSON.stringify(message.allowed_rights));
                        localStorage.setItem('allowed_rights', encryptedAllowedRights);
                    }
                };
    
                webSocket.onclose = () => {
                    if (shouldReconnect) {
                        // Try to reconnect in 10 seconds
                        setTimeout(setupWebSocket, 10000);
                    }
                };
            }
        };

        // Listen to the websocket when the check session flag is set
        if (currentSession != null) setupWebSocket();

        // Clear websocket by unmount
        shouldReconnect = false;
        return () => {
            if (webSocket) webSocket.close();
        };
    }, [currentSession, userHash, setAccessTo, setAllowedFeatures, setAllowedRights]);

    // Handle the logout (optionally serverside), delete the local storage items and redirect to the login page
    const handleLogout = async ({ logoutFromServer = false, showSessionAlert = false }: { logoutFromServer?: boolean, showSessionAlert?: boolean } = {}) => {
        await logout({ 
            logoutFromServer, 
            showSessionAlert,
            callbacks: { setIsLoggedIn, setRedirectTo, setFormAlert }
        });
    }

    return (
        <AuthenticationContext.Provider 
            value={{ currentSession, statesLoaded, setStatesLoaded, isLoggedIn, setIsLoggedIn, userHash, setUserHash, firstName, 
                setFirstName, lastName, setLastName, environmentHash, setEnvironmentHash, environmentPackage, setEnvironmentPackage,
                environmentType, setEnvironmentType, environmentStatus, setEnvironmentStatus, packageFeatures, setPackageFeatures, 
                activeFeatures, setActiveFeatures, accessTo, setAccessTo, allowedFeatures, setAllowedFeatures, allowedRights, 
                setAllowedRights, handleLogin, handleLogout, redirectTo, setRedirectTo, subscriptionIsTrial, setSubscriptionIsTrial,
                subscriptionStartDate, setSubscriptionStartDate, subscriptionEndDate, setSubscriptionEndDate,
                subscriptionShowNoPaymentMethodBanner, setSubscriptionShowNoPaymentMethodBanner }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

// Create a custom hook for the authentication context
export const useAuthContext = () => {
    return useContext(AuthenticationContext);
}