import React from 'react';
import { BillingData } from '../BillingTypes';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import BillingDetailsForm from './BillingDetailsForm';

interface BillingDetailsCardProps {
    loading: string;
    billingData: BillingData;
    refetchBillingDetails: () => void;
}

const BillingDetailsCard: React.FC<BillingDetailsCardProps> = ({ loading, billingData, refetchBillingDetails }) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();

    // Handle change billing details
    const handleChangeBillingDetails = () => {
        initializeModal(
            <BillingDetailsForm 
                billingData={billingData} 
            />, { 
                title: t('billing.general.edit_billing_details'),
                modalSize: 'small', 
                onSuccess: () => refetchBillingDetails(),
            }
        )
    };

    return (
        <div className='card billing-details'
             onClick={() => handleChangeBillingDetails()}>
            <div className='card-edit-header'>
                <h6>{t('billing.general.billing_details_header')}</h6>
                <span className='edit-icon'>
                    <FontAwesomeIcon 
                        icon={faPen} 
                        onClick={() => handleChangeBillingDetails()}
                    />
                </span>
            </div>
            {loading === 'show-loader' ? (
                <div className="loader"></div>
            ) : loading === 'success' && (
                <div>
                    <div className='field'>
                        <p className='label'>
                        {t('billing.general.company_name_label')}
                        </p>
                        <p>{billingData.company_name}</p>
                    </div>
                    <div className='field'>
                        <div className='label'>
                            {t('billing.general.contact_person_label')}
                        </div>
                        {billingData.first_name || billingData.last_name ? (
                            <div className='value'>
                                {billingData.first_name && (<span>{billingData.first_name}</span>)}
                                {billingData.last_name && (<span> {billingData.last_name}</span>)}
                            </div>
                        ) : (
                            <div className='value'>
                                -
                            </div>
                        )}
                        <div className='value'>
                            {billingData.email && billingData.email !== '' && billingData.email}
                        </div>
                    </div>
                    <div className='field'>
                        <div className='label'>
                            {t('billing.general.invoice_address_header')}
                        </div>

                        {billingData.street && billingData.house_number ? (
                            <div className='value'>
                                <span>{billingData.street}</span>
                                {billingData.house_number && (<span> {billingData.house_number}</span>)}
                            </div>
                        ) : (
                            <div className='value'>
                                -
                            </div>
                        )}

                        {billingData.postal_code && billingData.city && (
                            <div className='value'>
                                {billingData.postal_code && (<span>{billingData.postal_code} </span>)}
                                <span>{billingData.city}</span>
                            </div>
                        )}

                        {billingData.country && (
                            <div className='value'>
                                {billingData.country}
                            </div>
                        )}
                    </div>
                    <div className='field'>
                        <div className='label'>
                            {t('billing.general.invoice_email_short_label')}
                        </div>
                        <div className='value'>
                            {billingData.invoice_email && billingData.invoice_email !== '' ? billingData.invoice_email : '-'}
                        </div>
                    </div>
                    <div className='field'>
                        <div className='label'>
                            {t('billing.general.vat_number_short_label')}
                        </div>
                        <div className='value'>
                            {billingData.vat_number && billingData.vat_number !== '' ? billingData.vat_number : '-'}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default BillingDetailsCard;