import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentPackage, SubscriptionData } from './BillingTypes';
import { featureComparison, packageOptions } from './packageOptions';
import SwitchButton from 'components/buttons/SwitchButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useSettings } from 'services/settings/SettingsContext';
import { capitalizeString } from 'services/utils/capitalizeText';
import { formatDate } from 'services/utils/dateTimeUtils';

interface PackageSelectionProps {
    loading: string;
    currentSubscription: SubscriptionData;
    updatedSubscription: SubscriptionData;
    setUpdatedSubscription: React.Dispatch<React.SetStateAction<SubscriptionData>>;
};

const PackageSelection: React.FC<PackageSelectionProps> = ({ 
    loading, currentSubscription, updatedSubscription, setUpdatedSubscription
}) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();
    const [showComparisonTable, setShowComparisonTable] = useState<boolean>(false);

    // Handle seats change
    const handleSeatsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        // Sta alleen lege input of cijfers toe
        if (value === "" || /^[0-9]+$/.test(value)) {
            setUpdatedSubscription(prev => ({ ...prev, seats: value }))
        }
    };

    // If no seats are entered, fallback to '1'
    const handleSeatsBlur = () => {
        if (!updatedSubscription.seats) {
            setUpdatedSubscription(prev => ({ ...prev, seats: '1' }))
        }
    };

    // Determine if the subscription duration changed
    const configurePendingSubscriptionMessage = (currentSubscription: SubscriptionData) => {

        // If no end date or pending change is given, return null
        if (!currentSubscription.end_date || !currentSubscription.pending_subscription) {
            return null;
        }

        // Set the default message for pending downgrades
        let pendingSubscriptionMessage = t('billing.general.pending_downgrade_message', { 
            currentPackage: capitalizeString(currentSubscription.package),
            currentEndDate: formatDate(currentSubscription.end_date, userLocale, 'writtenMonth'),
            pendingPackage: capitalizeString(currentSubscription.pending_subscription.package)
        })

        // If the duration of the pending subscription differs, show the duration in the message
        if (currentSubscription.duration !== 'trial' && currentSubscription.duration !== currentSubscription.pending_subscription?.duration) {
            pendingSubscriptionMessage = t('billing.general.pending_duration_change_message', { 
                currentDuration: currentSubscription.duration === 'monthly' ? t('billing.general.monthly_label') : t('billing.general.yearly_label'),
                currentPackage: capitalizeString(currentSubscription.package),
                currentEndDate: formatDate(currentSubscription.end_date, userLocale, 'writtenMonth'),
                pendingDuration: currentSubscription.pending_subscription.duration === 'monthly' ? t('billing.general.monthly_label') : t('billing.general.yearly_label'),
                pendingPackage: capitalizeString(currentSubscription.pending_subscription.package)
            })
        }

        return pendingSubscriptionMessage;
    }

    return (
        <div className='wizard-step'>
            <div className='header-title'>
                <h2>{t('billing.general.set_subscription_header')}</h2>
            </div>
            {loading === 'show-loader' ? (
                <div className="loader"></div>
            ) : loading === 'success' && (
                <div className='step-content'>

                    {currentSubscription.pending_subscription && currentSubscription.end_date && (
                        <div className='pending-downgrade-message'>
                            <div className='message-box'>
                                <h6>
                                    {t('billing.general.pending_subscription_title')}
                                </h6>
                                <div>
                                    {configurePendingSubscriptionMessage(currentSubscription)}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='duration-selector'>
                        <span className={`duration-label left-label ${updatedSubscription.duration === 'monthly' ? 'active' : ''}`} 
                                onClick={() => setUpdatedSubscription(prev => ({ ...prev, duration: 'monthly' }) )}>
                            {t('billing.general.monthly_billing_label')}
                        </span>
                        <SwitchButton
                            value={updatedSubscription.duration === 'yearly' ? true : updatedSubscription.duration === 'trial' ? true : false}
                            defaultValue={'on'}
                            onToggle={(isOn) => setUpdatedSubscription(prev => ({ ...prev, duration: isOn ? 'yearly' : 'monthly' }) )}
                        />
                        <span className={`duration-label right-label ${updatedSubscription.duration === 'yearly' ? 'active' : ''}`}
                                onClick={() => setUpdatedSubscription(prev => ({ ...prev, duration: 'yearly' }) )}>
                            {t('billing.general.yearly_billing_label')}
                        </span>
                    </div>

                    <div className='package-options'>
                        {packageOptions.map(option => (
                            <div className={`package-option ${option.package === updatedSubscription.package ? 'selected' : ''} ${option.isBestChoice && 'best-choice'}`}
                                key={option.package}
                                onClick={() => setUpdatedSubscription(prev => ({ ...prev, package: option.package as EnvironmentPackage }) )}>
                                <div className='package-content'>
                                    {option.isBestChoice && (
                                        <div className='best-choice-label'>
                                            {t(`billing.general.best_choice_label`)}
                                        </div>
                                    )}
                                    <h4 className='package-label'>
                                        {t(`billing.package.${option.package}.label`)}
                                    </h4>
                                    <div className='package-explanation'>
                                        {t(`billing.package.${option.package}.explanation_text`)}
                                    </div>
                                    <div className='package-features'>
                                        <div className='included-label'>
                                            {t(`billing.package.${option.package}.included_label`)}
                                        </div>
                                        {option.features.map((feature, index) => (
                                            <div className='included-feature'
                                                    key={index}>
                                                {t(`billing.package.${option.package}.package_feature.${feature}`)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='package-price'>
                                        <div className='price-label'>
                                            <span className='currency-icon'>€ </span>
                                            {updatedSubscription.duration === 'yearly' ? option.yearlyPrice : updatedSubscription.duration === 'trial' ? option.yearlyPrice : option.monthlyPrice}
                                            <span>,-</span>
                                        </div>
                                        <div className='price-unit-label'>
                                            <span>{t('billing.general.per_seat_per_month_label')}, </span>
                                            <span>
                                                {updatedSubscription.duration === 'yearly' ? t('billing.general.yearly_invoiced_label') 
                                                    : updatedSubscription.duration === 'trial' ? t('billing.general.yearly_invoiced_label') 
                                                    : t('billing.general.monthly_invoiced_label')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='seats-selection'>
                        <div className='seats-header'>
                            <h3>
                                {t('billing.general.seats_amount_label')}
                            </h3>
                            <p className='seats-description'>
                                {t('billing.general.seats_amount_description')}
                            </p>
                        </div>
                        <input
                            type='text'
                            value={updatedSubscription.seats}
                            onChange={(e) => handleSeatsChange(e)}
                            onBlur={() => handleSeatsBlur()}
                            onKeyDown={(e) => {if (e.key === "." || e.key === "," || e.key === "e") e.preventDefault()}}  // Prevent entering decimals and exponentials
                            className='seats-input'
                        />
                    </div>

                    <div className='feature-comparison'>
                        <div className='feature-comparison-header'>
                            {showComparisonTable ? (
                                <span className='feature-comparison-label'
                                        onClick={() => setShowComparisonTable(prev => !prev)}>
                                    {t('billing.general.hide_feature_table_label')}
                                </span>
                            ) : (
                                <span className='feature-comparison-label'
                                        onClick={() => setShowComparisonTable(prev => !prev)}>
                                    {t('billing.general.show_feature_table_label')}
                                </span>
                            )}

                            <FontAwesomeIcon
                                icon={showComparisonTable ? faChevronUp : faChevronDown} 
                                className="feature-comparison-toggle-icon"
                            />                                  
                        </div>

                        {showComparisonTable && (
                            <div className="comparison-table">
                                {Object.entries(featureComparison).map(([category, data]) => {
                                    // Calculate the maximum amount of rows for a main feature
                                    const maxRows = Math.max(...Object.values(data.features).map(features => features.length));

                                    return (
                                        <div key={category} 
                                                className="comparison-section">
                                            <div className="comparison-header">
                                                <h4>{t(`billing.feature_comparison.${category}.label`)}</h4>
                                            </div>

                                            <table className="comparison-section-table">
                                                <thead>
                                                    <tr>
                                                        {packageOptions.map(packageOption => (
                                                            <th key={packageOption.package}>
                                                                {t(`billing.package.${packageOption.package}.label`)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(maxRows)].map((_, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {packageOptions.map(packageOption => {
                                                                const featureList = data.features[packageOption.package as keyof typeof data.features] || [];
                                                                const feature = featureList[rowIndex];
                                                                
                                                                return (
                                                                    <td key={packageOption.package}>
                                                                        {feature ? (
                                                                            <span className='tooltip-icon'>
                                                                                {t(`billing.feature_comparison.${category}.features.${feature}.label`)}
                                                                                <span className="tooltip tooltip-bottom">
                                                                                    {t(`billing.feature_comparison.${category}.features.${feature}.explanation`)}
                                                                                </span>
                                                                            </span>
                                                                        ) : "-"}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PackageSelection;