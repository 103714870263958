import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const TimeSheetSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Uren invoer wijze',
            explanationText: 'Bepaal of je de uren wil invoeren op basis van start- en eindtijd, of enkel op basis van duratie.',
            fields: [
                {
                    type: 'tabs',
                    name: 'job_time_registration_mode',
                    buttonSize: 'large',
                    options: [
                        { value: 'start-end', name: 'Start- en eindtijd' },
                        { value: 'duration', name: 'Duratie' },
                    ]
                }
            ]
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default TimeSheetSettings;