import { DragDropEvent } from "services/dragdrop/DragDropContext";
import { EventsByResourceType, EventsListType, EventType, ResourceGroupType, SchedulerDraggableId, SchedulerDroppableId } from "../SchedulingTypes";
import { convertDayKeyAndTimeSlotToEndDateTimeString, convertDayKeyAndTimeSlotToStartDateTimeString, generateTimeKeyFromDateTimeString } from "./schedulingUtils";
import { convertLocalDateTimeToUTC } from "internationalization/timezoneConversions";
import { saveData } from "services/api/saveData";
import { t } from "i18next";

export const handleEventDrag = async (
    dragResult: DragDropEvent<SchedulerDraggableId, SchedulerDroppableId>,
    events: EventsListType,
    resourceGroups: ResourceGroupType[],
    userLocale: string,
    userTimezone: string,
    setFloatingAlert: Function,
    setEvents: React.Dispatch<React.SetStateAction<EventsListType>>,
    setEventsByResource: React.Dispatch<React.SetStateAction<EventsByResourceType>>
) => {

    // Get the values from the drag result
    const { source, destination, draggableId } = dragResult;

    if (!destination) return;

    // Get the source type, destination type and draggable type
    const sourceType = source?.droppableId?.type
    const destinationType = destination.droppableId.type;
    const draggableType = draggableId.type;

    // Handle event drag
    if (draggableType === 'event') {

        // Handle drag to a timeslot
        if (sourceType === 'timeSlot' && destinationType === 'timeSlot') {

            // Get the source values
            const sourceResourceId = source?.droppableId?.resourceId;

            // Get the destination values
            const { resourceId: destinationResourceId, day: destinationDay, timeSlot: destinationTimeSlot } = destination.droppableId;

            if (!sourceResourceId || !draggableId.event.appointmentId) return;

            // Update the current event and appointment
            await updateEventAndUpdateAppointmentAfterDrag(
                draggableId.event,
                resourceGroups,
                events,
                sourceResourceId,
                destinationResourceId,
                destinationDay,
                destinationTimeSlot,
                userLocale,
                userTimezone,
                setEvents,
                setEventsByResource,
                setFloatingAlert
            );
        }

        // Handle drag to a day list
        if (sourceType === 'dayList' && destinationType === 'dayList') {

            // Get the source values
            const sourceResourceId = source?.droppableId?.resourceId;

            // Get the destination values
            const { resourceId: destinationResourceId, day: destinationDay } = destination.droppableId;

            if (!sourceResourceId || !draggableId.event.appointmentId) return;

            // Update the current event and appointment
            await updateEventAndUpdateAppointmentAfterDrag(
                draggableId.event,
                resourceGroups,
                events,
                sourceResourceId,
                destinationResourceId,
                destinationDay,
                draggableId.event.startTime!,
                userLocale,
                userTimezone,
                setEvents,
                setEventsByResource,
                setFloatingAlert
            );
        }
    }
}

// Updates the event and appointment after drag
const updateEventAndUpdateAppointmentAfterDrag = async (
    draggableEvent: EventType,
    resourceGroups: ResourceGroupType[],
    events: EventsListType,
    sourceResourceId: string | number,
    destinationResourceId: string | number,
    destinationDay: string,
    destinationStartTime: string,
    userLocale: string,
    userTimezone: string,
    setEvents: React.Dispatch<React.SetStateAction<EventsListType>>,
    setEventsByResource: React.Dispatch<React.SetStateAction<EventsByResourceType>>,
    setFloatingAlert: Function
) => {
    // Get the original event
    const eventKey = draggableEvent.key;
    const originalEvent = events[eventKey];

    // Delete the event from the former resource
    setEventsByResource(prev => {
        const updated = { ...prev };
        updated[sourceResourceId] = updated[sourceResourceId].filter(key => key !== eventKey);
        return updated;
    });

    // Convert the day keys and timeslots to local datetime objects
    const convertedStartDateTime = convertDayKeyAndTimeSlotToStartDateTimeString(destinationDay, destinationStartTime, userLocale, userTimezone);
    const convertedEndDateTime = convertDayKeyAndTimeSlotToEndDateTimeString(destinationDay, destinationStartTime, originalEvent.durationMinutes, userLocale, userTimezone);

    // Update the event with the new resouce, day and timeslot
    const updatedEvent = {
        ...originalEvent,
        resourceId: destinationResourceId,
        dayKey: destinationDay,
        timeSlot: destinationStartTime,
        startDateTime: convertedStartDateTime,
        endDateTime: convertedEndDateTime,
        startTime: generateTimeKeyFromDateTimeString(convertedStartDateTime),
        endTime: generateTimeKeyFromDateTimeString(convertedEndDateTime)
    };

    // Update the events state
    setEvents(prev => ({
        ...prev,
        [eventKey]: updatedEvent,
    }));

    // Add the event to the new resource state
    setEventsByResource(prev => {
        const newEventsByResource = { ...prev };

        // Create a new array for the destination resource if it doesn't exists
        if (!newEventsByResource[destinationResourceId]) {
            newEventsByResource[destinationResourceId] = [];
        }

        // Add the event key to the resource
        newEventsByResource[destinationResourceId].push(eventKey);

        return newEventsByResource;
    });

    // Convert the local datetimes to utc datetimes
    const utcStartDateTime = convertLocalDateTimeToUTC(convertedStartDateTime, userLocale, userTimezone);
    const utcEndDateTime = convertLocalDateTimeToUTC(convertedEndDateTime, userLocale, userTimezone);

    // Configure the submit data for the appointment
    const updatedAppointment = {
        id: originalEvent.appointmentId,
        uuid: null,
        job: originalEvent.job?.id,
        new_resource: destinationResourceId,
        timezone: userTimezone,
        start_datetime: utcStartDateTime,
        end_datetime: utcEndDateTime,
    }

    // Save the updated job details
    const response = await saveData({
        apiUrl: `patch_appointment/${draggableEvent.appointmentId}`,
        method: 'patch',
        data: updatedAppointment
    });

    // Find the name of the resource based on the destination resource id
    const findResourceName = (resourceId: number | string): string | null => {
        for (const group of resourceGroups) {
            const resource = group.resources.find(res => res.id === resourceId);
            if (resource) {
                return resource.name
            }
        }

        return '';
    };

    // Show floating alert on success
    if (response?.status === 200) {
        setFloatingAlert({
            type: 'success',
            message: t('scheduling.scheduling_board.appointment_dragged_to_resource_alert', { resource_name: findResourceName(destinationResourceId), date: destinationDay, time: destinationStartTime })
        });
    }
}