import React, { useContext, useState, useEffect } from 'react';
import { TabsFieldType, FieldOptionFetchResponse, FieldData, FieldOption } from 'types/FieldTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useGlobalContext } from 'GlobalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMapping } from 'services/utils/iconMapping';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

/*
 * TabsField.tsx
 * The tabsfield component renders fields of type 'tabs'. A tab field is a field where you see several 
 * options as tabs and where you can choose a tab. The options can be defined in a number of different ways: 
 * 1) By specifying hardcoded options in the field configuration. This is then passed to the component using 
 * the prop options. 2) By getting the options from the provided apiObject in the field configuration file. 
 * These options are then usually retrieved in a parent component and passed through the prop dropdownData, 
 * and 3) if no options are passed with the props options or dropdownData, a hard-coded boolean choice is set 
 * by default. It is also possible to choose in the TabsField whether labels are shown in the tabs, icons with 
 * labels, or just icons.
 */

interface TabsFieldProps extends TabsFieldType {
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    onClick?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    viewInEditMode?: boolean;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    errorMessage?: string | string[] | undefined;
    showIconOnly?: boolean;
}

const TabsField: React.FC<TabsFieldProps & { data?: FieldData, viewKey: string }> = ({  
    viewKey, name, label, data, defaultValue, helperText, tooltipText, apiObject, options, dropdownData, viewInEditMode, 
    showIconOnly, buttonSize = 'normal', onChange, disabled, alignment, saveOnBlur, isEditable, fullWidth = false, customClass
}) => {
    const { t } = useTranslation();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { packageFeatures } = useAuthContext();
    const { setUnsavedChanges } = useGlobalContext();
    const [value, setValue] = useState<string | number | boolean | undefined>(data?.name);
    const [selectedOption, setSelectedOption] = useState<FieldOption | undefined>();
    const [buttonOptions, setButtonOptions] = useState<FieldOption[]>([]);

    // Set tabs options from the fetched dropdown data array
    useEffect(() => {
        let tabsOptions: FieldOption[] = [];
        if (options) {
            // Use hardcoded options as options
            tabsOptions = options;
        } else if (apiObject && dropdownData && dropdownData[apiObject]) {
            // Use fetched options from api
            tabsOptions = dropdownData[apiObject].results
        } else {
            // If no hardcoded options are given and no options are fetched by api, use these default boolean options
            tabsOptions = [
                // { id: 1, value: true, name: t("forms.true_value") },
                // { id: 0, value: false, name: t("forms.false_value") },
                { value: true, name: t("forms.true_value") },
                { value: false, name: t("forms.false_value") },
            ];
        }

        // Checks if tabsOptions contains an ordering field to order the data
        const hasOrderingField = tabsOptions.some(item => item.hasOwnProperty('ordering'));
        if (hasOrderingField) {
            tabsOptions.sort((a, b) => (a.ordering || 0) - (b.ordering || 0));
        } 

        setButtonOptions(tabsOptions);
    }, [dropdownData, apiObject, data, name, options])

    // Set the current value of the form (if it exists)
    useEffect(() => {
        let newValue: string | undefined;
        let newSelectedOption: FieldOption | undefined;
    
        // Only find the current value if there is current data
        if (data && data[name] !== undefined) {
            // Find the current value in case no options are given (pick the default boolean value)
            if (buttonOptions.every((option: FieldOption) => typeof option.value === 'boolean')) {
                newValue = String(data?.[name]);
                newSelectedOption = buttonOptions.find((option: FieldOption) => String(option.value) === newValue);
            // Find the current value in case the options are passed directly from the parent AddEditForm component (it only gives strings)
            } else if (typeof data === 'string') {
                newValue = data;
                newSelectedOption = buttonOptions.find((option: FieldOption) => option.value === data);
            // Find the current value in case the options are passed directly from the parent LiveEditField component (it only gives an object)
            } else if (typeof data === 'object' && Object.keys(data).length > 0) {
                if (value === undefined) {
                    // Only execute this code on the first render when the user hasn't selected a new value (the value is undefined)
                    newValue = data[name];
                    newSelectedOption = buttonOptions.find((option: FieldOption) => option.id === data[name]);
                }                
            // Find the current value in case the options are passed as dropdownData from the parent component
            } else if (apiObject && dropdownData && dropdownData[apiObject]) {
                newValue = data?.[name];
                if (typeof newValue !== 'undefined') {
                    newSelectedOption = buttonOptions.find((option: FieldOption) => option.value === newValue);
                }
            }
        }
        
        // Set the found value as the current value
        if (newValue !== null && newValue !== undefined && newValue.toString() !== value) {
            if (apiObject && dropdownData && dropdownData[apiObject]) {
                if (!data || !(name in data)) {
                    setValue(undefined);
                } else {
                    setValue(newValue);
                }
            } else {
                setValue(newValue);
            }
        // Set the defaultValue if it exists and there is no current value
        } else if (defaultValue !== undefined && value === undefined) {
            setValue(defaultValue);
            newSelectedOption = buttonOptions.find((option: FieldOption) => option.id === defaultValue || option.value === defaultValue);
        }
    
        if (newSelectedOption) {
            setSelectedOption(newSelectedOption);
        }
    }, [data, name, options, buttonOptions]);

    // If there is a defaultValue, add it to the updatedData so that it is included when saving the field
    useEffect(() => {
        if (defaultValue !== undefined && value === undefined) {
            setUpdatedData({ ...updatedData, [name]: defaultValue });
        }
    }, [defaultValue]);

    // Handle the tabs change
    const handleTabsChange = (event: React.MouseEvent<HTMLButtonElement>, newValue: number | string | boolean) => {
        setValue(newValue);
        const newSelectedOption = buttonOptions.find((option: FieldOption) => {
            const optionValue = option.value;
            return optionValue === newValue || option.id === newValue
        });
        if (newSelectedOption) {
            setSelectedOption(newSelectedOption);
        }

        // Set updated data and unsaved changes flag
        setUnsavedChanges(viewKey, newValue !== data?.[name]);
        setUpdatedData({...updatedData, [name]: newValue });

        // Create a fake event to plug in the selected value
        const fakeEvent = {
            target: {
                name: name,
                value: newValue,
            },
        } as React.ChangeEvent<HTMLInputElement>;

        // Call the onChange function with the fake event
        if (onChange) {
            onChange(fakeEvent);
        }

        // Handle submit on field change if the save on blur prop is given
        if (saveOnBlur && handleSubmit) {
            handleSubmit({...updatedData, [name]: newValue });
        }
    };

    // Format the field value
    const fieldValue = selectedOption && selectedOption.name ? selectedOption.name : '';

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className={`tabs-field ${buttonSize} ${showIconOnly ? 'icon-only' : ''}`}>
                    {buttonOptions.map((option: FieldOption) => {

                        // Determine which button is active
                        let isActive: boolean;
                        if (apiObject && dropdownData && dropdownData[apiObject] && !data) {
                            // In case the value is given via dropdownData and apiObject props
                            isActive = value !== undefined && option.value !== undefined && (String(value) === String(option.id) || String(value) === String(option.value));
                        } else {
                            // In case the value is given via options props or set as hardcoded boolean values
                            isActive = (value !== undefined && (String(t(value)) === String(option.id) || String(value) === String(option.value)));
                        }

                        // Determine if the button is disabled when the optionally required feature is not included in the environment package
                        const disabledByRequiredFeature = option.requiredFeature && !packageFeatures.includes(option.requiredFeature)

                        return (
                            <button key={option.id}
                                    disabled={disabledByRequiredFeature}
                                    className={`${customClass ? customClass : ''} ${option.value} ${option.icon && showIconOnly ? 'tooltip-icon' : 'icon-text'} ${disabledByRequiredFeature ? 'tooltip-icon' : ''} ${isActive ? 'active' : ''} ${fullWidth ? 'stretched' : ''}`}
                                    onClick={(event) => {
                                    event.preventDefault();
                                    if (option.id !== undefined) {
                                        // Handle tabs change for fetched options
                                        handleTabsChange(event, option.id)
                                    } else {
                                        // Handle tabs change for hardcoded options or default options
                                        handleTabsChange(event, option.value);
                                    }}}>
                                {/* If an icon is given, show the icon */}
                                {option.icon && 
                                    <>
                                        <FontAwesomeIcon icon={iconMapping(option.icon)} className='tab-option-icon' />
                                        {/* If only the icon should be shown, give the name in a tooltip */}
                                        {showIconOnly &&
                                            <span className="tooltip">{t(option.name)}</span>
                                        }
                                    </>
                                }
                                {/* Show the name if it's not a showIconOnly button */}
                                {!showIconOnly && 
                                    t(option.name)
                                }
                                {/* Show a tooltip above the button if it's not available in the environments package */}
                                {disabledByRequiredFeature &&
                                    <span className="tooltip">{t('billing.option_not_available_tooltip')}</span>
                                }
                            </button>
                        );
                    })}
                </div>
            ) : (
                // View mode
                <FieldViewMode
                    value={fieldValue} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default TabsField;