import React, { ReactElement } from 'react';
import DropdownButton from 'components/buttons/DropdownButton';
import IconButton from 'components/buttons/IconButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { roleSpecificFeatures } from 'services/permissions/roleSpecificFeatures';
import { HeaderButtonAction, DropdownButtonItem, GroupedHeaderButtonItem, HeaderButtons, SingleHeaderButton } from 'types/ButtonTypes';
import ScheduleJobModal from '../../../views/jobs/ScheduleJobModal';
import { ModalProps } from '../ModalContext';
import { handleUpdateValue } from './handleUpdateValue';
import { handleDownloadPdf } from './handleDownloadPdf';
import { FieldOptionFetchResponse } from 'types/FieldTypes';
import DeleteModal from '../DeleteModal';
import { History } from 'history';
import { saveData } from 'services/api/saveData';
import { AxiosError } from 'axios';
import { convertLocalDateTimeToUTC } from 'internationalization/timezoneConversions';
import { handleCreateInvoice } from './handleCreateInvoice';
import { handleCreateJob } from './handleCreateJob';
import { handleRegisterPayment } from './handleRegisterPayment';
import { handleCreateOrder } from './handleCreateOrder';

/*
 * generateHeaderButtons.tsx
 * Generate the header buttons for detail modals based on the modal
 * configuration. It maps the given button types to its button components
 * and maps their action strings to the onclick actions. It checks if the
 * button should be shown for the user based on if the feature is active
 * and allowed in its role and if the user has the proper rights. If a 
 * condition is given with the button, it only renders the button if the
 * data corresponds to the specified values in the condition. In this
 * way, different statuses or properties can contain different buttons.
 */

const generateHeaderButtons = (
    headerButtons: HeaderButtons,
    apiObject: string, 
    data: any,
    dropdownData: Record<string, FieldOptionFetchResponse> | undefined,
    itemId: number,
    initializeModal: (content: ReactElement<any>, props?: ModalProps) => void,
    buttonLoader: Record<HeaderButtonAction, boolean>,
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
    setFloatingAlert: Function,
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>,
    { activeFeatures, allowedFeatures, allowedRights }: 
    { activeFeatures: string[], allowedFeatures: string[], allowedRights: string[] },
    userTimezone: string,
    userLocale: string,
    t: (key: string, options?: any) => string,
    closeModal?: () => void,
    fetchData?: () => void,
    refetchList?: () => void,
    history?: History,
): React.ReactNode => {

    // Map button types to the button components
    const buttonTypeToComponent = {
        primary: PrimaryButton,
        secondary: SecondaryButton,
        icon: IconButton
    };

    // Map button actions to onClick functions
    const buttonActionToOnClick = (
        action: HeaderButtonAction,
        button: SingleHeaderButton | GroupedHeaderButtonItem, 
        data: any,
        t: (key: string, options?: any) => string,
        buttonListItemId?: number,
        objectType?: string,
        actionValue?: string,
    ): (() => void) => {
        const actionMap: Record<HeaderButtonAction, () => void> = {
            editform: () => {
                if (button.actionComponent && React.isValidElement(button.actionComponent)) {
                    initializeModal(React.cloneElement(button.actionComponent, { itemId } as any), { itemId });
                }
            },
            download_pdf: () => {
                if (objectType) {
                    handleDownloadPdf({ itemId, buttonAction: action, data, t, setButtonLoader, objectType, layoutId: buttonListItemId, handleLogout })
                };
            },
            duplicate: () => console.log(`${action} clicked`),
            convert_proposal: () => console.log(`${action} clicked`),
            convert_project: () => console.log(`${action} clicked`),
            delete: () => initializeModal(
                <DeleteModal 
                    patchToServer={{ apiObject, itemId: itemId.toString() }} 
                    onConfirmDelete={() => {
                        // Handle deletion of a modal item
                        if (button.onSuccess === 'closeModal' && closeModal) {
                            // Close the modal
                            closeModal();

                            // Optionally refetch the parential list
                            if (refetchList) refetchList();
                        }

                        // Handle deletion of a page object
                        if (button.onSuccess === 'redirect' && button.redirectTo && history) {
                            // Redirect to the given redirect
                            history.push(button.redirectTo);
                        }

                    }}/>, { modalSize: 'extra-small' }
            ),
            deactivate: () => initializeModal(
                <DeleteModal 
                    patchToServer={{ apiObject, itemId: itemId.toString() }} 
                    deletionType='general.deactivate'
                    onConfirmDelete={() => {
                        // Handle deletion of a modal item
                        if (button.onSuccess === 'closeModal' && closeModal) {
                            // Close the modal
                            closeModal();

                            // Optionally refetch the parential list
                            if (refetchList) refetchList();
                        }

                        // Handle deletion of a page object
                        if (button.onSuccess === 'redirect' && button.redirectTo && history) {
                            // Redirect to the given redirect
                            history.push(button.redirectTo);
                        }

                    }}/>, { modalSize: 'extra-small' }
            ),
            merge: () => console.log(`${action} clicked`),
            add_job: () => {
                
            },
            create_order: () => {
                handleCreateOrder({
                    itemId,
                    initializeModal,
                    setFloatingAlert,
                    setButtonLoader,
                    buttonAction: action,
                    userLocale,
                    userTimezone,
                    workflowId: buttonListItemId,
                })
            },
            create_invoice: () => {
                handleCreateInvoice({
                    itemId,
                    initializeModal,
                    setFloatingAlert,
                    setButtonLoader,
                    buttonAction: action,
                    workflowId: buttonListItemId,
                })
            },
            create_credit_invoice: () => {

            },
            create_job: () => {
                handleCreateJob({
                    itemId,
                    initializeModal,
                    setFloatingAlert,
                    setButtonLoader,
                    buttonAction: action,
                    workflowId: buttonListItemId,
                })
            },
            send_invoice: () => {
                const sendInvoice = async () => {
                    try {
                        // Convert the current date time into utc based on the timezone of the user
                        const currentDateTime = new Date();
                        const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

                        // Send the request
                        const response = await saveData({ 
                            apiUrl: `send_invoice/${itemId}`, 
                            method: 'patch', 
                            data: { invoice_date: utcDateTime } 
                        });

                        if (response && response.status === 200) {
                            // Show success alert
                            setFloatingAlert({
                                type: 'success',
                                message: response.data ,
                            });

                            // Refresh the modal data
                            if (fetchData) {
                                await fetchData();
                            }
                        }
                    } catch (error) {
                        // Handle backend errors
                        const axiosError = error as AxiosError;
                        if (axiosError.response) {
                            const errorMessage = axiosError.response.data

                            // Show floating alert with warning
                            setFloatingAlert({
                                type: 'danger',
                                message: errorMessage || 'validation.invoice.sending_failed',
                            });
                        }
                    }
                }
                sendInvoice();
            },
            send_invoice_reminder: () => console.log(`${action} clicked`),
            register_payment: () => handleRegisterPayment({ invoiceId: itemId, t, initializeModal, fetchData}),
            create_purchase: () => console.log(`${action} clicked`),
            send_proposal: () => {
                const sendProposal = async () => {
                    try {
                        // Convert the current date time into utc based on the timezone of the user
                        const currentDateTime = new Date();
                        const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

                        // Send the request
                        const response = await saveData({ 
                            apiUrl: `send_proposal/${itemId}`, 
                            method: 'patch', 
                            data: { proposal_date: utcDateTime } 
                        });

                        if (response && response.status === 200) {
                            // Show success alert
                            setFloatingAlert({
                                type: 'success',
                                message: response.data ,
                            });

                            // Refresh the modal data
                            if (fetchData) {
                                await fetchData();
                            }
                        }
                    } catch (error) {
                        // Handle backend errors
                        const axiosError = error as AxiosError;
                        if (axiosError.response) {
                            const errorMessage = axiosError.response.data

                            // Show floating alert with warning
                            setFloatingAlert({
                                type: 'danger',
                                message: errorMessage || 'validation.proposal.sending_failed',
                            });
                        }
                    }
                }
                sendProposal();
            },
            // TODO: refactor proposal won and proposal lost functions to one function
            proposal_accepted: () => {
                const proposalWon = async () => {
                    try {
                        // Convert the current date time into utc based on the timezone of the user
                        const currentDateTime = new Date();
                        const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

                        // Send the request
                        const response = await saveData({ 
                            apiUrl: `proposal_accepted/${itemId}`, 
                            method: 'patch', 
                            data: { accept_date: utcDateTime } 
                        });

                        if (response && response.status === 200) {
                            // Show success alert
                            setFloatingAlert({
                                type: 'success',
                                message: response.data ,
                            });

                            // Refresh the modal data
                            if (fetchData) {
                                await fetchData();
                            }
                        }
                    } catch (error) {
                        // Handle backend errors
                        const axiosError = error as AxiosError;
                        if (axiosError.response) {
                            const errorMessage = axiosError.response.data

                            // Show floating alert with warning
                            setFloatingAlert({
                                type: 'danger',
                                message: errorMessage || 'validation.proposal.update_failed',
                            });
                        }
                    }
                }
                proposalWon();
            },
            proposal_rejected: () => {
                const proposalLost = async () => {
                    try {
                        // Send the request
                        const response = await saveData({ 
                            apiUrl: `proposal_rejected/${itemId}`, 
                            method: 'patch', 
                            data: { } 
                        });

                        if (response && response.status === 200) {
                            // Show success alert
                            setFloatingAlert({
                                type: 'success',
                                message: response.data ,
                            });

                            // Refresh the modal data
                            if (fetchData) {
                                await fetchData();
                            }
                        }
                    } catch (error) {
                        // Handle backend errors
                        const axiosError = error as AxiosError;
                        if (axiosError.response) {
                            const errorMessage = axiosError.response.data

                            // Show floating alert with warning
                            setFloatingAlert({
                                type: 'danger',
                                message: errorMessage || 'validation.proposal.update_failed',
                            });
                        }
                    }
                }
                proposalLost();
            },
            reopen_proposal: async () => {
                try {
                    // Send the request
                    const response = await saveData({ 
                        apiUrl: `patch_proposal/${itemId}`, 
                        method: 'patch', 
                        data: { status: 'sent' } 
                    });

                    if (response && response.status === 200) {
                        // Show success alert
                        setFloatingAlert({
                            type: 'success',
                            message: response.data ,
                        });

                        // Refresh the modal data
                        if (fetchData) {
                            await fetchData();
                        }
                    }
                } catch (error) {
                    // Handle backend errors
                    const axiosError = error as AxiosError;
                    if (axiosError.response) {
                        const errorMessage = axiosError.response.data

                        // Show floating alert with warning
                        setFloatingAlert({
                            type: 'danger',
                            message: errorMessage || 'validation.proposal.update_failed',
                        });
                    }
                }
            },
            deal_change_resolution: async () => {
                try {
                    // Convert the current date time into utc based on the timezone of the user
                    const currentDateTime = new Date();
                    const utcDateTime = convertLocalDateTimeToUTC(currentDateTime, userLocale, userTimezone);

                    // Send the request
                    const response = await saveData({ 
                        apiUrl: `patch_deal/${itemId}`, 
                        method: 'patch', 
                        data: { 
                            resolution: actionValue,
                            close_date: utcDateTime
                        } 
                    });

                    if (response && response.status === 200) {
                        // Show success alert
                        setFloatingAlert({
                            type: 'success',
                            message: `validation.deal.deal_${actionValue}_successfully`,
                        });

                        // Refresh the page data
                        if (fetchData) {
                            await fetchData();
                        }
                    }
                } catch (error) {
                    // Handle backend errors
                    console.error(error)
                }
            },

            deal_reopen: async () => {
                try {
                    // Send the request
                    const response = await saveData({ 
                        apiUrl: `patch_deal/${itemId}`, 
                        method: 'patch', 
                        data: { 
                            resolution: 'open',
                            close_date: null,
                            lost_reason: null,
                        } 
                    });

                    if (response && response.status === 200) {
                        // Show success alert
                        setFloatingAlert({
                            type: 'success',
                            message: 'validation.deal.deal_reopened_successfully',
                        });

                        // Refresh the page data
                        if (fetchData) {
                            await fetchData();
                        }
                    }
                } catch (error) {
                    // Handle backend errors
                    console.error(error)
                }
            },

            send_email: () => console.log(`${action} clicked`),
            send_email_sms: () => console.log(`${action} clicked`),
            schedule: () => {
                initializeModal(
                    <ScheduleJobModal 
                        job={data} 
                        setLoading={(isLoading) => setButtonLoader(prev => ({ ...prev, [action]: isLoading }))} 
                    />, 
                    { 
                        modalSize: 'medium',
                        onSuccess: () => {if (fetchData) fetchData()}
                    }
                )
            },
            reopen: () => handleUpdateValue({
                apiObject, itemId, apiField: 'resolution', updateValue: 'completed', buttonAction: action, setButtonLoader, setFloatingAlert, fetchData
            }),
            verify: () => handleUpdateValue({
                apiObject, itemId, apiField: 'resolution', updateValue: 'finished', buttonAction: action, setButtonLoader, setFloatingAlert, fetchData
            }),
        };

        return actionMap[action];
    }

    // Check if the given feature of the button is active and allowed for the user
    const isFeatureAllowed = (allowedFeature?: string[]): boolean => {

        // When no allowed feature is given with the button, show it always
        if (!allowedFeature || allowedFeature.length === 0) return true;

        // Check the given allowed features of the button to see if they are allowed
        for (const feature of allowedFeature) {

            // Check if the feature is active in the environment of the user and may belong to a role
            const isActive = activeFeatures.includes(feature);
            const isRoleSpecific = roleSpecificFeatures.includes(feature);

            // If the feature may belong to a role, it must be active and belong to the role of the user
            if (isRoleSpecific && (!isActive || !allowedFeatures.includes(feature))) {
                return false;

            // If the feature may not belong to a role, it must be active
            } else if (!isRoleSpecific && !isActive) {
                return false
            }
        }

        // If every allowed feature given with the button is active, return true
        return true;
    }

    // Check if the user is allowed to see the button based on its rights
    const isRightAllowed = (allowedRight?: string[]): boolean => {

        // If no allowed right is given with the button, show it always
        if (!allowedRight || allowedRight.length === 0) return true;

        // Check if the user has all given allowed rights, otherwise return false
        for (const right of allowedRight) {
            if (!allowedRights.includes(right)) {
                return false;
            }
        }

        // If every right is allowed for the user, the button must be active
        return true;
    }

    // Check if the user is restricted to see the button based on its rights
    const isRightRestricted = (restrictedRight?: string[]): boolean => {

        // If no restricted rights are given with the button, the button is not restricted
        if (!restrictedRight || restrictedRight.length === 0) return false;

        // Check if the user has one of the restricted rights, then the button is restricted
        for (const right of restrictedRight) {
            if (allowedRights.includes(right)) {
                return true;
            }
        }

        // If no restricted rights are found in the user, the button is not restricted
        return false;
    }

    // Check if the button should show
    const shouldShowButton = (button: SingleHeaderButton | GroupedHeaderButtonItem, data: any): boolean => {

        // Check if the conditions of the button are met
        if (button.condition) {
            for (const cond of button.condition) {
                const fieldValue = data[cond.field];

                if (cond.values && !cond.values.includes(fieldValue)) {
                    return false;
                }

                if (cond.notValues && cond.notValues.includes(fieldValue)) {
                    return false;
                }
            }
        }

        // Check feature and right restrictions
        if (!isFeatureAllowed(button.allowedFeature) || isRightRestricted(button.restrictedRight) || !isRightAllowed(button.allowedRight)) {
            return false;
        }

        // Show button if all conditions are met
        return true;
    }

    // Render buttons based on their conditions
    const renderButtons = (buttons: HeaderButtons, data: any): React.ReactNode[] => {
        return buttons.flatMap((button, index): React.ReactNode => {

            // Render download pdf buttons
            if ('action' in button && button.action === 'download_pdf' && dropdownData && button.query?.object && dropdownData[button.query.object]) {

                // Get the fetched pdf layouts from the dropdown data
                const fetchedPdfLayouts = dropdownData[button.query.object];

                // Convert the fetched pdf layouts into an array
                const pdfLayouts = Array.isArray(fetchedPdfLayouts) ? fetchedPdfLayouts : [];

                // Sort the pdf layouts
                pdfLayouts.sort((a, b) => {
                    // Sort on ordering
                    if (a.ordering !== b.ordering) {
                        return a.ordering - b.ordering;
                    }

                    // Sorts by name in alphabetical order if no ordering is set
                    return a.name.localeCompare(b.name);
                });

                if (pdfLayouts.length === 1) {
                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    // Determine the right button component
                    const ButtonComponent = buttonTypeToComponent[button.type];

                    return (
                        <ButtonComponent 
                            key={index} 
                            size={button.size || 'small'}
                            label={button.label}
                            onClick={buttonActionToOnClick(button.action, button, data, t, pdfLayouts[0].id, button.objectType, undefined)}
                            loading={buttonLoader[button.action]}
                            customClass={button.customClass}
                        />
                    );
                } else if (pdfLayouts.length > 1) {
                    const dropdownItems = pdfLayouts.map((layout) => ({
                        label: `Download ${layout.name.toLowerCase()}`,
                        onClick: buttonActionToOnClick(button.action, button, data, t, layout.id, button.objectType, undefined),
                    }));

                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size || 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }

            // Render create invoice buttons
            } else if ('action' in button && button.action === 'create_invoice' && dropdownData && dropdownData['invoice_workflow']) {

                // Get the fetched pdf layouts from the dropdown data
                const fetchedInvoiceTypes = dropdownData['invoice_workflow'];

                // Convert the fetched invoice types into an array
                const invoiceTypes = Array.isArray(fetchedInvoiceTypes) ? fetchedInvoiceTypes : [];

                // Sort the invoice types
                invoiceTypes.sort((a, b) => {
                    // Sort on ordering
                    if (a.ordering !== b.ordering) {
                        return a.ordering - b.ordering;
                    }

                    // Sorts by name in alphabetical order if no ordering is set
                    return a.name.localeCompare(b.name);
                });

                if (invoiceTypes.length === 1) {
                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    // Determine the right button component
                    const ButtonComponent = buttonTypeToComponent[button.type];

                    return (
                        <ButtonComponent 
                            key={index} 
                            size={button.size || 'small'}
                            label={button.label}
                            onClick={buttonActionToOnClick(button.action, button, data, t, invoiceTypes[0].id, undefined)}
                            loading={buttonLoader[button.action]}
                            customClass={button.customClass}
                        />
                    );
                } else if (invoiceTypes.length > 1) {
                    const dropdownItems = invoiceTypes.map((workflow) => ({
                        label: `${t('general.object_name_capitalize', { object_name: t(`invoice.general.object_name.singular`)})} ${workflow.name.toLowerCase()}`,
                        onClick: buttonActionToOnClick(button.action, button, data, t, workflow.id, undefined),
                    }));

                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size || 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }
            
            // Render create order buttons
            } else if ('action' in button && button.action === 'create_order' && dropdownData && dropdownData['order_workflow']) {

                // Get the fetched pdf layouts from the dropdown data
                const fetchedOrderTypes = dropdownData['order_workflow'];

                // Convert the fetched order types into an array
                const orderTypes = Array.isArray(fetchedOrderTypes) ? fetchedOrderTypes : [];

                // Sort the order types
                orderTypes.sort((a, b) => {
                    // Sort on ordering
                    if (a.ordering !== b.ordering) {
                        return a.ordering - b.ordering;
                    }

                    // Sorts by name in alphabetical order if no ordering is set
                    return a.name.localeCompare(b.name);
                });

                if (orderTypes.length === 1) {
                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    // Determine the right button component
                    const ButtonComponent = buttonTypeToComponent[button.type];

                    return (
                        <ButtonComponent 
                            key={index} 
                            size={button.size || 'small'}
                            label={button.label}
                            onClick={buttonActionToOnClick(button.action, button, data, t, orderTypes[0].id, undefined)}
                            loading={buttonLoader[button.action]}
                            customClass={button.customClass}
                        />
                    );
                } else if (orderTypes.length > 1) {
                    const dropdownItems = orderTypes.map((workflow) => ({
                        label: `${t('general.object_name_capitalize', { object_name: t(`order.general.object_name.singular`)})} ${workflow.name.toLowerCase()}`,
                        onClick: buttonActionToOnClick(button.action, button, data, t, workflow.id, undefined),
                    }));

                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size || 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }

            // Render create job buttons
            } else if ('action' in button && button.action === 'create_job' && dropdownData && dropdownData['job_workflow']) {

                // Get the fetched pdf layouts from the dropdown data
                const fetchedJobTypes = dropdownData['job_workflow'];

                // Convert the fetched job types into an array
                const jobTypes = Array.isArray(fetchedJobTypes) ? fetchedJobTypes : [];

                // Sort the order types
                jobTypes.sort((a, b) => {
                    // Sort on ordering
                    if (a.ordering !== b.ordering) {
                        return a.ordering - b.ordering;
                    }

                    // Sorts by name in alphabetical order if no ordering is set
                    return a.name.localeCompare(b.name);
                });

                if (jobTypes.length === 1) {
                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    // Determine the right button component
                    const ButtonComponent = buttonTypeToComponent[button.type];

                    return (
                        <ButtonComponent 
                            key={index} 
                            size={button.size || 'small'}
                            label={button.label}
                            onClick={buttonActionToOnClick(button.action, button, data, t, jobTypes[0].id, undefined)}
                            loading={buttonLoader[button.action]}
                            customClass={button.customClass}
                        />
                    );
                } else if (jobTypes.length > 1) {
                    const dropdownItems = jobTypes.map((workflow) => ({
                        label: `${t('general.object_name_capitalize', { object_name: t(`job.general.object_name.singular`)})} ${workflow.name.toLowerCase()}`,
                        onClick: buttonActionToOnClick(button.action, button, data, t, workflow.id, undefined),
                    }));

                    // Skip button if it should not be shown
                    if (!shouldShowButton(button, data)) return null;

                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size || 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }

            // Render dropdown buttons
            } else if (button.type === 'dropdown') {
                const dropdownItems = button.buttons.flatMap((subButton): DropdownButtonItem[] => {
                    // Determine for every grouped button if it should be shown
                    if (shouldShowButton(subButton, data)) {
                        return [{
                            label: subButton.label,
                            onClick: buttonActionToOnClick(subButton.action, subButton, data, t)
                        }]
                    }
                    return [];
                }).filter(Boolean);

                // Render the dropdown button
                if (dropdownItems.length > 0) {
                    return (
                        <DropdownButton 
                            key={`dropdown-${index}`} 
                            size={button.size ? button.size : 'small'}
                            label={button.label} 
                            items={dropdownItems} 
                            customClass={button.customClass} 
                        />
                    );
                }
                
            // Render other buttons
            } else {
                // Skip button if it should not be shown
                if (!shouldShowButton(button, data)) return null;

                // Determine the right button component
                const ButtonComponent = buttonTypeToComponent[button.type];

                // Determine the right onclick handler
                const onClickHandler = buttonActionToOnClick(button.action, button, data, t, itemId, button.objectType, button.actionValue);

                // Render the button component
                return (
                    <ButtonComponent 
                        key={index} 
                        size={button.size ? button.size : 'small'} 
                        tooltipText={button.tooltipText}
                        label={button.label} 
                        icon={button.icon}
                        onClick={onClickHandler} 
                        loading={buttonLoader[button.action]}
                        customClass={button.customClass} 
                    />
                );
            }
        }).filter(Boolean)
    }

    // Call the render buttons function
    const generatedButtons = renderButtons(headerButtons, data);

    // Return the generated buttons or null
    return generatedButtons.length > 0 ? generatedButtons : null;
};

export default generateHeaderButtons;