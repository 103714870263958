// Capitalizes a string
export const capitalizeString = (value: string) => {

    // Return if no value is given
    if (!value) {
        return ""
    };

    // Capitalize the first letter of the first word
    const capitalizedString = value.charAt(0).toUpperCase() + value.slice(1);

    return capitalizedString;
}