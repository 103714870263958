import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import DateNavigationButton from 'components/buttons/DateNavigationButton';
import IconButton from 'components/buttons/IconButton';
import DropdownButton from 'components/buttons/DropdownButton';
import JobForm from '../../views/jobs/JobForm';
import { generateDisplayDays, navigatePeriod } from './functions/schedulingUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faTablePivot } from '@fortawesome/pro-solid-svg-icons';
import { SchedulingViewType } from './SchedulingTypes';
import TabsButton from 'components/buttons/TabsButton';
import { saveData } from 'services/api/saveData';
import JobModal from '../../views/jobs/JobModal';

interface SchedulingNavigationProps {
    days: Date[];
    view: SchedulingViewType;
    showInbox: boolean;
    onViewChange: (view: SchedulingViewType) => void;
    onSetRenderTimeSlots: (viewType: string) => void;
    onSetShowInbox: (showInbox: boolean) => void;
    onSetDays: (days: Date[]) => void;
}

const SchedulingNavigation: React.FC<SchedulingNavigationProps> = ({ 
    days, view, showInbox, onViewChange, onSetRenderTimeSlots, onSetShowInbox, onSetDays
 }) => {
    const { t } = useTranslation();
    const { initializeModal } = useModal();

    // Handle date navigation and fetch corresponding appointments
    const handleNavigate = (direction: 'prev' | 'next' | 'date', date?: Date) => {
        let updatedView = view;

        // Handle next / prev buttons
        if (direction === 'prev' || direction === 'next') {
            updatedView = navigatePeriod(view, direction);

        // Handle date selection
        } else if (direction === 'date' && date) {
            updatedView = { ...view, displayDate: date };
        }

        // If the view is changed, update the states
        if (updatedView !== view) {
            // Update the view
            onViewChange(updatedView);
            
            // Generate new days for the updated view
            const newDays = generateDisplayDays(updatedView);
            onSetDays(newDays);
        }
    };

    // Handle create new job
    const handleCreateNewJob = async () => {
        const postJob = async () => {
            try {
                // Send the request to the server
                const response = await saveData({ apiUrl: 'post_job', method: 'post', data: {} });

                // Return the id of the newly created item
                if (response && response.status == 201) {
                    return response.data.id;

                // Throw error when creation failed
                } else {
                    throw new Error(`failed to post job`);
                }
            } catch (error) {
                console.log(`error on post job`, error);
                return null;
            }
        };

        // Call the create item function
        const newJobId = await postJob();

        // Open the modal of the newly created job
        if (newJobId) {

            // Open the modal directly
            initializeModal(
                React.cloneElement(<JobModal />, { newJobId }), { 
                    // Set the item id of the item to view
                    itemId: newJobId, 

                    // Set the modalSize to large for detail modals
                    modalSize: 'large'
                }
            );
        }
    }

    return (
        <div className='container scheduling-header'>
            <div className='content-left'>
                <PrimaryButton
                    onClick={() => handleCreateNewJob()}
                    onlyViewRestriction={true}
                    label={t('button.plus_add_object_label', { object_name: t(`job.general.object_name.singular`) })}
                    size="small"
                />
            </div>
            <div className='content-middle'></div>
            <div className='content-right button-container'>
                <TabsButton
                    buttons={[
                        { label: 'Dag', tabValue: 'day' },
                        { label: 'Week', tabValue: 'week' },
                    ]}
                    currentTabValue={view.isDayView ? 'day' : 'week'}
                    onTabsClick={(value) => onViewChange({ ...view, isDayView: value === 'day' ? true : false })}
                    size="small"
                />
                <DateNavigationButton
                    currentDate={days} 
                    isDayView={view.isDayView}
                    onNavigate={handleNavigate}
                />
                <IconButton
                    onClick={() => {onViewChange({ ...view, direction: view.direction === 'columns' ? 'stacked' : 'columns' })}}
                    icon={<FontAwesomeIcon icon={faTablePivot} />}
                    tooltipText='scheduling.scheduling_header.change_view_direction_tooltip'
                    size="small" 
                />
                <DropdownButton
                    label={view.viewType === 'employee_list' ? 'scheduling.scheduling_header.list_view_label' : view.viewType === 'employee_timeline' ? 'scheduling.scheduling_header.timeline_view_label' : 'Change view'}
                    activeItem={view.viewType === 'employee_list' ? 'scheduling.scheduling_header.list_view_label' : view.viewType === 'employee_timeline' ? 'scheduling.scheduling_header.timeline_view_label' : ''}
                    size="small"
                    items={[
                        {
                            label: 'scheduling.scheduling_header.list_view_label', 
                            onClick: () => {
                                    onViewChange({ ...view, viewType: 'employee_list' });
                                    onSetRenderTimeSlots('employee_list')
                            }
                        },
                        { 
                            label: 'scheduling.scheduling_header.timeline_view_label', 
                            onClick: () => {
                                onViewChange({ ...view, viewType: 'employee_timeline' });
                                onSetRenderTimeSlots('employee_timeline')
                            }
                        } 
                    ]} 
                />
                {/* <IconButton
                    onClick={() => initializeModal(React.cloneElement(<CustomizeSchedulingModal jobId={3} setLoading={() => {}} />), { modalSize: 'medium' })}
                    icon={<FontAwesomeIcon icon={faWrench} />}
                    tooltipText='scheduling.scheduling_header.customize_button_tooltip'
                    size="small" /> */}
                <IconButton
                    onClick={() => {onSetShowInbox(!showInbox)}}
                    icon={<FontAwesomeIcon icon={faInbox} />}
                    label='Planlijst'
                    isActive={showInbox}
                    size="small" 
                />
            </div>
        </div>
    )
}

export default SchedulingNavigation;